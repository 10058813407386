import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../_services/data.service';
import {RoundProgressModule} from 'angular-svg-round-progressbar'; 
import { timer } from 'rxjs';
import { Ubicacion } from "../models/ubicacion";
import { Ubitotal} from "../models/ubitotales";
import { NgbDateStruct, NgbCalendar, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';

import { TokenStorageService } from '../_services/token-storage.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertService } from '../alert.service';
import { Stream } from 'stream';
import { JsonPipe } from '@angular/common';
import { UserService } from '../_services/user.service';
import { RecursiveAstVisitor, ThrowStmt } from '@angular/compiler';
import { threadId } from 'worker_threads';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AnyMxRecord } from 'dns';
 
@Component({
  selector: 'app-veravisos',
  templateUrl: './veravisos.component.html',
  styleUrls: ['./veravisos.component.css']
})
export class VeravisosComponent implements OnInit {

  constructor(private dataservice :DataService,private token: TokenStorageService,private alertService: AlertService,private userService: UserService,private calendar: NgbCalendar) { }

  userid:number;
  tienda:number;
  currentUser:any;
  todastareas:any[];

  tareasactivas:any[];
  tareasproximas:any[];
  tareasenproceso:any[];
  todosmover:any[];
  todostienda:any[];
  porpedir:any[];
  pormover:any[];
  pordevolver:any[];
  ubicaciones: Ubicacion[];
  porpedirproceso:any[];
  pordemoverrproceso:any[];
  proveedoresconavisos:any[];
  pedidosproveedores:any[];
  tiendas:any[];
  articulospedidop:any[];
  proveedores_productosagotados:any[];


  avisosenviar:any[];
  avisosmover:any[];
  avisospedir:any[];  
  avisosrecibirfalta:any[];
  avisosenviarglobal:any[];
  avisosrecibirfaltaglobal:any[];
verocultos:boolean;
  ngOnInit(): void {

    this.verocultos=false;
    this.currentUser= this.token.getUser();
    this.userid=this.currentUser.id;
    this.tienda=this.currentUser.tienda;
    this.tiendas=[];  
    this.ubicaciones=[];
  
    this.sacartiendas();
    this.sacartodas();

    this.llenaravisos();
  }
 

  llenaravisos(){

    this.tareasactivas=[];
    this.tareasproximas=[];
    this.tareasenproceso=[];
    this.pordevolver=[];
    this.porpedirproceso=[];
    this.porpedir=[];
    this.todostienda=[];
    this.todosmover=[];
    this.pormover=[];
    this.pordemoverrproceso=[];
    this.proveedores_productosagotados=[];
    this.pedidosproveedores=[];
    this.articulospedidop=[1];
    this.avisosenviar=[];
    this.avisosmover=[];
    this.avisospedir=[];
    this.avisosrecibirfalta=[];
    this.avisosenviarglobal=[];
    this.avisosrecibirfaltaglobal=[];

    this.dataservice.avisos_productos_tienda(""+this.tienda).subscribe(res => {

      
      this.todostienda=res;

      this.separarproductostienda();

    },
    err => console.log(err)
    );
    this.dataservice.avisos_mover().subscribe(res => {

      
      this.todosmover=res;
      this.separarproductosmover();

    },
    err => console.log(err)
    ); 

    this.dataservice.tareas_user( ""+this.userid, ""+this.tienda).subscribe(res => {

      this.todastareas=res;
      this.separartareas();
    },
    err => console.log(err)
    );




    this.dataservice.avisos_proveedor().subscribe(res => {

      
      this.proveedoresconavisos=res;
      this.sacarproveeodres()
    },
    err => console.log(err)
    );




  }
  convertirfecha(fechasql:any, hora:string){ 


    let fecha =new Date(fechasql)
    let tiempoTranscurrido = Date.now();
    let hoy = new Date(tiempoTranscurrido);


    if(fecha.getTime()>hoy.getTime()){

      return false;
    
    }else{


      return true;
    }
 

  }

  formatearfecha(fecha2:any){

   
    let fecha=new Date(fecha2)
    let horas=""+fecha.getHours();
    let minutos=""+fecha.getMinutes();
    
    if(parseInt(horas)<10)horas="0"+horas;
    if(parseInt(minutos)<10)minutos="0"+minutos;

   return ""+fecha.getUTCDate()+"/"+(fecha.getUTCMonth()+1)+"/"+fecha.getUTCFullYear()+" A las "+horas+":"+minutos


  }

  separarproductostienda(){

   
    for (var i=0; i<this.todostienda.length; i++){

      if(this.todostienda[i].tipo=="Reponer"){

        if(this.todostienda[i].estado=="activo"){
         
          this.porpedir.push(this.todostienda[i])
     
      
        }else{
        
          if(this.todostienda[i].estado=="Proceso"){
     
            this.porpedirproceso.push(this.todostienda[i])

          }
        }

        
      }
      if(this.todostienda[i].tipo=="Devolver" && this.todostienda[i].estado=="activo" && this.comparfecha(this.todostienda[i].fecha)){

        this.pordevolver.push(this.todostienda[i])
      }
    }


  }

  comparfecha(fecha:any){

    var d1 = new Date();
    var d2 = new Date(fecha);

    if(d1 > d2){
      return true;
      }

    return false; 
  }



  separarproductosmover(){

    this.avisosmover=[];
    this.avisosenviar=[];
    this.avisosrecibirfaltaglobal=[];
    this.avisosrecibirfalta=[];
    this.avisospedir=[];
    this.porpedirproceso=[];


    for (var i=0; i<this.todosmover.length; i++){




      //mover
      if(this.todosmover[i].tipo=="Mover" && this.todosmover[i].tienda==this.tienda ){

        this.avisosmover.push(this.todosmover[i])

      }
 

      if( (this.todosmover[i].tipo=="MoverDeTienda" || this.todosmover[i].tipo=="MoverDeTiendaglobal" ) && this.todosmover[i].tienda==this.tienda ){

        this.avisosenviar.push(this.todosmover[i])

      }  

      if(this.todosmover[i].tipo=="MoverDeTiendaglobal"  && this.todosmover[i].usuario_destino==this.tienda ){

        this.avisosrecibirfaltaglobal.push(this.todosmover[i])

      }  
      


      
      if( this.todosmover[i].tipo=="MoverDeTienda" && this.todosmover[i].usuario_destino==this.tienda ){

        this.avisosrecibirfalta.push(this.todosmover[i])

      }  
      if(this.todosmover[i].tipo=="Pedir" && this.todosmover[i].tienda==this.tienda && this.todosmover[i].estado=="activo"){

        this.avisospedir.push(this.todosmover[i])

      }
     
      if(this.todosmover[i].tipo=="Pedir" && this.todosmover[i].tienda==this.tienda && this.todosmover[i].estado=="Proceso"){

        this.porpedirproceso.push(this.todosmover[i])

      }

   }


  }
  separartareas(){
  


    // Tipos: AvTienda, AvUser, Tarea
    //estado: Activo, En_proceso, Terminada

    //separar activas segun la fecha  Actuales > Ahora > Futuras
    for (var i=0; i<this.todastareas.length; i++){

      if(this.todastareas[i].estado=="activo"){

        if(this.convertirfecha(this.todastareas[i].fecha,this.todastareas[i].hora)){

          this.tareasactivas.push(this.todastareas[i]);
         
      
        }else{
          this.tareasproximas.push(this.todastareas[i]);
        
        }

      }
      
      if(this.todastareas[i].estado=="Proceso"){

        this.tareasenproceso.push(this.todastareas[i]);
        
      }

    }

  }


  ponerproceso(tarea:any){

    if(confirm("Poner la tarea en la lista de tareas en proceso?")) {

      tarea.estado="Proceso";

    this.dataservice.modificaviso(""+tarea.id,tarea).subscribe(res => {

    this.llenaravisos();

   },
   err => console.log(err)
 );
  }
  }
  ponerpedido(producto:any){

    if(confirm("El producto ya a sido pedido al proveedor?")) {

      producto.estado="Proceso";

    this.dataservice.modificaviso(""+producto.id,producto).subscribe(res => {

    this.llenaravisos();

   },
   err => console.log(err)
 );
  }
  }
  ponerterminada(tarea:any){
  
    if(confirm("Completar tarea?")) {

    tarea.estado="terminada";

    this.dataservice.modificaviso(""+tarea.id,tarea).subscribe(res => {
  
      this.llenaravisos();
  
     },
     err => console.log(err)
   );
  }
    
  }
  borraraviso(tarea:any){
    if(confirm("Estas seguro?")) {
          tarea.estado="Borrado";

      this.dataservice.modificaviso(""+tarea.id,tarea).subscribe(res => {

        this.llenaravisos();

      },
      err => console.log(err)
          );

  }
}  

borraravisod(tarea:any){
  if(confirm("ATENCION!!, TAMBIEN SE BORRARAN LOS AVISOS ASOCIADOS A ESTE")) {
        tarea.estado="Borrado";

    this.dataservice.borraravisoshijos(""+tarea.vienede).subscribe(res => {

      this.llenaravisos();
    
    },
    err => console.log(err)
        );

        let aviso={id:tarea.vienede, estado:"activo"};
        console.log("modificando tarea: "+JSON.stringify(tarea))
      this.dataservice.modificaviso(""+tarea.vienede,aviso).subscribe(res => {

          this.llenaravisos();
  
        },
        err => console.log(err)
            );
}
}


  postponer(tarea:any){

    if(confirm("La tarea se postpondrá hasta mañana a esta hora")) {
    let hoy=new Date();
    let DIA_EN_MILISEGUNDOS = 24 * 60 * 60 * 1000;
    let manana = new Date(hoy.getTime() + DIA_EN_MILISEGUNDOS);

    tarea.fecha=manana;
    tarea.estado="activo";

    this.dataservice.modificaviso(""+tarea.id,tarea).subscribe(res => {
  
      this.llenaravisos();
  
     },
     err => console.log(err)
   );

    }
  }
  sacarproveeodres(){

    this.pedidosproveedores=[];
    
    console.log("paso 1 "+JSON.stringify(this.proveedoresconavisos));

    for (var i=0; i<this.proveedoresconavisos.length; i++){

      this.comprovarproveedor(this.proveedoresconavisos[i].id)
      
    }
    console.log("paso 2 "+JSON.stringify(this.proveedores_productosagotados));
        this.sacaravisosproveedor();

  }
  sacaravisosproveedor(){

   let totaleuros=0;
    let totalavio=0;
    for (var i=0; i<this.proveedores_productosagotados.length; i++){

      totaleuros=this.sumartotal(this.proveedores_productosagotados[i])

      if(totaleuros>totalavio){
        
        this.pedidosproveedores.push(this.proveedores_productosagotados[i])
        //añadir a lista de avisos
      }

    }

  }
  sumartotal(prp:any){

    console.log("any "+JSON.stringify(prp))
    let total=0;
    let lista=prp[1];
  for (var i=0; i<lista.length; i++){

    total=total+lista[i].precio;
  }

    return total;
  }
async comprovarproveedor(id:string){

  let productosenproveedor:any;
  let productosagotados:any;
  console.log("paso 3 -id:"+id);
   
 await this.dataservice.getproductosprove2(id).subscribe(res => {
    
    productosenproveedor=res;
    let totalp=0;

    for (var i=0; i<productosenproveedor.length; i++){

        totalp=this.sacartotal(productosenproveedor[i].prodyctoId);
        if(totalp<1)productosagotados.push(productosenproveedor[i]);

    }

    this.proveedores_productosagotados.push({id,productosagotados});

  },
  err => console.log(err)
  );
    
}

sacartotal(id:string){
  console.log("paso 4 -id:"+id);
  this.dataservice.getubi_product_producto(id).subscribe(res => {

    console.log("paso 5 -total:"+JSON.stringify(res));
    return res;
 
  },
    err => console.log(err)
  );
    return 0
}

  nombreubicacion(id:string){

    let devo="";
    const j = this.ubicaciones.findIndex( fruta => fruta.id === parseInt(id));
    if(j!=-1){
      if(this.ubicaciones[j].Padre!=null){
    
        const k = this.ubicaciones.findIndex( fruta => fruta.id === this.ubicaciones[j].Padre);
        devo=this.ubicaciones[k].Nombre+" / "+this.ubicaciones[j].Nombre;
    
      }else{
    
        devo= this.nombretienda(this.ubicaciones[j].tienda)+": "+this.ubicaciones[j].Nombre;
      }

    } 
    return devo;

  }
  sacartodas(){

    this.dataservice.gettodasubi("0").subscribe(res => {
  
     
        this.ubicaciones=res;
 
       
      },
      err => console.log(err)
    );
    
 }

 nombretienda(id:number){

  let nombre="ERROR"


 
  const i = this.tiendas.findIndex( fruta => fruta.id === id );

 

  if(i!=-1){
  
   nombre=this.tiendas[i].name;
 

  }

  return nombre 
 
}


sacartiendas(){


  
  this.dataservice.sacartiendas().subscribe(
    res => {

      this.tiendas = res;
      

    },
    err => {
  
    }
  );

}
}
