<h2 style="color:red" > *** Ubicar productos por Barra *** </h2>

<div *ngIf="modo==null" >
    <button class="btn btn-info btn-block" (click)="modo=1">Stockar</button>
    <button class="btn btn-info btn-block" (click)="modo=2">Mover</button>
    <button class="btn btn-info btn-block" (click)="modo=3">Reestockar</button>
</div>

<div *ngIf="modo!=null" class="container"> 
    <br/> 
    <h2 *ngIf="modo==1" style="color:rgb(138, 8, 8)" > AÑADIENDO STOCK</h2>
    <h2 *ngIf="modo==2" style="color:rgb(138, 8, 8)" > MOVIENDO STOCK</h2>
    <h2 *ngIf="modo==3" style="color:rgb(138, 8, 8)" > REESTOCAKNDO<br>(Se borrara el stock anterior) </h2>
    <br>
  <div *ngIf="hayresultados" >
    <div *ngIf="!modificando"  >
  
  
      <h2 *ngIf="nombre"> UBICACION -->{{nombre}}</h2>
  <div class="row" style="margin-bottom: 20px;">
    <div>
  
      <input class="form-check-input" type="checkbox" [(ngModel)]="conbarra" (change)="cambiart()"  id="flexCheckDefault">
      <label class="form-check-label" for="flexCheckDefault">
        BUSCAR POR CODIGO DE BARRAS
      </label>
    </div> 
  
    <div style="margin-left: 30px;">
      <input class="form-check-input" type="checkbox" [(ngModel)]="concodigo" (change)="cambiard()"  id="flexCheckDefault">
      <label class="form-check-label" for="flexCheckDefault">
        BUSCAR POR CODIGO INTERNO
      </label>
        
    </div >
   
  
  
  </div>
  
  
  <alert></alert>
   <div class="col-md-4 offset-md-2">
      <div class="card"  *ngIf="!cargando">
        <div class="card-body">
          <form action="">
            <div *ngIf="!edit" class="form-group">
              <input type="text" id=barr name="title" [(ngModel)]="barra" placeholder="Codigo de Barras" class="form-control" autofocus>
               
            </div>
            
            <button class="btn btn-success btn-block" (click)="cargar()" style="display: none">
              Save 
            </button>
          </form>
        </div>
      </div>
  
    </div>
  
  </div>
  
  <div *ngIf="cargando">
    <div class="col-md-4 offset-md-2">
    <div class="card card-body text-center">
      
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
  
    </div>
  </div>
    </div>
  </div> 
  
  
    
    <div *ngIf="!modificando">
      <div *ngIf="!cargando">
  
  <div *ngIf="inicio==1 && (modo==1 || modo==3)">
    <div class="col-md-4 offset-md-2">
  
  
      <div class="card card-body text-center">
  
          <h3 id="nomb">{{ producto.Nombre}}</h3>
          <h3 style="color: red;" >Precio: {{producto.Precio | sumariva:producto.Iva }}€</h3>
          
          <a class="btn btn-success btn-sm btn-block"  (click)="aceptar()" style="margin-top:10px">aceptar</a>
  
          <div class="input-group mb-3" Style="margin-top: 10px;">
        
        <div class="input-group-prepend">
          <button type="button" class="btn btn-danger" (click)="menos(1)" >-</button>
      
        </div>
    
        
        <input *ngIf="editc" type="number"  class="form-control" name="cantidad" [(ngModel)]="cantidad" >
        <input *ngIf="!editc" type="number"  class="form-control" name="cantidad" [(ngModel)]="cantidad"  readonly>
        <div class="input-group-append">
  
          <button type="button" class="btn btn-success" (click)="mas(1)">+</button>
        </div>
  
      </div>
          
      <div class="row justify-content-center" >
  
        <button type="button" class="btn btn-danger" style="margin-right:10px" (click)="menos(100)">-100</button>
        <button type="button" class="btn btn-danger" style="margin-right:10px" (click)="menos(10)">-10</button>
        <button type="button" class="btn btn-warning" style="margin-right:10px" (click)="editc=!editc"><i class="fa-solid fa-pencil"></i></button>
  
        <button type="button" class="btn btn-success" style="margin-right:10px" (click)="mas(10)">+10</button>
        <button type="button" class="btn btn-success" style="margin-right:10px" (click)="mas(100)">+100</button>
  
      </div>
      <a class="btn btn-primary btn-sm btn-block" style="margin-top:10px"  (click)="cancelar()">cancelar</a>
      
  
      </div>
    </div>
  </div>
  
  </div>
  <div *ngIf="inicio==2 && (modo==1 || modo==3)">
      <div class="col-md-4 offset-md-2">
          <div class="card card-body text-center">
              <div class="alert alert-danger" role="alert">
                 EL ARTICULO NO ESTA EN LA BASE DE DATOS
                </div>
      
          </div>
      </div>
  </div>


   <!--  MOVIMIENTOS  -->

 <div *ngIf="inicio==1 && modo==2">
    <div class="col-md-12 ">
        <div class="card card-body text-center">
            <h3 id="nomb">{{ producto.Nombre}}</h3>
            <h3 style="color: red;" >Precio: {{producto.Precio | sumariva:producto.Iva }}€</h3>
            
            <a class="btn btn-success btn-sm"  (click)="subir()" style="margin-top:10px">aceptar</a>
 
        <div style="margin-top:10px" >
 
             ** MAXIMO {{antigua.cantidad}} **
             <div class="input-group mb-3" Style="margin-top: 10px;">
               <button type="button" class="btn btn-danger"  style="background-color: rgb(109, 2, 2);"  (click)="menos(100)" >-100</button>
               <button type="button" class="btn btn-danger"  style="background-color: rgb(206, 31, 31);"  (click)="menos(10)" >-10</button>
               <div class="input-group-prepend">
 
             
                 <button type="button" class="btn btn-danger" (click)="menos(1)" >-</button>
             
               </div>
         
               <input type="number"  class="form-control" name="cantidad" [(ngModel)]="cantidadelegida"  readonly>
               <div class="input-group-append">
         
                 <button type="button" class="btn btn-success"  (click)="mas(1)">+</button>
 
               </div>
               <button type="button" class="btn btn-success" style="background-color: rgb(26, 184, 47);" (click)="mas(10)">+10</button>
               <button type="button" class="btn btn-success"  style="background-color: rgb(0, 110, 6);"  (click)="mas(100)">+100</button>
             </div>
             
           
        </div>
 
            <h3>Estaba en:</h3>
 
            <div *ngFor="let ubi of todosantiguas">
 
             
             <a class="btn btn-primary btn-sm btn-block " *ngIf="ubi==antigua" Style="margin-bottom: 10px;margin-top:10px; ">{{sacarnombre(ubi.ubicacionesId)}}:{{ubi.cantidad}} </a>
             <a class="btn btn-secondary btn-sm btn-block" *ngIf="ubi!=antigua" Style="margin-bottom: 2px;" (click)="cambiar(ubi)">{{sacarnombre(ubi.ubicacionesId)}}:{{ubi.cantidad}} </a>
 
         </div>
        <a class="btn btn-danger btn-sm btn-block"  (click)="cancelar()">cancelar</a>
        
    
        </div>
      </div>
    </div>
  
  <div *ngIf="inicio==2 && modo==2">
      <div class="col-md-4 offset-md-2">
          <div class="card card-body text-center">
              <div class="alert alert-danger" role="alert">
                 EL ARTICULO NO ESTA EN LA BASE DE DATOS
                </div>
      
          </div>
      </div>
  </div>
  
  
   <!--  MOVIMIENTOS  -->
  <div *ngIf="pendientes>0">
    <div class="col-md-4 offset-md-2">
      <div class="card card-body text-center">
        <div class="alert alert-warning" role="alert">
          Operaciones Pendientes:{{pendientes}}
         </div>
  
   </div>
  </div>
  
  <div>
      
     <!--  <p>{{ user.email }}</p> -->
  </div>
  
  
  
  </div>
  <div class="row" style="margin-bottom: 20px;">
    <div>
  
      <input class="form-check-input" type="checkbox" [(ngModel)]="sweb"  id="flexCheckDefault3">
      <label class="form-check-label" for="flexCheckDefault">
        SUBIR A LA WEB
      </label>
    </div>
  </div>
   <!--  CATEGORIAS  -->
  <div  class="row" style="margin-top: 15px;" >
    <div class="col-md-3" ><h4>Categorizar producto:</h4></div>
  
      <div  class="col-md-4 " style="margin-top: 15px; margin-left: 30px;" >
     
        <select name="miselect" id="miselect" style="width: 250px;" [(ngModel)]="ubiselec" (change)="onChange($event.target.value)">
  
          <option [value]="item.id" *ngFor="let item of categoriaspornivel(0)">{{item.nombre}}</option>
  
        </select>
           
      </div>
  
      <div  *ngIf="nivelcate>0" class="col-md-4 " style="margin-top: 15px; margin-left: 30px;" >
        <select name="miselect" id="miselect" style="width: 250px;" [(ngModel)]="ubiselec" (change)="onChange($event.target.value)">
  
          <option [value]="item.id" *ngFor="let item of categoriaspornivel(1)">{{item.nombre}}</option>
  
        </select>
        <div  *ngIf="nivelcate>1" class="col-md-4 " style="margin-top: 15px; margin-left: 30px;" >
          <select name="miselect" id="miselect" style="width: 250px;" [(ngModel)]="ubiselec" (change)="onChange($event.target.value)">
    
            <option [value]="item.id" *ngFor="let item of categoriaspornivel(2)">{{item.nombre}}</option>
    
          </select>
      </div>
    </div>
  
  
  
   
  <div  class="row" style="margin-top: 50px;" [style.background-color]="getColorfondo()">
  
    <div *ngFor="let ub5i of categoriasaponer">
       
        <p><span class="badge badge-primary" >{{nombrecategoria(ub5i)}}</span><button class="btn btn-sm " (click)="borrarcate(ub5i)" ><i class="fas fa-times"></i></button> </p>
  
    </div>
    <p  class="col-md-12 " style="text-align: center;">*** ATENCION: Se borraran las categorias anteriores y se añadiran las seleccionadas ***</p>
  
  </div>
   <!-- FIN  CATEGORIAS  -->
  
  
  
  <h1 *ngIf="!hayresultados" style="margin-top: 20px;">Es necesario crear ubicaciones antes de ubicar los productos</h1>
  <button class="btn btn-info btn-block" (click)="cambiarmodo()">CAMBIAR MODO</button>
  </div>