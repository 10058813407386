<h5 style="margin-top:20px">ALbaranes de entrada</h5>



  <table>
    <tr>
    <td>
      <button *ngIf="tipo!='0'"  type="button" class="btn btn-secondary"  (click)="todos()" style="margin-bottom: 30px;" > TODOS </button>
      <button *ngIf="tipo=='0'"  type="button" class="btn btn-success"  (click)="todos()" style="margin-bottom: 30px;" > TODOS </button>
      
    </td>
    <td>
      <button *ngIf="tipo!='LD'"  type="button" class="btn btn-secondary" (click)="solodevos()" style="margin-bottom: 30px;;" > SOLO DEVOLUCIONES </button>
      <button *ngIf="tipo=='LD'"  type="button" class="btn btn-success" (click)="solodevos()" style="margin-bottom: 30px;" > SOLO DEVOLUCIONES </button>
    </td>
    

    <td>
      <button *ngIf="tipo!='LF'"  type="button" class="btn btn-secondary" (click)="sololibros()"  style="margin-bottom: 30px;" >SOLO ENTRADAS DE LIBROS</button>
      <button *ngIf="tipo=='LF'"  type="button" class="btn btn-success" (click)="sololibros()"  style="margin-bottom: 30px;" >SOLO ENTRADAS DE LIBROS</button>
   </td>
    <td >
      <input style="margin-left: 30px;"  id="filt"  list="datalist1"  [(ngModel)]="ids" type="search" (change)="selected(ids)" aria-label="Search" />
      <datalist id="datalist1">
        <option *ngFor="let re of proveedores" [value]="sacarproveedor(re.codigo)">
    
        </option>
      </datalist>
      <button *ngIf="cod=='0'" class="btn btn-success" (click)="filtrar(1,null)" style="margin-left: 10px;" >Filtrar porveedor</button>
      <button *ngIf="cod!='0'"  class="btn btn-secondary" (click)="filtrar(2,null)" style="margin-left: 10px;" >quitar filtro</button>
    </td>
    </tr>
    </table>

 








<!-- Date Filter -->
<form id="formFecha">
  <table>
  <tr>
  <td>DESDE:
  <input type='date' id="finicio"  placeholder='Fecha Inicio'class="form-control form-control-sm">
  </td>
  <td>HASTA:
  <input type='date' id="ffin"  placeholder='Fecha fin' class="form-control form-control-sm">
  </td>
  <td>
  <input type='button' id="btn_search" value="filtrar" class="btn btn-primary btn-sm"  style="margin-top: 23px;"(click)="ffecha(1)"> 
  </td>
  <td>
  <input *ngIf="fecha!='0'" type='button' id="btnLimpiar" value="Limpiar" class="btn btn-danger btn-sm" style="margin-top: 23px;" (click)="ffecha(2)">
  </td>
  </tr>
  </table>
  </form>
  <hr>
  <!-- Table -->


<ngb-pagination *ngIf="total>15" [collectionSize]="total" [maxSize]="10" [pageSize]="15"  [(page)]="page" (pageChange)="loadPage($event)"  style="margin-top:30px;"></ngb-pagination>
         
<table class="table">

  <tr>

    


    <td>Fecha</td>
    <td>Nº Albaran</td>
    <td>Proveedor</td>
    <td>Tipo</td>
    <td>Nºde Articulos / importe Neto</td>
   


 
  </tr>

 
    <tr *ngFor="let re of movi" >

    
     

      <td *ngIf="re.proveedor!=null">{{re.fecha | date: 'dd'}}/{{re.fecha | date:'MM'}}/{{re.fecha | date: 'yyyy'}}</td>
      <td *ngIf="re.proveedor!=null">{{re.documento}}</td>
      <td *ngIf="re.proveedor!=null" ><button type="button" class="btn btn-link" (click)="filtrar(3,re)">{{sacarproveedor(re.proveedor)}}</button></td>
      <td *ngIf="re.proveedor!=null" >{{re.tipo}}</td>
      <td *ngIf="re.proveedor!=null">{{ totalde(re.documento)}}</td>
      <td *ngIf="re.proveedor!=null" ><button type="button" class="btn btn-info" [routerLink]="['../dalbaran/',re.documento+'-'+re.proveedor]">Ver</button></td>
     
  
 
   
    </tr>



   

   
    </table>