<h1 style="text-align: center; margin-top: 20px;">Gestionar proveedores</h1>
<div class="container-fluid">
    <div *ngIf="!nueva"  >
      <div class="input-group">
        <div class="card-body d-flex justify-content-between align-items-center">
        <form action="" >
        <input class="form-control col-12"  id="filt"  type="search" placeholder="filtrar" aria-label="Search">
        <button class="btn btn-success btn-block" (click)="filtrar()" style="display: none"></button>
        </form>
        <a *ngIf="verrol()" class="btn btn-primary btn-lg " style="background-color: green; margin-top: 15px; margin-bottom: 15px;" (click)="nuevau()">+ Nuevo Proveedor +</a>
      </div>
    </div>
  
    </div>
      <header class="jumbotron" *ngIf="nueva">
    
        <div *ngIf="nueva" class="card">
          <div class="card-body"> 
            <h2 Style="margin-top: 20px;" *ngIf="editando">Editar Proveedor: </h2>
            <h2 Style="margin-top: 20px;" *ngIf="!editando">Nuevo Proveedor</h2>
            <form action="">
              <div  class="form-group">
            
                <input type="text" class="form-control"  [ngModel]="Nombrenueva"  [ngModelOptions]="{standalone: true}" id="nombre" placeholder="Nombre">
                <h3 Style="margin-top: 20px;">Tipo de pago</h3>
                <div  class="row">  

                    <div class="col-md-4" >
                      
    
                        <div class="form-check">

                            <input class="form-check-input" type="radio"  name="exampleRadios" id="exampleRadios1" value="option1"(change)="checkValue()"  checked>
                            <label class="form-check-label" for="exampleRadios1">
                            Proforma
                            </label>

                            <input class="form-check-input" type="radio"   name="exampleRadios" id="exampleRadios2" value="option2" (change)="checkValue()"  Style="margin-left: 40px;">
                            <label class="form-check-label" for="exampleRadios2"  Style="margin-left: 60px;">
                            Giro
                            </label>

                        </div>
                    </div>
                    <div class="col-md-8" *ngIf="isChecked">
                    
                        <input type="number" class="form-control"   [ngModel]='diass'  [ngModelOptions]="{standalone: true}" id="dias" style="width: 80px; "> <p>Dias</p>
                    </div>
                </div> 
                <h3 Style="margin-top: 20px;">Otros datos</h3>
                <div  class="row">  
                    <div class="col-md-3" >       <input type="number" class="form-control"   id="codigo" placeholder="Codigo Interno"></div>
                    <div class="col-md-3" >       <input type="number" class="form-control"    id="envio" placeholder="Tiempo de Envio"></div>
                    <div class="col-md-6" >       <input type="text" class="form-control"    id="direccion" placeholder="Direccion"></div>
             
                </div>
              </div>
   
              <button class="btn btn-lg btn-success" (click)="crear()">
                Salvar
              </button>
              <button class="btn btn-danger" Style="margin-left: 50px; " (click)="cancelar()">
                Cancelar
              </button>
  
              
            </form>
          </div> 
          <alert></alert>
        </div>
        
      </header>
    
    
    <div *ngIf="!nueva" >
      <ngb-pagination *ngIf="total>20" [collectionSize]="total" [maxSize]="20" [(page)]="page"
        (pageChange)="loadPage($event)"></ngb-pagination>
             <div  *ngFor="let ubi of  catepadres">
                    <div  class="row">  
                        <div class="col-md-8" >
                
                                    <button class="btn-group  btn-dark btn-block">

                                        <button  class="btn btn-dark" (click)="editar(ubi.id)" style="text-align: left;">  {{ubi.nombre}} 
                                            
                                           <a  [routerLink]="['../dproveedor/',ubi.id]"   style="margin-left: 20px;">
                                                <i class="fas fa-eye">  {{toti(ubi.id)}}</i>
                                            </a>
                                      
                                        </button>
                                   
                                        <button class="btn btn btn-danger"  *ngIf="!(toti(ubi.id)>0) && verrol()" (click)="borrar(ubi.id)" style="margin-left: 30px;"><i class="fas fa-trash-alt"></i></button>
                            
                                    </button> 
                                  
                                    
                        </div>  
                  
                  
                
                    </div>
                         
                    
            </div>
                     
    </div>
         

      
   
       
 
           
         
    
</div>  