import { Component, OnInit, Input, Output} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Ubicacion } from "../models/ubicacion";
import { Ubi_product } from "../models/ubi_product";
import { createUrlResolverWithoutPackagePrefix } from '@angular/compiler';
import { Router } from '@angular/router';
import { DataService } from '../_services/data.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { EventEmitter } from  '@angular/core';
import { threadId } from 'worker_threads';


@Component({
  selector: 'app-categoriahijo',
  templateUrl: './categoriahijo.component.html', 
  styleUrls: ['./categoriahijo.component.css']
})
export class CategoriahijoComponent implements OnInit {
  
  @Input() idp:number;
  @Input() nivel:number;
  @Output() notify: EventEmitter<string> = new EventEmitter<string>();


  constructor(private dataservice :DataService,private token: TokenStorageService) { }
  categoriashijo:any[];
  idpadre:number;
  currentUser : any;
  idhijoseleccionado:number;
  hijomostrado:number;
  margen:number;
  hijospadres:string[];
  seleccionado:string[];
  totarti:any;

ngOnInit(): void {

   
    this.hijospadres=[] 
    this.totarti=[];
    this.nivel=this.nivel+1;
    this.margen=15*(this.nivel+1);
    this.currentUser = this.token.getUser();
    console.log("user:"+ JSON.stringify(this.currentUser))
    this.hijomostrado=-1;
    this.llenardatos()

    this.idhijoseleccionado=-1;
  }
 
verrol(){


if(this.currentUser.roles=="ROLE_ADMIN")return true;

return false;
}

totalarticulos(){

  for(let i=0; i<  this.categoriashijo.length;i++){

    this.dataservice.totastockcate(""+this.categoriashijo[i].id).subscribe(res => {
      
      let d={
        
        id:this.categoriashijo[i].id,
        total:res
        
  
      };
    
      this.totarti.push(d);
  
  
      
    },
    err => console.log(err) 
    );

  }

  
}

llenardatos(){


    this.dataservice.sacarcatehijos(""+this.idp).subscribe(res => {

     console.log(res);
     this.categoriashijo=res;
     this.totalarticulos();
     this.sabersihayhijo();
   },
   err => console.log(err)
 );

}

recibirhijo(idhijo:string){

      this.pasarid(idhijo);
}

pasarid(idhijo:string){

      this.notify.emit(idhijo);

}

toti(id:number){



  const i = this.totarti.findIndex( fruta => fruta.id === id );


  if(i!=-1){
   
    return this.totarti[i].total;

  }else{

    return 0;
  }



}

tienehijos(id:string){

  const i = this.hijospadres.findIndex( element => element == id );


  if(i!=-1){
   
    return true;

  }else{

    return false;
  }



} 


sabersihayhijo(){

  for(let i=0; i<  this.categoriashijo.length;i++){

    

    this.dataservice.sacarcatehijos(""+this.categoriashijo[i].id).subscribe(res => {

      
      if(res!=null && res[0]!=null){

        let hijogg=""+res[0].padre;
        this.hijospadres.push(hijogg);
   
      }
      
    },
    err => console.log(err)
  );


  }
}

}