import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../_services/data.service';
import {RoundProgressModule} from 'angular-svg-round-progressbar'; 
import { timer } from 'rxjs';
import { Ubicacion } from "../models/ubicacion";
import { Ubitotal} from "../models/ubitotales";

import { TokenStorageService } from '../_services/token-storage.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertService } from '../alert.service';
import { Stream } from 'stream';
import { JsonPipe } from '@angular/common';
@Component({ 
  selector: 'app-gestor-categorias',
  templateUrl: './gestor-categorias.component.html',
  styleUrls: ['./gestor-categorias.component.css']
})
export class GestorCategoriasComponent implements OnInit {


  currentUser : any;
  nueva:boolean;
  editando:boolean;
  filtro:string="";
  catepadres:any[];
  hijospadres:string[];
  productosdentro:string[];
  Nombrenueva:string;
  Padrenuevo:number;
  ubi_totales:any[]=[];
  seleccionado :number;
  hijomostrado:number;
  modificando:any;
  categoriaacrear:any;


constructor(private dataservice :DataService,private token: TokenStorageService,private alertService: AlertService){}

 ngOnInit(){
   
  this.currentUser = this.token.getUser();
  this.hijospadres=[]; 
  this.productosdentro=[];
  this.nueva=false;
  this.editando=false;
  this.Padrenuevo=null;
  this.hijomostrado=-1;
  this.sacartodas();
}

verrol(){


  if(this.currentUser.roles=="ROLE_ADMIN")return true;
  
  return false;
  }
//*********************************************** */
//rellena la lista de ubicaciones
sacartodas(){


  this.dataservice.sacarcatepadres().subscribe(res => {

     console.log(res);
     
   
     this.catepadres=res;
     this.sacartotal()
     this.sabersihayhijo()
   

   },
   err => console.log(err)
 );

}

sabersihayhijo(){

  for(let i=0; i<  this.catepadres.length;i++){

    

    this.dataservice.sacarcatehijos(""+this.catepadres[i].id).subscribe(res => {

      
      if(res!=null && res[0]!=null){

        let hijogg=""+res[0].padre;
        this.hijospadres.push(hijogg);
   
      }
      
    },
    err => console.log(err)
  );


  this.productosdentro



  }
  
 

 


}

recibirhijo(idhijo:string){

  if(idhijo.substring(0, 1)=="n"){

    this.Padrenuevo=Number(idhijo.substring(1,idhijo.length));
    this.nuevau();

  }
  if(idhijo.substring(0, 1)=="e"){

    console.log("editando :****"+idhijo.substring(1,idhijo.length)+"****")
  }

  if(idhijo.substring(0, 1)=="b"){

    this.borrar(idhijo.substring(1,idhijo.length));
   // console.log("borrando :****"+idhijo.substring(1,idhijo.length)+"****")

  }



  



}
//*********************************************** */
//Filtra las ubicaciones segun creiterio filt
filtrar(){

}

//*********************************************** */
// saca el total de articulos en cada ubicacion 

async sacartotal(){ 
  
  let numubu=this.catepadres.length;
  
  
  for(let i=0; i<numubu;i++){
  
   
    await this.dataservice.totastockcate(this.catepadres[i].id).subscribe(res => {
      
  
      let d:Ubitotal={
        
        id:parseInt(this.catepadres[i].id),
        total:parseInt(res)
        
  
      };

      this.ubi_totales[i]=d;
  
      
    },
    err => console.log(err) 
    );
  
  
  }
  
  
}



nuevau(){

 this.nueva=true;
 

}


reset(){

  this.nueva=false;
  this.editando=false;
  this.hijospadres=[]; 
  this.Padrenuevo=null;
  this.modificando=null;
  this.sacartodas();
}

tienehijos(id:string){

  const i = this.hijospadres.findIndex( element => element == id );


  if(i!=-1){
   
    return true;

  }else{

    return false;
  }



}

//crea una nueva ubicacion
crear(){
if(!this.editando){



  this.categoriaacrear={

    nombre:(<HTMLInputElement>document.getElementById('nombre')).value,
    padre:this.Padrenuevo
  
  };


  this.dataservice.crearcate(this.categoriaacrear).subscribe(res => {
  

    this.reset();

  },
  err => console.log(err)
);


}else{


  this.categoriaacrear={
    id:this.modificando[0].id,
    nombre:(<HTMLInputElement>document.getElementById('nombre')).value,
    padre:this.modificando[0].padre
   

  };


  this.dataservice. modificarcate(this.modificando[0].id,this.categoriaacrear).subscribe(res => {
  

    this.reset();

  },
  err => console.log(err)
);

}


}


mostrarhijos(id:number){

  this.hijomostrado=id;

}

//carga los datos de la ubicacion a editar
editar(id :string){
  if(this.verrol()){


  
  this.nueva=true;
  this.editando=true;
  
  this.dataservice.buscarcate(id).subscribe(res => {
  
    this.modificando=res;
    (<HTMLInputElement>document.getElementById('nombre')).value=this.modificando[0].nombre;


  },
  err => console.log(err)
);


  }

}
nuevahija(padre:string){

  this.nueva=true;
  this.Padrenuevo=parseInt(padre);


}
//cancela la edicion 

cancelar(){

  this.reset();

}

toti(id:number){

  const i = this.ubi_totales.findIndex( fruta => fruta.id === id );


  if(i!=-1){
   
    return this.ubi_totales[i].total;

  }else{

    return 0;
  }

 
}
//actualiza una ubicacion ya existente
actualizar(){

  
}

borrar(id :string){

  if(confirm("tas seguro?")) {
  ///-comprobar si esta vacia


  this.dataservice.borrarcate(id).subscribe(res => {
  

    this.reset();

  },
  err => console.log(err)
);

 }
}
  
}
