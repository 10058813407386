import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Ubicacion } from "../models/ubicacion";
import { Mensaje } from "../models/mensaje";
import { createUrlResolverWithoutPackagePrefix } from '@angular/compiler';
import { Router } from '@angular/router';
import { Ubi_product } from "../models/ubi_product";
import { TokenStorageService } from '../_services/token-storage.service';
import { MoverComponent } from '../mover/mover.component';
import { AlertService } from '../alert.service';
import { DiscrepanciasComponent } from '../discrepancias/discrepancias.component';
import { MensajesService } from '../_services/mensajes.service';
import { DataService } from '../_services/data.service';
import { inflate } from 'zlib';


@Component({
  selector: 'app-avisos',
  templateUrl: './avisos.component.html',
  styleUrls: ['./avisos.component.css']
})
export class AvisosComponent implements OnInit {
  currentUser:any;
  avisos:any[];
  discrepancias:any[]=null;
continuar:boolean=false;
mensajesdiscrepancias:any[]=[];
  todosantiguas :Ubi_product[]=[];
  todosantiguastemp :Ubi_product[]=[];

  ubicaciones: Ubicacion[];
  barra:string="";

  mensajes:Mensaje[]=[];
  mensajesrecibidos:Mensaje[]=[];
  mensaje:Mensaje;
  movimiento:any={

    cantidad:0,
    user:0,
    productoId:0,
    destino:0,
    origen:0,
    tipo:""
  
  }

  constructor(private alertService: AlertService,private rutaActiva: ActivatedRoute, private router:Router, private dataservice :MensajesService, private token:TokenStorageService, private data:DataService) { }

  ngOnInit(): void {
/*
     this.currentUser = this.token.getUser();
     this.cargartodos();
     console.log("asdasdas"+ this.router.url);
     this.sacartodas();
     this.dataservice
     .getMessages()
     .subscribe((message: any) => {

      this.mensajes.push(message);
  
     if(message.destino==this.currentUser.id){


              if(this.router.url=="/avisos"){
          
                this.marcarleido(message); 
              } 
              
      }
            

        //  }
        if(this.router.url=="/avisos"){
          setTimeout(() => {  (<HTMLInputElement>document.getElementById('mensajes')).scrollTop=document.getElementById('mensajes').scrollHeight; }, 1000);
        }


          //}
   
       });
  }

  subir(){
    
    
     // this.dataservice.sendMessage(this.mensaje);
    //  this.mensaje = '';
    
   
    
    let destin:number;
  //  console.log("user "+JSON.stringify(this.currentUser))
      if(this.currentUser.id==1){

        destin=2;

      }else{

        destin=1;

      }

      let nuevomensaje:Mensaje={

          id:null,
          Origen:this.currentUser.id,
          Destino:destin,
          Tipo:"mensaje",
          Mensaje:this.barra,
          Estado:"enviado",

      }

      delete nuevomensaje.id;

      this.dataservice.crearmensaje(nuevomensaje).subscribe(res => {
    
     //   this.sacarmensajes();
        this.mensajes.push(nuevomensaje);
        this.barra="";
      },
      err => console.log(err)
      );
 
  }

  cargartodos(){
    
    this.sacaravisos();
    this.sacardiscrepancias();
    this.sacarmensajes();
        
  }

haydiscrepancias(){

let dev=false;


if( this.discrepancias!=null){

  if( this.discrepancias!=[]){

    if(this.discrepancias.length>0){

      dev=true;
      
      }

  }

}


return dev;
}

resolverreserva(idprod:string, iddisc:string, cantidad:number, resolucion:string){


  this.estaubicado(idprod);


  let idreserva="";
  let nomreubi:string; 
  let z:number;



  for (let i = 0; i <this.ubicaciones.length; i++) {

    nomreubi=(this.ubicaciones[i].Nombre);

    nomreubi=nomreubi.toLowerCase();

    z=nomreubi.indexOf("reservas");

    if(z!=-1){ 
    
      idreserva=""+this.ubicaciones[i].id;
  
    }

}



if(idreserva.length>0){

      if(resolucion=="Si"){

      //elimina ubi_stok de la ubicacion reservas
        this.eliminaadereserva(idprod,idreserva,cantidad);

        this.quitardiscre(iddisc);
    
      }else{
 
        this.eliminaade(idprod,idreserva,cantidad);

        this.quitardiscre(iddisc);
    

      }
}else{

  this.alertService.error("ERROR. No se encuantra la ubicacion Reservas. Por favor, Creela.");
  }
  
}

quitardiscre(id:string){


  this.data. borrarcrepancia(id).subscribe(res => {

    let indice:number=-1;

    for (let i = 0; i < this.mensajesdiscrepancias.length; i++) {
  
        if(this.mensajesdiscrepancias[i].mensaje==id){
  
          this.marcarleido(this.mensajesdiscrepancias[i])
          indice=i;
        }
    }
  
    if(indice==-1){
  
      console.log("Error no encuentro el mensaje de discrepancia");
  
    }

    this.sacardiscrepancias();

  },
  err => console.log(err)
  );

  
//buscar y borrar mensajes con tipo:discrepancia y mensaje:id

}

eliminaade(idarticulo:string,ubicacion:string, cantidad:number){

let total=this.todosantiguas.length;
let i:number=0;

while(cantidad>0 && i<total){

  if(this.todosantiguas[i].ubicacionesId!=parseInt(ubicacion)){

    if(this.todosantiguas[i].cantidad>cantidad){
  
      this.todosantiguas[i].cantidad=this.todosantiguas[i].cantidad-cantidad;

      let cantm=cantidad;
      let origen=this.todosantiguas[i].ubicacionesId;

      this.data.modificarubi_produc(""+this.todosantiguas[i].id,this.todosantiguas[i]).subscribe(res => {
    


        this.movimiento.cantidad=cantm;
        this.movimiento.user=this.currentUser.id;
        this.movimiento.productoId=this.todosantiguas[i].productosId;
        this.movimiento.destino=-1;
        this.movimiento.origen=origen; 

        this.crearmovi("Venta");
      },
      err => console.log(err)
      );
  
     cantidad=0;
    
    }else{
    
      let cantm=this.todosantiguas[i].cantidad;
      let origen=this.todosantiguas[i].ubicacionesId;
      // borra
      this.data.borrarubi_produc(""+this.todosantiguas[i].id).subscribe(res => {
    

       
        this.movimiento.cantidad=cantm;
        this.movimiento.user=this.currentUser.id;
        this.movimiento.productoId=this.todosantiguas[i].productosId;
        this.movimiento.destino=-1;
        this.movimiento.origen=origen; 

        this.crearmovi("Venta");
      },
      err => console.log(err)
      );
    
     cantidad=cantidad-this.todosantiguas[i].cantidad;
    }

    i++;

  }



}


}





crearmovi(tipo:string){

  this.movimiento.tipo=tipo;
  this.data.creamovi(this.movimiento).subscribe(res => {

    this.movimiento={

      cantidad:0,
      user:0,
      productoId:0,
      destino:0,
      origen:0,
      tipo:""
    
    }

  },
    err =>{

  });


}


eliminaadereserva(idarticulo:string,ubicacion:string, cantidad:number){



  let indice=-1;

  for (let i = 0; i < this.todosantiguas.length; i++) {
  
    if(this.todosantiguas[i].ubicacionesId==parseInt(ubicacion)){

      indice=i;
    }
   }
if(indice!=-1){


  if(this.todosantiguas[indice].cantidad>cantidad){
  
    this.todosantiguas[indice].cantidad=this.todosantiguas[indice].cantidad-cantidad;

    let cantm=cantidad;
    let origen=this.todosantiguas[indice].ubicacionesId;

    this.data.modificarubi_produc(""+this.todosantiguas[indice].id,this.todosantiguas[indice]).subscribe(res => {
  


      this.movimiento.cantidad=cantm;
      this.movimiento.user=this.currentUser.id;
      this.movimiento.productoId=this.todosantiguas[indice].productosId;
      this.movimiento.destino=-1;
      this.movimiento.origen=origen; 

      this.crearmovi("Venta");
    },
    err => console.log(err)
    );


  }else{
  
    let cantm=this.todosantiguas[indice].cantidad;
    let origen=this.todosantiguas[indice].ubicacionesId;
    // borra
    this.data.borrarubi_produc(""+this.todosantiguas[indice].id).subscribe(res => {
  

     
      this.movimiento.cantidad=cantm;
      this.movimiento.user=this.currentUser.id;
      this.movimiento.productoId=this.todosantiguas[indice].productosId;
      this.movimiento.destino=-1;
      this.movimiento.origen=origen; 

      this.crearmovi("Venta");
    },
    err => console.log(err)
    );
  
  }




}else{

  this.alertService.error("ERROR. No se encuantra la ubicacion Reservas. Por favor, Creela.");

}



      //elimina ubi_stok de la ubicacion reservas


}

estaubicado(barr:string){
  

  this.data.getubi_product_i(barr).subscribe(res => {

    this.todosantiguas=res;

    this.filtrar();
  },
  err => console.log(err)
  );

 

}
filtrar(){


  for (var i=0; i<this.todosantiguas.length; i++) {

    if(this.todosantiguas[i].user==this.currentUser.id)this.todosantiguastemp.push(this.todosantiguas[i]);
  }

  this.todosantiguas=this.todosantiguastemp;



}


sacaravisos(){
 


   
    /*this.dataservice.gettodaubi(this.idubi, ""+pagina).subscribe(res => {

      this.productos=res.rows;
      this.total=res.count

      console.log("resultado:"+JSON.stringify(res))
      this.hayresultados=true;
    
    },
    err => console.log(err)
    );
///---***---------------
  }
  sacardiscrepancias(){


    this.mensajesdiscrepancias=null;
    this.discrepancias=null;

    

    this.dataservice.mensajespara(this.currentUser.id, "discrepancia").subscribe(res => {
                                                        
      this.mensajesdiscrepancias=res;

      if(res.length>0){
        
        this.discrepancias=[];


        for (let i = 0; i < res.length; i++) {
  
          if(res[i].estado=="enviado")this.buscardiscre(res[i]);
         
        }

      }
 
   
    
    },
    err => console.log(err)
    );

  }


  buscardiscre(Mensa:any){



    this.data.sacardiscrepancia(""+Mensa.mensaje).subscribe(res => {
    
      this.discrepancias.push(res);


    },
      err => console.log(err)
      );
  

  }


  sacarmensajes(){

///---***---------------
    this.dataservice.mensajespara("3","mensaje").subscribe(res => {

      for (let i = 0; i < res.length; i++) {

        this. mensajes.push(res[i]);

        if(res[i].estado=="enviado" && res[i].destino==this.currentUser.id && this.router.url=="/avisos"){

              this.marcarleido(res[i]);
            
           // }

        }

        }
        setTimeout(() => {  (<HTMLInputElement>document.getElementById('mensajes')).scrollTop=document.getElementById('mensajes').scrollHeight; }, 1000);
           
    
    
    },
    err => console.log(err)
    );

  }

marcarleido(mensaje:any){

mensaje.estado="Leido";

this.dataservice.cambiarmensaje(""+mensaje.id,mensaje).subscribe(res => {

  },
  err => console.log(err)
  );
}


sacartodas(){

  this.data.gettodasubi(this.currentUser.id).subscribe(res => {

   
      this.ubicaciones=res;

     
    },
    err => console.log(err)
  );
   */
}

}
