import { Component, OnInit } from '@angular/core';
import { DataService } from '../_services/data.service';
import { Ubicacion } from "../models/ubicacion";
import { Ubi_product } from "../models/ubi_product";
import { TokenStorageService } from '../_services/token-storage.service';
import { AlertService } from '../alert.service';
import { JsonPipe } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';
import { Router } from '@angular/router';
import { animationFrame } from 'rxjs/internal/scheduler/animationFrame';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-entradas',
  templateUrl: './entradas.component.html',
  styleUrls: ['./entradas.component.css']
})
export class EntradasComponent implements OnInit {

  constructor( private router:Router,private dataservice :DataService,private token: TokenStorageService,private alertService: AlertService,private rutaActiva: ActivatedRoute) { }

  fecha:any;
  cod:any;
  movi:any[];
  proveedores:any[];
  totales:any[];
  currentUser:any;
  page: number;
  previousPage: number;
  total:number;   
  tipo:string;
  pselecionado:any;
  ids:any;


  ngOnInit(): void {
   
    this.fecha="0";
    this.cod="0";
    this.ids="";
    this.proveedores=[];
    this.tipo="0";
    this.totales=[];
    this.page=1;
    this.sacartodosproveedores();
    this.loadPage(1)
  }

  loadPage(page: number) {

    this.page=page;
    
    if (page !== this.previousPage) {

      this.previousPage = page;
      
      this.cargartodos(page);

    }else{
  
      this.cargartodos(page);

    }
  }




 getSelectedProductByName(id: string) {
  console.log("buscando proveedor con codigo: "+JSON.stringify(id))
    return this.proveedores.find(product => product.nombre == id);
}
quitarerrorp(){
  let tempral=[]

  for(let i=0; i<  this.proveedores.length;i++){

   if(  this.sacarproveedor(this.proveedores[i].codigo)!="ERROR")tempral.push(this.proveedores[i])
    
  }
  this.proveedores=tempral;
}
selected(selecionado:any){

  this.pselecionado=this.getSelectedProductByName(selecionado);
  console.log("SELECIONADO: "+JSON.stringify(this.pselecionado))
}

  async sacrtotales(){

    let paso;
    for(let i=0; i<  this.movi.length;i++){


      this.dataservice.albaranes_lineas(""+this.movi[i].documento).subscribe(res => {

       let total=0.0;
       let lineas=res;
       let articulos=0;
  
        for(let i=0; i<  lineas.length;i++){
      
        total=total+(parseFloat(lineas[i].Precio)*parseInt(lineas[i].cantidad));
        articulos=articulos+parseInt(lineas[i].cantidad)   
        }
        paso={"id":this.movi[i].documento,"total":total.toFixed(2),"articulos":articulos};
        this.totales.push(paso);

  
        
  
      },
      err => console.log(err)
      );
      

      
    }
    

  
  
    
  }
  filtrar(op:number,s:any){


    if(op==3){

      this.pselecionado={"codigo":s.proveedor};
    }
    if(op==2){
      this.pselecionado=null;

    }

    console.log("SS "+JSON.stringify(    this.pselecionado))
    this.loadPage(1)
     
  }

  ffecha(op:number){


    let inici=(<HTMLInputElement>document.getElementById('finicio')).value;
    let fin=(<HTMLInputElement>document.getElementById('ffin')).value;

    console.log("Desde "+inici+" hasta "+ fin)

    this.fecha="0";

    if(op==1){


      let fecha1=new Date(parseInt(inici.substr(0,4)),parseInt(inici.substr(5,2)),parseInt(inici.substr(8,2)));
      let fecha2=new Date(parseInt(fin.substr(0,4)),parseInt(fin.substr(5,2)),parseInt(fin.substr(8,2)));




      if(fecha1 <= fecha2 ){


        let dia= parseInt(fin.substr(8,2));
        if(dia<30)dia=dia+1;

        this.fecha=""+inici.substr(0,4)+"-"+inici.substr(5,2)+"-"+inici.substr(8,2)+":"+fin.substr(0,4)+"-"+fin.substr(5,2)+"-"+dia;

      }





    }
  
  

    this.loadPage(1);

  }


  totalde(id:string){
 

    const j =      this.totales.findIndex( fruta => fruta.id == id);
  
    if(j!=-1){

      return this.totales[j].articulos+" - "+this.totales[j].total+"€"
    }


  }

  sacartodosproveedores(){

    this.dataservice.sacratodosprov("-1").subscribe(res => {

   
      this.proveedores=res;
      this.quitarerrorp();
    

 
    
 
    },
    err => console.log(err)
  );
 


  }



  todos(){
    this.tipo="0";
    this.loadPage(1);

  }
  solodevos(){
    this.tipo="LD";
    this.loadPage(1);


  }

  sololibros(){
    console.log("LIBROS")
    this.tipo="LF";
    this.loadPage(1);
  }


  
  sacarproveedor(id:string){


    const j = this.proveedores.findIndex( fruta => fruta.codigo === parseInt(id));
  
    if(j!=-1){

      return this.proveedores[j].nombre;
    }

     return "ERROR"
  }

  cargartodos(page:number){

    
    if(this.pselecionado!=null){
      this.cod=this.pselecionado.codigo;
    }else this.cod="0";
    console.log(" FECHA "+this.fecha)

    this.dataservice.albaranes_todos(this.tipo,this.fecha,"0",""+page,this.cod).subscribe(res => {
 

      this.movi=res.rows;
      this.total=res.count;
      this.sacrtotales();

   //   console.log("respueta movis:"+JSON.stringify(this.movi))

     

    },
    err => console.log(err)
  );
  


  }




}
 