<div  *ngFor="let ubi of categoriashijo">
    <div  class="row">  
        <div class="col-md-6" >
           
                    <button class="btn-group  btn-secondary "  (click)='pasarid("e"+ubi.id)' [ngStyle]="{'margin-left.px': margen}">

                        <button class="btn btn-secondary"  style="text-align: left; ">  {{ubi.nombre}} 
                            

                            <a style="margin-left: 20px; color:aqua;" [routerLink]="['../dcategoria/',ubi.id]"  >
                                <i class="fas fa-eye" >  {{toti(ubi.id)}}</i>
                            </a>
 
                       
                        </button>
                       
        
            
                    </button> 
                    <button class="btn btn-lg btn-secondary"  *ngIf="hijomostrado!=ubi.id && tienehijos(ubi.id) " (click)='hijomostrado=ubi.id'><i class="fas fa-caret-square-down"></i></button> 
                    <button class="btn btn-lg  btn-secondary" *ngIf="hijomostrado==ubi.id && tienehijos(ubi.id)" (click)='hijomostrado=-1'><i class="fas fa-caret-square-up" ></i></button> 
                    <button *ngIf="verrol()" class="btn  btn-success " (click)='pasarid("n"+ubi.id)' style="text-align: right;" style="margin-left: 10px;"><i class="fas fa-plus-square"></i></button> 
                    <button class="btn btn btn-danger"  *ngIf="!tienehijos(ubi.id) && verrol()" (click)='pasarid("b"+ubi.id)' style="margin-left: 10px;"><i class="fas fa-trash-alt"></i></button>
                   
      
                </div>  
                         
                <div class="col-md-2" >

                    <app-ponerweb [idp]="ubi.id" [tipo]="'categoria'"></app-ponerweb>
                </div>

         </div>
         <div *ngIf="hijomostrado==ubi.id"><app-categoriahijo [idp]="ubi.id" [nivel]="nivel" (notify)='recibirhijo($event)' ></app-categoriahijo></div>

         
