import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Ubicacion } from "../models/ubicacion";
import { Ubi_product } from "../models/ubi_product";
import { promise } from 'protractor';
import { StringDecoder } from 'string_decoder';


const API_URL = 'https://pruebasstockaitor.es:30000/api/data/';
//const API_URL = 'http://145.239.197.238:30000/api/data/';
@Injectable({
  providedIn: 'root'
}) 

export class DataService {


  constructor(private http: HttpClient) { }
  
//************** -RELACIONES -********************* */   

  sacar_todas_rel(id:string): Observable<any>{

    return this.http.get(API_URL+`relaciones/${id}`);

  }  
  borrar_rel(id:string,tipo:string): Observable<any>{

    return this.http.delete(API_URL+`relaciones/${id}&${tipo}`);

  }  
  crear_rel(tipo:string,nueva:any): Observable<any>{

    return this.http.post(API_URL+`relaciones/${tipo}`,nueva);

  }  
  actualizar_rel(id:string,tipo:string,nueva:any): Observable<any>{

    return this.http.put(API_URL+`relaciones/${id}&${tipo}`,nueva);

  }  



//************** -UBICACIONES -********************* */   
  gettodasubi(tienda: string): Observable<any>{

    return this.http.get(API_URL+`ubicacion/${tienda}`);

  } 

  fusionarubis(ubi1: string,ubi2:string): Observable<any>{

    return this.http.get(API_URL+`fubicacion/${ubi1}&${ubi2}`);

  }

  cambiardeubi(ubinueva: string,ubivieja:string): Observable<any>{

    return this.http.get(API_URL+`caubicacion/${ubinueva}&${ubivieja}`);

  }

  getubi(id:string): Observable<any>{

    return this.http.get(API_URL+`ubicacionu/${id}`);

  }

  crearubi(nueva: Ubicacion): Observable<any>{

    return this.http.post(API_URL+`ubicacion/`,nueva);

  } 

  getpa(tienda: String): Observable<any>{

    return this.http.get(API_URL+`padres/${tienda}`);
 
  } 

  borrarubi(id: String): Observable<any>{

    return this.http.delete(API_URL+`ubicacion/${id}`);
   
  } 

  modificarubi(id:String, nueva: Ubicacion): Observable<any>{

    return this.http.put(API_URL+`ubicacion/:id`,nueva);
 
   
  } 

  totalubi(id:string): Observable<any>{

     return this.http.get(API_URL+`stocktotal/${id}`);
     
    } 
    
    

//*********************************** */
getproductos(buscar:string,ofset:string): Observable<any>{

  return this.http.get(API_URL+`productosid/${buscar}&${ofset}`);
  
}

getproducto(buscar:string): Observable<any>{

 return this.http.get(API_URL+`productosbarra/${buscar}`);
  
} 
getproductocodigo(buscar:string): Observable<any>{

  return this.http.get(API_URL+`productoscodigo/${buscar}`);
   
 } 
modificarproducto(productoId:String, nueva: any): Observable<any>{

  return this.http.put(API_URL+`productos/${productoId}`,nueva);

  
}  

getproductoweb(productoId:string): Observable<any>{

  return this.http.get(API_URL+`productoesweb/${productoId}`);

 } 
 getproductoexp(productoId:string): Observable<any>{

  return this.http.get(API_URL+`productoexp/${productoId}`);

 } 
  
getubi_product_u(user:string): Observable<any>{

    return this.http.get(API_URL+`stock/${user}`);
    
}

gettodaubi(id:string,offset:string): Observable<any>{

    return this.http.get(API_URL+`stockt/${id}&${offset}`);
    
}
gettodasubi_product(): Observable<any>{

  return this.http.get(API_URL+`stock/`);
}

getubi_product_p(id:string,filtro:string): Observable<any>{

  return this.http.get(API_URL+`stockp/${id}&${filtro}}`);

}

getubi_product_p_o(id:string,filtro:string,offset:string): Observable<any>{

  return this.http.get(API_URL+`stockpo/${id}&${filtro}&${offset}}`);

}


getubi_product_i(id:string): Observable<any>{

  return this.http.get(API_URL+`stocki/${id}`);
  
}

getubi_product_producto(id:string): Observable<any>{

  return this.http.get(API_URL+`stockproducto/${id}`);
  
}


crearubi_product(nueva:  Ubi_product): Observable<any>{

  return this.http.post(API_URL+`stock/`,nueva);

} 


borrarubi_produc(id: String): Observable<any>{

  return this.http.delete(API_URL+`stock/${id}`);
 
} 
borrartodasubi_produc(id: String): Observable<any>{

  return this.http.delete(API_URL+`stockall/${id}`);
 
} 

modificarubi_produc(id:String, nueva:  Ubi_product): Observable<any>{

  return this.http.put(API_URL+`stock/${id}`,nueva);
 
}  



//-------------------

creadevu(nueva: any): Observable<any>{


  return this.http.post(API_URL+`devoluciones/`,nueva);

} 

devouser(User:string): Observable<any>{


   return this.http.get(API_URL+`devolucionesu/${User}`);
   
}

devoarti(productoId:string): Observable<any>{


  return this.http.get(API_URL+`devolucionesi/${productoId}`);
  
}


borrardevo(id:String): Observable<any>{


  return this.http.delete(API_URL+`devoluciones/${id}`);
  
}
/******************transito*************** */
creatransito(nueva: any): Observable<any>{


  return this.http.post(API_URL+`transito/`,nueva);

} 

buscatransitoorigen(origen:string): Observable<any>{


   return this.http.get(API_URL+`transitoo/${origen}`);
   
}
buscatransitodestino(destino:string): Observable<any>{


  return this.http.get(API_URL+`transitod/${destino}`);
  
}

buscatransitoid(id:String): Observable<any>{


  return this.http.get(API_URL+`transitoi/${id}`);
  
}
borratransito(id:String): Observable<any>{


  return this.http.delete(API_URL+`transito/${id}`);
  
}


//***************Movimientos*************************** */

buscamoviid(productoId:string): Observable<any>{


  return this.http.get(API_URL+`movii/${productoId}`);
  
}

buscamovi(User:string,Tipo:string,offset:string): Observable<any>{


  return this.http.get(API_URL+`movi/${User}&${Tipo}&${offset}`);
  
}

buscamovi_pro(id:string,Tipo:string,offset:string,tienda:string): Observable<any>{


  return this.http.get(API_URL+`movid/${id}&${Tipo}&${offset}&${tienda}`);
}


creamovi(nueva: any): Observable<any>{


  return this.http.post(API_URL+`movi/`,nueva);

} 
//****************************************** */

sacardiscrepancia(id:string): Observable<any>{

  return this.http.get(API_URL+`discre/${id}`);
  
}

sacardiscrepanciaportipo(tipo:string,tienda:string): Observable<any>{

  return this.http.get(API_URL+`discret/${tipo}&${tienda}`);
  
}
borrarcrepancia(id:string): Observable<any>{

  return this.http.delete(API_URL+`discre/${id}`);
  
}

//***************************************** */

sacarcatehijos(id:string): Observable<any>{

  return this.http.get(API_URL+`cateh/${id}`);
  
}
sacarcatepadres(): Observable<any>{

  return this.http.get(API_URL+`catep/`);
  
}
buscarcate(parametro:string): Observable<any>{

  return this.http.get(API_URL+`cate/${parametro}`);
  
}
ponerwebcate(valor:boolean, id:number): Observable<any>{


  return this.http.put(API_URL+`catew/${id}`,valor);
}

buscarcates(): Observable<any>{

  return this.http.get(API_URL+`cate/`);
  
}
crearcate(nueva: any): Observable<any>{

  return this.http.post(API_URL+`cate/`,nueva);
  
}
modificarcate(id:string,nueva: any): Observable<any>{

  return this.http.put(API_URL+`cate/${id}`,nueva);
  
}


borrarcate(id:string): Observable<any>{

  return this.http.delete(API_URL+`cate/${id}`);
  
}

//****************************************** */

crearcate_prod(nueva: any): Observable<any>{

  return this.http.post(API_URL+`catepro/`,nueva);
   
}

crearcate_prodlote(lote: any): Observable<any>{

  return this.http.post(API_URL+`cateprol/`,lote);
   
}
borrarcate_prod(id:string): Observable<any>{

  return this.http.delete(API_URL+`catepro/${id}`);
  
}

borartodocate_prod(id:string,cate:string): Observable<any>{

  return this.http.delete(API_URL+`cateprotodo/${id}&${cate}`);
  
}

sacracatedepro(idp:string): Observable<any>{

  return this.http.get(API_URL+`catepro/${idp}`);
  
}

totastockcate(id:string): Observable<any>{

  return this.http.get(API_URL+`stocktotalcate/${id}`);
  
 } 

 getproductoscate(idc:string,offset:string): Observable<any>{

  return this.http.get(API_URL+`catepros/${idc}&${offset}`);
  
}
//****************************************** */
crearprov(nueva: any): Observable<any>{

  return this.http.post(API_URL+`prov/`,nueva);
  
}

borrarporv(id:string): Observable<any>{

  return this.http.delete(API_URL+`prov/${id}`);
  
}

sacratodosprov(offset:string): Observable<any>{

  return this.http.get(API_URL+`provo/${offset}`);
  
}

modificarprov(id:string,nueva: any): Observable<any>{

  return this.http.put(API_URL+`prov/${id}`,nueva);
  
}
buscarprov(parametro:string): Observable<any>{

  return this.http.get(API_URL+`prov/${parametro}`);
  
}


//****************************************** */


crearprove_prod(nueva: any): Observable<any>{

  return this.http.post(API_URL+`provepro/`,nueva);
 
}

borrarprove_prod(id:string): Observable<any>{

  return this.http.delete(API_URL+`proveproall/${id}`);
  
}

sacraprovedepro(id:string): Observable<any>{

  return this.http.get(API_URL+`proveproducto/${id}`);
  
}


totastockprove(id:string): Observable<any>{

  return this.http.get(API_URL+`stocktotalprov/${id}`);
  
 } 


getproductosprove(id:string,offset:string): Observable<any>{

  return this.http.get(API_URL+`proproveedor/${id}&${offset}`);
  
}

getproductosprove2(id:string): Observable<any>{

  return this.http.get(API_URL+`proproveedor2/${id}`);
  
}

borrarprove_prodes(id:string,prov:string): Observable<any>{

    return this.http.delete(API_URL+`provepro/${id}&${prov}`);
    
  }



//****************************************** */

sacartiendas(): Observable<any>{

  return this.http.get(API_URL+`tienda/`);

}
 
borrartienda(id:string): Observable<any>{

    return this.http.delete(API_URL+`tienda/${id}`);
    
  }

  creartienda(nueva: any): Observable<any>{

    return this.http.post(API_URL+`tienda/`,nueva);
   
  }

  cambiartienda(id:string,nueva: any): Observable<any>{

    return this.http.put(API_URL+`tienda/${id}`,nueva);
   
  }
  
//****************************************** */
modificaviso(id:string, nueva: any): Observable<any>{

  return this.http.put(API_URL+`aviso/${id}`,nueva);
 
}
crearaviso(nueva: any): Observable<any>{

  return this.http.post(API_URL+`aviso/`,nueva);
 
}

avisos_productos_tienda(tienda:string): Observable<any>{

return this.http.get(API_URL+`aviso/productos/${tienda}`);
}
avisos_mover(): Observable<any>{

  return this.http.get(API_URL+`aviso/mover/`);
  }

avisos_producto_todos(): Observable<any>{

return this.http.get(API_URL+`aviso/productos/`);
}

avisos_producto(id:string): Observable<any>{

return this.http.get(API_URL+`aviso/producto/${id}`);
}

tareas_user(id:string,tienda:string): Observable<any>{

return this.http.get(API_URL+`aviso/tareas/${id}&${tienda}`);
}

avisos_proveedor(): Observable<any>{

return this.http.get(API_URL+`aviso/proveedores`);
}

albaranes_todos(tipo:string,fecha:string,orden:string,offset:string,id:string,): Observable<any>{

  return this.http.get(API_URL+`albaranes/${tipo}&${fecha}&${orden}&${offset}&${id}`);}

albaranes_lineas(id:string): Observable<any>{

    return this.http.get(API_URL+`albaranes/lineas/${id}`);
}

albaranes_producto(tipo:string,fecha:string,orden:string,id:string): Observable<any>{

      return this.http.get(API_URL+`albaranes/producto/${tipo}&${fecha}&${orden}&${id}`);
}

  
albaranes_lineasubi(id:string,lin:any): Observable<any>{

  return this.http.put(API_URL+`albaranes/lineasub/${id}`, lin);
}
albaranes_cabezera(id:string): Observable<any>{

  return this.http.get(API_URL+`albaranes/cabezera/${id}`);
}


albaranes_lineasproducto(id:string): Observable<any>{

  return this.http.get(API_URL+`albaranes/lineasp/${id}`);
}

ventas_ariculo(id:string): Observable<any>{

  return this.http.get(API_URL+`ventasa/${id}`);
}

compras_proveedor(id:string,desde:string,hasta:string): Observable<any>{
 
  return this.http.get(API_URL+`albaranes/productos/${id}&${desde}&${hasta}`);
}



ventas_periodo(desde:string,hasta:string): Observable<any>{
 
  return this.http.get(API_URL+`ventasp/${desde}&${hasta}`);
}

inventario(tienda:string,cate:string): Observable<any>{

  return this.http.get(API_URL+`stockinv/${tienda}&${cate}`);
}

todoslibros(): Observable<any>{

  return this.http.get(API_URL+`stockl/`);
}

borraraviso(id:string): Observable<any>{

  return this.http.delete(API_URL+`aviso/${id}`);
  }
  borraravisoshijos(id:string): Observable<any>{

    return this.http.delete(API_URL+`avisoh/${id}`);
    }



//***************************************** */

private handleError<T>(operation = 'operation', result?: T) {
  return (error: any): Observable<T> => {
    console.error(error);
    this.log(`${operation} failed: ${error.message}`);

    return of(result as T);
  };
}

private log(message: string) {
  console.log(message);
}

  




}