import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { DataService } from '../_services/data.service';
import { Ubicacion } from "../models/ubicacion";
import { Ubi_product } from "../models/ubi_product";
import { TokenStorageService } from '../_services/token-storage.service';
import { AlertService } from '../alert.service';
import { JsonPipe } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';
import { Router } from '@angular/router';
import { animationFrame } from 'rxjs/internal/scheduler/animationFrame';
import { ThrowStmt } from '@angular/compiler';
import { ignoreElements, timeInterval } from 'rxjs/operators';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { threadId } from 'worker_threads';
import { JsonpClientBackend } from '@angular/common/http';

@Component({
  selector: 'app-inventario',
  templateUrl: './inventario.component.html',
  styleUrls: ['./inventario.component.css']
})
export class InventarioComponent implements OnInit {
 
  constructor(private dataservice :DataService) { }
  cargando:boolean;
  tiendas:any[];
  numtotal:number;
  costetotal:number;
  pvptotal:number;
  todosdatos:any[];
  categorias:any[];
  todascategorias:any[];

  ngOnInit(): void {
    this.todascategorias=[]
    this.llenarnombrecate();

    this.categorias=["1","5","7","6","4","9","61","62","63","8"];
    this.todosdatos=[];
    this.tiendas=[]
    this.numtotal=0;
    this.costetotal=0;
    this.pvptotal=0;
    this.cargando=true;
    this.sacartiendas()

  }

async sacardatos(){

  console.log(JSON.stringify(this.tiendas))
  let t;

  for (let i = 0; i <this.tiendas.length; i++) {

    t= await this.datodetieda(this.tiendas[i].id);
    this.todosdatos.push(t);
  }
  console.log("***** "+JSON.stringify(  this.todosdatos))
  this.sumartodo();
  this.cargando=false;
}

totaltienda(tienda:string){

  let pr=0;
  let val=0.0;
  
  for (let i = 0; i < this.todosdatos.length; i++){

    if(this.todosdatos[i][0].tienda==tienda){

      for (let j = 0; j < this.todosdatos[i].length; j++){

          if(parseInt(this.todosdatos[i][j].totalp)){
            pr=pr+parseInt(this.todosdatos[i][j].totalp);
            val=val+parseFloat(this.todosdatos[i][j].monto);
          }
 

        
      }

    }

  }
 
  if(pr==null){

    return "-"

  }

  return "Productos: "+pr+" - Neto: "+(new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(val));


}





datosn(categoria:string,tienda:string){

  let pr="";
  let val="";
  
  for (let i = 0; i < this.todosdatos.length; i++){

    if(this.todosdatos[i][0].tienda==tienda){

      for (let j = 0; j < this.todosdatos[i].length; j++){

        if(this.todosdatos[i][j].categoria==categoria){
          pr=this.todosdatos[i][j].totalp;
          val=this.todosdatos[i][j].monto;

        }
      }

    }

  }
 
  if(pr==null){

    return "-"

  }

  return "Productos: "+pr+" - Valor total: "+(new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(parseFloat(val)));
}

async sumartodo(){

  let dato=await this.dataservice.inventario("1","0").toPromise();

  this.numtotal=dato.PRODUCTOS[0].total
  this.costetotal=parseFloat(dato.MONTO[0].total);
  this.costetotal=parseFloat(this.costetotal.toFixed(2))

}


async datodetieda(tienda:string){


 let ti=[];
 let dato;
 let dato2;

 for (let i = 0; i <this.categorias.length; i++) {

      dato=await this.dataservice.inventario(tienda,this.categorias[i]).toPromise();
      dato2={"tienda":tienda,"categoria":this.categorias[i],"totalp":dato.PRODUCTOS[0].total,"monto":dato.MONTO[0].total}
      ti.push(dato2)
 }
 

 return ti;

}
llenarnombrecate(){

  this.dataservice.buscarcates().subscribe(res => {

    this.todascategorias=res;
  },

    err =>{

  });




}

nombrecategoria(id:number){

  if(this.todascategorias!=[]){
  
  
    const i =  this.todascategorias.findIndex( element => element.id == id );
  
  
    if(i!=-1){
     
      return this.todascategorias[i].nombre;
  
    }else{
  
      return "error";
    }
  
  }
  
    return "Sin nombre";
  
  
  
}

async sacartiendas(){

  
  this.tiendas = await this.dataservice.sacartiendas().toPromise();
  this.sacardatos()
}


async sinli(){

  let nombrearchivo="INVENTARIO.TXT";
  let archivo="";
  let date = new Date()
  let informe:string='';
  let day = date.getDate()
  let month = date.getMonth() + 1
  let mes=month.toString();
  
  if(mes.length==1){
    mes="0"+mes;
  }


  let year = date.getFullYear()
  let fecha=year.toString()+mes+day.toString();

  let todo =await this.dataservice.todoslibros().toPromise();

  console.log(" TODODS: "+todo.length)
  if (confirm("ATENCION \n Se  va a generar un nuevo archivo sinli")) {

    let registros=0
    let isbnG;
    let tt;
    let p;
    informe=informe+"I"+"LIBTORREVIEJA@SMOT.ES".padStart(50, " ")+"CEGAL@SINLI.COM".padStart(50, " ")+"CEGALD02"+"¿NTRASM?"+"\r\n" ;
    informe=informe+"C"+"PULSAR STORE SL".padStart(40, " ")+fecha+"EUR"+"\r\n" ;
    for (let i = 0; i <todo.length; i++) {
      
      if(todo[i].total!=null && todo[i].producto.IsbnG!=null){
  
        isbnG=""+todo[i].producto.IsbnG;
        tt=""+todo[i].total;
        p=""+parseFloat(todo[i].producto.Precio).toFixed(2)
        p=p.replace(".","");
        let lp="D"+isbnG.replace(/ /g, "")+tt.padStart(6, "0")+p.padStart(10, "0")+"\r\n" ;
  
        informe=informe+lp
        registros=registros+1
        
      }
    }
    let re=""+registros
      informe="INCEGALV03LIBEMISOLDESTINO"+re.padStart(5, "0")+"NTRASMI".padStart(7, "0")+"USUARIOLOCAL".padStart(15, " ")+"USUARIODESTINO".padStart(15, " ")+"TEXTL".padStart(7, " ")+"FANDE"+"\r\n"+informe;
    
  

    this.guardarArchivoDeTexto(informe ,nombrearchivo)
  }


}

guardarArchivoDeTexto = (contenido, nombre) => {
  const a = document.createElement("a");
  const archivo = new Blob([contenido], { type: 'text/plain' });
  const url = URL.createObjectURL(archivo);
  a.href = url;
  a.download = nombre;
  a.click();
  URL.revokeObjectURL(url);
}
} 
