<div class="container">
  <br/> 


  
<div class="row justify-content-center">
                      <div class="col-12 col-md-10 col-lg-8">
                        <h2>Buscar Articulos</h2>
 
                          <form class="card card-sm">
                              <div class="card-body row no-gutters align-items-center">
                                
                             
                                  <!--end of col-->
                                  <div class="col">
                                      <input id="barradebusqueda" class="form-control form-control-lg form-control-borderless" type="search"  autofocus>
                                  </div>
                                  <!--end of col-->
                                  <div class="col-auto">
                                      <button class="btn btn-lg btn-success" type="submit" (click)="buscar()" style="margin-left: 10px;">  <i class="fas fa-search h4 text-body"> </i></button>
                                  </div>
                                  <!--end of col-->
                              </div>
                          </form>   
                          <button  *ngIf="buscando!=null" class="btn btn-block btn-success" (click)="rep()" > Volver a buscar: {{buscando}} </button>
                      </div> 
                    
                      <div *ngIf="!ocultarlocal" class="col-12 col-md-10 col-lg-8" style="margin-top: 10px;">

                        <button *ngIf="nom==true" class="btn btn-sm btn-success" (click)="nom=false" style="margin-left: 10px;"> Nombre </button>
                        <button *ngIf="nom==false" class="btn btn-sm btn-secondary" (click)="nom=true" style="margin-left: 10px;"> Nombre </button>
                        <button *ngIf="barr==true" class="btn btn-sm btn-success" (click)="barr=false" style="margin-left: 10px;"> Barra </button>
                        <button *ngIf="barr==false" class="btn btn-sm btn-secondary" (click)="barr=true" style="margin-left: 10px;"> Barra </button>
                        <button *ngIf="cod==true" class="btn btn-sm btn-success" (click)="cod=false" style="margin-left: 10px;"> Codigo </button>
                        <button *ngIf="cod==false" class="btn btn-sm btn-secondary" (click)="cod=true" style="margin-left: 10px;"> Codigo </button>
                        <button *ngIf="autor==true" class="btn btn-sm btn-success" (click)="autor=false" style="margin-left: 10px;"> Autor </button>
                        <button *ngIf="autor==false" class="btn btn-sm btn-secondary" (click)="autor=true" style="margin-left: 10px;"> Autor </button> 
                        <button *ngIf="fcates==false" class="btn btn-info" (click)="fcates=true" style="margin-left: 40px;"> FILTRAR POR CATEGORIA </button> 
                        <button *ngIf="fcates==true" class="btn btn-warning" (click)="fcates=false" style="margin-left: 40px;"> QUITAR FILTRO </button> 

                        <div *ngIf="fcates==true" class="row">
                          <div  class="col-md-4 " style="margin-top: 15px; margin-left: 30px;" >               
                                <select name="miselect" id="miselect" style="width: 250px;" [(ngModel)]="ubiselec" (change)="onChange($event.target.value)">
                                  <option [value]="item.id" *ngFor="let item of categoriaspornivel(0)">{{item.nombre}}</option>
                                </select>                    
                          </div>
                          
                          <div  *ngIf="nivelcate>0" class="col-md-4 " style="margin-top: 15px; margin-left: 50px;" >

                                <select name="miselect" id="miselect" style="width: 250px;" [(ngModel)]="ubiselec" (change)="onChange($event.target.value)">
                                  <option [value]="item.id" *ngFor="let item of categoriaspornivel(1)">{{item.nombre}}</option>
                                </select>
              
                                <div  *ngIf="nivelcate>1" class="col-md-4 " style="margin-top: 15px; margin-left: 50px;" >
                                    <select name="miselect" id="miselect" style="width: 250px;" [(ngModel)]="ubiselec" (change)="onChange($event.target.value)">
                                      <option [value]="item.id" *ngFor="let item of categoriaspornivel(2)">{{item.nombre}}</option>
                                    </select>
                                </div>
                          </div>
                    
                    
                         
                        </div>
                        <div *ngIf="fcates==true" class="row" >
                          <div  *ngFor="let ub5i of categoriasaponer">
                            <p ><span class="badge badge-primary " >{{nombrecategoria(ub5i)}}</span><button class="btn btn-sm " (click)="borrarcate(ub5i)" ><i class="fas fa-times"></i></button> </p>

                           </div>
                        </div>
                      </div>
                      <!--end of col-->
                  </div>
 
<div *ngIf="proceso">

  <div class="text-center">

    <h1 style=" margin-top: 30px;" >Buscando...</h1>
   <div class="spinner-grow" style="width: 10rem; height: 10rem; margin-top: 30px;" role="status">
   
    </div>

  </div>




</div>

<div *ngIf="!proceso">

  <div id="resultados" *ngIf="hayresultados || hayresultados2">

    <div id="resultadoslocales" *ngIf="pp1.length>0 && !ocultarlocal" >    
    
          <div *ngIf="pp2.length>0">



            <ngb-pagination *ngIf="total2>10" [collectionSize]="total2" [maxSize]="10" [(page)]="page2" (pageChange)="loadPage2($event)"></ngb-pagination>
         
              <p>{{pp1.length}} Articulos con stock</p>

             
              
               <div *ngFor="let re of pp2">
                  

                <div *ngIf="re.local.length>0" style=" background-color:rgb(118, 243, 118); margin-bottom: 10px; border-style: double;">

 
                  <app-tarjeta-resultado [idp]="re.producto" [tipo]="'producto'"></app-tarjeta-resultado>

                  <div class="row justify-content-center" *ngFor="let cc of re.local" style="margin-top: 1px; background-color:rgb(118, 243, 118)" >
                   
                  
                        <div class="col-10">{{nombreubicaciones(cc.ubicacion)}} -> </div>
                        <div class="col-2">{{cc.cantidad}} Unidades </div>
                  
                  </div>

                  <div class="row justify-content-center" *ngFor="let cc of re.fuera" style="margin-top: 1px; background-color:rgb(224, 106, 50);" >
                 
                    <div class="col-5">{{nombretienda(cc.tienda)}} -> </div>
                    <div class="col-5">{{nombreubicaciones(cc.ubicacion)}} - </div>
                    <div class="col-2">{{cc.cantidad}} Unidades </div>
              
                   </div>

                </div>
                </div>  

               <div *ngFor="let re of pp2">
                  

                <div *ngIf="re.local.length==0" style=" background-color:rgb(224, 106, 50); margin-bottom: 10px; border-style: double;">


                  <app-tarjeta-resultado [idp]="re.producto" [tipo]="'producto'"></app-tarjeta-resultado>

                  <div class="row justify-content-center" *ngFor="let cc of re.fuera"  style="margin-top: 1px; background-color:rgb(224, 106, 50);" >
                   
                        <div class="col">{{nombretienda(cc.tienda)}} - </div>
                        <div class="col">{{nombreubicaciones(cc.ubicacion)}} - </div>
                        <div class="col">{{cc.cantidad}} Unidades </div>
                  
                  </div>

                </div>
                </div>      

        
        </div>

     </div>

 
    <div id="resultados2" *ngIf="productos.length>0" style="margin-top: 10px;">

            <p>Encontrados {{total}} articulos </p>
            <div *ngIf="total>20">
              <h4 style="color: rgb(134, 7, 7);">Demasiados resultados, ajusta más la busqueda para comprobar stock</h4>
            </div>

            <div *ngIf="total<20">
              <button  class="btn btn-sm btn-success" (click)="comprobarstock()" style="margin-left: 10px;"> Comprobar Stock</button>
            </div>

            <ngb-pagination *ngIf="total>20" [collectionSize]="total" [maxSize]="20" [(page)]="page"
            (pageChange)="loadPage($event)"></ngb-pagination>

              <table class="table">
                <tr>
                  <td></td>
                  <td>Nombre</td>
                  <td>Barra</td>
                  <td>Codigo</td>
                  <td>Precio</td>
                  <td *ngIf="datosampliados">Stock Global</td>
                  <td  *ngIf="datosampliados">Articulos similares con stock</td>

              </tr>
          
              <tr *ngFor="let re3 of productos" [ngStyle]="{'background-color': re3.Stock > 0 ? 'rgb(62, 231, 175)' : 'white'}">

                <td><a [routerLink]="['../articulo/',re3.Barra]"><img src="{{re3.Barra | sacarfoto}}" onerror="this.src='../../assets/nofoto.png'" width="70" height="100" /></a> </td>
                <td ><a [routerLink]="['../articulo/',re3.Barra]">{{re3.Nombre}}</a></td>
                <td >{{re3.Barra}}</td>
                <td >{{re3.Codigo}}</td>
                <td >{{re3.Precio | sumariva:re3.Iva}}€</td>
                <td *ngIf="datosampliados">{{re3.Stock}}</td>
 

                <div *ngFor="let re4 of re3.similares" style="background-color:rgb(62, 231, 175); font-size:10px; border-radius: 5px; padding: 1em;">
                  <a [routerLink]="['../articulo/',re4.Codigo,1]">
                  {{re4.Nombre}}<br>CODIGO:{{re4.Codigo}} - PRECIO:
                  {{re4.Precio | sumariva:re4.Iva}}€ <br>
                  <b>Stock Global:{{re4.Stock}}</b>
                      </a>
                </div>
           
              </tr>
              
          
              </table>
          </div> 
    </div>

  <button  style="margin-top: 10px; margin-bottom: 10px;" *ngIf="mostrar" class="btn btn-block btn-info" (click)="buscarmas()" > Buscar en la base de datos global </button>

  <div *ngIf="sinresultados" class="text-center" style="margin-top: 30px;">
    <h3 style="color: indianred;" >Sin resultados </h3>
  </div> 
  <alert></alert>




</div>
                
</div>


