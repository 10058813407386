<h5 style="margin-top:20px">Ultimos movimientos</h5>

<ngb-pagination *ngIf="total>15" [collectionSize]="total" [maxSize]="10" [pageSize]="15"  [(page)]="page" (pageChange)="loadPage($event)"></ngb-pagination>
         
<table class="table">


    <tr *ngFor="let re of movi">

      <td>{{re.createdAt | date: 'dd'}}/{{re.createdAt | date:'MM'}}/{{re.createdAt | date: 'yyyy'}}</td>
      <td><a [routerLink]="['../articulo/',re.producto.Barra]"> {{re.producto.Nombre}}</a></td>
      <td>{{re.tipo}}</td>
      <td>{{formatearorigen(re.user,re.origen,re.tipo,re.destino)}}</td>
      <td>{{ponermas(re)}}{{re.cantidad}}</td>

    </tr>

   
    </table>