

import { Component, ComponentFactoryResolver, OnInit, SystemJsNgModuleLoader } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../_services/data.service';
import {RoundProgressModule} from 'angular-svg-round-progressbar'; 
import { timer } from 'rxjs';
import { Ubicacion } from "../models/ubicacion";
import { Ubitotal} from "../models/ubitotales";
const JsBarcode = require('jsbarcode');
import { TokenStorageService } from '../_services/token-storage.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertService } from '../alert.service';
import { JsonPipe } from '@angular/common';
 
@Component({
  selector: 'app-relaciones',
  templateUrl: './relaciones.component.html',
  styleUrls: ['./relaciones.component.css']
})
export class RelacionesComponent implements OnInit {

  inputText1: string = '';
  inputText2: string = '';
  searchResults1: any[] = [];
  searchResults2: any[] = [];
  selectedResultIndex1: number = -1;
  selectedResultIndex2: number = -1;
  selectedResultValue1: any = null;
  selectedResultValue2: any = null;
  cantidadelegida:number;
  relacionesdeEmbalaje:any;
  relacionesdeComplement:any;
  relacionesdeSusti:any;
  productos:any[];

  modo:any;

  constructor(private dataservice :DataService,private token: TokenStorageService,private alertService: AlertService) { }

  ngOnInit(): void {

    this.modo=null;
    this.cantidadelegida=1;
    this.relacionesdeEmbalaje=[];
    this.relacionesdeComplement=[];
    this.relacionesdeSusti=[];

  }

  precio(producto:any){

    let precio=0;

    precio=parseFloat(producto.Precio)*parseFloat(producto.Iva);

    return precio.toFixed(2);


  }


   async datosproducto(id:string){

    //comporbar si ya esta, si no busca y añadir

    const k = this.productos.findIndex( fruta => fruta.id === id);
  
    if(k==-1){
       

    let pro=  await this.dataservice.getproductoweb(id).toPromise();
    if(pro){


      this.productos.push(pro[0])  
    }

     
    }
  

    
    }

    borrar(id:string){

      if(confirm("Deseas eliminar la relacion??")){

        let modos;

        if(this.modo==1){
  
         modos="embalaje";
    
  
       }
 
        if(this.modo==2){
 
         
         modos="complementario";
       
       }
 
       if(this.modo==3){
 
 
         modos="sustituto";
       
       }
 
       this.dataservice.borrar_rel(id,modos).subscribe(res => {    },
       err => {
         console.log(err)
         this.sacarrelaciones();
       
       }
       );
 
 
       console.log("Borrando: "+id);


      }
      

    }

    mostrardatos(id:string){

      const k = this.productos.findIndex( fruta => fruta.id === id);
      let datos="ERROR";


      if(k!=-1){
        datos=(this.productos)[k].Nombre

      }

      return datos;

    }
sacarrelaciones(){

    this.dataservice.sacar_todas_rel(this.selectedResultValue1.id).subscribe(res => {



        this.productos=[];
        this.relacionesdeEmbalaje=[];
        this.relacionesdeComplement=[];
        this.relacionesdeSusti=[];

  

        for (let i=0; i<res[0].length; i++) {

          if((res[0])[i].Embalaje!=this.selectedResultValue1.id){

            this.relacionesdeEmbalaje.push((res[0])[i]);
            this.datosproducto((res[0])[i].Embalaje)
          }
      
        }

        for (let i=0; i<res[1].length; i++) {


          if((res[1])[i].Complementario!=this.selectedResultValue1.id){

            this.relacionesdeComplement.push((res[1])[i]);
            this.datosproducto((res[1])[i].Complementario)
          }
        } 
        
        for (let i=0; i<this.relacionesdeSusti.length; i++) {


          if((res[2])[i].Sustituto!=this.selectedResultValue1.id){

            this.relacionesdeSusti.push((res[2])[i]);
            this.datosproducto((res[2])[i].Sustituto)
          }
        }

    },
    err => {
      console.log(err)
      this.cancelar();
    }
    );


  }

  search1() {
    console.log("11")
    if (this.inputText1.trim().length >= 4) {
      this.dataservice.getproductos(this.inputText1,"1").subscribe(
        (response: any) => {

          this.searchResults1 = response.rows;
          this.selectedResultIndex1 = -1;
          this.selectedResultValue1 = null;

          if(this.searchResults1.length==1){

            this.selectedResultIndex1=1; 
            this.selectedResultValue1= this.searchResults1[0];
            (<HTMLInputElement>document.getElementById('rel')).focus();
            this.sacarrelaciones();
          }
        },
        (error: any) => {
          console.error('Error al realizar la consulta', error);
        }
      );
    } else {
      this.searchResults1 = [];
    }
  }

  search2() {
  
    if (this.inputText2.trim().length >= 4) {
      this.dataservice.getproductos(this.inputText2,"1").subscribe(
        (response: any) => {
          this.searchResults2 = response.rows;
          this.selectedResultIndex2 = -1;
          this.selectedResultValue2 = null;
          
          if(this.searchResults2.length==1){

            this.selectedResultIndex2=1; 
            this.selectedResultValue2= this.searchResults2[0];
          }
        },
        (error: any) => {
          console.error('Error al realizar la consulta', error);
        }
      );
    } else {
      this.searchResults2 = [];
    }
  }

  selectResult1(index: number) {
    this.selectedResultIndex1 = index;
    this.selectedResultValue1 = this.searchResults1[index];
    (<HTMLInputElement>document.getElementById('rel')).focus();
    this.sacarrelaciones();
  }

  selectResult2(index: number) {
    this.selectedResultIndex2 = index;
    this.selectedResultValue2 = this.searchResults2[index];
    if(this.selectedResultValue1){

      (<HTMLInputElement>document.getElementById('aseptar')).focus();
    }else{

      (<HTMLInputElement>document.getElementById('prod')).focus();
    }

  }
 

  mas(cant:number){



 
      this.cantidadelegida=this.cantidadelegida+cant;
  
  
    
  }
  
  menos(cant:number){
  
  
  if(this.cantidadelegida-cant>1){
    
    this.cantidadelegida=this.cantidadelegida-1;
  
  }else{
  
    this.cantidadelegida=1;
  }
  }
  cambiarrel(){

    this.modo=null;
    this.cantidadelegida=1;
    this.selectedResultIndex2 = -1;
    this.selectedResultValue2 = null;
    this.selectedResultIndex1 = -1;
    this.selectedResultValue1 = null;
    this.searchResults2=[];
    this.searchResults1=[];
    this.inputText2="";
    this.inputText1="";
    this.relacionesdeEmbalaje=null;
    this.relacionesdeComplement=null;
    this.relacionesdeSusti=null;

    (<HTMLInputElement>document.getElementById('prod')).focus();
  }

  cancelar(){

     this.relacionesdeEmbalaje=null;
    this.relacionesdeComplement=null;
    this.relacionesdeSusti=null;
    this.cantidadelegida=1;
    this.selectedResultIndex2 = -1;
    this.selectedResultValue2 = null;
    this.selectedResultIndex1 = -1;
    this.selectedResultValue1 = null;
    this.searchResults2=[];
    this.searchResults1=[];
    this.inputText2="";
    this.inputText1="";
    (<HTMLInputElement>document.getElementById('prod')).focus();
  }
  cambiamodo(modo:number){

    this.modo=modo;

    setTimeout(this.foco, 500,1);


  }
  foco(n:number){


    if(n==1)(<HTMLInputElement>document.getElementById('prod')).focus();


  }
  async aceptar(){
/*
    sacar_todas_rel(id:string): Observable<any>{
    borrar_rel(id:string,tipo:string): Observable<any>{
    crear_rel(tipo:string,nueva:any): Observable<any>{
    actualizar_rel(id:string,tipo:string,nueva:any): Observable<any>{
*/

if(this.selectedResultIndex2==-1 || this.selectedResultIndex1==-1){
  console.log("-- ");
  alert("Tienes que selecionar dos productos!")
  return false;

} 
    let hay=await this.hayrelacion(this.selectedResultValue1.id,this.selectedResultValue2.id)

    if(hay){

      if (!confirm("ATENCION \n Ya existe una relacion de este tipo entre estos dos productos, desea añadir otra ?? ")){
        return false;
      }

    }
    if(this.modo==1 && this.cantidadelegida==1){

      if (!confirm("ATENCION \n a selecionado solo 1 unidad en cada Embalaje. Estas seguro?  ")){
        return false;
      }
  

    }

    
    let tipo="";
    let nueva;


    if(this.modo==1){
      tipo="embalaje";

      nueva={
        Producto:this.selectedResultValue1.id,
        Embalaje:this.selectedResultValue2.id,
        Cantidad:this.cantidadelegida
      };
    }
    
    if(this.modo==2){
      tipo="complementario";
      nueva={
        Producto:this.selectedResultValue1.id,
        Complementario:this.selectedResultValue2.id,
        Cantidad:this.cantidadelegida
      };
    }

    if(this.modo==3){
      tipo="sustituto";
      nueva={
        Producto:this.selectedResultValue1.id,
        Sustituto:this.selectedResultValue2.id
      };
    }
    console.log("TIPO:"+tipo+" - "+JSON.stringify(nueva));
    
    this.dataservice.crear_rel(tipo,nueva).subscribe(
      (response: any) => {
        this.alertService.success("Relacion creada con exito!");
      },
      (error: any) => {
        console.error('Error al realizar la consulta !!', error);
        this.alertService.error("Error:");
      }
    );
  
    this.cancelar();

  }

  async hayrelacion(id:string,relacionado:string){

    let relaciones=await this.dataservice. sacar_todas_rel(id).toPromise();
    let rel2=relaciones[(this.modo-1)];
    

    console.log("rel"+JSON.stringify(relaciones))
    console.log("rel2"+JSON.stringify(rel2))


    for (var i=0; i<rel2.length; i++) {

      if(this.modo==1){

        console.log("1");
        if((rel2[i].Embalaje==relacionado) || rel2[i].Producto==relacionado) return true;

      } 
      if(this.modo==2){
        console.log("2");
       if(rel2[i].Complementario==relacionado || rel2[i].Producto==relacionado ) return true;

      }
      if(this.modo==3){
        console.log("3");
        if(rel2[i].Sustituto==relacionado || rel2[i].Producto==relacionado ) return true;


      } 

    }
  

    return false;

  }
}