import { Component, OnInit } from '@angular/core';
import { DataService } from '../_services/data.service';
import { Ubicacion } from "../models/ubicacion";
import { Ubi_product } from "../models/ubi_product";
import { TokenStorageService } from '../_services/token-storage.service';
import { AlertService } from '../alert.service';
import { JsonPipe } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';
import { Router } from '@angular/router';
import { animationFrame } from 'rxjs/internal/scheduler/animationFrame';

@Component({
  selector: 'app-ultimosmovi',
  templateUrl: './ultimosmovi.component.html',
  styleUrls: ['./ultimosmovi.component.css']
})
export class UltimosmoviComponent implements OnInit {

  constructor( private router:Router,private dataservice :DataService,private token: TokenStorageService,private alertService: AlertService,private rutaActiva: ActivatedRoute) {
  }


  ubicaciones: Ubicacion[];
  movi:any[];
  currentUser:any;
  page: number;
  previousPage: number;
  total:number;

  ngOnInit(): void {
    this.sacartodas();
    this.currentUser = this.token.getUser();
    this.page=1;
    this.previousPage=1;
    this.cargartodos(this.page);


  }

  loadPage(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.cargartodos(this.page);
    }
  }

  cargartodos(page:number){

    this.dataservice.buscamovi(this.token.getUser().id,"*",""+page).subscribe(res => {
 
    
      this.movi=res.rows;
      this.total=res.count;

   //   console.log("respueta movis:"+JSON.stringify(this.movi))

     

    },
    err => console.log(err)
  );
  


  }

  formatearorigen(user:number,origen:number,tipo:string ,destino:number){

let tienda="";

if(user==1){ tienda="Pulsar"} else{

  tienda="Libreria"
} 

if(tipo=="Nuevo Stock"){

return tienda+": "+this.nombreubicaciones(""+destino);

}

if(tipo=="Devuelto"){

  return tienda;
}

if(tipo=="Cambio de ubicacion"){


  return tienda+": "+this.nombreubicaciones(""+origen)+" >> "+this.nombreubicaciones(""+destino);
}


if(tipo=="Añadido a devoluciones" || tipo=="Venta" || tipo=="Modificacion de cantidad" || tipo=="Eliminar de stock" || "Descartado en recepcion"){


  return tienda+": "+this.nombreubicaciones(""+origen);

}


if(tipo=="Traspaso entre tiendas"){

  //si esta ubicando
if(origen==-1){

  if(user=2){
    
    return "Libreria -> Pulsar: "+this.nombreubicaciones(""+destino);
 
   } else{
 
     return  "Pulsar -> Libreria: "+this.nombreubicaciones(""+destino);
   } 

}else{
//si se mandado
  if(user=1){

    console.log("origen: "+origen);

    return "Libreria: "+this.nombreubicaciones(""+origen)+" ->Pulsar";
 
   } else{
 
     return "Pulsar: "+this.nombreubicaciones(""+origen)+" ->  Libreria";
   } 

}


}
return "";
}

ponermas(cant:any){

  if(parseInt(cant.cantidad)>0 && (cant.tipo=="Modificacion de cantidad" || cant.tipo=="Nuevo Stock" )){

    return "+";


  }

  if(cant.tipo=="Eliminar de stock"){

    return "-";
  }
    
}

sacartodas(){

   this.dataservice.gettodasubi("0").subscribe(res => {
 
    
       this.ubicaciones=res;

      
     },
     err => console.log(err)
   );
   
}

nombreubicaciones(id:string){

  
  let devo="";
  const j = this.ubicaciones.findIndex( fruta => fruta.id === parseInt(id));

  if(j!=-1){

    if(this.ubicaciones[j].Padre!=null){
  
      const k = this.ubicaciones.findIndex( fruta => fruta.id === this.ubicaciones[j].Padre);
      devo=devo+this.ubicaciones[k].Nombre+" / "+this.ubicaciones[j].Nombre;
  
    }else{
  
      devo=devo+this.ubicaciones[j].Nombre;
    }
  }
  return devo;
  
  } 
  

}
