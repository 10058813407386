<div class="container mt-4">
    <div class="row">
      <div class="col-md-12">
        <!-- Card with image -->
        <div class="card " style="opacity:0.9" >
          <div class="row">
            <div class="col-md-2"> 
              <!-- Image -->

              <a [routerLink]="['../articulo/',idp.Codigo,1]"><img src="{{idp.Barra | sacarfoto}}"  class="img-fluid"  onerror="this.src='../../assets/nofoto.png'"  width="auto" height="100%" /> </a>
           
            </div>
            <div class="col-md-10">
              <!-- Table with product details -->
              <table class="table table-striped table-bordered">
                <thead>
                    <tr>
                        <td ><a [routerLink]="['../articulo/',idp.Codigo,1]">{{idp.Nombre}}</a></td>
                        <td >{{idp.Barra}}</td>
                        <td >Codigo:{{idp.Codigo}}</td>
                        <td >Precio:{{idp.Precio | sumariva:idp.Iva}}€</td>
                        <td ><app-ponerweb [idp]="idp.id" [tipo]="'producto'"></app-ponerweb></td>
                      </tr>
                </thead>
              </table>
              <!-- Two columns with article names -->

              <div class="row">

                <div class="col-md-5" style="margin-left:10px; font-size:11px">
                    <div *ngIf="complementos.length>0">
                        <p>Complementos:</p>
                        <div *ngFor="let result of complementos;" >
                            <a [routerLink]="['../articulo/',mostrarbarra(result.producto),1]"><div style="border-radius: 10px;background-color: #d1d1f7; align-items: center; padding: 1em;" [innerHTML]="mostrarnombres(result.producto,result.cantidad)"></div></a>
                        </div>
             
                    </div>
           
                </div>
                <div class="col-md-5" style="margin-left:10px; font-size:10px">
                    <div *ngIf="embalajes.length>0">
                        <p>Referencias de embalaje:</p>      
                        <div *ngFor="let result of embalajes;" >
                            <a [routerLink]="['../articulo/',mostrarbarra(result.producto),1]"><div style="border-radius: 10px;background-color:#c8bdcf; align-items: center; padding: 1em;" [innerHTML]="mostrarnombres2(result.producto,result.cantidad)"></div></a>
                        </div>
                    </div>
                 
                    <div *ngIf="contenido.length>0">
                        <p>Contenido:</p>
                        <div *ngFor="let result of contenido;" >
                            <a [routerLink]="['../articulo/',mostrarbarra(result.producto),1]"><div style="border-radius: 10px;background-color:#e2c3f7; align-items: center; padding: 1em;" [innerHTML]="mostrarnombres(result.producto,result.cantidad)"></div></a>
                        </div>
                    </div>
                
                
             
                </div>
              </div>
            </div>
      
          </div>

          <!-- Button for more info -->
          <div class="card-footer text-right">
            <button   *ngIf="mostrars==0" class="btn btn-primary btn-sm" (click)="similaress()" >Buscar productos similares</button>
            
            <div *ngIf="mostrars==1 && detallesimilares.length>0">
                <p>Productos similares:</p>
                <table class="table table-striped table-bordered">
                    <thead class="thead">
                        <tr>
                          <th>Nombre</th>
                          <th>C.Barra</th>
                          <th>Codigo</th>
                          <th>Precio</th>
                          <th>Stock</th>
                        </tr>
                      </thead>
                    <tr *ngFor="let d of detallesimilares">
                        <td><a [routerLink]="['../articulo/',d.Codigo,1]">{{d.Nombre}}</a></td>
                        <td>{{d.Barra}}</td>
                        <td>{{d.Codigo}}</td>
                        <td>{{d.Precio}}</td>
                        <td>{{d.Stock}}</td>
                    </tr>
                    
                </table>


            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
         
