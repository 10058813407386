import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import { HttpClient,HttpParams } from '@angular/common/http';

import { Ubicacion } from "../models/ubicacion";
import { Ubi_product } from "../models/ubi_product";
import { promise } from 'protractor';
import { StringDecoder } from 'string_decoder';


//const API_URL = 'http://localhost:3000/api/data/';
const API_URL = 'http://127.0.0.1:32769/api/data/';
const SOCKET_URL = 'http://127.0.0.1:8080';

@Injectable({
  providedIn: 'root'
})
export class MensajesService {

  

  constructor(private socket: Socket,private http: HttpClient){ }

 // public sendMessage(message) {
 //   this.socket.emit('new-message', message);
 // }
  public getMessages = () => {

      /*return Observable.create((observer) => {
              this.socket.on('actualizacion', (message) => {
               
                  observer.next(message);
              });
      });*/
  }


  public getTotal = () => {

  /* return Observable.create((observer) => {
            this.socket.on('total', (message) => {
   
                observer.next(message);
            });
    });*/
}

  mensajesde(Origen:string,Tipo:string): Observable<any>{


    return this.http.get(API_URL+`mensajeso/${Origen}&${Tipo}`);
    
  }
  mensajespara(Destino:string,Tipo:string): Observable<any>{
  
  
    return this.http.get(API_URL+`mensajesd/${Destino}&${Tipo}`);
    
  }
  cambiarmensaje(id:string, nueva:any): Observable<any>{
  
  
    return this.http.put(API_URL+`mensajes/${id}`,nueva);
    
  }
  crearmensaje( nueva:any): Observable<any>{
  
  
    return this.http.post(API_URL+`mensajes/`,nueva);
    
  }
  
}
