
<button type="button" class="btn btn-danger btn-xs" (click)="volver()">Volver</button>

<h5 style="margin-top:20px">Albaran: {{idalbaran}}</h5>
<table class="table">

  <tr >
    <td>
      <h3 style="margin-top:20px"> {{sacarproveedor(proveedor)}}</h3>

      </td>
      <td>
            
          <button *ngIf="mubiu!=true" type="button" class="btn btn-success btn-xs" (click)="modif()">Ubicar Productos</button>

          <div  *ngIf="mubiu">

            <h3 style="color:red">Selecionar ubicacion</h3>

            <a class="btn btn-success btn-sm btn-block"  (click)="subirselec()" style="margin-top:10px; margin-bottom:10px;  ">Aceptar</a> 
            
            <div class="form-group col-md-12">
           

              <select id="inputState" class="form-control"  [(ngModel)]="seleccionado" [ngModelOptions]="{standalone: true}" (change)="sacartodas()">

                  <option [ngValue]="pa" *ngFor="let pa of padres">{{pa.Nombre}}</option>

              </select>
          </div>

          <div class="form-group col-md-12" style="margin-top:10px; margin-bottom:10px;" *ngIf="seleccionado.id!=null && tienehijos">

              <label for="inputState">Sub-Ubicacion</label>
              <select id="inputState" class="form-control"  [(ngModel)]="seleccionado" [ngModelOptions]="{standalone: true}">

                  <option [ngValue]="pa2" *ngFor="let pa2 of hijos">{{pa2.Nombre}}</option>

              </select>
          </div>
       
          </div>
      

      
          <div  *ngIf="mubiu" class="col-md-12" >
            
                <button *ngIf="!catego" class="btn btn-block btn-success"  style="margin-top: 15px;" (click)="hacerc(1)" >PONER CATEGORIAS</button>
                <button *ngIf="catego" class="btn btn-block btn-secondary"  style="margin-top: 15px;" (click)="hacerc(2)"  >NO PONER CATEGORIAS</button>
          </div>
            
          <div *ngIf="catego && mubiu" class="row">
            <div  class="col-md-4 " style="margin-top: 15px; margin-left: 30px;" >               
                  <select name="miselect" id="miselect" style="width: 250px;" [(ngModel)]="ubiselec" (change)="onChange($event.target.value)">
                    <option [value]="item.id" *ngFor="let item of categoriaspornivel(0)">{{item.nombre}}</option>
                  </select>                    
            </div>
            
            <div  *ngIf="nivelcate>0" class="col-md-4 " style="margin-top: 15px; margin-left: 30px;" >
                  <select name="miselect" id="miselect" style="width: 250px;" [(ngModel)]="ubiselec" (change)="onChange($event.target.value)">
                    <option [value]="item.id" *ngFor="let item of categoriaspornivel(1)">{{item.nombre}}</option>
                  </select>

                  <div  *ngIf="nivelcate>1" class="col-md-4 " style="margin-top: 15px; margin-left: 30px;" >
                      <select name="miselect" id="miselect" style="width: 250px;" [(ngModel)]="ubiselec" (change)="onChange($event.target.value)">
                        <option [value]="item.id" *ngFor="let item of categoriaspornivel(2)">{{item.nombre}}</option>
                      </select>
                  </div>
            </div>
            <div class="col-md-12 " *ngIf="mubiu">
              <div  *ngFor="let ub5i of categoriasaponer">
                
                <p class="col-md-3 "><span class="badge badge-primary " >{{nombrecategoria(ub5i)}}</span><button class="btn btn-sm " (click)="borrarcate(ub5i)" ><i class="fas fa-times"></i></button> </p>
                
               </div>
            </div>
      
            <p  class="col-md-12 "  style="text-align: center;">*** ATENCION: Se borraran las categorias anteriores y se añadiran las seleccionadas ***</p>
          </div>
          <div  *ngIf="mubiu" class="col-md-12 " style="text-align: center;" >

            <input class="form-check-input" type="checkbox" [(ngModel)]="sweb"  id="flexCheckDefault3">
            <label class="form-check-label" for="flexCheckDefault">
              SUBIR A LA WEB
            </label>
          </div>

          <a *ngIf="mubiu" class="btn btn-danger btn-sm btn-block"  (click)="cancelarm()" style="margin-top:10px">Cancelar</a> 
     
        
</tr >



</table>

<table class="table">

    <tr >

     
    <td>Cantidad </td>
    <td>Codigo</td>
    <td>Nombre</td>
    <td>Coste - Coste+iva - PVP</td>

    <td *ngIf="mubiu==true && !t"><a  type="button" class="btn btn-secondary btn-xs" (click)="tods(1)">X</a></td>
    <td *ngIf="mubiu==true && t"><a  type="button" class="btn btn-info btn-xs" (click)="tods(2)">X</a></td>
 
  </tr>
    <tr *ngFor="let re of productos" >

    
      <td>{{re.cantidad}} x </td>
      <td>{{re.articulo}}</td>
      <td>{{sacarnombre(re.articulo)}}</td>
      <td>{{precioiva(re.articulo, re.Precio)}}</td>

      <td><input *ngIf="mubiu==true && re.ubicado==0" type="checkbox" id="cbox2"  [(ngModel)]="re.checked"> <label *ngIf="re.ubicado==1" for="cbox2">UBICADO</label> </td>
 
   
    </tr>

   
    </table>

    <h5 style="margin-top:20px">{{total()}}</h5>