import { JsonPipe } from '@angular/common';
import { JsonpClientBackend } from '@angular/common/http';
import { AbstractJsEmitterVisitor } from '@angular/compiler/src/output/abstract_js_emitter';
import { Component, OnInit } from '@angular/core';
import { Input, Output} from '@angular/core';
import { DataService } from '../_services/data.service';

@Component({
  selector: 'app-tarjeta-resultado',
  templateUrl: './tarjeta-resultado.component.html',
  styleUrls: ['./tarjeta-resultado.component.css']
})
export class TarjetaResultadoComponent implements OnInit {

  @Input() idp:any;
  @Input() tipo:string;

  valor:boolean;
  nueva:any;
  relp1:any[];
  relp2:any[];
  
  detallesimilares:any[];
  embalajes:any;
  contenido:any;
  complementos:any;
  similares:any;
  productos:any;
  mostrars:any;
  constructor(private dataservice :DataService) { }

  ngOnInit(): void {

    this.mostrars=0;
    this.productos=[];
    this.embalajes=[];
    this.complementos=[];
    this.similares=[];
    this.contenido=[];
    this.detallesimilares=[];
    this.mostrardatorelaciones();

  }

similaress() {
 
  this.mostrarnombres3();
  this.mostrars=1;

 } 

async mostrardatorelaciones(){



    let respuest= await this.dataservice.sacar_todas_rel(this.idp.id).toPromise();



    let emba=respuest[0];
    let compl=respuest[1];
    let simi=respuest[2];

    for (var i=0; i<emba.length; i++){
      
      if(emba[i].Producto==this.idp.id){

          //es el producto
          this.embalajes.push({producto:emba[i].Embalaje, cantidad:emba[i].Cantidad})
      }else{

          //es el embajae
          this.contenido.push({producto:emba[i].Producto, cantidad:emba[i].Cantidad})
      }

    }
    for (var i=0; i<compl.length; i++){
      
      if(compl[i].Producto==this.idp.id){

        //es el producto
        this.complementos.push({producto:compl[i].Complementario, cantidad:compl[i].Cantidad})
      }

    }
    for (var i=0; i<simi.length; i++){
     

      if(simi[i].Producto==this.idp.id){

        this.similares.push({producto:simi[i].Sustituto})
      }else{
        this.similares.push({producto:simi[i].Producto})

      }

    }

    this.sacarnombres();




}
async sacarnombres(){



  for (var i=0; i<this.embalajes.length; i++){

    
      const j =  this.productos.findIndex( element => element.id == this.embalajes[i].producto );

      if(j==-1){

        let pro= await this.dataservice.getproductoweb(this.embalajes[i].producto).toPromise();
    
        if(pro)this.productos.push(pro[0]);
      }
  }

  for (var i=0; i<this.complementos.length; i++){

    
    const j =  this.productos.findIndex( element => element.id == this.complementos[i].producto );
    if(j==-1){

      let pro= await this.dataservice.getproductoweb(this.complementos[i].producto).toPromise();
      if(pro)this.productos.push(pro[0]);
    }
}

for (var i=0; i<this.similares.length; i++){

    
  const j =  this.productos.findIndex( element => element.id == this.similares[i].producto );

  console.log(" -j- "+j);
  
  if(j==-1){

    let pro= await this.dataservice.getproductoweb(this.similares[i].producto).toPromise();

console.log(" -proi- "+JSON.stringify(pro))
    if(pro)this.productos.push(pro[0]);
  }
}

for (var i=0; i<this.contenido.length; i++){

    
  const j =  this.productos.findIndex( element => element.id == this.contenido[i].producto );
  if(j==-1){

    let pro= await this.dataservice.getproductoweb(this.contenido[i].producto).toPromise();



    if(pro)this.productos.push(pro[0]);
  }
}


}
mostrarbarra(id:string){

  const i =  this.productos.findIndex( element => element.id == id );


  if(i!=-1){


    return this.productos[i].Codigo;

  }

}
mostrarnombres(id:string,cantida:string){

  const i =  this.productos.findIndex( element => element.id == id );

  if(i!=-1){

    return  `<div class="container">
              <div class="row justify-content-center" >
                <div clas="col-2" >${cantida} x </div>
                <div class="col-10">${this.productos[i].Nombre}</div>
             </div>
            
             <div class="row justify-content-center">
                <div clas=col-4">C.Barras:${this.productos[i].Barra}  - </div>
                <div clas=col-4">Codigo:${this.productos[i].Codigo}  - </div>
                <div clas=col-2">Precio:${(parseFloat(this.productos[i].Precio)*parseFloat(this.productos[i].Iva)).toFixed(2)}€</div>
              
            </div>
            </div>`
  }

  return "Cargando..."

}

mostrarnombres2(id:string,cantida:string){

  const i =  this.productos.findIndex( element => element.id == id );

  if(i!=-1){

    return  `<div class="container">
              <div class="row justify-content-center" >
                <div class="col-12">${this.productos[i].Nombre}</div>
             </div>
            
             <div class="row justify-content-center">
                <div clas=col-4">C.Barras:${this.productos[i].Barra}  - </div>
                <div clas=col-4">Codigo:${this.productos[i].Codigo} - </div>
                <div clas=col-2">Precio:${(parseFloat(this.productos[i].Precio)*parseFloat(this.productos[i].Iva)).toFixed(2)}€</div>
                <div clas="col-6" ><b>(Contiene ${cantida} unidades de este articulo)</b></div>
            </div>
            </div>`
  }

  return "Cargando..."

}

async mostrarnombres3(){


console.log(" -d- "+JSON.stringify(this.similares))


  for (var i=0; i<this.similares.length; i++){


    const j =  this.productos.findIndex( element => element.id == this.similares[i].producto);

    let precio=(parseFloat(this.productos[j].Precio)*parseFloat(this.productos[j].Iva)).toFixed(2);
    let stock = await this.sacarstock(this.productos[j].id);
    this.detallesimilares.push({Nombre:this.productos[j].Nombre,Barra:this.productos[j].Barra,Codigo:this.productos[j].Codigo,Precio:precio,Stock:stock})

  }




}

async sacarstock(id:string){


  let total=0;
        
  let stock=await this.dataservice.getubi_product_producto(id).toPromise();

         for (let i = 0; i < stock.length; i++){ 
 
           total=total+parseInt(stock[i].total);
        }

    
return total;
      



}
}

