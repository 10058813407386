import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { BoardUserComponent } from './board-user/board-user.component';
import { BoardModeratorComponent } from './board-moderator/board-moderator.component';
import { BoardAdminComponent } from './board-admin/board-admin.component';
import { GestorUbicacionesComponent } from './gestor-ubicaciones/gestor-ubicaciones.component';
import {   MoverComponent } from './mover/mover.component';
import {  NuevostockComponent} from './nuevostock/nuevostock.component';
import {VercategoriaComponent  } from './vercategoria/vercategoria.component';
import {DevolucionesComponent  } from './devoluciones/devoluciones.component';
import {AvisosComponent  } from './avisos/avisos.component';
import {FichaarticuloComponent  } from './fichaarticulo/fichaarticulo.component';
import {CambiardetiendaComponent  } from './cambiardetienda/cambiardetienda.component'; 
import {UltimosmoviComponent  } from './ultimosmovi/ultimosmovi.component';
import {GestorCategoriasComponent  } from './gestor-categorias/gestor-categorias.component';
import {VerdetallecateComponent  } from './verdetallecate/verdetallecate.component';
import {GestorProveedoresComponent  } from './gestor-proveedores/gestor-proveedores.component';
import {VeredetalleproveComponent  } from './veredetalleprove/veredetalleprove.component';
import {CrearavisosComponent  } from './crearavisos/crearavisos.component';
import {VeravisosComponent  } from './veravisos/veravisos.component';
import {EntradasComponent  } from './entradas/entradas.component';
import {DetallealbaranComponent  } from './detallealbaran/detallealbaran.component'
import {InformeventasComponent  } from './informeventas/informeventas.component'
import {VentascComponent  } from './ventasc/ventasc.component'
import {InventarioComponent  } from './inventario/inventario.component'
import {StockPorBarraComponent} from './stock-por-barra/stock-por-barra.component'
import {RelacionesComponent} from './relaciones/relaciones.component'

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'user', component: BoardUserComponent },
  { path: 'mod', component: BoardModeratorComponent },
  { path: 'admin', component: BoardAdminComponent },
  { path: 'nuevo/:id', component:NuevostockComponent},
  { path: 'gestU', component:  GestorUbicacionesComponent },
  { path: 'mover/:id', component:  MoverComponent },
  { path: 'categoria/:id', component: VercategoriaComponent},
  { path: 'articulo/:id/:codigo', component: FichaarticuloComponent},
  { path: 'articulo/:id', component: FichaarticuloComponent},
  { path: 'devoluciones', component: DevolucionesComponent},
  { path: 'avisos', component:AvisosComponent },
  { path: 'cambiar', component:CambiardetiendaComponent },
  { path: 'umovi', component:UltimosmoviComponent},
  { path: 'gescat', component:GestorCategoriasComponent },
  { path: 'dcategoria/:id', component:VerdetallecateComponent },
  { path: 'dproveedor/:id', component:VeredetalleproveComponent },
  { path: 'proveedores', component:GestorProveedoresComponent },
  { path: 'creaaviso', component:CrearavisosComponent },
  { path: 'veraviso', component:VeravisosComponent },
  { path: 'entradas', component:EntradasComponent },
  { path: 'dalbaran/:id', component:DetallealbaranComponent},
  { path: 'ventas', component:InformeventasComponent},
  { path: 'ventasc', component:VentascComponent},
  { path: 'inventario', component:InventarioComponent},
  { path: 'stockbarra', component:StockPorBarraComponent},
  { path: 'relaciones', component:RelacionesComponent},
  { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
