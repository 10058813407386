
import { Component, OnInit } from '@angular/core';
import { DataService } from '../_services/data.service';
import { Ubicacion } from "../models/ubicacion";
import { Ubi_product } from "../models/ubi_product";
import { TokenStorageService } from '../_services/token-storage.service';
import { AlertService } from '../alert.service';
import { JsonPipe } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';
import { Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Component({
  selector: 'app-cambiardetienda',
  templateUrl: './cambiardetienda.component.html',
  styleUrls: ['./cambiardetienda.component.css']
})  
export class CambiardetiendaComponent implements OnInit {

  ubicaciones: Ubicacion[]; //todas las ubicaciones  
  tiendas:any[];
  tiendaaenviar:number;
  padres :Ubicacion[];  //solo las padres
  
  discrepancias:any[];
  hijos :Ubicacion[]=[]; //los hijos de la categoria selecionada
  todosantiguas :Ubi_product[]=[];
  todosantiguastemp :Ubi_product[]=[];

  antigua:Ubi_product;

  artubicando:Ubi_product={

    id:null,
    productosId:null,
    ubicacionesId:null,
    user:null,
    cantidad:null  


  };

  ubicando:boolean;

  producto:any;
  currentUser : any;
  inicio: number;
  nombre:string="";
  cantidad:number=1;
  edit: boolean = false;
  tienehijos: boolean = false;
  hayresultados: boolean = false; 
  seleccionado :Ubicacion={
    id:null,
    user:null,
    tienda:0,
    Nombre: "",
    Padre:null,
    Notas:"",
    Prioridad:0
  }
discrepanciaubicando:string;
  idubi:string;
  cosa: Ubicacion ={
    id:null,
    user:null,
    tienda:0,
    Nombre: "",
    Padre:null,
    Notas:"",
    Prioridad:0
  }
  barra:string;
  movimiento:any={

    cantidad:0,
    user:0,
    productoId:0,
    destino:0,
    origen:-1,
    tipo:""
  
  }

  cantidadelegida:number;
///revisadas
uddis:boolean;
  listaenviados:any[];
  listaporrecibir:any[];
  nombredetienda:string;
  esta:Ubi_product[];
  transito:any={
    
    productosId:0,
    origen:0, 
    destino:0, 
    cantidad:0
  }
  productoubicando:any={
    nombre:"", 
    barra:0,
    cantidad:0


  }

  constructor( private router:Router,private dataservice :DataService,private token: TokenStorageService,private alertService: AlertService,private rutaActiva: ActivatedRoute) {

}



//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
ngOnInit() {

  this.cantidadelegida=1;
  this.tiendaaenviar=-1;
  this.inicio=0;
  this.uddis=false;
  this.idubi=this.rutaActiva.snapshot.params.id;
  this.ubicando=false;
  this.currentUser = this.token.getUser();
  this.sacartiendas()

  
  this.sacartodas();
  this.sacarenviados();
  this.sacarporrecibir();
  this.sacardiscrepancias();
}
siguiente(tienda:any){

  this.nombredetienda=tienda.name;
  this.tiendaaenviar=tienda.id;
}
//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------


//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------

sacarnombre(id:string){


  let devo="";
  const j = this.ubicaciones.findIndex( fruta => fruta.id === parseInt(id));
  if(j!=-1){
    if(this.ubicaciones[j].Padre!=null){
  
      const k = this.ubicaciones.findIndex( fruta => fruta.id === this.ubicaciones[j].Padre);
      devo=this.ubicaciones[k].Nombre+" / "+this.ubicaciones[j].Nombre;
  
    }else{
  
      devo=""+this.ubicaciones[j].Nombre;
    }
  }
  return devo;
  

}


//---------------------------------------------------------
//---------------------------------------------------------
sacardiscrepancias(){
  console.log("Sacando discrepancias");
  this.discrepancias=[];
  this.dataservice.sacardiscrepanciaportipo("DEVOLUCION VENTA",this.currentUser.tienda).subscribe(res => {

   
    this.discrepancias=res;
    console.log("DEVO VENTA: "+JSON.stringify(this.discrepancias));

  },
  err => console.log(err)
);
 


}

descartartr(){
  if(confirm("tas segur@?")) {
  for(let i=0; i<  this.listaporrecibir.length;i++){

    this.descartar(this.listaporrecibir[i].id);
  }
  }
}

descartartd(){
  if(confirm("tas segur@?")) {
  for(let i=0; i<  this.discrepancias.length;i++){
  this.descartard(this.discrepancias[i].id)
  }
}


}
//---------------------------------------------------------
//---------------------------------------------------------

sacartodas(){

  this.dataservice.gettodasubi("0").subscribe(res => {

   
      this.ubicaciones=res;

      let tota=this.ubicaciones.length;
      this.tienehijos=false;
      this.hijos=[];

      for (var i=0; i<tota; i++) {
        

        if(this.ubicaciones[i].Padre==this.seleccionado.id ){

         let hijo:Ubicacion=this.ubicaciones[i];

          this.hijos.push(hijo);
          
        }
      }
    
      if(this.hijos.length>0){

        this.tienehijos=true;

      }

    },
    err => console.log(err)
  );
  
}
sacarhijos(){

  this.sacartodas();
  
  console.log("categori des:"+this.seleccionado.id); //categoria destino
}
sacapadres(){

  this.dataservice.getpa(""+this.currentUser.tienda).subscribe(res => {

  this.padres=res;

  if(this.padres.length>0){
    
    this.hayresultados=true;

  }else{
    this.hayresultados=false;
  }  
  },
  err => console.log(err)
  );

}


//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------



//si son distinta ubicacion
/*
productoId:req.body.ProductoId,
cantidad:req.body.Cantidad,
user:req.body.User,
estado: "activo"
*/
subir(){

  //si son distinta ubicacion
 
  
  this.transito.destino=this.tiendaaenviar;

  this.movimiento.destino=-1;
  this.transito.productosId=this.producto.id;
  this.transito.origen=this.token.getUser().tienda;
  this.transito.cantidad=this.cantidadelegida;
  
  this.movimiento.origen=this.antigua.ubicacionesId;


  if(this.antigua.cantidad>this.cantidadelegida){

    this.antigua.cantidad=this.antigua.cantidad-this.cantidadelegida;
  
  
    // descuaneta 1
    this.dataservice.modificarubi_produc(""+this.antigua.id,this.antigua).subscribe(res => {
  
    },
    err => console.log(err)
    );
  
  
  }else{
  
  
    // borra
    this.dataservice. borrarubi_produc(""+this.antigua.id).subscribe(res => {
  
    },
    err => console.log(err)
    );
  
  
  }
  
   this.movimiento.cantidad= this.cantidadelegida;
   this.movimiento.user=this.token.getUser().id;
   this.movimiento.productoId=this.producto.id;
 
   
 

  //crea transito 
  this.dataservice.creatransito(this.transito).subscribe(res => {


  this.crearmovi("Traspaso entre tiendas");
  this.sacarporrecibir();
  this.sacarenviados();
  },
  err => console.log(err)
  );
  
      
  

  
  }
  
//---------------------------------------------------------
//---------------------------------------------------------




async cargar(){

  let busqueda= (<HTMLInputElement>document.getElementById('barr')).value;
 
  let error=false;
 
  if(this.inicio==1 ){
 
     if(parseInt(this.idubi)!=null){
     this.subir();
 
     }else{
       error=true;
       this.alertService.error("Error de ubicacion");
       this.playAudio("fail");
     }
  }
  
  
  if(!error){
 
         await this.dataservice. getproducto(""+busqueda).subscribe(res => {
 
             this.producto=res;
            
             console.log(JSON.stringify(this.producto))
             
             if(res!=null){
 
               this.sacartodas();
               this.estaubicado(""+this.producto.id);
               this.cantidad=1;
               this.playAudio("ok");
             }else {
               this.inicio=2;
               this.playAudio("fail");
             }
           },
           err => console.log(err)
           );
 
          
  }
  (<HTMLInputElement>document.getElementById('barr')).value="";
  (<HTMLInputElement>document.getElementById('barr')).focus();
 }
 
//---------------------------------------------------------
//---------------------------------------------------------
cancelar(){

this.inicio=0;
this.cantidad=1;
this.producto=[];
this.todosantiguas=[];
this.todosantiguastemp=[];

(<HTMLInputElement>document.getElementById('barr')).focus();
}

//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------

cambiar(nueva:Ubi_product){
 
  this.antigua=nueva;

  (<HTMLInputElement>document.getElementById('barr')).focus();
}

//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------

filtrar(){


  for (var i=0; i<this.todosantiguas.length; i++) {

    if(this.todosantiguas[i].user==this.currentUser.tienda)this.todosantiguastemp.push(this.todosantiguas[i]);
  }

  this.todosantiguas=this.todosantiguastemp;
}

estaubicado(barr:string){
  

  this.dataservice.getubi_product_i(barr).subscribe(res => {

    console.log("esta en"+JSON.stringify(res))

    this.todosantiguas=res;


    if(res!=null){

       console.log("Esta en la ubicacion"+JSON.stringify(this.todosantiguas))

       this.filtrar();
       if(this.todosantiguas.length>0){

         this.antigua=this.todosantiguas[0];
         this.inicio=1;
       }else{

        this.antigua=null;
        this.inicio=2;

       }
     

    }else{

      this.todosantiguas=[];
      this.antigua=null;
      this.inicio=2;
    }
  
  },
  err => console.log(err)
  );

 

}

sacarenviados(){

this.listaenviados=[];

let origen=this.currentUser.tienda;



  this.dataservice.buscatransitoorigen(origen).subscribe(res => {

    //this.listaenviados=res;

  },
  err => console.log(err)
  );
}


sacarporrecibir(){

  this.listaporrecibir=[];
  let destino=this.currentUser.tienda;

  this.dataservice.buscatransitodestino(destino).subscribe(res => {
 
    this.listaporrecibir=res;
    console.log("Por recibi: "+JSON.stringify(res))
  },
  err => console.log(err)
  );
}



playAudio(tipo :string){

  let audio = new Audio();
  if(tipo==="ok"){
    audio.src = "../../../assets/beep-ok.wav";
  }else{
    audio.src = "../../../assets/beep-f.wav";
  }


  audio.load();
  audio.play();
}


//********************ubicar******************** */

ubicar(id:string){

  console.log()
  this.ubicando=true;
  this.sacapadres();
  
  this.artubicando.id=parseInt(id);
  this.artubicando.user=this.currentUser.id;
  console.log("dasdas");
  this.dataservice.buscatransitoid(id).subscribe(res => {
   
  console.log("producto ubi:"+JSON.stringify(res))
    this.artubicando.productosId=res.productoId;
    this.artubicando.cantidad=res.cantidad;
   
    this.productoubicando.barra=res.producto.Barra;
    this.productoubicando.nombre=res.producto.Nombre;
    this.productoubicando.cantidad=res.cantidad;


    console.log("caantidad a ubicar:"+ this.artubicando.cantidad)

  },
  err => console.log(err)
  );

  }
  ubicar2(producto:any, iddiscrepancia:string){

    console.log("ubicando :"+JSON.stringify(producto));
    this.ubicando=true;
    this.uddis=true;
    this.sacapadres();
    this.discrepanciaubicando=iddiscrepancia;
    this.artubicando.user=this.currentUser.tienda;
 

      this.artubicando.productosId=producto.id;
      this.artubicando.cantidad=1;
     
      this.productoubicando.barra=producto.Barra;
      this.productoubicando.nombre=producto.Nombre;
      this.productoubicando.cantidad=1;
/*
    this.sacapadres();
    
    this.artubicando.id=parseInt(idproducto);
    this.artubicando.user=this.currentUser.tienda;
 
    this.dataservice.buscatransitoid(id).subscribe(res => {
     
    console.log("producto ubi:"+JSON.stringify(res))
      this.artubicando.productosId=res.productoId;
      this.artubicando.cantidad=res.cantidad;
     
      this.productoubicando.barra=res.producto.Barra;
      this.productoubicando.nombre=res.producto.Nombre;
      this.productoubicando.cantidad=res.cantidad;
  
  
      console.log("caantidad a ubicar:"+ this.artubicando.cantidad)
  
    },
    err => console.log(err)
    );
  */
    }

    ponerdiscrepancia(){


  

      this.artubicando.ubicacionesId=this.seleccionado.id;
   
      this.movimiento.cantidad= "1";
      this.movimiento.user=this.artubicando.user;
      this.movimiento.productoId=this.artubicando.productosId;
      this.movimiento.destino=this.artubicando.ubicacionesId;
      this.movimiento.origen=-1;
   
      let cantidadantigua=0;
     
     this.dataservice.crearubi_product(this.artubicando).subscribe(res => {
     
                     this.crearmovi("Ubicando productos devueltos en caja");
   
                     this.descartard(this.discrepanciaubicando);
                 
                   },
                     err =>{
     
          });
     
               
     
             
        
    
   
   
       this.ubicando=false;
       this.uddis=false;


    }


  poneren(){
  
  
   

   let idtransito=this.artubicando.id;
   
   this.artubicando.ubicacionesId=this.seleccionado.id;
   this.movimiento.cantidad= this.artubicando.cantidad;
   this.movimiento.user=this.artubicando.user;
   this.movimiento.productoId=this.artubicando.productosId;
   this.movimiento.destino=this.artubicando.ubicacionesId;
   this.movimiento.origen=-1;

   let cantidadantigua=0;
  
    this.dataservice.getubi_product_i(""+this.artubicando.productosId).subscribe(res => { 
  
          if(res){
  
            let encontrao="";
            this.esta=res;
            console.log("resultado buscar anterior "+JSON.stringify(res))

           for (let i = 0; i < this.esta.length; i++) {

             if(this.esta[i].ubicacionesId==this.artubicando.ubicacionesId){
                encontrao=""+this.esta[i].id;
                cantidadantigua=this.esta[i].cantidad;
                console.log("caantidad que habia:"+  cantidadantigua +"id ubi-pro: "+encontrao)
              }
            }

            if(encontrao!=""){
  
              
              this.artubicando.cantidad= this.artubicando.cantidad+cantidadantigua;
         
              console.log("caantidad total:"+this.artubicando.cantidad+" encontrao: "+encontrao)
          
            delete this.artubicando.id;


           //   this.stock.id= parseInt(encontrao);
                this.dataservice.modificarubi_produc(encontrao,this.artubicando).subscribe(res => {
  
                    this.crearmovi("Traspaso entre tiendas");

                 this.elimirtransito(idtransito);
                  },
                  err =>{
  
                  });
  
   
            }else{
              
                delete this.artubicando.id;
           
                this.dataservice.crearubi_product(this.artubicando).subscribe(res => {
  
                  this.crearmovi("Traspaso entre tiendas");

                      this.elimirtransito(idtransito);
              
                },
                  err =>{
  
                });
  
            }
  
          }
     
    },
    err =>{
           this.cancelar();
    });


    this.ubicando=false;
  
  }

elimirtransito(id:number){


    this.dataservice.borratransito(""+id).subscribe(res => {
   
      this.sacarporrecibir();

      },
      err => console.log(err)
      );
  }

crearmovi(tipo:string){

  this.movimiento.tipo=tipo;

  this.dataservice.creamovi(this.movimiento).subscribe(res => {

    this.movimiento={

      cantidad:0,
      user:0,
      productoId:0,
      destino:0,
      origen:0,
      tipo:""
    
    }
    this.cancelar();

  },
    err =>{

  });



}


  cancelarsubir(){
  
    this.ubicando=false;
  
  }




  
//********************ubicar******************** */

descartar(id:string){


  this.dataservice.buscatransitoid(id).subscribe(res => {
   
    

        this.movimiento.cantidad= res.cantidad;
        this.movimiento.user=this.currentUser.id;
        this.movimiento.productoId=res.productoId;
        this.movimiento.destino=-1;
        this.movimiento.origen=-1;

        this.crearmovi("Descartado en recepcion");

        this.elimirtransito(parseInt(id));
    },
    err => console.log(err)
    );
  


  }

  descartard(id:string){

  


    this.dataservice.borrarcrepancia(id).subscribe(res => {
     
          this.sacardiscrepancias();
      },
      err => console.log(err)
      );
    
  
  
    }
  

  nombretienda(id:number){

    let nombre="ERROR"
  
  
   
    const i = this.tiendas.findIndex( fruta => fruta.id === id );
  
   
  
    if(i!=-1){
    
     nombre=this.tiendas[i].name;
   
  
    }
  
    return nombre 
  
  }
  
  sacartiendas(){


  
  this.dataservice.sacartiendas().subscribe(
    res => {

      this.tiendas = res;
      

    },
    err => {
  
    }
  );

}

mas(cant:number){



  if((this.cantidadelegida+cant)>this.antigua.cantidad){

    this.cantidadelegida=this.antigua.cantidad;

  }else{

    this.cantidadelegida=this.cantidadelegida+cant;


  } 
}

menos(cant:number){


if(this.cantidadelegida-cant>1){
  
  this.cantidadelegida=this.cantidadelegida-1;

}else{

  this.cantidadelegida=1;
}
}

}


  
 