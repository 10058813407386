<div class="container">
  <div *ngIf="!nueva"  >
    <div class="input-group">
      <div class="card-body d-flex justify-content-between align-items-center">
      <form action="">
      <input class="form-control col-12"  id="filt"  type="search" placeholder="filtrar" aria-label="Search">
      <p> (No filtra sububicaciones)</p>
      <button class="btn btn-success btn-block" (click)="sacartodas()" style="display: none"></button>
      </form>
      <a class="btn btn-primary btn-lg " style="background-color: green; margin-top: 15px; margin-bottom: 15px;" (click)="nuevau()">+ Nueva Ubicacion +</a>
    </div>
  </div>
    <div   class="float-right" style="margin: 30px;">
    
      <button  *ngIf="!fusion" class="btn btn-dark " (click)="fusion=true" >Fusionar Ubicaciones</button>
      <button  *ngIf="!fusion" class="btn btn-info " (click)="aimprimir=1; fusion=true;" >IMPRIMIR CODIGOS </button>
      <div *ngIf="fusion">
        <h4 *ngIf="aimprimir==1">Imprimir Etiquetas</h4><h4 *ngIf="aimprimir==0">Fusionando</h4>
        <p>Ubicaciones selecionadas:{{this.afusionar.length}}</p>
        <button  *ngIf="aimprimir==1" class="btn btn-outline-primary btn-sm" (click)="seleccionartodos()" >Todas</button>
        <button  *ngIf="aimprimir==1" class="btn btn-outline-secondary btn-sm " (click)="seleccionarningunp()" >Ninguna</button><br><br>
        <button  *ngIf="aimprimir==1" class="btn btn-success " (click)="imprimiretiquetas(0)" >Aceptar</button>
        <button  *ngIf="aimprimir==0" class="btn btn-success " (click)="fusionar()" >Aceptar</button>
        <button   class="btn btn-danger " (click)="cancelarfusion()" >cancelar</button>
  
      </div>

    </div> 
   
   
  </div>
    <header class="jumbotron" *ngIf="nueva">
  
      <div *ngIf="nueva" class="card">
        <div class="card-body">
          <h2 *ngIf="editando">Editar Ubicacion: </h2>
          <h2 *ngIf="!editando">Nueva Ubicacion</h2>
          <form action="">
            <div  class="form-group">
          
              <input type="text" class="form-control"  [ngModel]="cosa.Nombre"  [ngModelOptions]="{standalone: true}" id="nombre" placeholder="Nombre">
              
              <div class="form-row" style="margin-top:10px;">

              
              <textarea class="form-control" id="exampleFormControlTextarea1" [ngModel]="cosa.Notas"  [ngModelOptions]="{standalone: true}" rows="3" placeholder="Notas" ></textarea>
         
            </div>
              
            </div>
     
          
            
           <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
            <label class="form-check-label" for="defaultCheck1">
              Escaparate? (Sera la ultima de la que se quitaran los productos en caso de estar en dos sitos)
            </label>

          
          </div>
          
            <button class="btn btn-lg btn-success" (click)="cargar()">
              Salvar
            </button>
            <button class="btn btn-danger" Style="margin-left: 50px; " (click)="cancelar()">
              Cancelar
            </button>

            
          </form>
        </div>
        <alert></alert>
      </div>
      
    </header>
  
  
  <div *ngIf="!editando && !nueva">

    <button class="btn btn-info" Style="margin-left: 50px; " (click)="formatear()">
      {{formato}}
    </button>

    <div *ngIf="!lista">
    <div class="row">
        <div class="block" *ngFor="let ubi of ubicaciones2">
          <div *ngIf="ubi.Padre==null" style="margin-top: 10px;" >
            <div class="card text-center" >
      
              <div class="card-header bg-dark text-white align-items-center " >
              
                    <div class="card-title d-flex justify-content-between align-items-center">

                      <input *ngIf="(fusion && !tienehijos(ubi.id)) || (fusion && aimprimir==1)" type="checkbox" id="cbox2" style="margin: 20px;"  (change)="changed($event,ubi.id)">


                      <h6><i *ngIf="ubi.Prioridad==1" class="fas fa-snowflake"></i> {{ubi.Nombre}} 
                      </h6>
                      <button class="btn btn-success btn-sm" (click)="nuevahija(ubi.id)"><i class="fas fa-plus-square"></i></button>
                    </div>

                    <button class="btn btn-sm btn-warning " (click)="editar(ubi.id)"><i class="fas fa-edit"></i></button>

                    <button *ngIf="ubi.Notas!=''" class="btn btn-sm btn-info" (click)="hideme2[ubi.id]=!hideme2[ubi.id]"><i class="fas fa-sticky-note"></i></button>
                    <button *ngIf="((toti(ubi.id))>0)"class="btn btn-sm btn-secondary" [routerLink]="['../categoria/',ubi.id]"><i class="fas fa-eye"></i> {{toti(ubi.id)}}</button>
                    <button class="btn btn-sm btn-success " [routerLink]="['../mover/',ubi.id]"><i class="fas fa-exchange-alt"></i></button>
                    <button class="btn btn-sm btn-success " [routerLink]="['../nuevo/',ubi.id]"><i class="fas fa-cart-plus"></i></button>
                    <button class="btn btn-sm btn-info" (click)="imprimiretiquetas(ubi.id)"><i class="fas fa-barcode"></i></button>
                    <button *ngIf="!((toti(ubi.id))>0) && !tienehijos(ubi.id)" class="btn btn-sm btn-danger " (click)="borrar(ubi.id)"><i class="fas fa-trash-alt"></i></button>
            
              </div>   
            

              <div *ngIf="tienehijos(ubi.id)" class="card-body" >

                <div [hidden]="!hideme2[ubi.id]">
                  <p class="small font-italic">{{ubi.Notas}}</p>
                </div>

                <button *ngIf="tienehijos(ubi.id)" class="btn btn-info btn-block" (click)="hideme[ubi.id] = !hideme[ubi.id]"><i class="fas fa-caret-square-down"></i> Sub-Ubicaciones</button>

                    
                      <div [hidden]="!hideme[ubi.id]">

                      <div *ngFor="let ubi2 of ubicaciones">
              
                        <div *ngIf="ubi2.Padre==ubi.id" class="card text-center" style="margin-top: 10px; background-color: gray;">

                          <input *ngIf="fusion" type="checkbox" id="cbox2" style="margin: 20px;"  (change)="changed($event,ubi2.id)">

                          <p><i *ngIf="ubi2.Prioridad==1" class="fas fa-snowflake"></i>{{ubi2.Nombre}}</p>

                          <div [hidden]="!hideme2[ubi2.id]">
                            <p class="small font-italic">{{ubi2.Notas}}</p>
                          </div>

                        <div class="row align-items-center" style=" display: flex; justify-content: center;">

                          <button class="btn btn-sm btn-warning " (click)="editar(ubi2.id)"><i class="fas fa-edit"></i></button>
                          <button *ngIf="ubi2.Notas!=''" class="btn btn-sm btn-info" (click)="hideme2[ubi2.id]=!hideme2[ubi2.id]"><i class="fas fa-sticky-note"></i></button>
                          <button *ngIf="((toti(ubi2.id))>0)" class="btn btn-sm btn-secondary " [routerLink]="['../categoria/',ubi2.id]"><i class="fas fa-eye"></i> {{toti(ubi2.id)}}</button>
                          <button *ngIf="((toti(ubi2.id))==0)" class="btn btn-sm btn-danger " (click)="borrar(ubi2.id)"><i class="fas fa-trash-alt"></i></button>
                          <button class="btn btn-sm btn-success " [routerLink]="['../mover/',ubi2.id]"><i class="fas fa-exchange-alt"></i></button>
                          <button class="btn  btn-sm btn-success " [routerLink]="['../nuevo/',ubi2.id]"><i class="fas fa-cart-plus"></i></button>
                      </div>
                      </div>
                      </div>
      
                    </div>

              
      
                  
                </div>
              </div> 
            </div>
          </div>
    </div> 
  </div>
    
  </div>
  <div *ngIf="lista">
    <table class="table table-dark">


      <div  *ngFor="let ubi of ubicaciones2">

        <tr *ngIf="ubi.Padre==null"  >
    
                  <td>  <i *ngIf="ubi.Prioridad==1" class="fas fa-snowflake"></i> {{ubi.Nombre}} </td>
                  <td> 

                  <button class="btn btn-sm btn-warning " (click)="editar(ubi.id)"><i class="fas fa-edit"></i></button>
                  <button *ngIf="((toti(ubi.id))>0)"class="btn btn-sm btn-secondary" [routerLink]="['../categoria/',ubi.id]"><i class="fas fa-eye"></i> {{toti(ubi.id)}}</button>
                  <button class="btn btn-sm btn-success " [routerLink]="['../mover/',ubi.id]"><i class="fas fa-exchange-alt"></i></button>
                  <button class="btn btn-sm btn-success " [routerLink]="['../nuevo/',ubi.id]"><i class="fas fa-cart-plus"></i></button>
                  </td> 
                  <td><button *ngIf="!((toti(ubi.id))>0) && !tienehijos(ubi.id)" class="btn btn-sm btn-danger " (click)="borrar(ubi.id)"><i class="fas fa-trash-alt"></i></button></td>
                 
                  <td class="text-right"><button class="btn btn-success btn-sm" (click)="nuevahija(ubi.id)"><i class="fas fa-plus-square"></i></button></td>
                  <td class="text-right"><button *ngIf="ubi.Notas!=''" class="btn btn-sm btn-info" (click)="hideme2[ubi.id]=!hideme2[ubi.id]"><i class="fas fa-sticky-note"></i></button></td>
                  <td class="text-right"><button *ngIf="tienehijos(ubi.id)" class="btn btn-info btn-block" (click)="hideme[ubi.id] = !hideme[ubi.id]"><i class="fas fa-caret-square-down"></i> Sub-Ubicaciones</button>
                  </td>

          

        </tr>
        <tr>


              <div [hidden]="!hideme2[ubi.id]">
                <p class="small font-italic">{{ubi.Notas}}</p>
              </div>

        </tr>  
                  
                <div [hidden]="!hideme[ubi.id]">

                    <div *ngFor="let ubi2 of ubicaciones">
            
                      <tr *ngIf="ubi2.Padre==ubi.id"  style=" background-color: gray;">
                      
                        <td><i *ngIf="ubi2.Prioridad==1" class="fas fa-snowflake"></i>{{ubi2.Nombre}}</td>

                        <td>
                          <button *ngIf="((toti(ubi2.id))>0)" class="btn btn-sm btn-secondary " [routerLink]="['../categoria/',ubi2.id]"><i class="fas fa-eye"></i> {{toti(ubi2.id)}}</button>
                        </td>
                        <td>
                          <button class="btn btn-sm btn-warning " (click)="editar(ubi2.id)"><i class="fas fa-edit"></i></button>
                          <button *ngIf="ubi2.Notas!=''" class="btn btn-sm btn-info" (click)="hideme2[ubi2.id]=!hideme2[ubi2.id]"><i class="fas fa-sticky-note"></i></button>
                        
                        </td>
                     
                        <td>
                          <button class="btn btn-sm btn-success " [routerLink]="['../mover/',ubi2.id]"><i class="fas fa-exchange-alt"></i></button>
                          <button class="btn  btn-sm btn-success " [routerLink]="['../nuevo/',ubi2.id]"><i class="fas fa-cart-plus"></i></button>
                        </td>
                        <td class="text-right">
                          <button *ngIf="((toti(ubi2.id))==0)" class="btn btn-sm btn-danger " (click)="borrar(ubi2.id)"><i class="fas fa-trash-alt"></i></button>
                       
                        </td>
                          

                      </tr>

                        <tr [hidden]="!hideme2[ubi2.id]">
                          <p class="small font-italic">{{ubi2.Notas}}</p>
                        </tr>

                
                    </div>
    
                  </div>

            
    
                
              </div>
             
  
    </table>

  </div> 

  </div>  

