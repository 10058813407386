


<button type="button" class="btn btn-danger btn-xs" (click)="volver()">Volver</button>

    <h3>CATEGORIA: {{nombreu}} , {{total}} Articulos</h3>
    
<div class="row">
    <div class="col-md-8 col-md-offset-1">
        <div class="card-body d-flex justify-content-between align-items-center">
				
            <ngb-pagination *ngIf="total>10" [collectionSize]="total" [maxSize]="10" [(page)]="page" (pageChange)="loadPage($event)"></ngb-pagination>
            <button type="button" class="btn btn-danger" (click)="vaciar()"><i class="fas fa-trash-alt"></i> BORRAR TODOS LOS ARTICULOS </button>

        </div>
    </div>
    <div class="col-md-3">
        <h2 style="margin-top:15px ;">Añadir articulos: </h2>
       
               <form action="">
                 <div class="form-group">
                   <input type="text" id=barr name="title" [(ngModel)]="barra" placeholder="Codigo de Barras" class="form-control" autofocus>
                   
                 </div>
                 
                 <button class="btn btn-success btn-block" (click)="cargarart()" style="display: none">
                   Save
                 </button>
               </form>
       
   
        
    </div>
</div>
   
<div class="row" *ngIf="inicio==1">
    <div class="col-md-12 "> 
      <div class="text-center">
          <h3 id="nomb">{{ producto.Nombre}}</h3>        
          <a class="btn btn-success btn-sm" style="margin-bottom: 20px;margin-right: 20px;" (click)="subir()">aceptar</a>
          <a class="btn btn-danger btn-sm" style="margin-bottom: 20px;" (click)="cancelar()">cancelar</a>
      </div>
   
    </div>
  </div>
<alert></alert>





      <table class="table">
       
        <tr>
            <td></td>
            <td>Nombre</td>
            <td>Barra</td> 
            <td>Codigo</td>
            <td>Precio</td>
            <td></td>

        </tr>
        <tr *ngFor="let re of productos">
            
          <td><a [routerLink]="['../../articulo/',re.producto.Barra]"><img src="{{re.producto.Barra | sacarfoto}}"  onerror="this.src='../../assets/nofoto.png'" width="70" height="100" /></a></td>

          <td><a [routerLink]="['../../articulo/',re.producto.Barra]"> {{re.producto.Nombre}}</a></td>
          <td>{{re.producto.Barra}}</td>
          <td>{{re.producto.Codigo}}</td> 
          <td>{{re.producto.Precio | sumariva:re.producto.Iva}}</td>
          <td> <button type="button" class="btn btn-danger btn-xs" (click)="borrar(re.producto.id)"><i class="fas fa-trash-alt"></i></button></td>
          <app-ponerweb [idp]="re.producto.id" [tipo]="'producto'"></app-ponerweb>
        </tr> 

       
        </table>



