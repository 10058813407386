import { Component, OnInit } from '@angular/core';
import { DataService } from '../_services/data.service';
import { Ubicacion } from "../models/ubicacion";
import { Ubi_product } from "../models/ubi_product";
import { TokenStorageService } from '../_services/token-storage.service';
import { AlertService } from '../alert.service';
import { JsonPipe } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mover',
  templateUrl: './mover.component.html',
  styleUrls: ['./mover.component.css']
})  

export class MoverComponent implements OnInit {

  ubicaciones: Ubicacion[]; //todas las ubicaciones 

  todosantiguas :Ubi_product[]=[];
  todosantiguastemp :Ubi_product[]=[];
  cantidadelegida:number;
  antigua:Ubi_product; 
  conbarra:boolean;
  concodigo:boolean;
  stock:Ubi_product={
 
    id:null,
    productosId:null,
    ubicacionesId:null,
    user:null,
    cantidad:null
  };
  
  ubi2:any;
  ubiantigua2:any;
  cantidadelegida2:number;

  movimiento:any={

    cantidad:0,
    user:0,
    productoId:0,
    destino:0,
    origen:0,
    tipo:""
  
  }

  modo:boolean;
  producto:any;
  currentUser : any;
  inicio: number;
  nombre:string="";
  cantidad:number=1;
  edit: boolean = false;
  tienehijos: boolean = false;
  hayresultados: boolean = false; 
  seleccionado :Ubicacion={
    id:null,
    user:null,
    tienda:0,
    Nombre: "",
    Padre:null,
    Notas:"",
    Prioridad:0
  }
  idubi:string;
  cosa: Ubicacion ={
    id:null,
    user:null,
    tienda:0,
    Nombre: "",
    Padre:null,
    Notas:"",
    Prioridad:0
  }
  barra:string;
  fijarcategorias:boolean;
  todascategorias:any[];
  categoriasaponer:any[];
  ubiselec:any;

  
  fijarprov:boolean;
  priorit:number;
  provselec:any;
  proveedores:any[];
  proveedoressaponer:any[];
  maximo2:any;
tienda:any;
avisod:boolean;
avisos:boolean;
conembalaje:boolean;
cajas:any[];
cantconembalaje:number;

  constructor( private router:Router,private dataservice :DataService,private token: TokenStorageService,private alertService: AlertService,private rutaActiva: ActivatedRoute) {

}



//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
ngOnInit() {
  this.maximo2=0;
  this.conembalaje=true;
  this.modo=true;
  this.cantidadelegida=1;
  this.conbarra=true;
  this.concodigo=false;
 this.avisod=false;
 this.avisos=false;
  this.fijarcategorias=false;
  this.categoriasaponer=[];

  this.fijarprov=false;
  this.proveedoressaponer=[];

  this.cantconembalaje=1;
  this.inicio=0;
  this.idubi=this.rutaActiva.snapshot.params.id;
  this.currentUser = this.token.getUser();
  this.tienda=this.currentUser.tienda;
  this.sacartodas();
  this.llenarnombrecate();
  this.cajas=[];
  
}

cambiart(){
  if(this.conbarra){
    this.concodigo=false;
  }else{
    this.concodigo=true;

  }

 
  
}
cambiard(){
  if(this.concodigo){
    this.conbarra=false;
  }else{
    this.conbarra=true;

  }
 

}
calcularmaximo(){


  let total= this.antigua.cantidad+(this.ubiantigua2.cantidad*this.cajas[0].cantidad)
  this.maximo2=total;

  return ""+this.antigua.cantidad+" Unidades + "+this.ubiantigua2.cantidad+" Cajas de "+this.cajas[0].cantidad+" Unidades = "+total; 

}

cambiardt(){

    this.conembalaje=!this.conembalaje;
 


}




//***************************************************************** */

//--------------------CATEGORIAS---------------------------
//---------------------------------------------------------

llenarcates(res:any){
 

  
  this.dataservice.  sacracatedepro(""+res.id).subscribe(res => { 

    this.categoriasaponer=[];

    for(let i=0; i<res.length;i++){

      this.categoriasaponer.push(res[i].categoriaId);

    }
  
  },
    err =>{


    });
}
categorizar(res:any, ctr:any){

  let producto=res.productoId;
  
    this.dataservice.borrarcate_prod(res.productoId).subscribe(res => {
  
      for(let i=0; i< ctr.length;i++){
  
        let cate_prod={
  
          productosId:producto,
          categoriaId:ctr[i]
        
        }
        console.log("creando pp:"+JSON.stringify(cate_prod))
        this.dataservice.crearcate_prod(cate_prod).subscribe(res => {
      
        },
        err =>{
      
        });
      
       
      }
  
  
    },
    err =>{
  
    });
  
  
  
  
  }

  nombrecategoria(id:number){

    if(this.todascategorias.length!=0){
    
    
      const i =  this.todascategorias.findIndex( element => element.id == id );
    
    
      if(i!=-1){
       
        return this.todascategorias[i].nombre;
    
      }else{
    
        return "error";
      }
    
    }
    
      return "Sin nombre";
    
    
    
    }
    borrarcate(id:number){
    
    
      const i = this.categoriasaponer.findIndex( element => element == id );
    
      console.log("borranbdo "+ id+ " esta el "+ i)
    
      if(i!=-1){
    
        this.categoriasaponer.splice(i, 1);
      
      }
    }
    getColorfondo(){

      if(this.fijarcategorias){
    
        return "pink";
      }
    
      return "transparent"
    
    }
    llenarnombrecate(){

      this.dataservice.buscarcates().subscribe(res => {
    
        this.todascategorias=res;
      },
     
        err =>{
    
      });   
    
    }
      onChange(categoria:number) {


        const i = this.categoriasaponer.findIndex( element => element == categoria );
      
      
        if(i==-1){
        
          this.categoriasaponer.push(categoria)
        }
      
        (<HTMLInputElement>document.getElementById('miselect')).value="";
      
      }

//---------------------------------------------------------
//---------------------------------------------------------
sacarnombre(id:string){


  let devo="";
  const j = this.ubicaciones.findIndex( fruta => fruta.id === parseInt(id));
  if(j!=-1){
    if(this.ubicaciones[j].Padre!=null){
  
      const k = this.ubicaciones.findIndex( fruta => fruta.id === this.ubicaciones[j].Padre);
      devo=this.ubicaciones[k].Nombre+" / "+this.ubicaciones[j].Nombre;
  
    }else{
  
      devo=""+this.ubicaciones[j].Nombre;
    }
  }
  return devo;
  

}


//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------

sacartodas(){

   this.dataservice.gettodasubi("0").subscribe(res => {
 
    
       this.ubicaciones=res;

      if(res){
        this.nombre=this.sacarnombre(this.idubi);
        this.hayresultados=true;
      } 

     },
     err => console.log(err)
   );
   
 }

//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------

async subir(){

if(!this.conembalaje){  

//si son distinta ubicacion
if(parseInt(this.idubi)!=this.antigua.ubicacionesId){


 
this.stock.productosId=this.producto.id;
this.stock.ubicacionesId=parseInt(this.idubi);
this.stock.user=this.token.getUser().tienda;
this.stock.cantidad=this.cantidadelegida;


if(this.antigua.cantidad>this.cantidadelegida){

  this.antigua.cantidad=this.antigua.cantidad-this.cantidadelegida;

  // descuaneta 1
  let d= await this.dataservice.modificarubi_produc(""+this.antigua.id,this.antigua).toPromise();


}else{


  // borra
  let d= await this.dataservice. borrarubi_produc(""+this.antigua.id).toPromise();

}


    const i = this. todosantiguas.findIndex( fruta => fruta.ubicacionesId === parseInt(this.idubi) );

    //si ya habia alguno en esa hubicacion
    if(i!=-1){

      this. todosantiguas[i].cantidad=this. todosantiguas[i].cantidad+ this.cantidadelegida;

      await this.dataservice.modificarubi_produc(""+this.todosantiguas[i].id,this.todosantiguas[i]).subscribe(res => {

          
        this.cantidadelegida=1;


        this.guardasmovi(this.stock,this.antigua.ubicacionesId);
      },
      err => {
        console.log(err)
        this.cancelar();
      }
      );

    
    }else{

      await this.dataservice. crearubi_product(this.stock).subscribe(res => {

        
         this.guardasmovi(this.stock,this.antigua.ubicacionesId);
      },
      err => {
        console.log(err)
        this.cancelar();
      }
      );
 

    }



  




}else{

    
  this.alertService.error("No se puede mover ya esta en esa ubicacion");

}
}else{
///---------------------------------------------aubir con emba----


//si son distinta ubicacion
if((parseInt(this.idubi)!=this.antigua.ubicacionesId)){


  let totalcajasquehay=this.ubiantigua2.cantidad;
  let totalsueltosquehay=this.antigua.cantidad;
  let totalcajas_aquitar=0; //de la ubicacion actual
  let totalcajas_aponer=0 //en la nueva
  let totalsueltos_aquitar=0; // en la aubicacion actual
  let totalsueltos_aponer=0;  //en la nueva
  let totalsueltos_aponer2=0; //en la ubicacion actual
  let totalamover=this.cantconembalaje;
  let cantembalaje=this.cajas[0].cantidad;
  let pico=0;

  if(totalsueltosquehay<totalamover){

 
    totalsueltos_aquitar=totalsueltosquehay;
    totalamover=totalamover-totalsueltosquehay;
    pico=(totalamover % cantembalaje);

    if(pico==0){

      totalsueltos_aponer=totalsueltos_aquitar;
      totalcajas_aquitar=totalamover / cantembalaje;
      totalcajas_aponer=totalcajas_aquitar;

    }else{

      totalsueltos_aponer=totalsueltos_aquitar+pico;
      totalamover=totalamover-pico;
      totalcajas_aquitar=(totalamover / cantembalaje)+1;
      totalcajas_aponer=(totalamover / cantembalaje);
      totalsueltos_aponer2=cantembalaje-pico
    }




  }else{

    totalsueltos_aquitar=totalamover;
    totalsueltos_aponer=totalamover;
  }

  if(parseInt(this.idubi)==this.ubiantigua2.ubicacionesId && totalcajas_aponer>0){ 

    alert("No es posible maver cajas a la misma ubicacion");
 
  }else{

if(confirm(" -Se van a quitar "+totalsueltos_aquitar+" unidades y "+ totalcajas_aquitar +" Cajas de la ubicacion actual.\n -Se van a poner "+totalsueltos_aponer+" unidades y "+totalcajas_aponer+" Cajas en la nueva ubicacion.\n -Se va añadir un remante de "+totalsueltos_aponer2+" unidades a la Ubicacion actual\n\n ¿Deseas continuar?")){
//de la ubicacion actual

  let cajasacponer:Ubi_product={ 
    id:null,
    productosId:this.cajas[0].producto[0].id,
    ubicacionesId:parseInt(this.idubi),
    user:this.token.getUser().tienda,
    cantidad:totalcajas_aponer
  }
  
  let sueltosacponer1:Ubi_product={ 

    id:null,
    productosId:this.producto.id,
    ubicacionesId:parseInt(this.idubi),
    user:this.token.getUser().tienda,
    cantidad: totalsueltos_aponer
  }
  
  let sueltosacponer2:Ubi_product={ 

    id:null,
    productosId:this.producto.id,
    ubicacionesId:this.antigua.ubicacionesId,
    user:this.token.getUser().tienda,
    cantidad:totalsueltos_aponer2
  }
  


  let seaborrado=false;
  
  if(totalcajas_aquitar>0){

    if(totalcajas_aquitar>=this.ubiantigua2.cantidad){
      //si son todas borra
      console.log("borrando todos cajas antiguas")
      let d= await this.dataservice. borrarubi_produc(""+this.ubiantigua2.id).toPromise();
    }else{
       //si no moodificar
      
      this.ubiantigua2.cantidad=this.ubiantigua2.cantidad-totalcajas_aquitar;
      console.log("modificando cajas antiguas. nueva cantidad:"+  this.ubiantigua2.cantidad)
      let d= await this.dataservice.modificarubi_produc(""+ this.ubiantigua2.id, this.ubiantigua2).toPromise();


    }

    //crear movimiento
    

  } 

//en la nueva
  if(totalcajas_aponer>0){

        const i = this.cajas[0].ubicaciones.findIndex( fruta => fruta.ubicacionesId === parseInt(this.idubi) );
        console.log("i="+i+" - "+JSON.stringify(this.cajas[0])+" - iubi "+this.idubi)
      if(i!=-1){
        console.log("añadiendo cajas a la nueva ubicacion que ya habian cajas")
        this.cajas[0].ubicaciones[i].cantidad= this.cajas[0].ubicaciones[i].cantidad+totalcajas_aponer;
        let d= await this.dataservice.modificarubi_produc(""+ this.cajas[0].ubicaciones[i].id, this.cajas[0].ubicaciones[i]).toPromise();
      
          //si ya existe modificar
      }else{
        console.log("creando nueva stock de cajas: "+JSON.stringify(this.cajas[0]))
       let l= await this.dataservice. crearubi_product(cajasacponer).toPromise();
     
        //si no crear
      }
      this.guardasmovi(cajasacponer,this.ubiantigua2.ubicacionesId);
      
      //crear movimiento
  } 

// en la aubicacion actual
  if(totalsueltos_aquitar>0){


    if(totalsueltos_aquitar>=this.antigua.cantidad){
      console.log("quitamdo todo el stock individaul antiguo")
      //si son todas borra
      seaborrado=true;
      let d= await this.dataservice. borrarubi_produc(""+this.antigua.id).toPromise();
    }else{
      console.log("modificando stock individual antiguo antiguo")
      this.antigua.cantidad=this.antigua.cantidad-totalsueltos_aquitar;
      let d= await this.dataservice.modificarubi_produc(""+this.antigua.id,this.antigua).toPromise();
       //si no moodificar

    }


        //crear movimiento

  } 
//en la nueva
  if(totalsueltos_aponer>0){

    const i = this. todosantiguas.findIndex( fruta => fruta.ubicacionesId === parseInt(this.idubi) );

    if(i!=-1){
      console.log("añadiendo stock individual a nueva ubicacion que ya habia")
      this. todosantiguas[i].cantidad=this. todosantiguas[i].cantidad+totalsueltos_aponer;
      let d= await this.dataservice.modificarubi_produc(""+this.todosantiguas[i].id,this.todosantiguas[i]).toPromise();
      //si ya existe modificar
    }else{
      console.log("creando nuevo stock individual a nueva ubicacion")
     let d= await this.dataservice. crearubi_product(sueltosacponer1).toPromise();
      //si no crear
    }

    this.guardasmovi(sueltosacponer1,this.antigua.ubicacionesId);
        //crear movimiento
  }  
  
  //en la ubicacion actual
  if(totalsueltos_aponer2>0){
    
    if(seaborrado){

      let d= await this.dataservice. crearubi_product(sueltosacponer2).toPromise();
       //si no crear
       console.log("creando nuevo stock individual en la ubicacion actual de remanate porque se a borrado")
    }else{
      this.antigua.cantidad=this.antigua.cantidad+totalsueltos_aponer2;
      let d= await this.dataservice.modificarubi_produc(""+this.antigua.id,this.antigua).toPromise();
      console.log("modificando stock individual  con el remanate ")
      //si ya existe modificar

    }

  

        //crear movimiento
  } 

  this.cancelar();


}}

  
  }else{
  
      
    this.alertService.error("No se puede mover algunos de los productos ya esta en esa ubicacion");
  
  }
}
}

//---------------------------------------------------------

poneravisosd(dias:number, ubi:number,idp:string){

  let fecha= new Date();
  let sumadiasmilisegundos=dias*24*60*60*1000
  let fechacondiasmas=fecha.getTime()+sumadiasmilisegundos;




  let aviso={

    tipo:"Devolver",
    productoId:parseInt(idp),
    fecha: fechacondiasmas,
    usuario:this.currentUser.id,
    ubicacion:ubi,
    estado:"activo",
    texto:dias,
    tienda: this.tienda
  }
  this.dataservice.crearaviso(aviso).subscribe(res => {


  },
  err => console.log(err)

  );
}
poneravisos(cantidad:number,ubi:number,idp:string){

  let aviso={

    tipo:"MinStock",
    productoId:parseInt(idp),
    fecha: new Date(),
    usuario:this.currentUser.id,
    cantidad:cantidad,
    estado:"activo",
    ubicacion:ubi,
    tienda: this.tienda
  }

  this.dataservice.crearaviso(aviso).subscribe(res => {


    },
    err => console.log(err)
  
    );
}
//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------


guardasmovi(sub:any, origen:number){

  console.log("subiendo st "+JSON.stringify(sub));

  this.movimiento.cantidad=sub.cantidad;
  this.movimiento.user=sub.user;
  this.movimiento.productoId=sub.productosId
  this.movimiento.destino=sub.ubicacionesId;
  this.movimiento.origen=origen;
  this.movimiento.tipo="Cambio de ubicacion";

console.log("subiendo movi "+JSON.stringify(this.movimiento));

  this.dataservice.creamovi(this.movimiento).subscribe(res => {

    this.movimiento={

      cantidad:0,
      user:0,
      productoId:0,
      destino:0,
      origen:0,
      tipo:""
    
    }
    this.cancelar();

  },
    err =>{

  });
}

async sacarcajas(){

  this.cajas=[];
  let rela=await this.dataservice.sacar_todas_rel(this.producto.id).toPromise();
  let filtradas=[];
  console.log("pro:" +JSON.stringify(rela))
  for (var i=0; i<rela[0].length; i++){ 
    //si son embalajes
    if(rela[0][i].Embalaje!=this.producto.id){

      filtradas.push(rela[0][i]);
    }

  }
  console.log("filtradas :" +JSON.stringify(filtradas))
  if(filtradas.length >1){

    alert("ATENCION: HAY MAS DE UNA REFERENCIA DE EMBALAJE PARA ESTE ARTICULO, NO ES POSIBLE SELECIONAR CAJAS");
    return true;
  }
  
  if(filtradas.length ==1){


  // caja= {producto:{}, ubicaciones:[{ubi_stock},{ubi_stock}]}
  //sacar nombre de caja
  let pr = await this.dataservice. getproductoweb(filtradas[0].Embalaje).toPromise();
  //sacar ubistock de caja

  let ubis = await this.dataservice.getubi_product_i(filtradas[0].Embalaje).toPromise();

  let detienda=[];

    if(ubis!=null){


     
      for (var i=0; i<ubis.length; i++) {

       if(ubis[i].user==this.currentUser.tienda){
        detienda.push(ubis[i]);
       this.ubiantigua2=ubis[i]
       }
      }


    }
  if(detienda.length>0)this.cajas=[{producto:pr,ubicaciones:detienda,cantidad:filtradas[0].Cantidad}]
  }

  console.log("DATOS CAJA:" +JSON.stringify(this.cajas))


}


async cargar(){

 let busqueda= (<HTMLInputElement>document.getElementById('barr')).value;

 let error=false;

 if(this.inicio==1 ){

    if(parseInt(this.idubi)!=null){
    this.subir();

    }else{
      error=true;
      this.alertService.error("Error de ubicacion");
      this.playAudio("fail");
    }
 }
 
  
 if(!error){
  if(this.conbarra){

        await this.dataservice. getproducto(""+busqueda).subscribe(res => {

            this.producto=res;
             
        

            
            if(res!=null){
              if(this.conembalaje)this.sacarcajas();
              this.sacartodas();
              this.estaubicado(""+this.producto.id);
              this.cantidad=1;
              this.playAudio("ok");
         
              
            }else {
              this.inicio=2;
              this.playAudio("fail");
            }
          },
          err => console.log(err)
          );
        }
        
    if(this.concodigo){
      await this.dataservice. getproductocodigo(""+busqueda).subscribe(res => {

        this.producto=res;
   

        console.log(JSON.stringify(this.producto))
        
        if(res!=null){

          if(this.conembalaje)this.sacarcajas();
          this.sacartodas();
          this.estaubicado(""+this.producto.id);
          this.cantidad=1;
          this.playAudio("ok");

          
        }else {
          this.inicio=2;
          this.playAudio("fail");
        }
      },
      err => console.log(err)
      );

    }
         
 }
 (<HTMLInputElement>document.getElementById('barr')).value="";
 (<HTMLInputElement>document.getElementById('barr')).focus();
}

//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
cancelar(){

this.inicio=0; 
this.cantidad=1;
this.producto=[];
this.todosantiguas=[];
this.todosantiguastemp=[];


(<HTMLInputElement>document.getElementById('barr')).focus();
}

//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------

cambiar(nueva:Ubi_product){
 
  this.cantidadelegida=1;
  this.antigua=nueva;

  (<HTMLInputElement>document.getElementById('barr')).focus();
}


cambiar2(nueva:any){
 
  this.cantidadelegida2=1;
  this.ubiantigua2=nueva;

  (<HTMLInputElement>document.getElementById('barr')).focus();
}
//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------

filtrar(){


  for (var i=0; i<this.todosantiguas.length; i++) {

    if(this.todosantiguas[i].user==this.currentUser.tienda)this.todosantiguastemp.push(this.todosantiguas[i]);
  }

  this.todosantiguas=this.todosantiguastemp;
}

estaubicado(barr:string){
  

  this.dataservice.getubi_product_i(barr).subscribe(res => {

    console.log("esta en"+JSON.stringify(res))

    this.todosantiguas=res;


    if(res!=null){

       console.log("Esta en la ubicacion"+JSON.stringify(this.todosantiguas))

       this.filtrar();
       if(this.todosantiguas.length>0){

         this.antigua=this.todosantiguas[0];
         this.inicio=1;
       }else{

        this.antigua=null;
        this.inicio=2;

       }
     

    }else{

      this.todosantiguas=[];
      this.antigua=null;
      this.inicio=2;
    }
  
  },
  err => console.log(err)
  );

 

}
volver(){

  this.router.navigate(['/gestU']);

}

playAudio(tipo :string){

  let audio = new Audio();
  if(tipo==="ok"){
    audio.src = "../../../assets/beep-ok.wav";
  }else{
    audio.src = "../../../assets/beep-f.wav";
  }


  audio.load();
  audio.play();
}



mas(cant:number){

if(!this.conembalaje){

  if((this.cantidadelegida+cant)>this.antigua.cantidad){

    this.cantidadelegida=this.antigua.cantidad;

  }else{

    this.cantidadelegida=this.cantidadelegida+cant;


  } 

}else{

  if((this.cantconembalaje+cant)>this.maximo2){

    this.cantconembalaje=this.maximo2;

  }else{

    this.cantconembalaje=this.cantconembalaje+cant;


  } 

}


}

menos(cant:number){

  if(!this.conembalaje){
    if(this.cantidadelegida-cant>1){
      
      this.cantidadelegida=this.cantidadelegida-1;

    }else{

      this.cantidadelegida=1;
    }
    }
else{


    if(this.cantconembalaje-cant>1){
      
      this.cantconembalaje=this.cantconembalaje-1;

    }else{

      this.cantconembalaje=1;
    }
    

}
}


}



  