import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Ubicacion } from "../models/ubicacion";
import { Ubi_product } from "../models/ubi_product";
import { createUrlResolverWithoutPackagePrefix } from '@angular/compiler';
import { Router } from '@angular/router';
import { DataService } from '../_services/data.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { MoverComponent } from '../mover/mover.component';
import { AlertService } from '../alert.service';
import { AnyNaptrRecord } from 'dns';

@Component({
  selector: 'app-verdetallecate',
  templateUrl: './verdetallecate.component.html',
  styleUrls: ['./verdetallecate.component.css']
})
export class VerdetallecateComponent implements OnInit {


  productos:any[]=[];
  hayresultados:boolean;
  idubi:string;
  totalde:number;
  nombreu:string;

 
  currentUser:any;

  page: number; 
  previousPage: number;
  total:number;
  barra:any;
  inicio:any;
  producto:any;

  constructor(private alertService: AlertService,private rutaActiva: ActivatedRoute, private router:Router, private dataservice :DataService, private token:TokenStorageService) { }

  ngOnInit(): void {
    this.currentUser = this.token.getUser();
    this.idubi=this.rutaActiva.snapshot.params.id;
 
    this.cargar(1);
    this.sacartotal();
    this.sacarnombre();
  
    this.page=1;
    this.previousPage =1;
    
  }

  loadPage(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.cargar(this.page);
    }
  }

  cargar(pagina:number){


    this.dataservice.getproductoscate(this.idubi, ""+pagina).subscribe(res => {

      this.productos=res.rows;

      this.total=res.count 

      this.hayresultados=true;
    
    },
    err => console.log(err)
    );


  }
  sacartotal(){ 

  this.dataservice.totastockcate(this.idubi).subscribe(res => {

    this.totalde=res;
     
  },
  err => console.log(err)
  );

}
  volver(){

    this.router.navigate(['/gescat']);

}

sacarnombre(){



  this.dataservice.buscarcate(""+this.idubi).subscribe(res => {

    this.nombreu=res[0].nombre;
     console.log(res);
     
   


   },
   err => console.log(err)
 );

}



subir(){

  let producto=this.producto.id;

    let cate_prod={
  
      productosId:producto,
      categoriaId:this.idubi
        
    }

  
        this.dataservice.crearcate_prod(cate_prod).subscribe(res => {
            
          this.cancelar();
            
        },
        err =>{
      
        });
      
       
      
  

  
  
  

}


cargarart(){


 let busqueda= (<HTMLInputElement>document.getElementById('barr')).value;
 let error=false;



  if(this.inicio==1 ){

      this.subir();


  }
  (<HTMLInputElement>document.getElementById('barr')).value="";
  (<HTMLInputElement>document.getElementById('barr')).focus;
 



    
 
          this.dataservice. getproducto(""+busqueda).subscribe(res => {

         
          
            //  console.log("Producto buscado:"+JSON.stringify(this.producto.Nombre))
              
              if(res!=null){
                
     
                this.producto=res;
      
                this.inicio=1;
                this.playAudio("ok");

      
              }else{
                
               this.playAudio("fail");
               this.inicio=2;
              }
           
          

            },
            err =>{
              
              this.playAudio("fail");
              this.inicio=2;
              this.alertService.error("--"+err.message);

            });
  


}

playAudio(tipo :string){

  let audio = new Audio();
  if(tipo==="ok"){
    audio.src = "../../../assets/beep-ok.wav";
  }else{
    audio.src = "../../../assets/beep-f.wav";
  }


  audio.load();
  audio.play();
}
borrar(id:string){


  if(confirm("tas segur@?")) {


    this.dataservice. borartodocate_prod(id,this.idubi).subscribe(res => {

      this.cargar(1);
      this.sacartotal();
      this.sacarnombre();
    },
    err => console.log(err)
    );

  }

}





vaciar(){

  if(confirm("tas segur@?")) {
    if(confirm("vas a borrarlo to, seguro seguro????")) {

      this.dataservice. borartodocate_prod("todo",  this.idubi).subscribe(res => {
          
        this.volver();
      },
      err => console.log(err)
      );
  


    }
}

}



cancelar(){

this.producto=null;
this.inicio=0;


this.cargar(1);
this.sacartotal();
this.sacarnombre();
}



}