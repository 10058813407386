import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ventasc',
  templateUrl: './ventasc.component.html',
  styleUrls: ['./ventasc.component.css']
})
export class VentascComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
