<div class="container">
  
  <button type="button" class="btn btn-danger btn-xs" (click)="volver()">Volver</button>

<div *ngIf="hayresultados">
  <h2>Moviendo productos a: {{nombre}}</h2>
  <label *ngIf="conembalaje" style="color:red"><b>¡¡ Se abriran embalajes si es posible !!</b></label>



  <div class="row justify-content-center" style="margin-bottom: 20px;">
    <div>
  
      <input class="form-check-input" type="checkbox" [(ngModel)]="conbarra" (change)="cambiart()"  id="flexCheckDefault">
      <label class="form-check-label" for="flexCheckDefault">
        BUSCAR POR CODIGO DE BARRAS
      </label>
    </div>
  
    <div style="margin-left: 30px;">
      <input class="form-check-input" type="checkbox" [(ngModel)]="concodigo" (change)="cambiard()"  id="flexCheckDefault">
      <label class="form-check-label" for="flexCheckDefault">
        BUSCAR POR CODIGO INTERNO
      </label>
      
    </div > 
   
   
  
  </div>
  <alert></alert>
  <div class="row justify-content-center" >



    <div class="col-md-4 ">
       <div class="card">
         <div class="card-body">
           <form action="">
             <div *ngIf="!edit" class="form-group">
               <input type="text" id=barr name="title" [(ngModel)]="barra" placeholder="Codigo de Barras" class="form-control" autofocus>
               
             </div>
              
             <button class="btn btn-success btn-block" (click)="cargar()" style="display: none">
               Save
             </button>
           </form>
         </div>
       </div>
     </div> 
   
   <div *ngIf="inicio==1">
     <div class="col-md-12 ">
       <div class="card card-body text-center">
           <h3 id="nomb">{{ producto.Nombre}}</h3>
           <h3 style="color: red;" >Precio: {{producto.Precio | sumariva:producto.Iva }}€</h3>
           
           <a class="btn btn-success btn-sm"  (click)="subir()" style="margin-top:10px">aceptar</a>

       <div style="margin-top:10px" >

            <p *ngIf="!conembalaje || cajas.length==0">** MAXIMO {{antigua.cantidad}} **</p>
            <p *ngIf="conembalaje && cajas.length>0">** MAXIMO ** <br>{{calcularmaximo()}} </p>

            <div class="input-group mb-3" Style="margin-top: 10px;">
              <button type="button" class="btn btn-danger"  style="background-color: rgb(109, 2, 2);"  (click)="menos(100)" >-100</button>
              <button type="button" class="btn btn-danger"  style="background-color: rgb(206, 31, 31);"  (click)="menos(10)" >-10</button>
              <div class="input-group-prepend">

            
                <button type="button" class="btn btn-danger" (click)="menos(1)" >-</button>
            
              </div>
        
              <input *ngIf="!conembalaje || cajas.length==0" type="number"  class="form-control" name="cantidad" [(ngModel)]="cantidadelegida"  readonly>
              <input *ngIf="conembalaje && cajas.length>0" type="number"  class="form-control" name="cantidad" [(ngModel)]="cantconembalaje"  readonly>
              <div class="input-group-append">
        
                <button type="button" class="btn btn-success"  (click)="mas(1)">+</button>

              </div>
              <button type="button" class="btn btn-success" style="background-color: rgb(26, 184, 47);" (click)="mas(10)">+10</button>
              <button type="button" class="btn btn-success"  style="background-color: rgb(0, 110, 6);"  (click)="mas(100)">+100</button>
            </div>
            
          
       </div>

           <h3>Estaba en:</h3>

           <div *ngFor="let ubi of todosantiguas">

            
            <a class="btn btn-primary btn-sm btn-block " *ngIf="ubi==antigua" Style="margin-bottom: 10px;margin-top:10px; ">{{sacarnombre(ubi.ubicacionesId)}}:{{ubi.cantidad}} </a>
            <a class="btn btn-secondary btn-sm btn-block" *ngIf="ubi!=antigua" Style="margin-bottom: 2px;" (click)="cambiar(ubi)">{{sacarnombre(ubi.ubicacionesId)}}:{{ubi.cantidad}} </a>

        </div>

        <div style="background-color:rgb(240, 177, 96);" *ngIf="conembalaje && cajas.length>0">
          <h3>Stock en embalaje: {{cajas[0].producto.Nombre}} </h3>
          <h4>{{cajas[0].cantidad}} UNIDADES EN CADA EMBALAJE</h4>          
          <div *ngFor="let ubi of cajas[0].ubicaciones">

         
            <a class="btn btn-primary btn-sm btn-block " *ngIf="ubi==ubiantigua2" Style="margin-bottom: 10px;margin-top:10px; ">{{sacarnombre(ubi.ubicacionesId)}}:{{ubi.cantidad}} </a>
            <a class="btn btn-secondary btn-sm btn-block" *ngIf="ubi!=ubiantigua2" Style="margin-bottom: 2px;" (click)="cambiar2(ubi)">{{sacarnombre(ubi.ubicacionesId)}}:{{ubi.cantidad}} </a>
   
        </div>

        </div>
     

       <a class="btn btn-danger btn-sm btn-block"  (click)="cancelar()" style="margin-top: 20px;">cancelar</a>
       
   
       </div>
     </div>
   </div>
   
   <div *ngIf="inicio==2">
       <div class="col-md-12">
           <div class="card card-body text-center">
               <div class="alert alert-danger" role="alert">
                  EL ARTICULO NO ESTA UBICADO, DEBE UBICARLO ANTES DE MOVERLO
                 </div>
       
           </div>
       </div>
   </div>
</div>
   <div>
      
      <!--  <p>{{ user.email }}</p> -->

   </div>
 

    <button *ngIf="conembalaje" class="btn btn-success btn-block" (click)="cambiardt()" style="margin-top: 20px;">ABRIR CAJAS SI ES NECESARIO</button>
    <button *ngIf="!conembalaje" class="btn btn-secondary btn-block" (click)="cambiardt()" style="margin-top: 20px;">ABRIR CAJAS SI ES NECESARIO</button>



   <h1 *ngIf="!hayresultados" style="margin-top: 20px;">Es necesario crear ubicaciones antes de mover los productos</h1>
</div>