<div class="container">
  <alert></alert>
 <!--   <div *ngIf="haydiscrepancias()" style="margin-top:40px" >
    <h4>Upss, ¿Me puedes ayudar? </h4>
    <table class="table">

    <tr *ngFor="let dis of discrepancias" >

      <div *ngIf="dis.tipo=='Reservas'">

        <p>Se a vendido esto y estaba reservado:</p> 
 
        
         
            <td><img src="{{dis.producto.Barra | sacarfoto}}"  onerror="this.src='../../assets/nofoto.png'" width="70" height="100" /> </td>
            <td>{{dis.producto.Nombre}}: </td>
            <td>{{dis.cantidad}} Uds</td>
            <td>
              <button class="btn btn-success " (click)="resolverreserva(dis.productoId,dis.id,dis.cantidad,'Si')">SI </button>
              ¿era una reserva?
              <button class="btn btn-danger " (click)="resolverreserva(dis.productoId,dis.id,dis.cantidad,'No')"> NO </button>
            </td>
         

      </div>
        
    </tr>
</table>

  </div>





  <h5>Enviar mensajes</h5>
  <div class="messaging">
        <div class="inbox_msg">
          
          <div class="mesgs">

            <div class="msg_history" id="mensajes">

            <div *ngFor="let mess of mensajes">
                <div *ngIf="mess.destino==this.currentUser.id" class="incoming_msg">

                  <div *ngIf="currentUser.id==1" class="incoming_msg_img" > <img src="../../assets/logopape.png" alt="sunil"> </div>
                  <div *ngIf="currentUser.id==2" class="incoming_msg_img"> <img src="../../assets/logopulsar.png" alt="sunil"> </div>
 
                  <div class="received_msg">
                    <div class="received_withd_msg" style="background-color: aquamarine;">
                     
                      <p *ngIf="mess.estado!='enviado'" >{{mess.mensaje}}</p>
                      <p *ngIf="mess.estado=='enviado'" style="background-color: blueviolet; color:aliceblue">{{mess.mensaje}}</p>
                    </div>
                  </div>
                </div>

                <div *ngIf="mess.origen==this.currentUser.id" class="outgoing_msg">
                  <div class="sent_msg">
                    <p>{{mess.mensaje}}</p>
          
                </div>
              </div>
            </div>

       
          </div>

          <div class="type_msg">
            <div class="input_msg_write">

            <form action="">
                <div class="form-group">

                  <input type="text" id=barr name="title" [(ngModel)]="barra"  class="write_msg" placeholder="Mandar mensaje" />
                </div>
                
                <button class="btn btn-success btn-block" (click)="subir()" style="display: none">
                  Save
                </button>

              </form>
            </div>
          </div>
        </div>
        
  
        
      </div>
    --> </div>