import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule } from '@angular/core';


import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { HomeComponent } from './home/home.component';
import { BoardAdminComponent } from './board-admin/board-admin.component';
import { BoardUserComponent } from './board-user/board-user.component';
import { BoardModeratorComponent } from './board-moderator/board-moderator.component';
import { ProfileComponent } from './profile/profile.component';

import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { GestorUbicacionesComponent } from './gestor-ubicaciones/gestor-ubicaciones.component';
import { SumarivaPipe } from './sumariva.pipe';
import { NuevostockComponent } from './nuevostock/nuevostock.component';
import { AlertComponent } from './alert/alert.component';
import { AlertService } from './alert.service';
import { MoverComponent } from './mover/mover.component';
import { NombretiendaPipe } from './nombretienda.pipe';
import { VercategoriaComponent } from './vercategoria/vercategoria.component';
import { DevolucionesComponent } from './devoluciones/devoluciones.component';
import { DiscrepanciasComponent } from './discrepancias/discrepancias.component';
import { ArticuloComponent } from './articulo/articulo.component';
import { AvisosComponent } from './avisos/avisos.component';
import { SacarfotoPipe } from './sacarfoto.pipe';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { FichaarticuloComponent } from './fichaarticulo/fichaarticulo.component';
import { CambiardetiendaComponent } from './cambiardetienda/cambiardetienda.component';
import { UltimosmoviComponent } from './ultimosmovi/ultimosmovi.component';
import { Variables } from './variables';
import { GestorCategoriasComponent } from './gestor-categorias/gestor-categorias.component';
import { CategoriahijoComponent } from './categoriahijo/categoriahijo.component';
import { VerdetallecateComponent } from './verdetallecate/verdetallecate.component';
import { GestorProveedoresComponent } from './gestor-proveedores/gestor-proveedores.component';
import { VeredetalleproveComponent } from './veredetalleprove/veredetalleprove.component';
import { CrearavisosComponent } from './crearavisos/crearavisos.component';
import { VeravisosComponent } from './veravisos/veravisos.component';
import { ErrorInterceptor} from './_helpers/error.interceptor';
import { PonerwebComponent } from './ponerweb/ponerweb.component';
import { EntradasComponent } from './entradas/entradas.component';
import { DetallealbaranComponent } from './detallealbaran/detallealbaran.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InformeventasComponent } from './informeventas/informeventas.component';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData} from '@angular/common';
import { VentascComponent } from './ventasc/ventasc.component';
import { InventarioComponent } from './inventario/inventario.component';
import { StockPorBarraComponent } from './stock-por-barra/stock-por-barra.component';
import { RelacionesComponent } from './relaciones/relaciones.component';
import { TarjetaResultadoComponent } from './tarjeta-resultado/tarjeta-resultado.component'

const config: SocketIoConfig = { url: 'https://localhost:32787', options: {} };
registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    BoardAdminComponent,
    BoardUserComponent,
    BoardModeratorComponent,
    ProfileComponent,
    GestorUbicacionesComponent,
    SumarivaPipe,
    NuevostockComponent,
    AlertComponent,
    MoverComponent,
    NombretiendaPipe,
    VercategoriaComponent,
    DevolucionesComponent,
    DiscrepanciasComponent,
    ArticuloComponent,
    AvisosComponent,
    SacarfotoPipe,
    FichaarticuloComponent,
    CambiardetiendaComponent,
    UltimosmoviComponent,
    GestorCategoriasComponent,
    CategoriahijoComponent,
    VerdetallecateComponent,
    GestorProveedoresComponent,
    VeredetalleproveComponent,
    CrearavisosComponent,
    VeravisosComponent,
    PonerwebComponent,
    EntradasComponent,
    DetallealbaranComponent,
    InformeventasComponent,
    VentascComponent,
    InventarioComponent,
    StockPorBarraComponent,
    RelacionesComponent,
    TarjetaResultadoComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    SocketIoModule.forRoot(config)
  ],
  providers: [authInterceptorProviders,AlertService,Variables,{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },  {provide: LOCALE_ID, useValue: 'es' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
