import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sumariva'
})
export class SumarivaPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {

    if (args != null) {

      if(args[0]=='2'){
          return Math.round((value*1.04)*100)/100;

      }else{
        
       return Math.round((value*1.21)*100)/100;
    
      } 

    }

  }

}
