import { Component, OnInit } from '@angular/core';

import { DataService } from '../_services/data.service';
import {RoundProgressModule} from 'angular-svg-round-progressbar'; 
import { timer } from 'rxjs';
import { Ubicacion } from "../models/ubicacion";
import { TokenStorageService } from '../_services/token-storage.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertService } from '../alert.service';
import { JsonpClientBackend } from '@angular/common/http';

@Component({
  selector: 'app-board-user',
  templateUrl: './board-user.component.html',
  styleUrls: ['./board-user.component.css']
})
export class BoardUserComponent implements OnInit {
  sinresultados:boolean;
  expandir:boolean;
  ubicaciones: Ubicacion[];
  ubiselec:any; 
  mostrar:boolean=true; 
  currentUser : any;
  nueva:boolean;
  hayresultados:boolean;
  hayresultados2:boolean;
  pp1:any=[];
  pp2:any=[];
  barra:string;
  productos:any = [];
  stocklocal:any = [];
  total:number;
  total2:number;
  buscando:string;
  page: number;
  previousPage: number;
  page2: number;
  previousPage2: number;
  ubicacion:any[];
  tiendas:any[];
  tiendaactual:number;
  nom:boolean;
  autor:boolean; 
  cod:boolean;
  barr:boolean;  
  todascategorias:any[];
  categoriasaponer:any[];
  catego:boolean;
  nivelcate:number; 
  fcates:boolean;
  repetir:boolean;
  proceso:boolean;
  ocultarlocal:boolean;
  datosampliados:boolean;

  constructor(private dataservice :DataService,private token: TokenStorageService,private alertService: AlertService){}

ngOnInit(){
  
 this.ocultarlocal=false; 
this.repetir=false;
this.proceso=false;
this.expandir=false;
this.mostrar=false;
this.categoriasaponer=[];
this.nivelcate=0;
this.catego=false;
this.fcates=false;
this.llenarnombrecate()
this.nom=true;
this.autor=true; 
this.cod=true;
this.barr=true; 
document.getElementById( 'barradebusqueda').focus();
this.hayresultados=false;
this.hayresultados2=false;
this.currentUser = this.token.getUser();
this.tiendaactual=this.currentUser.tienda;
this.total=0;
this.total2=0;
this.sacartodas();
this.sacartiendas();
this.page=1;
this.previousPage =1;
this.sinresultados=false;
this.page2=1;
this.previousPage2 =1;
this.datosampliados=false;



}

nombretienda(id:number){

  let nombre="ERROR"


 
  const i = this.tiendas.findIndex( fruta => fruta.id === id );

 

  if(i!=-1){
  
   nombre=this.tiendas[i].name;
 

  }

  return nombre 

}


sacartiendas(){


  
  this.dataservice.sacartiendas().subscribe(
    res => {

      this.tiendas = res;
      

    },
    err => {
  
    }
  );

}
async buscarmas(){
  this.ocultarlocal=true; 
  this.expandir=true;
  this.proceso=true;
  await this.buscarglogal(1);
  this.proceso=false;
}

mostrarre(){

if(this.hayresultados || this.hayresultados2){
    
  return true;

}else{

  return false;
}

}

nombreubicaciones(id:string){

let devo="";

const j = this.ubicaciones.findIndex( fruta => fruta.id === parseInt(id));
if(j!=-1){
  if(this.ubicaciones[j].Padre!=null){

    const k = this.ubicaciones.findIndex( fruta => fruta.id === this.ubicaciones[j].Padre);
    devo=this.ubicaciones[k].Nombre+" / "+this.ubicaciones[j].Nombre;

  }else{

    devo=""+this.ubicaciones[j].Nombre;
  }
}
return devo;

} 

eslocal(categoria:number){

 let  loes:boolean=false;


 const j = this.ubicaciones.findIndex( fruta => fruta.id === categoria);

 if(j!=-1){

   if(this.ubicaciones[j].tienda==this.tiendaactual){

     loes=true;

   } 

  }


//comprobar si la categoria pertenece a la tienda actual
return loes;
 

}

separaruseres(){

  for (let i = 0; i < this.stocklocal.length; i++) {


   const j = this.pp1.findIndex( fruta => fruta.producto.id === this.stocklocal[i].producto.id);

   //si no existe ese producto
   if(j==-1){  
   
 

    if(this.eslocal(this.stocklocal[i].ubicacionesId)){

      this.pp1.push({producto:this.stocklocal[i].producto,local:[{ubicacion:this.stocklocal[i].ubicacionesId,cantidad:this.stocklocal[i].cantidad}],fuera:[]});

    }else{

      this.pp1.push({producto:this.stocklocal[i].producto,local:[],fuera:[{tienda:this.stocklocal[i].user,ubicacion:this.stocklocal[i].ubicacionesId,cantidad:this.stocklocal[i].cantidad}]});
    }

  }else{
 
    if(this.eslocal(this.stocklocal[i].ubicacionesId)){

      this.pp1[j].local.push({ubicacion:this.stocklocal[i].ubicacionesId,cantidad:this.stocklocal[i].cantidad});

      

    }else{
      this.pp1[j].fuera.push({tienda:this.stocklocal[i].user,ubicacion:this.stocklocal[i].ubicacionesId,cantidad:this.stocklocal[i].cantidad});
     
    }
  }
 //




  }


  this.ordenarpp1()
  this.total2=this.pp1.length;
  this.pp2=this.pp1.slice(0,10);



}
ordenarpp1(){

  let pp3=[];
  for (let i = 0; i < this.pp1.length; i++) {

    if(this.pp1[i].local.length >0){

      pp3.push(this.pp1[i]);

    }



  }
  for (let i = 0; i < this.pp1.length; i++) {



    if(this.pp1[i].local.length ==0){

      pp3.push(this.pp1[i]);

    }

  }
  this.pp1=pp3;
}

verrel(id:string){

  return "dd"


}

tiendadeubiacion(){



}





async buscarenlocal(page:number){
  this.datosampliados=false;
  this.ocultarlocal=false; 
  this.pp1=[];
  this.pp2=[];
  this.stocklocal=null;
  this.page2=1;
  this.previousPage2=1;

  let filtro="";

  if(this.nom){filtro=filtro+"1" }else {filtro=filtro+"0"} 
  if(this.autor){filtro=filtro+"1" }else {filtro=filtro+"0"} 
  if(this.cod){filtro=filtro+"1" }else {filtro=filtro+"0"} 
  if(this.barr){filtro=filtro+"1" }else {filtro=filtro+"0"} 
  if(filtro=="0000")filtro="1111";

  filtro=filtro+"C";
  if(this.fcates){

    for (let i = 0; i < this.categoriasaponer.length; i++) {

      filtro=filtro+this.categoriasaponer[i]+"-";
  
    }
  

  }


  let result= await this.dataservice.getubi_product_p(this.buscando,filtro).toPromise();


  this.stocklocal=result;

  if(this.stocklocal){
        
          this.hayresultados=true;
         
          this.separaruseres();
  }

    this.mostrar=true;
   
      
  
}
 
loadPage(page: number) {
  if (page !== this.previousPage) {
    this.previousPage = page;

    this.buscarglogal(this.page);
  }
} 


loadPage2(page: number) {

  if (page !== this.previousPage2) {
    


  this.pp2=this.pp1.slice((page*10)-10,(page*10));

 
    this.previousPage2 = page;

    

  }
} 

async buscarglogal(page:number){


  let respuest= await this.dataservice. getproductos( this.buscando,""+page).toPromise();
 
    this.mostrar=false;
    this.productos=respuest.rows;
    this.total=respuest.count


    if( this.productos.length>0){


      this.hayresultados2=true;

    }else{

      this.sinresultados=true;
    }
      

} 

async comprobarstock(){
  
  this.proceso=true;


  for (let i = 0; i < this.productos.length; i++) {
  
    let stock= await this.sacarstock(this.productos[i].id)

    this.productos[i].Stock=stock;

  

    let respuest= await this.dataservice.sacar_todas_rel(this.productos[i].id).toPromise();
    let simi=respuest[2];
    this.productos[i].similares=[];

    for (var j=0; j<simi.length; j++){

      let susti;

      if(simi[j].Producto==this.productos[i].id){
         susti=simi[j].Sustituto;
      }else{
         susti=simi[j].Producto;
      }
    console.log("sustitiuo: "+susti)
      let stock2= await this.sacarstock(susti);
      console.log("Stok sus: "+stock2)

      if(stock2>0){

        let pro= await this.dataservice.getproductoweb(susti).toPromise();
        console.log("ENCONTRADO: "+JSON.stringify(pro))
        this.productos[i].similares.push({Nombre:pro[0].Nombre, Precio:pro[0].Precio, Iva:pro[0].Iva, Codigo:pro[0].Codigo,Stock:stock2});
        console.log("reee: "+JSON.stringify(this.productos[i].similares))
      }
    
    }

   

  }
  this.ordenarsegunda();
  this.datosampliados=true;
  this.proceso=false;

}

ordenarsegunda(){
  
  let temporal=[];
  for (let i = 0; i < this.productos.length; i++) {

    if(this.productos[i].similares.length >0)temporal.push(this.productos[i])


  }

  for (let i = 0; i < this.productos.length; i++) {

    if(this.productos[i].similares.length ==0)temporal.push(this.productos[i])

  }
  this.productos=temporal;
}


rep(){

this.repetir=true;
(<HTMLInputElement>document.getElementById('barradebusqueda')).value=""+this.buscando;
(<HTMLInputElement>document.getElementById('barradebusqueda')).focus();
}


async buscar(){

  this.sinresultados=false;
  this.expandir=false;
  this.productos=[];
  this.proceso=true;

  if(!this.repetir){
    
    this.buscando=(<HTMLInputElement>document.getElementById('barradebusqueda')).value;
     
  }else{
    this.repetir=false;

  }
    (<HTMLInputElement>document.getElementById('barradebusqueda')).value="";
  
 if( this.buscando!=""){


  await this.buscarenlocal(1);


/* busqueda extra-*/
 



this.proceso=false;


}else{

  this.alertService.error("PERO ESCRIBE ALGO CALATRAVAAA!!");
  this.mostrar=true;

}

}
//-----------------CATEGORIAS ----------------------------------------

onChange(categoria:number) {

  const i = this.categoriasaponer.findIndex( element => element == categoria );


  if(i==-1){
  
    this.categoriasaponer.push(categoria)
  }

  (<HTMLInputElement>document.getElementById('miselect')).value="";

  let cate1=(this.categoriaspornivel(1)).length
  let cate2=(this.categoriaspornivel(2)).length

  if(cate2>0){
    this.nivelcate=2;
  }
  if(cate1>0){
    this.nivelcate=1;
  }

}

llenarnombrecate(){

  this.dataservice.buscarcates().subscribe(res => {

    this.todascategorias=res;
  },

    err =>{

  });




}

categoriaspornivel(nivel:number){

    let catenivel:any[]=[];

    if(nivel==0){

      for(let i=0; i<this.todascategorias.length;i++){

        if(this.todascategorias[i].padre==null){
  
            catenivel.push(this.todascategorias[i])

          

        }
      }
  
    }

    if(nivel==1){



      for(let i=0; i<this.todascategorias.length;i++){

      if(this.todascategorias[i].padre!=null){


        if(this.estaseleccionada(this.todascategorias[i].padre)){

     

            catenivel.push(this.todascategorias[i])

          
    

      }

      }
        
      }

    }


    return catenivel;

}

estaseleccionada(id:number){

  let esta=false;


  for(let i=0; i<this.categoriasaponer.length;i++){

    if(this.categoriasaponer[i]==id)esta=true;

  }


  return esta;

}
nombrecategoria(id:number){

if(this.todascategorias.length!=0){


  const i =  this.todascategorias.findIndex( element => element.id == id );


  if(i!=-1){
   
    return this.todascategorias[i].nombre;

  }else{

    return "error";
  }

}

  return "Sin nombre";



}
//*********************hubicaciones**************************** */


borrarcate(id:number){


  const i = this.categoriasaponer.findIndex( element => element == id );


  if(i!=-1){

    this.categoriasaponer.splice(i, 1);
  
  }
  let cate1=(this.categoriaspornivel(1)).length
  let cate2=(this.categoriaspornivel(2)).length

  if(cate2==0){
    this.nivelcate=1;
  }
  if(cate1==0){

    this.nivelcate=0;
  }

}
 
sacartodas(){

   this.dataservice.gettodasubi("0").subscribe(res => {
 
    
       this.ubicaciones=res;

      
     },
     err => console.log(err)
   );
   
}

async sacarstock(id:string){


  let total=0;
        
  let stock=await this.dataservice.getubi_product_producto(id).toPromise();


         for (let i = 0; i < stock.length; i++){ 
 
           total=total+parseInt(stock[i].total);
        }

    
return total;
      



}
}