import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Ubicacion } from "../models/ubicacion";
import { Ubi_product } from "../models/ubi_product";
import { createUrlResolverWithoutPackagePrefix } from '@angular/compiler';
import { Router } from '@angular/router';
import { DataService } from '../_services/data.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { MoverComponent } from '../mover/mover.component';
import { AlertService } from '../alert.service';
import { NgbCalendarGregorian } from '@ng-bootstrap/ng-bootstrap';
import { SequenceEqualOperator } from 'rxjs/internal/operators/sequenceEqual';
import { publishReplay } from 'rxjs/operators';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Component({ 
  selector: 'app-fichaarticulo',
  templateUrl: './fichaarticulo.component.html',
  styleUrls: ['./fichaarticulo.component.css']
})
export class FichaarticuloComponent implements OnInit {




  totalmovis:number;
  tiendas:any[]; 
  idarticulo:number;
  articulo:any;
  ventas:any[]=[];
  movimientos:any[]=[];
  movimientossinfiltrar:any[]=[];
  movimientosfiltrados:any[]=[];
  alertasdevo:any[];
  alertasstocubi:any[];
  alertasstoctiendas:any[];
  filtro:number=3;
  devoluciones:any[]=[];
  totalstock:number=0;
  totalventas:number=0;
  totaldevo:number=0;
  totaldevoa:number=0;
  tentradas:number=0;
  ceveseras:any;
page:number;
previousPage:number;
  mostrar:boolean=true;
  currentUser : any;
  nueva:boolean;
  hayresultados:boolean;
  hayresultados2:boolean;
  pp1:any=[];
  pp2:any=[];
  stocklocal:any = [];
  proveedoresp:any[];
  todascategorias:any[];
  categoriasaponer:any[];
  categoriasaponernuevas:any[];
  ubiselec:any;
  ubicaciones: Ubicacion[];
  haycambiocate:boolean;
 haycambioprov:boolean;
  fijarprov:boolean;
  priorit:number;
  provselec:any;
  proveedores:any[];
  proveedoressaponer:any[];
  proveedoressaponernuevos:any[];
  prioritnueva:number;
  hayalertas:boolean;
  roles:any;
  verventas:boolean; 
  filtrot:number;
  verprove:boolean;
  vermovis:boolean;
  embalajes:any;
  contenido:any;
  complementos:any;
  similares:any;
  tipom:string;
  tiendam:number;
lineasp:any[];
lineaspfiltradas:any[];
productos:any;


  constructor(private alertService: AlertService,private rutaActiva: ActivatedRoute, private router:Router, private dataservice :DataService, private token:TokenStorageService) { }

  ngOnInit(): void {

    this.embalajes=[];
    this.complementos=[];
    this.similares=[];
    this.contenido=[];
    this.tipom="0";
    this.tiendam=0;
    this.proveedoresp=[];
    this.lineasp=[];
    this.lineaspfiltradas=[];
        this.productos=[];
    this.totalmovis=0;
    this.filtrot=0;
    this.verventas=false;
    this.verprove=false;
    this.vermovis=false;
    this.tiendas=[];
    this.sacartiendas();
    this.hayalertas=false;

    this.idarticulo=this.rutaActiva.snapshot.params.id;
    
    this.currentUser = this.token.getUser();
    this.roles =  this.currentUser.roles;
    this.alertasdevo=[];
    this.alertasstocubi=[];
    this.alertasstoctiendas=[];
    this.sacartodas();
    this.cargar();
    this.llenarproveeores()
    this.llenarnombrecate();

  this.page=1;
  this.previousPage=1;
 
  }

  //modulo categorias-*---------------------------------


  verrol(){


    if(""+this.roles=="ROLE_ADMIN" )return 1;
    if(""+this.roles=="ROLE_ROOT" )return 2;

    return 0;
    }
    
    
    formatearh(hora:string){

        let horac=hora.substr(8,2);

        "Fri Jan 07 13:25:40 CET 2022"

        if(hora.substr(4,3)=="Jan")horac=horac+"/1/";
        if(hora.substr(4,3)=="Feb")horac=horac+"/2/";
        if(hora.substr(4,3)=="Mar")horac=horac+"/3/";
        if(hora.substr(4,3)=="Apr")horac=horac+"/4/";
        if(hora.substr(4,3)=="May")horac=horac+"/5/";
        if(hora.substr(4,3)=="Jun")horac=horac+"/6/";
        if(hora.substr(4,3)=="Jul")horac=horac+"/7/";
        if(hora.substr(4,3)=="Aug")horac=horac+"/8/";
        if(hora.substr(4,3)=="Sep")horac=horac+"/9/";
        if(hora.substr(4,3)=="Oct")horac=horac+"/10/";
        if(hora.substr(4,3)=="Nov")horac=horac+"/11/";
        if(hora.substr(4,3)=="Dec")horac=horac+"/12/";

          horac=horac+hora.substr(24,4)+" - "+hora.substr(11,8);

        return horac; 

    }
  borrarubi(re:any){

    if(confirm("tas segur@?")) {

     
    
   
  
  
  
      this.dataservice. borrarubi_produc(re.id).subscribe(res => {
  
 this.cargar();
      },
      err => console.log(err)
      );
  
    }



      console.log("BORRANDO: "+JSON.stringify(re));

  }
llenarnombrecate(){

    this.dataservice.buscarcates().subscribe(res => {
  
      this.todascategorias=res;
    },
  
      err =>{
  
    });
  
  
  
  
  }

cancelarcat(){
 
  this.categoriasaponer=[];
  this.categoriasaponernuevas=[];
  this.haycambiocate=false;
  this.llenarcates(this.articulo)

}

eslocal(categoria:number){

  let  loes:boolean=false;
 
 
  const j = this.ubicaciones.findIndex( fruta => fruta.id === categoria);
 
  if(j!=-1){
 
    if(this.ubicaciones[j].tienda==this.currentUser.tienda){
 
      loes=true;
 
    } 
 
   }
 
 
 //comprobar si la categoria pertenece a la tienda actual
 return loes;
 
 
 }


 quetienda(categoria:number){

  let  nomtienda:number=0;
 
 
  const j = this.ubicaciones.findIndex( fruta => fruta.id === categoria);
 
  if(j!=-1){
    nomtienda=this.ubicaciones[j].tienda

   }
 
 
 //comprobar si la categoria pertenece a la tienda actual
 return nomtienda;
 
 
 }
comprobarsicambio(){

  this.haycambiocate=true;

//console.log("comprobarcambio")
//console.log("viejas: "+this.categoriasaponer+" poner: "+this.categoriasaponernuevas)
//console.log(" longitud viejas: "+this.categoriasaponer.length+" poner: "+this.categoriasaponernuevas.length)

  if(this.categoriasaponernuevas.length==this.categoriasaponer.length){


    let noesta=false;
   
if(this.categoriasaponer.length!=1){

  for(let i=0; i<  this.categoriasaponer.length-1;i++){


    const g =  this.categoriasaponernuevas.findIndex( element => element == this.categoriasaponer[i] );
    
    if(g==-1){
      noesta=true;

    }
  }


}else{

  if(this.categoriasaponer[0]!=this.categoriasaponernuevas[0]) noesta=true;

}



    if(noesta==false){   
      this.haycambiocate=false;
 
    }
  
  

}


} 
nombrecategoria(id:number){

  if(this.todascategorias.length==0){
  
  
    const i =  this.todascategorias.findIndex( element => element.id == id );
  
  
    if(i!=-1){
     
      return this.todascategorias[i].nombre;
  
    }else{
  
      return "error";
    }
  
  }
  
    return "Sin nombre";
  
  
  
  }
  
borrarcate(id:number){



  const i = this.categoriasaponernuevas.findIndex( element => element == id );


  if(i!=-1){

    this.categoriasaponernuevas.splice(i, 1);
  
  }

 
  this.comprobarsicambio();
}

categorizar(){

  let producto=this.articulo.id;

    this.dataservice.borrarcate_prod(producto).subscribe(res => {
  
      for(let i=0; i<  this.categoriasaponernuevas.length;i++){
  
        let cate_prod={
  
          productosId:producto,
          categoriaId:this.categoriasaponernuevas[i]
        
        }

  
        this.dataservice.crearcate_prod(cate_prod).subscribe(res => {
            
          
            if(i== this.categoriasaponernuevas.length-1)this.cancelarcat();
        },
        err =>{
      
        });
      
       
      }
  
  
    },
    err =>{
  
    });
  
  
  
  
  }
  llenarcates(res:any){
 

  
    this.dataservice.  sacracatedepro(""+res.id).subscribe(res => { 
  
      this.categoriasaponer=[];
  
      for(let i=0; i<res.length;i++){
  
        this.categoriasaponer.push(res[i].categoriaId);
        this.categoriasaponernuevas.push(res[i].categoriaId);
      }


    },
      err =>{
  
  
      });
  }
  
onChange(categoria:number) {



  const i = this.categoriasaponernuevas.findIndex( element => element == categoria );


  if(i==-1){
  
    this.categoriasaponernuevas.push(categoria)
  }

  (<HTMLInputElement>document.getElementById('miselect')).value="";
  
  this.comprobarsicambio();
  
}
  //FIN modulo categorias-*---------------------------------


//**********************Proveedores******************************************* */
comprobarsicambio2(){

  this.haycambioprov=true;

  console.log("Comprobando cambio ")

    if(this.proveedoressaponer.length==this.proveedoressaponernuevos.length){
      console.log("Son iguales de largo")
  
      let noesta=false;

      if(this.prioritnueva!=this.priorit)noesta=true;

      if(this.proveedoressaponer.length!=1){
      
        for(let i=0; i<  this.proveedoressaponer.length-1;i++){
      
      
          const g =  this.proveedoressaponernuevos.findIndex( element => element == this.proveedoressaponer[i] );
          
          if(g==-1){

            noesta=true;
            console.log("no esta: "+this.proveedoressaponer[i])
          }else{

            console.log("esta "+this.proveedoressaponer[i])
          }
        }
      
      
      }else{
      
        if(this.proveedoressaponer[0]!=this.proveedoressaponernuevos[0]) noesta=true;
        console.log("Solo mide 1")
      }
  
  
  
      if(noesta==false){   

        this.haycambioprov=false;
   
      }
    
      if( this.haycambioprov==false){

        console.log("No hay cambio")

      }
    
  
  }else{


  }
}
llenarproveeores(){

  this.dataservice.sacratodosprov("-1").subscribe(res => {

    
  
    this.proveedores=res;


  },
  err => console.log(err)
);



}

cambiandoprv(proveedor:number) {
  
    const i = this.proveedoressaponernuevos.findIndex( element => element == proveedor );

    if(i==-1){
    
      this.proveedoressaponernuevos.push(proveedor)
    }

    this.comprobarsicambio2();

    (<HTMLInputElement>document.getElementById('miselect2')).value="";
  
 
}

prioritario(proveedor:number) {

    console.log("PRIORITARIOI; "+proveedor) 
    
    this.priorit=proveedor;
 
}


cancelprov(){

  this.proveedoressaponer=[];
  this.proveedoressaponernuevos=[];
  this.haycambioprov=false;

  this.llenarprov(this.articulo)



}


borrarprov(id:number){

    const i = this.proveedoressaponernuevos.findIndex( element => element == id );
  
    if(i!=-1){
  
      this.proveedoressaponernuevos.splice(i, 1);
      if(this.prioritnueva==id)this.prioritnueva=null;
    }
    this.comprobarsicambio2();
}

clearForm(){

    (<HTMLFormElement>document.getElementById("Myform")).reset();
    this.prioritnueva=null; 

    this.comprobarsicambio2();
}

nombreprov(id:number){

  if(this.proveedores.length>0){
  
  
    const i =  this.proveedores.findIndex( element => element.codigo == id );
  
  
    if(i!=-1){
     
      return this.proveedores[i].nombre;
  
    }else{
  
      return "error";
    }
  
  }
  
    return "Sin nombre";
  
  
  
  }

  getColorfondo2(){

    if(this.fijarprov){
  
      return "pink";
    }
  
    return "transparent"
  
  }

  
proveerizar(){



  let producto=this.articulo.id;

    this.dataservice.borrarprove_prod(""+producto).subscribe(res => {
  
      for(let i=0; i<  this.proveedoressaponernuevos.length;i++){
        let priori=0;

        if(this.priorit!=null){

          if(this.prioritnueva==this.proveedoressaponernuevos[i]){

            priori=1;
          }

        }

        let prov_prod={
  
          productoId:producto,
          proveedorId:this.proveedoressaponernuevos[i],
          prioridad:priori
        
        }
        console.log("enviando "+JSON.stringify( prov_prod));

        this.dataservice.crearprove_prod(prov_prod).subscribe(res => {
      
        },
        err =>{
      
        });
      
       
      }
  
      this.cancelprov();
    },
    err =>{
  
    });
  


}

async llenarprov(res:any){

  await this.dataservice.sacraprovedepro(""+res.id).subscribe(res => { 

    this.proveedoressaponer=[];
 

    for(let i=0; i<res.length;i++){

      this.proveedoressaponer.push(res[i].proveedorId);
      this.proveedoressaponernuevos.push(res[i].proveedorId);
      if(res[i].prioridad==1){
        this.priorit=res[i].proveedorId;
        this.prioritnueva=this.priorit;
      }
    }
   
  },

    err =>{


    });


}

//***************************************************************** */




 

  cargar(){

    
    this.categoriasaponer=[];
    this.categoriasaponernuevas=[];
    this.haycambiocate=false;
    this.haycambioprov=false;
    this.proveedoressaponer=[];
    this.proveedoressaponernuevos=[];

    if(this.rutaActiva.snapshot.params.codigo==null){



      this.dataservice.getproducto(""+this.idarticulo).subscribe(res => {

        this.articulo=res;
        this.sacarventas(res.Codigo);
        this.cargaralertas(res);
        this.llenarcates(res)
        this.llenarprov(res)
        this.sacarp();
  
        this.buscarenlocal(""+res.id);
        this.sacamovi(1);
        this.mostrardatorelaciones();
       // this.sacardevos(res.id);
  
      },
      err => console.log(err)
      );

    }else{

      this.dataservice.getproductocodigo(""+this.idarticulo).subscribe(res => {

        this.articulo=res;
        this.sacarventas(res.Codigo);
        this.cargaralertas(res);
        this.llenarcates(res)
        this.llenarprov(res)
        this.sacarp();
        this.mostrardatorelaciones();
        this.buscarenlocal(""+res.id);
        this.sacamovi(1);
       // this.sacardevos(res.id);
  
      },
      err => console.log(err)
      );

    }




  }


async sacarp(){



  let cabezeras=[];
  this.lineasp = await this.dataservice.albaranes_lineasproducto(""+this.articulo.Codigo).toPromise();

  for (var i=0; i<this.lineasp.length; i++){
  this.tentradas=this.tentradas+this.lineasp[i].cantidad;
    let cabeze = await this.dataservice.albaranes_cabezera(""+this.lineasp[i].documento).toPromise();

    cabezeras.push(cabeze);
  }
  this.ceveseras=cabezeras;

  let proveedoresdd=[];

  for (var i=0; i<cabezeras.length; i++){


    let pro={"id":cabezeras[i].proveedor,"nombre":this.nombreprov(cabezeras[i].proveedor),"precio":0, "entradas":0}
  
 

    //recorremos todas las lienas
    for (var z=0; z<this.lineasp.length; z++){

      //si es de ese proveedor
      if(pro.id==this.proveedordelinea(this.lineasp[z].documento,cabezeras)){

        pro.entradas=pro.entradas+this.lineasp[z].cantidad;
        //ponemos el precio, pero solo una vez
        if(pro.precio==0)pro.precio=this.lineasp[z].Precio
  
      }
  
    }
    const J = proveedoresdd.findIndex( fruta => fruta.id === pro.id );
    if(J==-1){

      proveedoresdd.push(pro);


    }else{


     if(proveedoresdd[J].precio!=0)proveedoresdd[J].precio=pro.precio;
    }
    

 

  }

 this.proveedoresp=proveedoresdd;

 this.lineaspfiltradas= this.lineasp;
 
/*
 for (var i=0; i<cabezeras.length; i++){

  for (var z=0; i<this.lineasp.length; z++){

    if(cabezeras[i].documento==this.lineasp[z].documento){
      
      let precio= this.lineasp[z].Precio;
      console.log(" PRECIO "+precio);
  //    if(this.proveedoresp[i].precio>precio || this.proveedoresp[i].precio==0)this.proveedoresp[i].precio=precio


    }

  }
  

 }
*/
 


}

proveedordelinea(id:any,cabezeras:any){

  let proveedor;

  for (var z=0; z<cabezeras.length; z++){

    
    if(cabezeras[z].documento==id){

      proveedor=cabezeras[z].proveedor

    }

  }

return proveedor;

}


filtrarlineasp(id:string){

if(id=="0"){

  this.lineaspfiltradas=   this.lineasp;
}else{
  this.lineaspfiltradas=[];

  for (var i=0; i<this.lineasp.length; i++){
  
    if(this.proveedordelinea(this.lineasp[i].documento,this.ceveseras)==id)this.lineaspfiltradas.push(this.lineasp[i]);
  }


}



}


cargaralertas(articulo:any){

    this.hayalertas=true;

    this.dataservice.avisos_producto(""+articulo.id).subscribe(res => {

      this.separaralertas(res);


    },
    err => console.log(err)
    );





}
separaralertas(alertas:any){


  this.alertasdevo=[];
  this.alertasstocubi=[];
  this.alertasstoctiendas=[];

  for (var i=0; i<alertas.length; i++){

    if(alertas[i].tipo=="Devolver")this.alertasdevo.push(alertas[i]);
    if(alertas[i].tipo=="MinStock")this.alertasstocubi.push(alertas[i]);
    if(alertas[i].tipo=="MinStockTienda")this.alertasstoctiendas.push(alertas[i]);
    

  }
  
  
}

formatarstockubi(re:any){


  return "Avisar si el stock baja de "+re.cantidad+" en la ubicacion "+this.nombretienda(re.tienda)+":"+this.nombreubicaciones(re.ubicacion);
}
formatarstocktienbda(re:any){

  return "Avisar si el stock baja de "+re.cantidad+" en la tienda "+this.nombretienda(re.tienda);


}
formatardevo(re:any){

  return "Devolver "+re.cantidad+" Unidades, de la ubicacion "+this.nombretienda(re.tienda)+":"+this.nombreubicaciones(re.ubicacion)+", "+re.texto+" dias despues de la ubicacion" ;

}


borraraviso(aviso:any){

  if(confirm("seguro que deseas borrar la alerta?")) {


    this.dataservice.borraraviso(aviso.id).subscribe(res => {
      this.dataservice.borraravisoshijos(""+aviso.id).subscribe(res => {

   
    
      },
      err => console.log(err)
          );
  this.cargar();
  
     },
     err => console.log(err)
   );
    }




}




sacartiendas(){


  
  this.dataservice.sacartiendas().subscribe(
    res => {

      this.tiendas = res;
      

    },
    err => {
  
    }
  );

}
buscarenlocal(id:string){
  
  this.pp1=[];
  this.pp2=[];
    this.stocklocal=null;
  
    //"0001C-"
    this.dataservice.getubi_product_i(id).subscribe(res => {
  
      this.stocklocal=res;
  
   
      if(this.stocklocal.length>0){
        
          this.hayresultados=true;
          this.mostrar=true;
      
      } 
      
    },
    err => console.log(err)
    );
  
  
  
  }
  nombretienda(id:number){

    let nombre="ERROR"
  
   
    const i = this.tiendas.findIndex( fruta => fruta.id === id );
   
   
  
    if(i!=-1){
    
     nombre=this.tiendas[i].name;
  
  
    }
  
    return nombre 
  
  }

  filtrarventast(id:number){

    this.filtrot=id;



  }

  nombreubicaciones(id:string){
  
    
    let devo="";
    const j = this.ubicaciones.findIndex( fruta => fruta.id === parseInt(id));

    if(j!=-1){
      if(this.ubicaciones[j].Padre!=null){
    
        const k = this.ubicaciones.findIndex( fruta => fruta.id === this.ubicaciones[j].Padre);
        devo=this.ubicaciones[k].Nombre+" / "+this.ubicaciones[j].Nombre;
    
      }else{
    
        devo=""+this.ubicaciones[j].Nombre;
      }
    }
    return devo;
    
    } 

 
  
//*********************hubicaciones**************************** */



sacartodas(){

  this.dataservice.gettodasubi("0").subscribe(res => {

   
      this.ubicaciones=res;

     
    },
    err => console.log(err)
  );
  
}
sacardevos(id:string){


  this.dataservice.devoarti(id).subscribe(res => {

   
    this.devoluciones=res;
      this.formateardevo();


   console.log("result de"+JSON.stringify(res))
  },
  err => console.log(err)
);

}

formateardevo(){

  for (let i = 0; i < this.devoluciones.length; i++) {

      if(this.devoluciones[i].estado=="activo"){
        
        this.totaldevo=this.totaldevo+this.devoluciones[i].cantidad;

      }else{

          this.totaldevoa=this.totaldevoa+this.devoluciones[i].cantidad;
      }
  }

}


//*****************sacar ventas********** */

sacarventas(id:string){

  console.log("--- VENTAS DE "+id)
  this.dataservice.ventas_ariculo(id).subscribe(res => {

   console.log("--- "+JSON.stringify(res))
    this.ventas=res;

    for (let i = 0; i < this.ventas.length; i++) {
      this.totalventas=this.totalventas+this.ventas[i].Cantidad;
    }

   
  },
  err => console.log(err)
);


}



filtramas(tienda:number){
  
  this.tiendam=tienda;
  this.loadPage(1)
}

formatearmovi(ew:any){

  
  
  if(ew.tipo=="Nuevo Stock"){
  
  return this.nombreubicaciones(""+ew.destino);
  
  }
  

  if(ew.tipo=="Cambio de ubicacion"){
  
  
    return this.nombreubicaciones(""+ew.origen)+" >> "+this.nombreubicaciones(""+ew.destino);
  }
  
  

  
  
 
  return "";



}

comprobar(vector:any){


 let dev:boolean=false;
 
    
    if(vector!=null){

      if(vector.length>0){
          dev=true;

      }
    }

return dev;
}

//************sacar movi*******************/
sacamovi(page:number){

  this.dataservice.buscamovi_pro(""+this.articulo.id,this.tipom,""+page,""+this.tiendam).subscribe(res => {

   
    this.movimientos=res.rows.reverse();
    this.totalmovis=res.count;
 

  },
  err => console.log(err)
);

}


loadPage(page: number) {

  if (page !== this.previousPage) {
    this.previousPage = page;
    this.sacamovi(this.page);

  }
}




async mostrardatorelaciones(){


  this.embalajes=[];
  this.complementos=[];
  this.similares=[];
  this.contenido=[];

  let respuest= await this.dataservice.sacar_todas_rel(this.articulo.id).toPromise();



  let emba=respuest[0];
  let compl=respuest[1];
  let simi=respuest[2];

  for (var i=0; i<emba.length; i++){
    

    if(emba[i].Producto==this.articulo.id){

        //es el producto
        let pro= await this.dataservice.getproductoweb(emba[i].Embalaje).toPromise();
        this.embalajes.push({producto:pro[0], cantidad:emba[i].Cantidad, id:emba[i].id})

    }else{
        
        let pro= await this.dataservice.getproductoweb(emba[i].Producto).toPromise();
        this.contenido.push({producto:pro[0], cantidad:emba[i].Cantidad,id:emba[i].id})
    }

  }
  console.log("Embalajes:" +JSON.stringify(this.embalajes))
  console.log("Contenido:" +JSON.stringify(this.contenido))

  for (var i=0; i<compl.length; i++){
    
    if(compl[i].Producto==this.articulo.id){

      //es el producto
      let pro= await this.dataservice.getproductoweb(compl[i].Complementario).toPromise();
      this.complementos.push({producto:pro[0], cantidad:compl[i].Cantidad,id:compl[i].id})
    }

  }

 console.log("Complementos:" +JSON.stringify(this.complementos))

  for (var i=0; i<simi.length; i++){
   

    if(simi[i].Producto==this.articulo.id){

      let pro= await this.dataservice.getproductoweb(simi[i].Sustituto).toPromise();
      this.similares.push({producto:pro[0],id:simi[i].id})

    }else{

      let pro= await this.dataservice.getproductoweb(simi[i].Producto).toPromise();
      this.similares.push({producto:pro[0],id:simi[i].id})

    }

  }
  console.log("Similares:" +JSON.stringify(this.similares))



}

async borrarrel(id:string,tipo:string){

  if(confirm("Se va a borrar la relacion, estas seguro?")){

    let pro=await this.dataservice. borrar_rel(id,tipo).toPromise();
    this.mostrardatorelaciones();

  }



}

mostrarbarra(id:string){

  const i =  this.productos.findIndex( element => element.id == id );


  if(i!=-1){


    return this.productos[i].Codigo;

  }

}




filtrarmovi(pagina:number){



  this.movimientossinfiltrar=[];
  this.movimientosfiltrados=[];

  for (let i = 0; i < this.movimientos.length; i++) {

  if(this.movimientos[i].tipo!="Venta" && this.movimientos[i].tipo!="Añadido a devoluciones" && this.movimientos[i].tipo!="Devuelto"){


    if(this.filtro==1 ){

      if(this.movimientos[i].user==1) this.movimientossinfiltrar.push(this.movimientos[i]);

    }

    if(this.filtro==2 ){

      if(this.movimientos[i].user==2) this.movimientossinfiltrar.push(this.movimientos[i]);

    }

    if(this.filtro==3 ) this.movimientossinfiltrar.push(this.movimientos[i]);
    


  }
}



if(((pagina-1)*10)+10>this.movimientossinfiltrar.length){



  for (let i = (pagina-1)*10 ; i < this.movimientossinfiltrar.length; i++) {

    this.movimientosfiltrados.push( this.movimientossinfiltrar[i]);
  }

}else{


  for (let i = (pagina-1)*10 ; i < ((pagina-1)*10)+10; i++) {

    this.movimientosfiltrados.push( this.movimientossinfiltrar[i]);
  }

}

  





}



}
 