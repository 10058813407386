import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../_services/data.service';
import { Ubicacion } from "../models/ubicacion";
import { Ubi_product } from "../models/ubi_product";
import { TokenStorageService } from '../_services/token-storage.service';
import { AlertService } from '../alert.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Router } from '@angular/router';
import { JsonpClientBackend } from '@angular/common/http';
import { TransitiveCompileNgModuleMetadata } from '@angular/compiler';
 
@Component({
  selector: 'app-nuevostock',
  templateUrl: './nuevostock.component.html',
  styleUrls: ['./nuevostock.component.css']
})

export class NuevostockComponent implements OnInit {

  avisod:boolean; 
  avisos:boolean;
  sweb:boolean;
  nivelcate:number;
  editc:boolean;
  movimiento:any={

    cantidad:0,
    user:0,
    productoId:0, 
    destino:0,
    origen:0,
    tipo:""

  }

  conbarra:boolean;
  concodigo:boolean;
  ubicaciones: Ubicacion[];
  pendientes:number=0;
  esta:Ubi_product[];
  nombre:string="";
  idubi:string;

  stock:Ubi_product={

    id:0,
    productosId:null,
    ubicacionesId:null,
    user:null,
    cantidad:null,
   

  };
  
  producto:any;
  cargando:boolean=false;
  inicio: number;
  cantidad:number=1;
  edit: boolean = false;
  tienehijos: boolean = false;
  hayresultados: boolean = false; 
  modificando:boolean=false;
  currentUser : any;

  seleccionado :Ubicacion={
    id:null,
    user:null,
    tienda:0,
    Nombre: "",
    Padre:null,
    Notas:"",
    Prioridad:0 
   
  }

  cosa: Ubicacion ={
    id:null,
    user:null,
    tienda:0,
    Nombre: "",
    Padre:null,
    Notas:"",
    Prioridad:0
  }
  
  barra:string;
  fijarcategorias:boolean;
  ubiselec:any; 
  todascategorias:any[];
  categoriasaponer:any[];
  fijarprov:boolean;
  priorit:number;
  provselec:any;
  proveedores:any[];
  proveedoressaponer:any[];
  tienda:any;
  botona:boolean;

  constructor(private router:Router, private rutaActiva: ActivatedRoute, private dataservice :DataService,private token: TokenStorageService,private alertService: AlertService,private cdRef: ChangeDetectorRef) {

  }

ngOnInit() {

    this.editc=false;
    this.nivelcate=0;
    this.sweb=false;
    this.conbarra=true;
    this.concodigo=false;
    this.botona=false;
    this.avisod=false;
    this.avisos=false;
    this.currentUser = this.token.getUser();
    this.tienda=this.currentUser.tienda;
    this.fijarcategorias=false;
    this.categoriasaponer=[];
    this.fijarprov=false;
    this.proveedoressaponer=[];
    this.inicio=0;
    this.currentUser = this.token.getUser();
    this.idubi=this.rutaActiva.snapshot.params.id;

    this.llenarnombrecate();
   
    this.sacartodas();
    

}

//***************************CATEGORIAS************************************** */


getColorfondo(){

  if(this.fijarcategorias){

    return "pink";
  }

  return "transparent"

}

onChange(categoria:number) {

  const i = this.categoriasaponer.findIndex( element => element == categoria );


  if(i==-1){
  
    this.categoriasaponer.push(categoria)
  }

  (<HTMLInputElement>document.getElementById('miselect')).value="";

  let cate1=(this.categoriaspornivel(1)).length
  let cate2=(this.categoriaspornivel(2)).length

  if(cate2>0){
    this.nivelcate=2;
  }
  if(cate1>0){
    this.nivelcate=1;
  }

}

async llenarnombrecate(){


  await this.dataservice.buscarcates().subscribe(res => {

    this.todascategorias=res;


  },

    err =>{

  });




}

categoriaspornivel(nivel:number){

    let catenivel:any[]=[];

    if(nivel==0){

      for(let i=0; i<this.todascategorias.length;i++){

        if(this.todascategorias[i].padre==null){
          if(this.sweb==true){
            
            if(this.todascategorias[i].web==1)catenivel.push(this.todascategorias[i])

          }else{

            catenivel.push(this.todascategorias[i])

          }

        }
      }
  
    }

    if(nivel==1){



      for(let i=0; i<this.todascategorias.length;i++){

      if(this.todascategorias[i].padre!=null){


        if(this.estaseleccionada(this.todascategorias[i].padre)){

          if(this.sweb==true){
            
            if(this.todascategorias[i].web==1)catenivel.push(this.todascategorias[i])

          }else{

            catenivel.push(this.todascategorias[i])

          }
    

      }

      }
        
      }

    }


    return catenivel;

}

estaseleccionada(id:number){

  let esta=false;


  for(let i=0; i<this.categoriasaponer.length;i++){

    if(this.categoriasaponer[i]==id)esta=true;

  }


  return esta;

}

nombrecategoria(id:number){

 

  if(this.todascategorias.length!=0){


    const i =  this.todascategorias.findIndex( element => element.id == id );


    if(i!=-1){
    
      return this.todascategorias[i].nombre;

    }else{

      return "error";
    }

  }

  return "Sin nombre";



}
borrarcate(id:number){


  const i = this.categoriasaponer.findIndex( element => element == id );


  if(i!=-1){

    this.categoriasaponer.splice(i, 1);
  
  }
  let cate1=(this.categoriaspornivel(1)).length
  let cate2=(this.categoriaspornivel(2)).length

  if(cate2==0){
    this.nivelcate=1;
  }
  if(cate1==0){

    this.nivelcate=0;
  }

}
 
 
async categorizar(res:any ,ctr:any){

  let producto=res;
  console.log("- 2 Categorizando .. ");
    await this.dataservice.borrarcate_prod(producto).subscribe(async res => {
      console.log("- 3 Categorias enteriores borradas");
      for(let i=0; i<  ctr.length;i++){
  
        let cate_prod={
  
          productosId:producto,
          categoriaId:ctr[i]
        
        }
   
        await this.dataservice.crearcate_prod(cate_prod).subscribe(res => {
          console.log("-4 Categorizado: "+cate_prod);
        },
        err =>{
      
        });
      
       
      }
  
  
    },
    err =>{
  
    });
  
  
  
  
  }

  llenarcates(res:any){
 

  
    this.dataservice.  sacracatedepro(""+res.id).subscribe(res => { 
  
      this.categoriasaponer=[];
  
      for(let i=0; i<res.length;i++){
  
        this.categoriasaponer.push(res[i].categoriaId);
  
      }
    
    },
      err =>{ 
  
  
      });
  }
  
  
//***************************************************************** */
aceptar(){


  this.subir();
  this.botona=true;

}

async subir(){

  console.log("Subiendo stock .. ");
    this.stock.productosId=this.producto.id;
    this.stock.ubicacionesId=parseInt(this.idubi);
    this.stock.user=this.token.getUser().tienda;
    this.cargando=true;
    this.cdRef.detectChanges();

    if(this.sweb){

      this.producto.web=this.sweb

      let ggg= await this.dataservice.modificarproducto(this.producto.id,this.producto).toPromise();
      
      if(ggg){
  
        console.log("* 1 Puesto en la web ");
      }
     


    }

    if(this.categoriasaponer.length>0){
      

     console.log("- 2 Categorizando .. ");
       let ccc= await this.dataservice.borrarcate_prod(this.producto.id).toPromise();
       if(ccc){

        console.log("- 3 Categorias enteriores borradas");
        for(let i=0; i<  this.categoriasaponer.length;i++){
    
          let cate_prod={
    
            productosId:this.producto.id,
            categoriaId:this.categoriasaponer[i]
          
          }
     
          let crear=await this.dataservice.crearcate_prod(cate_prod).toPromise();
          if(crear){
            console.log("-4 Categorizado: "+cate_prod);
          }
         
        
         
        }
       }
     
         


    }

    console.log("5 sacando ubicaciones anteriores");
    await this.dataservice.getubi_product_i(this.producto.id).subscribe(async res => { 
     
      this.cargando=false;
      this.cdRef.detectChanges();
        if(res){

          let encontrao="";
          this.esta=res;
          console.log("6 Comprobando donde esta.. ");
         for (let i = 0; i < this.esta.length; i++) {
           if(this.esta[i].ubicacionesId==parseInt(this.idubi)){
              encontrao=""+this.esta[i].id;
              this.stock.cantidad=this.esta[i].cantidad;
            }
          }
          if(encontrao!=""){
            console.log("7 Esta en la misma ubicacion. aumentando");
              this.stock.productosId=this.producto.id;
              this.stock.ubicacionesId=parseInt(this.idubi)
              this.stock.user=this.token.getUser().tienda;
              this.stock.cantidad=this.stock.cantidad+this.cantidad;
      
              this.stock.id= parseInt(encontrao);

              let g = await this.dataservice.modificarubi_produc(""+encontrao,this.stock).toPromise();
              
              if(g){
                console.log("8 aumentada cantidad en stock");
                this.stock.cantidad=this.cantidad;
                await this.guardasmovi( this.stock);

              }
     

 
          }else{
              console.log("7 No esta ubicado en esta ubicacion. Creando nueva");
              this.stock.productosId=this.producto.id;
              this.stock.ubicacionesId=parseInt(this.idubi);
              this.stock.user=this.token.getUser().tienda;
              this.stock.cantidad=this.cantidad;

              let g= await this.dataservice.crearubi_product(this.stock).toPromise();
              
              if(g){       console.log("8 CREADA");  
             
                 this.guardasmovi( this.stock);}


          }

        }
        
  },
  err =>{
        this.cargando=false;
         this.cancelar();
  });



}

sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}

async guardasmovi(sub:any){

  console.log("9 Guandando Movimiento .. ");
  this.movimiento.cantidad=sub.cantidad;
  this.movimiento.user=sub.user;
  this.movimiento.productoId=sub.productosId
  this.movimiento.destino=sub.ubicacionesId;
  this.movimiento.origen=-1;
  this.movimiento.tipo="Nuevo Stock";



  await this.dataservice.creamovi(this.movimiento).subscribe(res => {

    this.movimiento={

      cantidad:0,
      user:0,
      productoId:0,
      destino:0,
      origen:0,
      tipo:""
    
    }
    console.log("10 .. Movimiento guardado");
    if( this.botona)this.cancelar();

  },
    err =>{

  });




}
playAudio(tipo :string){

  let audio = new Audio();
  if(tipo==="ok"){
    audio.src = "../../../assets/beep-ok.wav";
  }else{
    audio.src = "../../../assets/beep-f.wav";
  }


  audio.load();
  audio.play();
}

//****************************************************************** */
async cargar(){

 let busqueda= (<HTMLInputElement>document.getElementById('barr')).value;
 let error=false;
 this.cargando=true;

  if(this.inicio==1 ){

      if(parseInt(this.idubi)!=null){
  
       
      await this.subir();
 
     
      }else{
        error=true;
        this.playAudio("fail");
      }

  }


  if(!error){


  

 if(this.conbarra){

 
 this.cdRef.detectChanges();

  await this.dataservice. getproducto(""+busqueda).subscribe(async res => {
    this.cargando=false;
    this.cdRef.detectChanges();      
    (<HTMLInputElement>document.getElementById('barr')).value="";
    (<HTMLInputElement>document.getElementById('barr')).focus();

    //console.log("Producto buscado:"+JSON.stringify(this.producto.Nombre))
    
    if(res!=null){
       

      this.producto=res;

      this.cantidad=1;
      this.inicio=1;
      this.playAudio("ok");


    }else{
      
     this.playAudio("fail");
     this.inicio=2;
    }
 


  },
  err =>{
    
    this.playAudio("fail");
    this.inicio=2;
    this.alertService.error("--"+err.message);

  });




 }

 if(this.concodigo){

  this.cdRef.detectChanges();
 
          await this.dataservice. getproductocodigo(""+busqueda).subscribe(res => {

            this.sleep(2000);  
            this.cargando=false;
             this.cdRef.detectChanges();
             (<HTMLInputElement>document.getElementById('barr')).value="";
             (<HTMLInputElement>document.getElementById('barr')).focus();
            //  console.log("Producto buscado:"+JSON.stringify(this.producto.Nombre))
              
              if(res!=null){
                
     
                this.producto=res;
                this.cantidad=1;
                this.inicio=1;
                this.playAudio("ok");

      
              }else{
                
               this.playAudio("fail");
               this.inicio=2;
              }
           
          

            },
            err =>{
              
              this.playAudio("fail");
              this.inicio=2;
              this.alertService.error("--"+err.message);

            });
  }}

  this.cargando=false;
  this.cdRef.detectChanges();
  (<HTMLInputElement>document.getElementById('barr')).value="";
  (<HTMLInputElement>document.getElementById('barr')).focus();
}

//****************************************************************** */
cancelar(){

  this.inicio=0;
  this.cantidad=1;
  this.producto=[];
  this.modificando=false;
  this.stock={

    id:0,
    productosId:null,
    ubicacionesId:null,
    user:null,
    cantidad:null,
  }; 

  this.barra="";
  this.botona=false;
}

//****************************************************************** */
//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
sacarnombre(id:string){


  let devo="";
  const j = this.ubicaciones.findIndex( fruta => fruta.id === parseInt(id));

  if(j!=-1){

    if(this.ubicaciones[j].Padre!=null){
  
      const k = this.ubicaciones.findIndex( fruta => fruta.id === this.ubicaciones[j].Padre);
      devo=this.ubicaciones[k].Nombre+" / "+this.ubicaciones[j].Nombre;
  
    }else{
  
      devo=""+this.ubicaciones[j].Nombre;
    }
  }
  return devo;
  

}
//********** categorias ****************/
//**********************************************/

sacartodas(){

   this.dataservice.gettodasubi("0").subscribe(res => {
 
    
       this.ubicaciones=res;
        
          this.nombre=this.sacarnombre(this.idubi);
          this.hayresultados=true;
          

     },
     err => console.log(err)
   );
   
}


//**********botones + -************/

mas(cant:number){

  this.cantidad=this.cantidad+cant;
}

menos(cant:number){


if(this.cantidad-cant>1){
  
  this.cantidad=this.cantidad-1;

}else{

  this.cantidad=1;
}
}

cambiart(){
  if(this.conbarra){
    this.concodigo=false;
  }else{
    this.concodigo=true;

  }

 
  
}
cambiard(){
  if(this.concodigo){
    this.conbarra=false;
  }else{
    this.conbarra=true;

  }
 

}

}


  