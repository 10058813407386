<div class="container">
  <header class="jumbotron" style="margin-top: 30px;">

<h1>Stockaitor 1.7</h1>

<h4 style="margin-top: 15px;margin-left: 20px;">Cambios de version</h4>  
<p style="margin-top: 15px;margin-left: 30px;">
  <br><b>- Mejoras del buscador</b><br>
  &nbsp;&nbsp;&nbsp;&nbsp;Agrupacion de resultados para que no aparezcan repetidos,<br>	
  &nbsp;&nbsp;&nbsp;&nbsp;mostrando el stock por articulo en vez de por tienda.<br>	
  &nbsp;&nbsp;&nbsp;&nbsp;Ademas se a añadido a los articulos datos de los productos relacionados.<br>	<br>	
  
  <br><b>- Gestion de relaciones</b><br>
  &nbsp;&nbsp;&nbsp;&nbsp;Se pueden crear a mano, 3 tipos de relaciones<br>	
  &nbsp;&nbsp;&nbsp;&nbsp;Embalajes, Productos Sustitutivos y prodcutos Complementarios.<br>	

  <br><b>- Ubicar por codigo de Barras</b><br>
  &nbsp;&nbsp;&nbsp;&nbsp;Ahora es posible stockar o mover en candena pasando el codigo de cada ubicacion.<br>	

  <br><b>- Gestion de ubicaciones</b><br><br>	
  &nbsp;&nbsp;&nbsp;&nbsp;Las ubicaciones tienen un codigo de barras asociado<br>	
  &nbsp;&nbsp;&nbsp;&nbsp;Es posible imprimir el codigo directamente desde esta ventana.<br>	


</p>
<h4 style="margin-top: 15px;margin-left: 20px;"><b>Correccion de errores</b></h4> 
<p style="margin-top: 15px;margin-left: 30px;">

  &nbsp;&nbsp;&nbsp;&nbsp;Arreglado el fallo de de acceso por HTTPS<br>

		      	


		
			



</p>
  
</header>

</div> 
