
<div class="container" *ngIf="tiendaaenviar==-1" style="margin-top:20px" >


  <div *ngFor="let re of tiendas">
    <button *ngIf="re.id!=this.currentUser.tienda" type="button" (click)="siguiente(re)" class="btn btn-dark btn-lg btn-block"  style="margin-bottom:15px; height: 80px;">ENVIAR ARTICULOS A: {{re.name}}</button>
  </div>


  <div *ngIf="!ubicando">
 
    <div *ngIf=" listaporrecibir.length>0">
      <h5 style="margin-top:15px;">Articulos pendientes de confirmar recepcion:</h5>
      <button type="button" class="btn btn-danger btn-xs" (click)="descartartr()"> Descartar todo</button>

     <table class="table">
     
         <tr> 
             <td></td> 
             <td>Nombre</td> 
             <td>Barra</td>
             <td>Codigo</td>
             <td>Precio</td> 
             <td>Cantidad</td>
             <td>Origen</td>
  
         </tr>
         
         <tr *ngFor="let re of listaporrecibir">
           <td><img src="{{re.producto.Barra | sacarfoto}}" width="70" height="100" /> </td>
           <td><a [routerLink]="['../articulo/',re.producto.Barra]"> {{re.producto.Nombre}}</a></td>
           <td>{{re.producto.Barra}}</td>
           <td>{{re.producto.Codigo}}</td>
           <td>{{re.producto.Precio | sumariva:re.producto.Iva}}</td>
           <td>{{re.cantidad}}</td>
           <td>{{nombretienda(re.origen)}}</td>
           <td><button type="button" class="btn btn-success btn-xs" (click)="ubicar(re.id)"><i class="fas fa-cart-plus"> Ubicar</i></button></td>
           <td><button type="button" class="btn btn-danger btn-xs" (click)="descartar(re.id)"><i class="fas fa-minus-square"> Descartar</i></button></td>
  
           
         </tr>
  
        
         </table>
     <!--  <p>{{ user.email }}</p> -->
  </div>
  <div *ngIf=" discrepancias.length>0">
    <h5 style="margin-top:15px;">Articulos devueltos en caja</h5>
    <button type="button" class="btn btn-danger btn-xs" (click)="descartartd()">Descartar todo</button>

   <table class="table">
   
       <tr>
           <td></td> 
           <td>Nombre</td> 
           <td>Barra</td>
           <td>Codigo</td>
           <td>Precio</td>
           <td>Cantidad</td>
   

       </tr>
       <tr *ngFor="let re of discrepancias">
         <td><img src="{{re.producto.Barra | sacarfoto}}" width="70" height="100" /> </td>
         <td><a [routerLink]="['../articulo/',re.producto.Barra]"> {{re.producto.Nombre}}</a></td>
         <td>{{re.producto.Barra}}</td>
         <td>{{re.producto.Codigo}}</td>
         <td>{{re.producto.Precio | sumariva:re.producto.Iva}}</td>
         <td>{{re.cantidad}}</td>

        
         <td><button type="button" class="btn btn-success btn-xs" (click)="ubicar2(re.producto,re.id)"><i class="fas fa-cart-plus"> Ubicar</i></button></td>
        
         <td><button type="button" class="btn btn-danger btn-xs" (click)="descartard(re.id)"><i class="fas fa-minus-square"> Descartar</i></button></td>

         
       </tr>

      
       </table>
   <!--  <p>{{ user.email }}</p> -->
</div>

  
  <alert></alert>
  
  
  
       <div *ngIf="listaenviados.length>0">
          <h5 style="margin-top:15px;">Articulos enviados</h5>
         <table class="table">
        
             <tr>
                
                 <td>Nombre</td>
                 <td>Barra</td>
                 <td>Codigo</td>
                 <td>Precio</td>
                 <td>Cantidad</td>
                 <td>Destino</td>
     
     
             </tr>
             <tr *ngFor="let re of listaenviados">
            
               <td><a [routerLink]="['../articulo/',re.producto.Barra]"> {{re.producto.Nombre}}</a></td>
               <td>{{re.producto.Barra}}</td>
               <td>{{re.producto.Codigo}}</td>
               <td>{{re.producto.Precio | sumariva:re.producto.Iva}}</td>
               <td>{{re.cantidad}}</td>
               <td>{{nombretienda(re.destino)}}</td>
             </tr>
     
            
             </table>
         <!--  <p>{{ user.email }}</p> -->
      </div>
      
      
      </div>
  
  
      <div *ngIf="ubicando">
  
          <h3 style="color:red">Ubicando:  </h3>
          <table class="table">
        
              <tr>
                  <td><img src="{{productoubicando.barra | sacarfoto}}" width="70" height="100" /> </td>
                  <td> {{productoubicando.nombre}}</td>
                  <td><h3> {{productoubicando.cantidad}} unidades</h3></td>
  
  
              </tr>
          
          
          </table>
   
          <a *ngIf="!uddis" class="btn btn-success btn-sm btn-block"  (click)="poneren()" style="margin-top:10px">aceptar</a> 
          <a *ngIf="uddis" class="btn btn-success btn-sm btn-block"  (click)="ponerdiscrepancia()" style="margin-top:10px">aceptar</a> 
     
          <div class="form-group col-md-4">
         
            <label for="inputState">Nueva Ubicacion</label>
            <select id="inputState" class="form-control"  [(ngModel)]="seleccionado" [ngModelOptions]="{standalone: true}" (change)="sacarhijos()">
        
                <option [ngValue]="pa" *ngFor="let pa of padres">{{pa.Nombre}}</option>
         
            </select>
        </div>
        <div class="form-group col-md-4" *ngIf="seleccionado.id!=null && tienehijos">
        
            <label for="inputState">Sub-Ubicacion</label>
            <select id="inputState" class="form-control"  [(ngModel)]="seleccionado" [ngModelOptions]="{standalone: true}">
        
                <option [ngValue]="pa2" *ngFor="let pa2 of hijos">{{pa2.Nombre}}</option>
        
            </select>
        </div>
        <a class="btn btn-danger btn-sm btn-block"  (click)="cancelarsubir()">cancelar</a>
  
  
    </div>
</div>

<div class="container" *ngIf="tiendaaenviar!=-1">
  <a  class="btn btn-danger btn-sm"  (click)="(tiendaaenviar=-1) && cancelar()">Volver</a>

  <div class="row" style="margin-top:15px;">

   
    <h2 style="margin-top:15px ;">Enviar articulos a {{nombredetienda}}:</h2>
      <div class="col-md-4">
       
         <div class="card">
           <div class="card-body">
             <form action="">
               <div *ngIf="!edit" class="form-group">
                 <input type="text" id=barr name="title" [(ngModel)]="barra" placeholder="Codigo de Barras" class="form-control" autofocus>
                 
               </div>
               
               <button class="btn btn-success btn-block" (click)="cargar()" style="display: none">
                 Save
               </button>
             </form>
           </div>
         </div>
       </div>
    </div>
     <div *ngIf="inicio==1">
       
       <div class="col-md-5 ">
         <div class="card card-body text-center">
             <h3 id="nomb">{{ producto.Nombre}}</h3>
             <h3 style="color: red;" >Precio: {{producto.Precio | sumariva:producto.Iva }}€</h3>
             
             <a class="btn btn-success btn-sm"  (click)="subir()" style="margin-top:10px">aceptar</a>

             <div style="margin-top:10px" >

              ** MAXIMO {{antigua.cantidad}} **
              <div class="input-group mb-3" Style="margin-top: 10px;">
                <button type="button" class="btn btn-danger"  style="background-color: rgb(109, 2, 2);"  (click)="menos(100)" >-100</button>
                <button type="button" class="btn btn-danger"  style="background-color: rgb(206, 31, 31);"  (click)="menos(10)" >-10</button>
                <div class="input-group-prepend">
  
              
                  <button type="button" class="btn btn-danger" (click)="menos(1)" >-</button>
              
                </div>
          
                <input type="number"  class="form-control" name="cantidad" [(ngModel)]="cantidadelegida"  readonly>
                <div class="input-group-append">
          
                  <button type="button" class="btn btn-success"  (click)="mas(1)">+</button>
  
                </div>
                <button type="button" class="btn btn-success" style="background-color: rgb(26, 184, 47);" (click)="mas(10)">+10</button>
                <button type="button" class="btn btn-success"  style="background-color: rgb(0, 110, 6);"  (click)="mas(100)">+100</button>
              </div>
              
            
         </div>

             <h3>Estaba en:</h3>
  
             <div *ngFor="let ubi of todosantiguas">
  
              
              <a class="btn btn-primary btn-sm btn-block " *ngIf="ubi==antigua" Style="margin-bottom: 10px;margin-top:10px; ">{{sacarnombre(ubi.ubicacionesId)}}:{{ubi.cantidad}} </a>
              <a class="btn btn-secondary btn-sm btn-block" *ngIf="ubi!=antigua" Style="margin-bottom: 2px;" (click)="cambiar(ubi)">{{sacarnombre(ubi.ubicacionesId)}}:{{ubi.cantidad}} </a>
  
          </div>
         <a class="btn btn-danger btn-sm btn-block"  (click)="cancelar()">cancelar</a>
         
     
         </div>
       </div>
     </div>
     <div *ngIf="inicio==2">
         <div class="col-md-4 offset-md-2">
             <div class="card card-body text-center">
                 <div class="alert alert-danger" role="alert">
                    EL ARTICULO NO ESTA UBICADO, DEBE UBICARLO ANTES DE DEVOLVERLO
                   </div>
         
             </div>
         </div>
     </div>
     





</div>