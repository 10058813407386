<div class="container" *ngIf="modificando2==-1">
  <header class="jumbotron">
    <div class="row "> 
      <div class="col-md-8">
      <h4>Gestionar usuarios</h4>
      </div>
      <div class="col-md-4">
        <button  *ngIf="modificando==0" type="button" style="float: left;" class="btn btn-success btn-lg" (click)="modificando=-2">+</button>
        </div>
 </div>
   
    <div *ngIf=" modificando==0">
    <table class="table" style="margin-top: 20px;">
     
      <tr>
          <td></td>
          <td>Nombre</td>
          <td>Tienda</td>
          <td>Email</td>
          <td>Fecha de creacion</td>
          <td>Privilegios</td>
          <td></td>
        
        
      </tr>

      <tr *ngFor="let user of content">
       
          <td>{{ user.id }}</td>
          <td>{{ user.username }}</td>
          <td >{{ nombretienda(user.tienda ) }}</td>
          <td>{{ user.email }}</td>
          <td>{{user.createdAt | date: 'dd'}}/{{user.createdAt | date:'MM'}}/{{user.createdAt | date: 'yyyy' }}</td>
          <td >{{ sacarrol(user.id) }}</td>
          <td><button *ngIf=" modificando!= user.id" type="button" style="margin-right: 10px;" class="btn btn-warning btn-xs" (click)="cambiar(user)">CAMBIAR</button>
            <button class="btn btn btn-danger"   (click)="borrar(user.id)" style="margin-left: 30px;"><i class="fas fa-trash-alt"></i></button></td>
         
   

        </tr>
        
    
    </table>
  </div>

  </header>
  <div *ngIf=" modificando!=0">

    <h3 *ngIf=" modificando!=-2" class="text-center">USUARIO: {{usermodificando.username}}</h3>
    <h4 *ngIf=" modificando!=-2" class="text-center" style="margin-bottom: 50px;">EMAIL: {{ usermodificando.email }}</h4>
         
        <form>
          <div *ngIf=" modificando==-2" class="form-row">
            <label for="inputState">NOMBRE</label>
            <input type="text" class="form-control" id="nameuser"  >
            <label for="inputState">CONTRASEÑA</label>
            <input type="text" class="form-control" id="passuser"  >
          </div>
          <div *ngIf=" modificando==-2"  class="form-row">
           
            <label for="inputState">CORREO</label>
            <input type="text" class="form-control" id="email">
          </div>

          <div class="form-row" *ngIf=" modificando!=-2">
            <div class="form-group col-md-6">
              <label for="inputState">TIENDA</label>
              <select id="inputtienda" class="form-control" (change)="mostra()">
                <option  id="option" [value]="item.id" *ngFor="let item of tiendas" >{{item.name}}</option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label for="inputState">PRIVILEGIOS</label>
              <select id="inputrol" class="form-control">
              
                <option>["USER"]</option> 
                <option>["ADMIN"]</option>
                <option>["DESHABILITADO"]</option>
              </select>
            </div>
          </div>
          <button type="button" class="btn btn-danger btn-xs" (click)="cancelar()" style="margin-right: 100px;">CANCELAR</button>
          <button type="button" class="btn btn-success btn-xs" (click)="modificar()">ACEPTAR</button>
       
        </form>
      </div>

</div>

<div class="container" *ngIf="modificando==0">
  <header class="jumbotron">
    <div class="row "> 
      <div class="col-md-8">
      <h4>Gestionar Tiendas</h4>
      </div>
      <div class="col-md-4">
        <button  *ngIf="modificando2==-1" type="button" style="float: left;" class="btn btn-success btn-lg" (click)="modificando2=-2">+</button>
     
                        
        </div>
 </div>
    <div *ngIf=" modificando2==-1">
    <table class="table" style="margin-top: 20px;">
     
      <tr>
          <td>Codigo</td>
          <td>Tienda</td>
          <td>Fecha de creacion</td>
          <td> </td>
      </tr>

      <tr *ngFor="let user of tiendas">
       
          <td>{{ user.id }}</td>
          <td>{{ user.name }}</td>
          <td>{{user.createdAt | date: 'dd'}}/{{user.createdAt | date:'MM'}}/{{user.createdAt | date: 'yyyy' }}</td>
       
          <td><button *ngIf=" modificando2!= user.id" type="button" style="margin-right: 10px;" class="btn btn-warning btn-xs" (click)="cambiartinda(user)">CAMBIAR</button>
            <button  *ngIf="!comprobarsiuser(user.id)" class="btn btn btn-danger"   (click)="borrart(user)" style="margin-left: 30px;"><i class="fas fa-trash-alt"></i></button></td>
   

        </tr>
        
    
    </table>
  </div>

  </header>
  <div *ngIf=" modificando2!=-1">

    <h3 *ngIf="modificando2!=-2" class="text-center">Tienda: {{tiendamodificando.name}}</h3>
    <h4 *ngIf="modificando2!=-2" class="text-center" style="margin-bottom: 50px;">ID: {{ tiendamodificando.id }}</h4>
         
        <form>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputState">Nombre</label>
              <input type="text" class="form-control" id="nametienda"  placeholder="Nuevo nombre">
              
            </div>
      
          </div>
          <button type="button" class="btn btn-danger btn-xs" (click)="cancelart()" style="margin-right: 100px;">CANCELAR</button>
          <button type="button" class="btn btn-success btn-xs" (click)="modificart(tiendamodificando.id)">ACEPTAR</button>
       
        </form>
      </div>

</div>

