import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { DataService } from '../_services/data.service';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { User } from "../models/user";
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'app-board-admin',
  templateUrl: './board-admin.component.html',
  styleUrls: ['./board-admin.component.css']
})
export class BoardAdminComponent implements OnInit {

  content:any[];  
  modificando:number;
  numbrecambio:string; 
  tiendacambio:any;
  user_roles: any[] = []
  usermodificando:any;

  tiendamodificando:any;
  modificando2:number;
  tiendas:any[];
  tiendaselecionada:any;
nuevat:boolean;
  constructor(private userService: UserService,private dataservice :DataService,private authService: AuthService) { }

  ngOnInit() {

    this.modificando=0;
    this.modificando2=-1;
    this.numbrecambio="";
    this.tiendacambio=null;
    this.nuevat=false;
    
    this.sacartodos();
   
  }
nombretienda(id:string){

    let nombre="ERROR"



    const i = this.tiendas.findIndex( fruta => fruta.id === id );


    if(i!=-1){
    
     nombre=this.tiendas[i].name;


    }

    return nombre 

}


  sacartiendas(){


    
    this.dataservice.sacartiendas().subscribe(
      res => {

        this.tiendas = res;
       
 
      },
      err => {
        this.content = JSON.parse(err.error).message;
      }
    );

  }
sacartodos(){
  this.content=[];
  this.userService.sacarusers().subscribe(
    res => {
      this.content = res;
      this.sacartiendas();
      this.llenarroles();

    },
    err => {
      this.content = JSON.parse(err.error).message;
    }
  );

}

    llenarroles(){

      this.user_roles=[];
      
      for(let i=0; i<this.content.length;i++){
    
        this.userService.sacarrol(""+this.content[i].id).subscribe(res => {
 
          let user_rol:any={
            id:0,
            rol:0
      
          };
          user_rol.id=this.content[i].id
          user_rol.rol=res.roles;
          this.user_roles.push(user_rol);

     
         
        },
        err => console.log(err)
      );
      console.log(" iteracion: "+i);
      }
     


  }
;

  sacarrol(id){

    const i = this.user_roles.findIndex( fruta => fruta.id === id );
 


    if(i!=-1){
     
      return JSON.stringify(this.user_roles[i].rol);
  
    }else{
  
      return "ERROR";
    }


  }
    cambiar(user:any){

      this.usermodificando=user;
      this.modificando=this.usermodificando.id;


    }
mostra(){

console.log("mos "+((<HTMLInputElement>document.getElementById('option')).value)[0])

}
modificar(){

if(this.modificando!=-2){



      let rolnuevo=0;
      let tiendanueva=0;

      this.numbrecambio=(<HTMLInputElement>document.getElementById('inputrol')).value;
     this.tiendacambio=(<HTMLInputElement>document.getElementById('inputtienda')).value;



if(this.numbrecambio!=""){

    if(this.numbrecambio=="[\"USER\"]"){
  
    rolnuevo=1;
    }else{
  
    if(this.numbrecambio=="[\"ADMIN\"]"){
  
      rolnuevo=2;
    }else{
  
      rolnuevo=0;
    }
  
    }

    let user:any={rol:rolnuevo}

    this.userService.modificaruserr(""+ this.modificando,user).subscribe(res => {
 

      this.modificando=0;
      this.numbrecambio="";
      this.tiendacambio=null;
      this.sacartodos();
     
    },
    err => console.log(err)
    );

  }

  if(this.tiendacambio!=""){


    if(this.tiendacambio=="Pulsar"){
    
      tiendanueva=1;
    }else{
    
      if(this.tiendacambio=="Pape 1"){
    
        tiendanueva=2;
      }else{
    
        tiendanueva=3;
      }
    
    }

    let user:any={tienda:this.tiendacambio}
  
    this.userService.modificarusert(""+ this.modificando,user).subscribe(res => {
    

      this.modificando=0;
      this.numbrecambio="";
      this.tiendacambio=null;
      this.sacartodos();
    
    },
    err => console.log(err)
    );

  }
}else{

  let form={
    username: (<HTMLInputElement>document.getElementById('nameuser')).value,
    email: (<HTMLInputElement>document.getElementById('email')).value,
    password:(<HTMLInputElement>document.getElementById('passuser')).value

  }

  this.authService.register(form).subscribe(
    data => {
      this.modificando=0;
      this.numbrecambio="";
      this.tiendacambio="";
      this.sacartodos();
    },
    err => {
        console.log(err)
    }
  );

}

}
cancelart(){


  this.modificando2=-1;
}

modificart(idt:string){

  let tien={

    id:idt,
    name:(<HTMLInputElement>document.getElementById('nametienda')).value

  }

  if(this.modificando2==-2){

    this.dataservice.creartienda(tien).subscribe(
      res => {

        this.modificando2=-1;
        this.sacartodos();
      },
      err => {
        this.content = JSON.parse(err.error).message;
      }
    );

  }else{
console.log("ddff "+this.modificando2)
    this.dataservice.cambiartienda(""+this.modificando2,tien).subscribe(
      res => {

        this.modificando2=-1;
       this.sacartodos();
 
      },
      err => {
        this.content = JSON.parse(err.error).message;
      }
    );


  }



}

cancelar(){

    this.modificando=0;
    this.numbrecambio="";
    this.tiendacambio="";
}
cambiartinda(tienda:any){

this.modificando2=tienda.id;
this.tiendamodificando=tienda

}

borrart(t:any){

  if(confirm("tas seguro?")) {
  this.dataservice.borrartienda(t.id).subscribe(
    res => {

      this.modificando2=-1;
     this.sacartodos();

    },
    err => {
      this.content = JSON.parse(err.error).message;
    }
  );

  }
}
borrar(id:string){


  if(confirm("tas seguro ?")) {

  this.userService.borraruser(id).subscribe(
    res => {

      this.modificando=0;
     this.sacartodos();

    },
    err => {
      this.content = JSON.parse(err.error).message;
    }
  );

  }


}
comprobarsiuser(idt:string){

let esta=true;
  const i = this. content.findIndex( fruta => fruta.tienda === idt );


  if(i==-1){
  
    esta=false;


  }
return esta;
//return true si no tiene usuarios


}

}

