import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { DataService } from '../_services/data.service';
import { Ubicacion } from "../models/ubicacion";
import { Ubi_product } from "../models/ubi_product";
import { TokenStorageService } from '../_services/token-storage.service';
import { AlertService } from '../alert.service';
import { JsonPipe } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';
import { Router } from '@angular/router';
import { animationFrame } from 'rxjs/internal/scheduler/animationFrame';
import { ThrowStmt } from '@angular/compiler';
import { ignoreElements } from 'rxjs/operators';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AnyMxRecord } from 'dns';
  
@Component({
  selector: 'app-informeventas',
  templateUrl: './informeventas.component.html',
  styleUrls: ['./informeventas.component.css']
})
export class InformeventasComponent implements OnInit {

  constructor(private dataservice :DataService) { }
  cargando:boolean;
  hoy:Date;
  ventas:any[];
  ventasp3:any[];
  ventasportiket1:any[];
  ventasportiket3:any[];
  Libros1:number; 
  Comics1:number;
  Juegos1:number;
  Merchan1:number;
  Bellasartes1:number;
  Lego1:number;
  Manualidades1:number;
  Papeleria1:number;
  sincategoria1:number;
  Libros2:number;
  Comics2:number;
  Juegos2:number;
  Merchan2:number;
  Bellasartes2:number;
  Lego2:number;
  Manualidades2:number;
  Papeleria2:number;
  sincategoria2:number;
  filtradoporcategorias:any[];
  totalp1:number; 
  totalp3:number;
  todascategorias:any[];
  p1:boolean;
  p2:boolean;
  todo:boolean;
  otro:boolean;
  fechaInicio:any;
  fechaFin:any;
  tiendas:any;
  totalVentas:any;
  productosporcategorias:any[];
  ventasportiket:any[];
  categoriaquehay:any[];

  totalestiendas:any[];
  ventasmostradas:any[];
  totalescategorias:any[];
  productosportienda:any[];

  mostrados:any[];
  proveedores:any[];
  pselecionado:any;
  cod:any;
  ids:any;
  cantidadDias:any;

  categoriaquehayM:any[];
  productosporcategoriasM:any[];
  totalestiendasM:any[];
  productosportiendaM:any[];

  ngOnInit(): void {

    this.sacartodosproveedores();
    this.llenarnombrecate();
    this.sacartiendas();
    this.otro=false;
    this.hoy = new Date();
    this.cargartodo();
    this.totalVentas=0;
    this.proveedores=[];
    this.cod="0";
    this.cantidadDias=0;
    this.ids="";
    this.categoriaquehayM=[];
    this.productosporcategoriasM=[];
    this.totalestiendasM=[];
    this.productosportiendaM=[];
  
  }

  getSelectedProductByName(id: string) {
    console.log("buscando proveedor con codigo: "+JSON.stringify(id))
      return this.proveedores.find(product => product.nombre == id);
  }

  selected(selecionado:any){

    this.pselecionado=this.getSelectedProductByName(selecionado);
    console.log("SELECIONADO: "+JSON.stringify(this.pselecionado))
  }

  
  filtrarp(){

this.cargando=true;
    let temporal=[];

    const fechaInput = new Date(this.fechaInicio);

    fechaInput.setDate(fechaInput.getDate() - this.cantidadDias);

    this.dataservice.compras_proveedor(this.pselecionado.codigo,fechaInput.toISOString(),this.fechaFin).subscribe(res => {

      let resultado=res[0];
   
      console.log("PRODUCTOS DEL PROVEEDOR EN PERIDO:"+resultado.length);

      if(this.mostrados.length > resultado.length){
    

        for(let i=0; i<resultado.length;i++){

          console.log("i-"+i);
          const j = this.mostrados.findIndex( fruta => fruta.CodigoProducto == parseInt(resultado[i].id_articulo));
             
          for(let k=0; k<this.mostrados.length;k++){

          if(this.mostrados[k].IdProducto == resultado[i].id_articulo) console.log("ENCONTRADO");

         }
      

          if(j!=-1){

            console.log("encontrado: "+JSON.stringify(this.mostrados[j]));
            temporal.push(this.mostrados[j]);
            console.log("ANADIDO 1");
          }
         
    
        }
    


      }else{

       

        for(let i=0; i<this.mostrados.length;i++){

          console.log("i-"+i);

          console.log("sss22");
          const j = resultado.findIndex( fruta => fruta.id_articulo === parseInt(this.mostrados[i].IdProducto));

          if(j!=-1){

            temporal.push(this.mostrados[i]);
            console.log("ANADIDO 2");
          }
         
    
        }

      }

      this.mostrados=temporal;

      let categoriasquehaytemp=[];
      let tiendasquehaytempo=[];

      //reconstruir  tiendas

      for (let k = 0; k < this.mostrados.length; k++){

   
        let totalVenta = 0;
    
        totalVenta = parseFloat(this.mostrados[k].Precio) * parseInt(this.mostrados[k].cantidad);
   
        const t = tiendasquehaytempo.findIndex( fruta => fruta.tienda === this.mostrados[k].Tienda );
        
        if(t!=-1){

          tiendasquehaytempo[t].totalProductos= parseInt(tiendasquehaytempo[t].totalProductos)+parseInt(this.mostrados[k].cantidad);
          tiendasquehaytempo[t].totalVentas=parseFloat(tiendasquehaytempo[t].totalVentas)+totalVenta;
         
          
    
        }else{
         
          tiendasquehaytempo.push({
            tienda: this.mostrados[k].Tienda ,
            totalProductos: this.mostrados[k].cantidad,
            totalVentas: totalVenta.toFixed(2)});
    
           
    
    
    
        }
    
        const f =  categoriasquehaytemp.findIndex( fruta => fruta.Categoria === this.mostrados[k].categoria );
        
        if(f!=-1){

          categoriasquehaytemp[f].totalProductos= parseInt(categoriasquehaytemp[f].totalProductos)+parseInt(this.mostrados[k].cantidad);
          categoriasquehaytemp[f].totalVentas=parseFloat(categoriasquehaytemp[f].totalVentas)+totalVenta;
         
          
    
        }else{
         
            categoriasquehaytemp.push({
            Categoria: this.mostrados[k].categoria ,
            totalProductos: this.mostrados[k].cantidad,
            totalVentas: totalVenta.toFixed(2)});
               

        }


      }

      this.totalestiendasM=tiendasquehaytempo;
      this.categoriaquehayM=categoriasquehaytemp;
      //reconstruir categorias 


      this.cargando=false;
   
  
 
    },
    err => console.log(err))
 
  
 
   
     
  }

  quitarfiltros(){

    this.mostrados=this.ventas;
    this.pselecionado=null;

    this.categoriaquehayM= this.categoriaquehay;
    this.productosporcategoriasM=this.productosporcategorias;
    this.totalestiendasM= this.totalestiendas;
    this.productosportiendaM= this.productosportienda;

    this.totalmostrado();

  }

  quitarerrorp(){
    let tempral=[]
  
    for(let i=0; i<  this.proveedores.length;i++){
  
     if(  this.sacarproveedor(this.proveedores[i].codigo)!="ERROR")tempral.push(this.proveedores[i])
      
    }
    this.proveedores=tempral;
  }

  sacartodosproveedores(){

    this.dataservice.sacratodosprov("-1").subscribe(res => {

   
      this.proveedores=res;
      this.quitarerrorp();
  
 
    },
    err => console.log(err)
  );
 


  }
  sacarproveedor(id:string){


    const j = this.proveedores.findIndex( fruta => fruta.codigo === parseInt(id));
  
    if(j!=-1){

      return this.proveedores[j].nombre;
    }

     return "ERROR"
  }


sacartiendas(){

  this.dataservice.sacartiendas().subscribe(
    res => {

      this.tiendas = res;
     

    },
    err => {
  
    }
  );

}


filtrartienda(id:string){

  let totalVenta = 0;
  let temporal=[];
  this.totalestiendasM=[];
  let catego=[];
  let totalecategotempo=[];
  let totalestiedastempo=[];
  this.cargando=true;
  for (let i = 0; i < this.mostrados.length; i++){


    if(this.mostrados[i].Tienda==id){



        temporal.push(this.mostrados[i]);
        totalVenta = parseFloat(this.mostrados[i].Precio) * parseInt(this.mostrados[i].cantidad);



        //  categorias
        catego=this.obtenerNumeros(this.mostrados[i].Categoria);

        console.log("---catego: "+catego+ "-- id "+id);
    
        for (let j = 0; j < catego.length; j++){
        
  
        

            const l = totalecategotempo.findIndex( fruta => fruta.Categoria === catego[j]);

            if(l!=-1){

                totalecategotempo[l].totalProductos= parseInt(totalecategotempo[l].totalProductos)+parseInt(this.mostrados[i].cantidad);
                totalecategotempo[l].totalVentas= (parseFloat(totalecategotempo[l].totalVentas)+ totalVenta).toFixed(2);
  

            }else{
            
                totalecategotempo.push({
                Categoria: catego[j],
                totalProductos: parseInt(this.mostrados[i].cantidad),
                totalVentas: totalVenta.toFixed(2)});

            }
          

        }
        ///-----------------
        
        if(totalestiedastempo.length>0){
        
          totalestiedastempo[0].totalProductos= parseInt(totalestiedastempo[0].totalProductos)+parseInt(this.mostrados[i].cantidad);
          totalestiedastempo[0].totalVentas=parseFloat(totalestiedastempo[0].totalVentas)+totalVenta;
        
          

        }else{

          totalestiedastempo.push({
            tienda: id,
            totalProductos: this.mostrados[i].cantidad,
            totalVentas: totalVenta.toFixed(2)});
          

        }

    }



  } 

  this.categoriaquehayM=totalecategotempo;
  this.totalestiendasM=totalestiedastempo;
  this.mostrados=temporal;

  this.cargando=false;

}



filtrarcategoria(id:string){



  this.categoriaquehayM=this.categoriaquehay;
  this.productosporcategoriasM=this.productosporcategorias;

  let categoriasquehaytemp=[];
  let tiendasquehaytempo=[];
  this.cargando=true;

  let catego=[];

  let totalVenta = 0;
  let temporal=[];
  let poner=false;


  for (let i = 0; i < this.mostrados.length; i++){


    catego=this.obtenerNumeros(this.mostrados[i].Categoria);


    for (let j = 0; j < catego.length; j++){

        if(catego[j]==id){

          poner=true;
     
          totalVenta = parseFloat(this.mostrados[i].Precio) * parseInt(this.mostrados[i].cantidad);

          const l = categoriasquehaytemp.findIndex( fruta => fruta.Categoria === catego[j]);

          if(l!=-1){
    
  
            categoriasquehaytemp[l].totalProductos= parseInt( categoriasquehaytemp[l].totalProductos)+parseInt(this.mostrados[i].cantidad);
            categoriasquehaytemp[l].totalVentas= (parseFloat( categoriasquehaytemp[l].totalVentas)+ totalVenta).toFixed(2);

    
          }else{
    
            categoriasquehaytemp.push({
              Categoria: id,
              totalProductos: parseInt(this.mostrados[i].cantidad),
              totalVentas: totalVenta.toFixed(2)});
             
          }


        }

    }
    if(poner){

      temporal.push(this.mostrados[i]);
      poner=false
    }

  }

  this.categoriaquehayM=categoriasquehaytemp;
  this.mostrados=temporal;

  for (let k = 0; k < this.mostrados.length; k++){

    console.log("mostrado "+ k)
    let totalVenta = 0;

    totalVenta = parseFloat(this.mostrados[k].Precio) * parseInt(this.mostrados[k].cantidad);
    console.log("totalventas "+totalVenta)
    const t = tiendasquehaytempo.findIndex( fruta => fruta.tienda === this.mostrados[k].Tienda );
    
    if(t!=-1){

      console.log("añadiendo a tienda"+ JSON.stringify(tiendasquehaytempo[t]))
      tiendasquehaytempo[t].totalProductos= parseInt(tiendasquehaytempo[t].totalProductos)+parseInt(this.mostrados[k].cantidad);
      tiendasquehaytempo[t].totalVentas=parseFloat(tiendasquehaytempo[t].totalVentas)+totalVenta;
     
      

    }else{
      console.log("creando tienda"+ JSON.stringify(this.mostrados[k]))
      tiendasquehaytempo.push({
        tienda: this.mostrados[k].Tienda ,
        totalProductos: this.mostrados[k].cantidad,
        totalVentas: totalVenta.toFixed(2)});

       



    }

  
  }
  console.log("dd"+JSON.stringify(tiendasquehaytempo)+"--")
  this.totalestiendasM=tiendasquehaytempo;
  this.cargando=false;
}

nombretienda(id:string){

  let nombre="ERROR"



  const i = this.tiendas.findIndex( fruta => fruta.id === id );


  if(i!=-1){
  
   nombre=this.tiendas[i].name;


  }

  return nombre 

}

cargartodo(){

  this.totalp1=0; 
  this.totalp3=0;
  this.Libros1=0;
  this.Comics1=0;
  this.Juegos1=0;
  this.Merchan1=0;
  this.Bellasartes1=0;
  this.Lego1=0;
  this.Manualidades1=0;
  this.Papeleria1=0;
  this.sincategoria1=0;
  this.Libros2=0;
  this.Comics2=0;
  this.Juegos2=0;
  this.Merchan2=0;
  this.Bellasartes2=0;
  this.Lego2=0;
  this.Manualidades2=0;
  this.Papeleria2=0;
  this.sincategoria2=0;
  this.filtradoporcategorias=[]
  this.cargando=false;
  this.p1=false;
  this.p2=false;
  this.todo=true;
  this.fechaFin = new Date().toISOString().split('T')[0];
  this.fechaInicio = new Date().toISOString().split('T')[0];
  //this.sacarventas();

}  



ffecha(op:number){

if(op==2){

  this.otro=false;
  this.hoy = new Date();
  this.cargartodo()

}else{

  let inici=(<HTMLInputElement>document.getElementById('finicio')).value;
  this.hoy = new Date(inici);
  this.cargartodo()
}

}

 cambiar(boton:number){

  if(boton==1){

    this.p1=true;
    this.p2=false;
    this.todo=false;


  }

  if(boton==2){

    this.p1=false;
    this.p2=true;
    this.todo=false;


  }  
  if(boton==3){

    this.p1=false;
    this.p2=false;
    this.todo=true;


  }

 }
  async sacarventas(){

    this.cargando=true;

    const fechaInicios = new Date(this.fechaInicio);
    const fechaFins = new Date(this.fechaFin);
    this.ventas= [];
   
    

    this.pselecionado=null;

    this.categoriaquehayM=[];
    this.productosporcategoriasM=[];
    this.totalestiendasM=[];
    this.productosportiendaM=[];



    fechaFins.setDate(fechaFins.getDate() + 1);

    this.ventas= await this.dataservice.ventas_periodo(fechaInicios.toISOString(),fechaFins.toISOString()).toPromise();

    this.mostrados=this.ventas;
    this.filtrar()
    

  }

  llenarnombrecate(){

    this.dataservice.buscarcates().subscribe(res => {
  
      this.todascategorias=res;
    },
  
      err =>{
  
    });
  
  
   
  
  }
  totalmostrado(){


    let total=0;


    for (let i = 0; i <this.mostrados.length; i++) {

      total=total+(parseFloat(this.mostrados[i].Precio)*parseFloat(this.mostrados[i].cantidad));
     }

     return total.toFixed(2);
      

  }


  nombrecategoria(id:number){

    
    if(this.todascategorias!=null){
      
      if(id==0){

        return "SIN CATEGORIZAR"
      }
    
      const i =  this.todascategorias.findIndex( element => element.id == id );
    
    
      if(i!=-1){
       
        return this.todascategorias[i].nombre;
    
      }else{
    
        return "error";
      }
    
    }
    
      return "Sin nombre";
    
    
    
    }
  sacarcategorias(cates){

    let cat="-";

    for (let i = 0; i <cates.length; i++) {

        cat=cat+this.nombrecategoria(cates[i].categoriaId)+"-"
    }
    return cat;

  }

  vertotal(re,tienda){


    let todas;
    let totao=0;

    if(tienda==1){
 
     //todas=this.ventasp1;
 
    }else{
 
     todas=this.ventasp3;
     
    }
 
    for (let i = 0; i <todas.length; i++) {
      
      if((todas[i].venta).Ticket==re){

        totao=totao+(parseFloat((todas[i].venta).Precio)*parseInt((todas[i].venta).Cantidad));

      }
 
    }

    return totao.toFixed(2)+"€";

  }
  comprobarc(cate,comp,s){

    if(s==1){

      let esta=false;

      for (let i = 0; i <cate.length; i++) {
  
        if(comp.includes(cate[i].categoriaId))esta=true;
  
      }
  
      return esta;


    }else{

      let esta=true;
      comp=[1,5,7,6,4,9,61,62,63,8];
  
      for (let i = 0; i <cate.length; i++) {
  
        if(comp.includes(cate[i].categoriaId))esta=false;
  
      }
  
      return esta;


    }
    

  }
/*
  filt(op:number){

    this.filtradoporcategorias=[];
    let c=[];
    let comp;
    let s=1;
    if(op==1)comp=[1,5];
    if(op==2)comp=[7];
    if(op==3)comp=[6];
    if(op==4)comp=[4,9]; 
    if(op==5)comp=[61,62,63];
    if(op==6)comp=[8];
    if(op==7)s=0;

    if(this.p2 || this.todo){

      for (let i = 0; i <this.ventasp3.length; i++) {

        c=this.ventasp3[i].categorias;
        if(this.comprobarc(c,comp,s))this.filtradoporcategorias.push(this.ventasp3[i])
      }



    }
    if(this.p1 || this.todo){
      for (let i = 0; i <this.ventasp1.length; i++) {

        c=this.ventasp1[i].categorias;
        if(this.comprobarc(c,comp,s))this.filtradoporcategorias.push(this.ventasp1[i])

      }
    
    }



  }
*/
  sinli(){


    let informe:string='';
    let nombrearchivo="VENTAS.TXT";
    let comp=[1,5];
    let ventas=[]
    let c;
    let tik;
    let tikets=[]

    let date = new Date()

    let day = date.getDate()
    let month = date.getMonth() + 1
    let mes=month.toString();
    
    if(mes.length==1){
      mes="0"+mes;
    }


    let year = date.getFullYear()
    let fecha=year.toString()+mes+day.toString();
    
    if (confirm("ATENCION \n Se  va a generar un nuevo archivo sinli")) {


      for (let i = 0; i <this.ventasp3.length; i++) {
        c=this.ventasp3[i].categorias;
        if(this.comprobarc(c,comp,1))ventas.push(this.ventasp3[i])
      }


      for (let i = 0; i <this.ventas.length; i++) {

        c=this.ventas[i].categorias;
        if(this.comprobarc(c,comp,1))ventas.push(this.ventas[i])

      }

     

      for (let i = 0; i < ventas.length; i++) {
        console.log(JSON.stringify(ventas))
        tik=(ventas[i].venta).Ticket;

        if(! tikets.includes(tik)){

          tikets.push(tik)
        }
      

      }
      let registros=0;
      informe=informe+"I"+"LIBTORREVIEJA@SMOT.ES".padStart(50, " ")+"CEGAL@SINLI.COM".padStart(50, " ")+"CEGALV03"+"¿NTRASM?"+"\r\n" ;
      informe=informe+"C"+"PULSAR STORE SL".padStart(40, " ")+fecha+"EUR"+"\r\n" ;
      for (let i = 0; i < tikets.length; i++) {
       
       let tiket=""+tikets[i]


       let t=tiket.padStart(10, "0"); 
       let lin=this.sacarlineas(tikets[i],ventas)
       let total=lin[lin.length-1]
       let cbtik="T0000000000"+fecha+t+total+"\r\n" 

       if(lin.length>1){
        informe=informe+cbtik;
        registros=registros+1;
        for (let j = 0; j < lin.length-1; j++) {
 
         informe=informe+lin[j]+"\r\n";
         registros=registros+1;
        }


       }
  
      }

      let re=""+registros
      informe="INCEGALV03LIBEMISOLDESTINO"+re.padStart(5, "0")+"NTRASMI".padStart(7, "0")+"USUARIOLOCAL".padStart(15, " ")+"USUARIODESTINO".padStart(15, " ")+"TEXTL".padStart(7, " ")+"FANDE"+"\r\n"+informe;
    
      this.guardarArchivoDeTexto(informe,nombrearchivo)
  

    }

  }
  excel(){


    let informe:string="Fecha;Ticket;Tienda;Nombre;Codigo;Precio;Cantidad;Categorias";
    let nombrearchivo="INFOMEVENTAS.xlc";
   
    if (confirm("ATENCION \n Se  va a generar un nuevo archivo excel")) {

      let linea="";
      let categor=[];
      let cat="";

      for (let i = 0; i < this.mostrados.length; i++) {
  
      categor=this.obtenerNumeros(this.mostrados[i].Categoria);
      cat=""; 

        for (let j = 0; j < categor.length; j++){          

          
          cat=cat+this.nombrecategoria(categor[j])+" ";
        }                
      linea=""+this.mostrados[i].Fecha+";"+this.mostrados[i].Ticket+";"+this.nombretienda(this.mostrados[i].Tienda)+";"+this.mostrados[i].NombreP+";"+this.mostrados[i].CodigoProducto+";"+this.mostrados[i].Precio+";"+this.mostrados[i].cantidad+";"+cat;

      informe=informe+linea+"\r\n";

      }



      this.guardarArchivoDeTexto(informe,nombrearchivo)
  

    }

  }
  sacarlineas(Ticket,ventas){

    let lineforma=[];
    let lin
    let cantidad
    let isbn
    let Precio
    let total=0

    for (let j = 0; j < ventas.length-1; j++) {


      if((ventas[j].venta).Ticket==Ticket && (ventas[j].producto).IsbnG!=null){

        cantidad=""+(ventas[j].venta).Cantidad

        if((ventas[j].venta).Cantidad<0){
          cantidad=cantidad.padStart(5, "0")
          cantidad="-"+cantidad
        }else{
          cantidad=cantidad.padStart(6, "0")
        }
        isbn=""+(ventas[j].producto).IsbnG
        Precio=""+((ventas[j].producto).Precio).toFixed(2)
        Precio=Precio.replace(".","");
        lin="D"+isbn.replace(/ /g, "")+cantidad+Precio.padStart(10, "0");
        lineforma.push(lin)
        total=total+parseFloat(Precio)
        total=parseFloat(total.toFixed(2));
      }

   


    }
  
    lineforma.push((""+total).replace(".","").padStart(10, "0"))

    return lineforma

  }

guardarArchivoDeTexto = (contenido, nombre) => {
   
    const a = document.createElement("a");
   
    const archivo = new Blob([contenido], { type: 'text/plain'});
    const url = URL.createObjectURL(archivo);
    a.href = url;
    a.download = nombre;
    a.click();
    URL.revokeObjectURL(url);
}

  verticket(re,tienda){

   let todas;
   let linea=[]

   if(tienda==1){

    todas=this.ventas;

   }else{

    todas=this.ventasp3;
    
   }

   for (let i = 0; i <todas.length; i++) {

    if((todas[i].venta).Ticket==re)linea.push(todas[i])

   }
 
   return(linea);
  }

  sacarcategoriasproducto(cates){



    const numerosEncontrados = cates.match(/\d+/g);

    // Si se encuentran números, devolvemos un array con ellos, de lo contrario, devolvemos un array vacío
    return numerosEncontrados ? numerosEncontrados.map(Number) : [];



  }

  obtenerNumeros(cadena: string){

    const numerosEncontrados = cadena.match(/\d+/g); // Encuentra todos los grupos de dígitos en la cadena


   
   // return [];
    if (!numerosEncontrados) return [0]; // Si no se encontraron números, devuelve un vector vacío
 
    return numerosEncontrados.map(numero => parseInt(numero, 10)); // Convierte cada número de string a número entero
}



  mostrarcatessuper(id:any, rango:any)
  {
    

    if(rango==1){

        if(id=="0"){
          return true;
        }else  return false;
    }

    if(rango==2){

      if([1,5,4,6,7,8,9].includes(id)){
        return true;

      }else  return false;
      
    }
    if(rango==3){

      if(![1,5,4,6,7,8,9,0].includes(id))return true;

    }
    
    return false;
  }
  
  

  ponerencates(venta:any,categoria:any){

    let totalVenta = 0;
    let productosVendidos = 0;


      totalVenta += parseFloat(venta.Precio) * parseInt(venta.cantidad);
      productosVendidos += parseInt(venta.cantidad);

      this.productosporcategorias.push({Venta:venta,Categoria:categoria});

      const i = this.categoriaquehay.findIndex( fruta => fruta.Categoria === categoria );

     

      if(i!=-1){
      
    
  
        this.categoriaquehay[i].totalProductos= parseInt(this.categoriaquehay[i].totalProductos)+parseInt(venta.cantidad);
        this.categoriaquehay[i].totalVentas= (parseFloat(this.categoriaquehay[i].totalVentas)+ totalVenta).toFixed(2);


      }else{

          this.categoriaquehay.push({
          Categoria: categoria,
          totalProductos: productosVendidos,
          totalVentas: totalVenta.toFixed(2)});
 


  
      }


  }

  totaltiendas(venta:any){

    
    

      let totalVenta = 0;

      totalVenta += parseFloat(venta.Precio) * parseInt(venta.cantidad);


      const i = this.totalestiendas.findIndex( fruta => fruta.tienda === venta.Tienda );
      


      if(i!=-1){
      
        this.totalestiendas[i].totalProductos= parseInt(this.totalestiendas[i].totalProductos)+parseInt(venta.cantidad);
        this.totalestiendas[i].totalVentas=(parseFloat(this.totalestiendas[i].totalVentas)+totalVenta).toFixed(2);
       
        

      }else{

        this.totalestiendas.push({
          tienda: venta.Tienda ,
          totalProductos: venta.cantidad,
          totalVentas: totalVenta.toFixed(2)});

         


  
      }



  }

  async filtrar(){

    
    
    this.categoriaquehay=[];
    this.productosporcategorias=[];

    this.totalestiendas=[];
    this.productosportienda=[];

    let catego=[];



 


    //{"id":404,"Ticket":871386,"Tienda":2,"Ubicacion":null,"NombreP":"VARIOS PAPELERIA","IdProducto":2,"CodigoProducto":2,"Fecha":"2024-02-29T17:48:59.000Z","Precio":2.9,"cantidad":1,"Categoria":"[]"}

      for (let i = 0; i < this.ventas.length; i++){ 
        
       
 
      

        catego=this.obtenerNumeros(this.ventas[i].Categoria);

        for (let j = 0; j < catego.length; j++){

          
          this.ponerencates(this.ventas[i],catego[j]);


        }
        if(catego.length==0){

          this.ponerencates(this.ventas[i],0);

        }

        this.totaltiendas(this.ventas[i]); 
      
       // this.ventas[i].stock=await this.sacarstock(this.ventas[i].IdProducto);
      }

      this.categoriaquehayM=this.categoriaquehay;
      this.productosporcategoriasM=this.productosporcategorias;
      this.totalestiendasM=this.totalestiendas;
      this.productosportiendaM=this.productosportienda;
      this.cargando=false;
    

  }

async sacarstock(){

  let stock;
  let total=0;


      for (let i = 0; i < this.mostrados.length; i++){ 
        
        total=0;
        stock=await this.dataservice.getubi_product_producto(this.mostrados[i].IdProducto).toPromise();

         for (let i = 0; i < stock.length; i++){ 
 
           total=total+parseInt(stock[i].total);
        }

        this.mostrados[i].stock=total;

      }



}
 
}
