<div id="app"> 
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a href="/user"  class="navbar-brand" routerLink="user">Buscar Productos</a>
    <a href="/login" *ngIf="!isLoggedIn" routerLink="login" class="navbar-brand"> -- Logetae para continuar --></a>
    
  
    <ul class="navbar-nav mr-auto" routerLinkActive="active">


      <li class="nav-item dropdown"  *ngIf="isLoggedIn">
        <a class="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Gestionar
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a href="/gestionu" class="nav-link" routerLink="gestU"  style=" color:black"><i class="fas fa-map-marked-alt"></i> Ubicaciones</a>
          <a href="/gescat" class="nav-link" routerLink="gescat" style=" color:black"><i class="fas fa-sitemap"></i> Categorias</a>
          <a href="/relaciones" class="nav-link" routerLink="relaciones" style=" color:black"><i class="fas fa-diagram-project"></i> Relaciones de productos</a>
          <a *ngIf="verrol()==2" href="/admin" class="nav-link" routerLink="admin" style=" color:black"><i class="fas fa-users"></i> Usuarios y tiendas</a>
        </div>
      </li> 

      <li class="nav-item dropdown"  *ngIf="isLoggedIn">
        <a class="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Movimientos
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a href="/devoluciones" class="nav-link" routerLink="devoluciones" style=" color:black"><i class="fas fa-undo"></i> Devolver porductos</a>
          <a href="/stockbarra" class="nav-link" routerLink="stockbarra" style=" color:black"><i class="fas fa-barcode"></i> Stockar por Codigo de Barras</a>
          <a *ngIf=" listaporrecibir.length==0" href="/cambiar" class="nav-link" routerLink="cambiar" style=" color:black"><i class="fas fa-people-carry"></i> Mover entre tiendas</a>
          <a *ngIf=" listaporrecibir.length>0" href="/cambiar" class="nav-link" routerLink="cambiar" style="background-color:red; color:white; border-radius: 15%;" ><i class="fas fa-people-carry"></i>  Mover entre tiendas</a>
  
          <a href="/entradas" class="nav-link" routerLink="entradas" style=" color:black"><i class="fa-solid fa-boxes-stacked"></i>  Entradas de productos</a>
  
     
        </div>
      </li>
      <li class="nav-item dropdown"  *ngIf="isLoggedIn && verrol()==1"  >
        <a class="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Informes
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">

          <a href="/ventas" class="nav-link" routerLink="ventas" style=" color:black"><i class="fa-solid fa-sack"></i>Ventas Diarias</a>
          <a href="/inventario" class="nav-link" routerLink="inventario" style=" color:black"><i class="fa-solid fa-sack"></i>Inventario</a>
  
        </div>
      </li>


   <!--   <li class="nav-item dropdown"  *ngIf="isLoggedIn">
        <a class="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
         Alertas
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a href="/creaaviso" class="nav-link" routerLink="creaaviso" style=" color:black"> <i class="fas fa-user-clock"></i> Programar eventos</a>
          <a href="/veraviso" class="nav-link" routerLink="veraviso" style=" color:black"><i class="fas fa-tasks"></i> Ver alertas</a>
       
     
        </div>
      </li>
    -->
    </ul>
    <h1 style="margin-left:10px; color: rgb(144, 69, 243);" *ngIf="isLoggedIn">{{tienda}}</h1>
  
     
<div *ngIf="false">

  <ul class="nav-item" *ngIf="isLoggedIn">
    <div class="row"  *ngIf="nmensjaes>0 || notificaciones.length>0">
     
      <a href="/avisos" class="btn btn-danger" routerLink="avisos" style="margin-top:10px"> Mensajes: {{nmensjaes+notificaciones.length}}</a>
    </div>

    <a *ngIf="nmensjaes==0  && notificaciones.length==0"  href="/avisos" class="btn btn-info" routerLink="avisos" style="margin-top:10px">Mensajes</a>

  </ul>
 

</div>

    <ul class="navbar-nav ml-auto" *ngIf="!isLoggedIn">

      <li class="nav-item">
        <a href="/login" class="nav-link" routerLink="login">Login</a>
      </li>
      <li class="nav-item">
        <a href="/register" class="nav-link" routerLink="register">Registrarse</a>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto" *ngIf="isLoggedIn">
      <li class="nav-item">
        <a href="/profile" class="nav-link" routerLink="profile">{{ username }}</a>
      </li>
      <li class="nav-item">
        <a href class="nav-link" (click)="logout()">LogOut</a>
      </li>
    </ul>

  </nav>

  <div class="container">
    <router-outlet></router-outlet>
  </div>
</div>
