
import { Component, OnInit, SystemJsNgModuleLoader } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../_services/data.service';
import {RoundProgressModule} from 'angular-svg-round-progressbar'; 
import { timer } from 'rxjs';
import { Ubicacion } from "../models/ubicacion";
import { Ubitotal} from "../models/ubitotales";
const JsBarcode = require('jsbarcode');
import { TokenStorageService } from '../_services/token-storage.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertService } from '../alert.service';
 
 
@Component({
  selector: 'app-gestor-ubicaciones',
  templateUrl: './gestor-ubicaciones.component.html',
  styleUrls: ['./gestor-ubicaciones.component.css']
})
export class GestorUbicacionesComponent implements OnInit {
   
  currentUser : any;
  nueva:boolean;
  editando:boolean;
  formato:string="Ver como lista";
  ubi_totales:Ubitotal[]=[];
  toty:Ubitotal={

    id:0,
    total:0
  };
 aimprimir:number;
  lista:boolean=false;
  hideme:any = {};
  hideme2:any = {};
  hideme3:any = {};
  filtro:string="";
  cosa: Ubicacion ={
    id:null,
    user:null,
    tienda:0,
    Nombre: "",
    Padre:null,
    Notas:"",
    Prioridad:0
  }
  ninguna: Ubicacion={

    id:null,
    user:null,
    tienda:0,
    Nombre: "Ninguna",
    Padre:null,
    Notas:"",
    Prioridad:0
  }
  ubicaciones: any[] = []
  ubicaciones2: any[] = []
  padres :Ubicacion[]; 
  seleccionado :number;
selecionadas:any[];
fusion:boolean;
afusionar:any[];


constructor(private dataservice :DataService,private token: TokenStorageService,private alertService: AlertService){}

 ngOnInit(){
  this.afusionar=[];
  this.selecionadas=[];
  this.fusion=false;
  this.nueva=false;
  this.editando=false;
  this.currentUser = this.token.getUser();
  console.log("Usuario***"+JSON.stringify(this.currentUser))
  this.cosa.user=this.currentUser.id;
  this.cosa.tienda=this.currentUser.tienda;
  this.aimprimir=0;
  this.sacartodas();
}



seleccionartodos(){


  for (let i = 0; i < this.ubicaciones.length; i++) {

    this.afusionar.push(this.ubicaciones[i].id)

  }
  var checkboxes = document.querySelectorAll('input[type="checkbox"]');

  // Recorrer cada checkbox y deseleccionarlo
  checkboxes.forEach(function(checkbox) {
    // Convertir el elemento a HTMLInputElement
    var checkboxInput = checkbox as HTMLInputElement;
    // Deseleccionar el checkbox
    checkboxInput.checked = true;
});

}
seleccionarningunp(){


  this.afusionar=[];

  var checkboxes = document.querySelectorAll('input[type="checkbox"]');

    // Recorrer cada checkbox y deseleccionarlo
    checkboxes.forEach(function(checkbox) {
      // Convertir el elemento a HTMLInputElement
      var checkboxInput = checkbox as HTMLInputElement;
      // Deseleccionar el checkbox
      checkboxInput.checked = false;
  });
}

sacarnombre(id:string){


  let devo="";
  const j = this.ubicaciones.findIndex( fruta => fruta.id === parseInt(id));
  if(j!=-1){
    if(this.ubicaciones[j].Padre!=null){
  
      const k = this.ubicaciones.findIndex( fruta => fruta.id === this.ubicaciones[j].Padre);
      devo=this.ubicaciones[k].Nombre+" / "+this.ubicaciones[j].Nombre;
  
    }else{
  
      devo=""+this.ubicaciones[j].Nombre;
    }
  }
  return devo;
  

}

imprimiretiquetas(id){

  if(id!=0){

    this.afusionar.push(id)
    for(let j=0; j<this.ubicaciones.length;j++){

      if(this.ubicaciones[j].Padre==id)this.afusionar.push(this.ubicaciones[j].id);

    }
  }

  let  barcodeData=[];

  for(let i=0; i<this.afusionar.length;i++){


    let numeroString = this.afusionar[i].toString();

    // Calcular cuántos ceros se deben agregar
    let cerosFaltantes = 10 - numeroString.length;

    // Agregar ceros a la izquierda
    for (let i = 0; i < cerosFaltantes; i++) {
        numeroString = '0' + numeroString;
    }
    let nomb=this.sacarnombre(this.afusionar[i]);
    if (nomb.length > 35) {
      nomb = nomb.slice(0, 35);
  }

  
    numeroString="UBI"+numeroString;

   let codigo={ barcodeText: numeroString, text: nomb };
    barcodeData.push(codigo);

  }


    this.crearetiquetas(barcodeData);
    this.cancelarfusion();
}

crearetiquetas(barcodeData:any){
/*
// Crear un nuevo documento para la impresión
const printWindow = window.open('', '_blank');
    
// Crear un elemento <div> para renderizar los códigos de barras y el texto
const containerDiv = document.createElement('div');
containerDiv.style.margin = '5px'; // Margen entre los códigos de barras y el texto
containerDiv.style.columnCount = '4'; 
// Iterar sobre los datos de los códigos de barras y el texto
barcodeData.forEach(data => {
   
  const barcodeDiv = document.createElement('div');

  // Crear un elemento <canvas> para cada código de barras
  const canvas = document.createElement('canvas');

  // Generar el código de barras en el elemento <canvas>
  JsBarcode(canvas, data.barcodeText, {
      format: 'CODE128', // Puedes cambiar el formato del código de barras si es necesario
      displayValue: false,
      margin: 10,
  //    width: 100, // Ancho del código de barras
     //height: 35// Margen alrededor del código de barras
  });

  // Establecer el tamaño del código de barras
     // Redimensionar el canvas a 100px de ancho
     canvas.style.marginBottom = '10px';
     canvas.style.height = '55px';

   // Altura del código de barras
  canvas.style.border = '3px solid black'; // Añadir un borde de 3px alrededor del código de barras

  // Crear un elemento de texto para cada código de barras
  const textElement = document.createElement('div');
  textElement.textContent = data.text;
  textElement.style.fontSize = '8px'; // Tamaño de la fuente del texto
  textElement.style.marginBottom = '3px'; // Margen superior del texto

  // Agregar el elemento <canvas> y el elemento de texto al <div> del código de barras
  barcodeDiv.appendChild(textElement);
  barcodeDiv.appendChild(canvas);
  

  // Agregar el <div> del código de barras al contenedor
  containerDiv.appendChild(barcodeDiv);
});

// Agregar el contenedor al cuerpo del documento de impresión
printWindow.document.body.appendChild(containerDiv);

printWindow.print();

  printWindow.close();*/


// Crear un nuevo documento para la impresión
const printWindow = window.open('', '_blank');

// Crear un elemento <div> para renderizar los códigos de barras y el texto
const containerDiv = document.createElement('div');
containerDiv.style.display = 'flex';
containerDiv.style.flexWrap = 'wrap';
containerDiv.style.width = '100%'; // Ajustar el ancho al 100% de la página

// Tamaño fijo de cada elemento de texto y código de barras
const elementWidth = 150; // 150px de ancho
const elementHeight = 80; // 80px de alto
const horizontalMargin = 50; // Margen horizontal de 50px
const verticalMargin = 25; // Margen vertical de 25px
const rowsPerPage = 10; // Cantidad de filas por página
const extraMargin = 25; // Margen adicional de 25px después de cada conjunto de 10 filas

// Iterar sobre los datos de los códigos de barras
barcodeData.forEach((data, index) => {
    // Calcular el número de fila actual y la página actual
    const rowNumber = index % rowsPerPage;
    const pageNumber = Math.floor(index / rowsPerPage);

    // Calcular la posición horizontal del elemento
    const leftPosition = (index % 4) * (elementWidth + horizontalMargin);

    // Calcular la posición vertical del elemento
    const topPosition = (pageNumber * rowsPerPage + rowNumber) * (elementHeight + verticalMargin);

    // Agregar margen adicional después de cada conjunto de 10 filas
    if (rowNumber === rowsPerPage - 1 && index !== barcodeData.length - 1) {
        containerDiv.style.marginBottom = extraMargin + 'px';
    }

    // Crear un elemento <div> para cada código de barras y texto
    const barcodeDiv = document.createElement('div');
    barcodeDiv.style.width = elementWidth + 'px';
    barcodeDiv.style.height = elementHeight + 'px';
    barcodeDiv.style.marginRight = horizontalMargin + 'px';
    barcodeDiv.style.marginBottom = verticalMargin + 'px';
    barcodeDiv.style.textAlign = 'center';

    // Crear un elemento de texto para el código de barras
    const textElement = document.createElement('div');
    textElement.textContent = data.text;
    textElement.style.fontSize = '9px'; // Tamaño de la fuente del texto

    // Crear un elemento <canvas> para cada código de barras
    const canvas = document.createElement('canvas');

    // Generar el código de barras en el elemento <canvas>
    JsBarcode(canvas, data.barcodeText, {
        format: 'CODE128', // Puedes cambiar el formato del código de barras si es necesario
        displayValue: false,
        margin: 10,
    });

    // Establecer el tamaño del código de barras
    canvas.style.height = '50px'; // Altura del código de barras
    canvas.style.border = '2px solid black'; // Añadir un borde de 3px alrededor del código de barras

    // Agregar el elemento de texto y el <canvas> al <div> del código de barras
    barcodeDiv.appendChild(textElement);
    barcodeDiv.appendChild(canvas);

    // Agregar el <div> del código de barras al contenedor
    containerDiv.appendChild(barcodeDiv);
});

// Agregar el contenedor al cuerpo del documento de impresión
printWindow.document.body.appendChild(containerDiv);

// Llamar a la función de impresión de la ventana de impresión
printWindow.print();

printWindow.close();
}

//*********************************************** */
//rellena la lista de ubicaciones
sacartodas(){


  this.dataservice.gettodasubi(""+this.cosa.tienda).subscribe(res => {

     console.log(res);
     
   
     this.ubicaciones=res;

     this.filtrar();
     this.sacartotal();

   },
   err => console.log(err)
 );

}

//*********************************************** */
//Filtra las ubicaciones segun creiterio filt
filtrar(){

let filt=(<HTMLInputElement>document.getElementById('filt')).value;
this.ubicaciones2=[];

if(filt!=""){

let numubu=this.ubicaciones.length;

for(let i=0; i<numubu;i++){

    if(((this.ubicaciones[i].Nombre+"").toLowerCase()).includes(filt.toLowerCase())){

      this.ubicaciones2.push(this.ubicaciones[i]);

    }

  }
}else{

  this.ubicaciones2=this.ubicaciones;
}


}

async fusionar(){



  if(this.afusionar.length>1){

    if (confirm("ATENCION \n Se van a fusionar "+this.afusionar.length+" ubicaciones en 1!!!\n Esta operacion es ireversible, tas segur@??? ")) {

      

      this.cosa.Nombre="FUSION"+JSON.stringify(this.afusionar);
      this.cosa.Notas=null;
      this.cosa.Padre=null;
      this.cosa.id=null;

      let nueva = await this.dataservice.crearubi(this.cosa).toPromise();

      console.log("---- "+ JSON.stringify(nueva))

      console.log("lent-"+this.afusionar.length);
      for(let i=0; i<this.afusionar.length;i++){

        console.log("ID:"+this.afusionar[i]+" -"+this.afusionar.length);
        let ok= await this.dataservice.fusionarubis(nueva.id,this.afusionar[i]).toPromise();
        console.log("ID2:"+this.afusionar[i]+"  "+ok);
     }
     this.fusion=false;
     this.sacartodas();
     this.sacapadres();

    }

    //aser la fusionasion

  }
}

cancelarfusion(){

this.fusion=false;
this.afusionar=[];
this.aimprimir=0;
}

changed(evt:any, id:string) {


  if(evt.target.checked){

    if(this.afusionar.indexOf(id)==-1){

      this.afusionar.push(id);
  
    }


  }else{

    if(this.afusionar.indexOf(id)!=-1){

      this.afusionar.splice(this.afusionar.indexOf(id),1);;
  
    }


  }



}
//*********************************************** */
// saca el total de articulos en cada ubicacion 

async sacartotal(){ 
  
  let numubu=this.ubicaciones2.length;
  
  
  for(let i=0; i<numubu;i++){
  
   
    await this.dataservice.totalubi(this.ubicaciones2[i].id).subscribe(res => {
      
  
      let d:Ubitotal={
        
        id:parseInt(this.ubicaciones2[i].id),
        total:parseInt(res)
        
  
      };
      this.ubi_totales[i]=d;
  
      
    },
    err => console.log(err) 
    );
  
  
  }
  
  
}

//*********************************************** */
formatear(){

  if(this.formato=="Ver como tarjetas"){

    this.formato="Ver como lista";
    this.lista=false;
  }else{

    this.formato="Ver como tarjetas";
    this.lista=true;
  }
  
  

}

nuevau(){

 this.nueva=true;
 
 this.sacapadres();
 

}



sacapadres(){

  this.dataservice.getpa(""+this.currentUser.tienda).subscribe(res => {

    this.padres=res;
    this.padres.length
    
    if(this.editando){

      this.removeItemFromArr ( this.padres, this.cosa )
    }
    this.padres.unshift(this.ninguna);
  
  },
  err => console.log(err)
  );

} 

toti(id:number){
//console.log("total de "+id+" lenght: "+this.ubi_totales.length+" JS:"+JSON.stringify(this.ubi_totales))

 // if(this.ubi_totales){

    const i = this.ubi_totales.findIndex( fruta => fruta.id === id );
 


    if(i!=-1){
     
      return this.ubi_totales[i].total;
  
    }else{
  
      return 0;
    }


//  } else{  return 0;} 



}

cargar(){ 

  
    this.crear();
}

removeItemFromArr ( arr, item ) {


const i = arr.findIndex( fruta => fruta.id === item.id );



console.log("i "+ i+" item "+item.id+"arr " +JSON.stringify(arr));

  if ( i !== -1 ) {
    console.log("estaba");
      arr.splice( i, 1 );
  }
}

reset(){

  this.nueva=false;
  this.editando=false;
  this.cosa.Nombre="";
  this.cosa.Notas=null;
  this.cosa.Padre=null;
  this.cosa.id=null;

 this.seleccionado=null;
 // this.seleccionado.Notas=null;
 // this.seleccionado.Padre=null;
 // this.seleccionado.id=null;
  this.sacapadres();
}

tienehijos(id: string){

  const i = this.ubicaciones.findIndex( fruta => fruta.Padre === id );

    if(i!=-1){
      return true;
    }else{
      return false;
    }
}

//crea una nueva ubicacion
crear(){





if(!this.editando){


if(this.seleccionado !== null){

  this.cosa.Padre=this.seleccionado;

}

  this.cosa.Nombre=(<HTMLInputElement>document.getElementById('nombre')).value;
  this.cosa.Notas=(<HTMLInputElement>document.getElementById('exampleFormControlTextarea1')).value;

if((<HTMLInputElement>document.getElementById('defaultCheck1')).checked){
 
  this.cosa.Prioridad=1;

}else this.cosa.Prioridad=0;

  this.dataservice.crearubi(this.cosa).subscribe(res => {

      this.reset();
      this.sacartodas()
      this.sacapadres();
    },
    err => console.log(err)
  );
  }else this.actualizar();

}



//carga los datos de la ubicacion a editar
editar(id :string){

  this.nueva=true;
  this.editando=true;
  

    this.dataservice.getubi(id).subscribe(res => {
      console.log("d--- " +JSON.stringify(res))
        this.cosa=res;
        
        if(this.cosa.Prioridad==1)(<HTMLInputElement>document.getElementById('defaultCheck1')).checked=true;

      },
      err => console.log(err)
     );
     this.sacapadres();
     
    //busca los datos de la ubicacion padre si tiene

   /* if(this.cosa.Padre!=null){

      this.dataservice.getubi(""+this.cosa.Padre).subscribe(res => {

      this.seleccionado=res;

      },
      err => console.log(err)
      );
    }*/


}
nuevahija(padre:string){

  this.nueva=true;
  this.seleccionado=parseInt(padre);
  
}
//cancela la edicion 

cancelar(){

  this.reset();



}

//actualiza una ubicacion ya existente
actualizar(){

  

  
    this.cosa.Nombre=(<HTMLInputElement>document.getElementById('nombre')).value;
    this.cosa.Notas=(<HTMLInputElement>document.getElementById('exampleFormControlTextarea1')).value;
  
    console.log("chan "+ this.cosa.id+" item "+JSON.stringify(this.cosa));

    if((<HTMLInputElement>document.getElementById('defaultCheck1')).checked){
 
      this.cosa.Prioridad=1;
    
    }else this.cosa.Prioridad=0;

  this.dataservice.modificarubi(""+this.cosa.id,this.cosa).subscribe(res => {

    this.reset();
    this.sacartodas();
    this.sacapadres();
   },
   err => console.log(err)
 );

}

borrar(id :string){

  if(confirm("tas seguro?")) {
  ///-comprobar si esta vacia
  const i = this.ubicaciones.findIndex( fruta => fruta.Padre === id );

    if(i!=-1){

      console.log("tiene hijos");

    }else { 


      this.dataservice.borrarubi(id).subscribe(res => {
        console.log(res);
        this.sacartodas();
        this.sacapadres();
      },
      err => console.log(err)
 );

}}
}
  
}