import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Ubicacion } from "../models/ubicacion";
import { Ubi_product } from "../models/ubi_product";
import { createUrlResolverWithoutPackagePrefix } from '@angular/compiler';
import { Router } from '@angular/router';
import { DataService } from '../_services/data.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { MoverComponent } from '../mover/mover.component';
import { AlertService } from '../alert.service';
import { getLocaleTimeFormat } from '@angular/common';
@Component({
  selector: 'app-vercategoria',
  templateUrl: './vercategoria.component.html',
  styleUrls: ['./vercategoria.component.css']
})
export class VercategoriaComponent implements OnInit {
  movimiento:any={
 
    cantidad:0,
    user:0,
    productoId:0,
    destino:0,
    origen:0,
    tipo:""
  
  }
  aseleccion:any[];
 
  rubi:boolean;
  progreso=0;
  pmax=100;
  productos:any[]=[];
  hayresultados:boolean;
  idubi:string;
  totalde:number;
  nombreu:string;
  editando:boolean;
  mubiu:boolean;
  idmodificandose:number;

  ubicaciones: Ubicacion[]; //todas las ubicaciones 

  padres :Ubicacion[];  //solo las padres

  hijos :Ubicacion[]=[]; //los hijos de la categoria selecionada
  cantidad:number=1;
 categoriadestino:number;
 currentUser:any;
  seleccionado :Ubicacion={
    id:null,
    user:null,
    tienda:0,
    Nombre: "",
    Padre:null,
    Notas:"",
    Prioridad:0
  }
  tienehijos: boolean = false;
  cosa: Ubicacion ={
    id:null,
    user:null,
    tienda:0,
    Nombre: "",
    Padre:null,
    Notas:"",
    Prioridad:0
  }
  page: number;
  previousPage: number;
  total:number;
  roles:any;


  constructor(private alertService: AlertService,private rutaActiva: ActivatedRoute, private router:Router, private dataservice :DataService, private token:TokenStorageService) { }

  ngOnInit(): void {
  
    this.aseleccion=[];
    this.rubi=false;
    this.currentUser = this.token.getUser();
    this.roles =  this.currentUser.roles;
    this.idubi=this.rutaActiva.snapshot.params.id;
    this.sacapadres();
    this.cargar(1);
    this.sacartotal();
    this.sacarnombre();
    this.editando=false;
    this.mubiu=false;
    this.page=1;
    this.previousPage =1;
    
  }

  loadPage(page: number) {

    if (page !== this.previousPage) {
      this.previousPage = page;
      this.cargar(this.page);
    }
  }

  async aceptare(){

    if(this.aseleccion.length>0 && this.seleccionado.id!=null){

      if (confirm("ATENCION \n Se van a cambiar "+this.aseleccion.length+" articulos\n a la ubicacion "+this.seleccionado.Nombre+"\nEsta operacion es ireversible, tas segur@??? ")) {
        for(let i=0; i<this.aseleccion.length;i++){

          let ok= await this.dataservice.cambiardeubi(""+this.seleccionado.id, this.aseleccion[i]).toPromise()
          
          

        }
        this.page=1;
        this.previousPage =1;
        this.cargar(1);
        this.aseleccion=[]
        this.rubi=false;
      }


    }

  }

  cancelare(){

    this.aseleccion=[]
    this.rubi=false;
  }
  estaselect(id:string){

    if(this.aseleccion.includes(id)){

      return true;

    }else return false


  }

  changed(evt:any, id:string) {


    if(evt.target.checked){
  
      if(this.aseleccion.indexOf(id)==-1){
  
        this.aseleccion.push(id);
    
      }
  
  
    }else{
  
      if(this.aseleccion.indexOf(id)!=-1){
  
        this.aseleccion.splice(this.aseleccion.indexOf(id),1);;
    
      }
  
  
    }
  
  
  
  }
  ponertodoweb(op:number){


      
      this.dataservice.gettodaubi(this.idubi, ""+-1).subscribe(res => {

        this.cambiarweb(res,op);
      
      },
      err => console.log(err)
      );
  

  }

  cambiarweb(productoss:any, op:number){

    
      let prowe;
      let i;



    for (i in productoss) { 

        prowe={
          id:productoss[i].productoId,
          web:op
         }

         this.dataservice.modificarproducto(productoss[i].productoId,prowe).subscribe(res => { 

   
        },
        err =>{
        
        });
  }

}

  async pcomics(cateaponer:string){

    //para cada producto

 
    this.dataservice.gettodaubi(this.idubi, ""+-1).subscribe(res => {

      this.ponerncategorias(res,cateaponer);

    
    },
    err => console.log(err)
    );

 }


  verrol(){


    if(""+this.roles=="ROLE_ADMIN" )return 1;
    if(""+this.roles=="ROLE_ROOT" )return 2;

    return 0;
    }

 async ponerncategorias(productoss:any, cate:string){


  let productosflitrados=[]
  let i:any;
  let cate_prod:any

  for (i in productoss) { 
      
     // borrar todas las categorias anteriores
     cate_prod={
   
      productoId:productoss[i].productoId,
      categoriaId:cate
    
    }
    productosflitrados.push(cate_prod)
    
   }

  

      this.dataservice.crearcate_prodlote(productosflitrados).subscribe(res => {},
        err => console.log(" ERROR creando :"+err)
      );

  

}

timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
 
  cargar(pagina:number){ 

    this.dataservice.gettodaubi(this.idubi, ""+pagina).subscribe(res => {

      this.productos=res.rows;
      this.total=res.count; 
      console.log("resultado:"+JSON.stringify(res))
      this.hayresultados=true;
    
    },
    err => console.log(err)
    );


  }
  sacartotal(){

  this.dataservice.totalubi(this.idubi).subscribe(res => {

    this.totalde=res;
     
  },
  err => console.log(err)
  );

}
  volver(){

    this.router.navigate(['/gestU']);

}

sacarnombre(){


  this.dataservice.getubi(""+this.idubi).subscribe(res => {

    this.nombreu=res.Nombre;
     console.log(res);
     
   


   },
   err => console.log(err)
 );

}

mas(){
  this.cantidad=this.cantidad+1;
}

menos(){

if(this.cantidad>1)this.cantidad=this.cantidad-1;

}
modif(id:string){

  const i = this.productos.findIndex( fruta => fruta.id === id );
  this.idmodificandose=i;
  

  this.mubiu=true;
  this.editando=true;


} 
modifc(id:string){
 
 //categoria destino
  const i = this.productos.findIndex( fruta => fruta.id === id );
  this.idmodificandose=i;
  this.cantidad= this.productos[i].cantidad;


  this.editando=true;
  this.mubiu=false;
}


borrar(id:string){

 
  if(confirm("tas segur@?")) {

    let i = this.productos.findIndex( fruta => fruta.id ===  id);
  
 



    this.dataservice. borrarubi_produc(id).subscribe(res => {

      this.movimiento.user=this.currentUser;
      this.movimiento.productoId=this.productos[i].productoId;
      this.movimiento.cantidad=this.productos[i].cantidad
      this.movimiento.destino=-1;
      this.movimiento.origen=this.productos[i].ubicacionesId;
      
      this.crearmovi("Eliminar de stock");

      this.cargar(1);
      this.sacartotal();
      this.sacarnombre();
    },
    err => console.log(err)
    );

  }

}


excel(){
  
  let informe:string="CODIGO;EAN;NOMBRE;PVP;CANTIDAD\r\n";
       
  let nombrearchivo="InformeStock_"+this.nombreu+".csv";

  
  if (confirm("ATENCION \n Se va a descargar Excel")) {


    this.dataservice.gettodaubi(this.idubi, "-1").subscribe(res => {

      this.productos=res;


      let precio=0.0;

      for (let i = 0; i < this.productos.length; i++) {

        if(this.productos[i].producto.Iva==2){
        //4%
        precio=parseFloat(this.productos[i].producto.Precio)*1.04;
        }
        if(this.productos[i].producto.Iva==1){
        //21%
        precio=parseFloat(this.productos[i].producto.Precio)*1.21;
        }
        informe=informe+this.productos[i].producto.Codigo+";"+this.productos[i].producto.Barra+";"+this.productos[i].producto.Nombre.substring(0,30)+";"+(Math.round((precio + Number.EPSILON) * 100) / 100).toFixed(2)+";"+this.productos[i].cantidad+"\r\n";
       
      }
  
      this.guardarArchivoDeTexto(informe,nombrearchivo)

    },
    err => console.log(err)
    );

     

    }


    

}

guardarArchivoDeTexto = (contenido, nombre) => {
   
  const a = document.createElement("a");
 
  const archivo = new Blob([contenido], { type: 'text/plain'});
  const url = URL.createObjectURL(archivo);
  a.href = url;
  a.download = nombre;
  a.click();
  URL.revokeObjectURL(url);
}

sacapadres(){

  this.dataservice.getpa(""+this.currentUser.tienda).subscribe(res => {

  this.padres=res;

  if(this.padres.length>0){
    
    this.hayresultados=true;

  }else{
    this.hayresultados=false;
  }  
  },
  err => console.log(err) 
  );

}

vaciar(){
  if(confirm("tas segur@?")) {
    if(confirm("vas a borrarlo to, seguro seguro????")) {

      this.dataservice. borrartodasubi_produc(  this.idubi).subscribe(res => {
          
        this.volver();
      },
      err => console.log(err)
      );
  


    }
}

}


//------------------------------------------------------
async mover(){


  let stckactual:Ubi_product[];

  console.log("Productois"+JSON.stringify(this.productos)+" idmodificandose: "+this.idmodificandose)

 
  this.movimiento.user=this.currentUser;
  this.movimiento.productoId=this.productos[this.idmodificandose].productoId;
  this.movimiento.cantidad=this.productos[this.idmodificandose].cantidad
  this.movimiento.destino=this.categoriadestino;
  this.movimiento.origen=this.productos[this.idmodificandose].ubicacionesId;

   await this.dataservice.getubi_product_i(this.productos[this.idmodificandose].productoId).subscribe(res => {

    stckactual=res;

    console.log("***resultado: "+JSON.stringify(stckactual));
    let i=-1; 

    if(res){
        i = stckactual.findIndex( fruta => fruta.ubicacionesId ===  this.categoriadestino);
    }
    console.log("(i:"+i);
    
    
  //si ya esta en al categoria destino
  if(i!=-1){

    stckactual[i].cantidad=  stckactual[i].cantidad+ this.productos[this.idmodificandose].cantidad//ubicacions vieja


    this.productos[this.idmodificandose].productoId //producto

    console.log("categori des:"+this.categoriadestino); //categoria destino


    //suma cantidad en destino
    this.dataservice.modificarubi_produc(""+stckactual[i].id,stckactual[i]).subscribe(res => {
  

      this.crearmovi("Cambio de ubicacion");
    },
    err => console.log(err)
    );

  }else{



  let stock:Ubi_product={

    id:null,
    productosId:this.productos[this.idmodificandose].productoId,
    ubicacionesId: this.categoriadestino,
    user:this.token.getUser().id,
    cantidad:this.productos[this.idmodificandose].cantidad,

  };

  console.log("categori des: "+ this.categoriadestino+" nstock "+JSON.stringify(stock) ); //categoria destino
  //si no esta
 

    //crea nueva ubi-stok
 
    this.dataservice. crearubi_product(stock).subscribe(res => {

      this.crearmovi("Cambio de ubicacion");
    },
    err => console.log(err)

    );
    console.log("creanda! ")
  }


  },
  err => console.log(err)
  ); 

  
  
  //borra vieja  
  this.dataservice. borrarubi_produc(""+this.productos[this.idmodificandose].id).subscribe(res => {
  
  },
  err => console.log(err)
  );


}

//------------------------------------------------------

async aceptar(){

 

if(this.mubiu){

  if(this.seleccionado.id!=null){


  
  this.categoriadestino=this.seleccionado.id;
 

  if( this.categoriadestino!=parseInt(this.idubi)){

    if(confirm("tas segur@?")) {

      this.mover();
     
      }
  }else{
  
      
  this.alertService.error("No se puede mover ya esta en esa ubicacion");

  }}else{
  
      
    this.alertService.error("Debes selecionar una ubicacion de destino");
  
  }

}else{
  
let difcantidad=this.cantidad-this.productos[this.idmodificandose].cantidad;
this.movimiento.cantidad=difcantidad;
this.movimiento.user=this.currentUser;

  this.movimiento.productoId=this.productos[this.idmodificandose].productoId;
  this.movimiento.destino=this.productos[this.idmodificandose].ubicacionesId;
  this.movimiento.origen=this.productos[this.idmodificandose].ubicacionesId;


    this.productos[this.idmodificandose].cantidad=this.cantidad;

      await this.dataservice.modificarubi_produc(""+this.productos[this.idmodificandose].id,this.productos[this.idmodificandose]).subscribe(res => {

     
        this.crearmovi("Modificacion de cantidad");
      },
      err => console.log(err)
      );
  

    
}



}

crearmovi(tipo:string){

  this.movimiento.tipo=tipo;
  this.dataservice.creamovi(this.movimiento).subscribe(res => {

    this.movimiento={

      cantidad:0,
      user:0,
      productoId:0,
      destino:0,
      origen:0,
      tipo:""
    
    }
    this.cancelar();

  },
    err =>{

  });


 

}

cancelar(){


this.cantidad=1;
this.editando=false;
this.mubiu=false;
this.seleccionado={
  id:null,
  user:null,
  tienda:0,
  Nombre: "",
  Padre:null,
  Notas:"",
  Prioridad:0
}

this.cargar(1);
this.sacartotal();
this.sacarnombre();
}

//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
sacarnombre2(id:string){

  console.log("sacando nombre id:"+id)
  let tota=this.ubicaciones.length;

  for (var i=0; i<tota; i++) {
    console.log("i "+i);
    if(""+this.ubicaciones[i].id==id){

      console.log("if "+this.ubicaciones[i].Nombre);
      return this.ubicaciones[i].Nombre;
      
    }
  }

}

//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
sacarhijos(){

  this.sacartodas();
  
  console.log("categori des:"+this.seleccionado.id); //categoria destino
}

mostradestini(){

  console.log("categori mdes:"+this.seleccionado.id);
}

//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------

async sacartodas(){

   let re=await this.dataservice.gettodasubi(this.token.getUser().tienda).toPromise();
   
    
       this.ubicaciones=re;

       let tota=this.ubicaciones.length;
       this.tienehijos=false;
       this.hijos=[];

       for (var i=0; i<tota; i++) {
         

         if(this.ubicaciones[i].Padre==this.seleccionado.id ){

          let hijo:Ubicacion=this.ubicaciones[i];

           this.hijos.push(hijo);
           
         }
       }
       console.log("total:"+tota)
       if(this.hijos.length>0){
     
         this.tienehijos=true;

       }

 
   
 }


}
