<div class="container">
  
  
  <div *ngIf="hayresultados">

    <alert></alert>

    <div class="row" style="margin-top:15px;">
      <h2 style="margin-top:15px;">Devolver productos: </h2>
      <div class="col-md-4" >
         <div class="card">
           <div class="card-body">
             <form action="">
               <div *ngIf="!edit" class="form-group">
                 <input type="text" id=barr name="title" [(ngModel)]="barra" placeholder="Codigo de Barras" class="form-control" autofocus>
                 
               </div>
               
               <button class="btn btn-success btn-block" (click)="cargar()" style="display: none">
                 Save 
               </button>
             </form>
           </div>
         </div> 
       </div>
      </div>
     <div *ngIf="inicio==1">
       <div class="col-md-4 offset-md-2">
         <div class="card card-body text-center">
             <h3 id="nomb">{{ producto.Nombre}}</h3>
             <h3 style="color: red;" >Precio: {{producto.Precio | sumariva:producto.Iva }}€</h3>
             
             <a class="btn btn-success btn-sm"  (click)="subir()" style="margin-top:10px">aceptar</a>
             <h3>Estaba en:</h3>
  
             <div *ngFor="let ubi of todosantiguas">
  
              
              <a class="btn btn-primary btn-sm btn-block " *ngIf="ubi==antigua" Style="margin-bottom: 10px;margin-top:10px; ">{{sacarnombre(ubi.ubicacionesId)}}:{{ubi.cantidad}} </a>
              <a class="btn btn-secondary btn-sm btn-block" *ngIf="ubi!=antigua" Style="margin-bottom: 2px;" (click)="cambiar(ubi)">{{sacarnombre(ubi.ubicacionesId)}}:{{ubi.cantidad}} </a>
  
          </div>
         <a class="btn btn-danger btn-sm btn-block"  (click)="cancelar()">cancelar</a>
         
     
         </div>
       </div>
     </div>
     <div *ngIf="inicio==2">
         <div class="col-md-4 offset-md-2">
             <div class="card card-body text-center">
                 <div class="alert alert-danger" role="alert">
                    EL ARTICULO NO ESTA UBICADO, DEBE UBICARLO ANTES DE DEVOLVERLO
                   </div>
         
             </div>
         </div>
     </div>
     
     <div *ngIf="listadevo.length>0"> 
         <h5>Articulos en la estanteria de devoluciones</h5>
        <table class="table">
       
            <tr>
                <td></td> 
                <td>Nombre</td>
                <td>Barra</td>
                <td>Codigo</td>
                <td>Precio</td>
                <td>Cantidad</td>
                <td></td>
    
            </tr>
            <tr *ngFor="let re of listadevo">

              <td><img src="{{re.producto.Barra | sacarfoto}}" onerror="this.src='../../assets/nofoto.png'" width="70" height="100" /> </td>
              <td><a [routerLink]="['../articulo/',re.producto.Barra]"> {{re.producto.Nombre}}</a></td>
              <td>{{re.producto.Barra}}</td>
              <td>{{re.producto.Codigo}}</td>
              <td>{{re.producto.Precio | sumariva:re.producto.Iva}}</td>
              <td>{{re.cantidad}}</td>
              <td><button type="button" class="btn btn-danger btn-xs" (click)="borrar(re.id)"><i class="fas fa-check-circle"></i></button></td>
        
            </tr>
    
           
            </table>
        <!--  <p>{{ user.email }}</p> -->
     </div>
     </div>
     <h1 *ngIf="!hayresultados" style="margin-top: 20px;">Es necesario crear ubicaciones antes de mover los productos</h1>
  </div>