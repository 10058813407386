
  <a *ngIf="editando" class="btn btn-danger btn-sm"  (click)="cancelarart()">Volver</a>
<div class="container" style="text-align: center; margin-top: 30PX;">
<h1  style="margin-bottom:20px;">
    PROGRAMAR UN AVISO
</h1>
<div *ngIf="!editando">

    <button type="button" (click)="editar(1)" class="btn btn-dark btn-lg btn-block"  style="margin-bottom:15px; height: 80px;">STOCK MINIMO DE ARTICULOS</button>
    <button *ngIf="false" type="button" (click)="editar(4)" class="btn btn-dark btn-lg btn-block" style="margin-bottom:15px; height: 80px;">PEDIDOS A PROVEEDORES</button>
    <button *ngIf="false" type="button" (click)="editar(3)" class="btn btn-dark btn-lg btn-block" style="margin-bottom:15px; height: 80px;">ASIGNAR TAREAS</button>


    <alert></alert>
</div>

<div *ngIf="editando">

    <h4>{{tituloeditado}}</h4>
  
<div *ngIf="tituloeditado=='POR ARTICULO'">

   


<div class="row">
    <h2 style="margin-right:50px ;">Añadir articulos: </h2>
    
    <form action="">
      <div class="form-group">
        <input type="text" id=barr name="title"  placeholder="Codigo de Barras" class="form-control" autofocus>
        
      </div>
      
      <button class="btn btn-success btn-block" (click)="cargarart()" style="display: none">
        Save
      </button>
    </form>
   
</div>

    
    <div class="row" *ngIf="inicio==1">
        <div class="col-md-12 ">
          <div class="text-center">
              <h3 id="nomb">{{ producto.Nombre}}</h3>        
              <a class="btn btn-success btn-sm" style="margin-bottom: 20px;margin-right: 20px;" (click)="subir()">aceptar</a>
              <a class="btn btn-danger btn-sm" style="margin-bottom: 20px;" (click)="cancelar()">cancelar</a>
          </div>
       
        </div>
    </div>
    <div *ngIf=" articuloseleccionados.length>0">
       <table class="table">
      
           <tr>
               <td></td> 
               <td>Nombre</td>
               <td>Barra</td>
               <td>Codigo</td>
               <td>Precio</td>
               <td></td>
   
           </tr>

           <tr *ngFor="let re of articuloseleccionados">

             <td><img src="{{re.Barra | sacarfoto}}" width="70" height="100" /> </td>
             <td><a [routerLink]="['../articulo/',re.Barra]"> {{re.Nombre}}</a></td>
             <td>{{re.Barra}}</td>
             <td>{{re.Codigo}}</td>
             <td>{{re.Precio | sumariva:re.Iva}}</td>
             <td><button type="button" class="btn btn-danger btn-xs" (click)="descartar(re.id)"><i class="fas fa-minus-square"> Desartar</i></button></td>
             
           </tr>
   
          
           </table>

<div *ngIf="verrol()">          
         
 
        

                
          

  <div style="margin-left: -100px;" >

    <div class="form-row justify-content-center" style="margin-left: 100px;">
      <div class="form-group col-md-5">
      <label for="inputState">Tienda</label>

    

      <select id="selectiendas" class="form-control" [(ngModel)]="tselecianda" (change)="verquepasa()">
        <option [ngValue]="item" *ngFor="let item of tiendas">{{item.name}}</option>
      </select>
    

      <input class="form-check-input" type="checkbox" (change)="cambaraatienda()" id="todalatienda">
        <label class="form-check-label" for="flexCheckDefault">
            Toda la tienda
        </label>
    </div>
  </div>
    <div  class="form-row justify-content-center" style="margin-left: 100px;" >
      <div *ngIf="!todotienda" class="form-group col-md-5 ">
      <label for="inputState">Ubicacion</label>
      <select id="inputState" class="form-control" [(ngModel)]="ubiselecionada" (change)="peroque()">
        <option [value]="item.id" *ngFor="let item of ubicaciones">{{item.Nombre}}</option>
      </select>
    </div>
    <div class="form-group col-md-2">
      <label for="inputCity">Cantidad minima</label>
      <input type="number" class="form-control" id="inputCantidad" placeholder="0">
    </div>
  </div>

   
   
  </div>

    


  
</div>  

<div *ngIf="!verrol()">

  <div class="form-row justify-content-center" style="margin-left: 100px;">
    <div class="form-group col-md-5 ">
    <label for="inputState">Ubicacion</label>
    <select id="inputState" class="form-control" [(ngModel)]="ubiselecionada">
      <option [value]="item.id" *ngFor="let item of ubicaciones">{{item.Nombre}}</option>
    </select>
  </div>
  <div class="form-group col-md-2">
    <label for="inputCity">Cantidad minima</label>
    <input type="number" class="form-control" id="inputCantidad" placeholder="0">
  </div>
</div>

</div>

<alert></alert>
              <a class="btn btn-success btn-lg" style="margin-bottom: 20px;margin-right: 80px;" (click)="subirart()">ACEPTAR</a>
              <a class="btn btn-danger btn-lg" style="margin-bottom: 20px;" (click)="cancelarart()">CANCELAR</a>
            
      
</div>


</div> 

<div *ngIf="tituloeditado=='POR FECHA'">

  <div style="margin-left: -100px;" *ngIf="verrol()">
<div class="row justify-content-md-center">

  <div  class="col-md-6" style="margin-left: 100px; margin-bottom: 50px;">

    <a *ngIf="unuser" class="btn btn-success btn-lg btn-block"  (click)="unuser=true">A UN USUARIO</a>
    <a *ngIf="!unuser" class="btn btn-secondary btn-lg btn-block"  (click)="unuser=true">A UN USUARIO</a>
    <a *ngIf="!unuser" class="btn btn-success btn-lg btn-block"  (click)="unuser=false">A TODOS LOS USUARIOS DE UNA TIENDA</a>
    <a *ngIf="unuser" class="btn btn-secondary btn-lg btn-block" (click)="unuser=false">A TODOS LOS USUARIOS DE UNA TIENDA</a>
  
  </div>
</div>



    <div *ngIf="!unuser" class="form-row justify-content-center" style="margin-left: 100px;">
      <div class="form-group col-md-5">
      <label for="inputState">Tienda</label>

    

      <select id="selectiendas" class="form-control" [(ngModel)]="tselecianda" (change)="verquepasa()">
        <option [ngValue]="item" *ngFor="let item of tiendas">{{item.name}}</option>
      </select>
    

    </div>
  </div>
    <div  *ngIf="unuser" class="form-row justify-content-center" style="margin-left: 100px;" >
      <div  class="form-group col-md-5 ">
      <label for="inputState">Usuario</label>
      <select id="inputState" class="form-control" [(ngModel)]="userselecionado" (change)="peroque()">
        <option [value]="item.id" *ngFor="let item of users">{{item.username}}</option>
      </select>
    </div>
    
  </div>


   
  </div>
  <div class="row justify-content-md-center">

    <div  class="col-md-6" style="margin-bottom: 50px;">
  
      <a *ngIf="puntual" class="btn btn-success btn-lg btn-block"  (click)="puntual=true">PUNTUAL</a>
      <a *ngIf="!puntual" class="btn btn-secondary btn-lg btn-block"  (click)="puntual=true">PUNTUAL</a>
      <a *ngIf="!puntual" class="btn btn-success btn-lg btn-block"  (click)="puntual=false">PERIODICO</a>
      <a *ngIf="puntual" class="btn btn-secondary btn-lg btn-block" (click)="puntual=false">PERIODICO</a>
    
    </div>

  </div>

  
  <div *ngIf="puntual" style="margin-bottom: 50px;" >
    <h3>Fecha y hora del aviso</h3>

    <ngb-datepicker #dp [(ngModel)]="model" (navigate)="navigateEvent($event)"></ngb-datepicker>
    <input style="margin-left: 50px;" [(ngModel)]="hora" type="time" name="hora" min="18:00" max="21:00" step="3600" />

  </div>

    
<div *ngIf="!puntual">


<div class="row justify-content-md-center" style="margin-bottom:30px ;" >

  <a class="btn btn-success"  style="margin-right:30px;" [ngStyle]="{'background-color':estilo(2)}" (click)="Mensual=true;Semanal=false;Diario=false">Mensualmente</a>
  <a class="btn btn-success" style="margin-right:30px;" [ngStyle]="{'background-color':estilo(1)}" (click)="Semanal=true;Mensual=false;Diario=false">Semanalmente</a>
  <a class="btn btn-success"  [ngStyle]="{'background-color':estilo(3)}" (click)="Diario=true;Mensual=false;Semanal=false">Diariamente</a>

</div>


<div *ngIf="Semanal" class="btn-group" role="group" style="display: block;" aria-label="Basic checkbox toggle button group">

      
      <label *ngIf="estadia('lunes')" class="badge badge-primary" (click)="quitadia('lunes')" >Lunes</label>
      <label *ngIf="!estadia('lunes')" class="badge badge-secondary" (click)="ponedia('lunes')" >Lunes</label>
  
      <label *ngIf="estadia('Martes')" class="badge badge-primary" (click)="quitadia('Martes')" >Martes</label>
      <label *ngIf="!estadia('Martes')" class="badge badge-secondary" (click)="ponedia('Martes')" >Martes</label>

      <label *ngIf="estadia('Miercoles')" class="badge badge-primary" (click)="quitadia('Miercoles')" >Miercoles</label>
      <label *ngIf="!estadia('Miercoles')" class="badge badge-secondary" (click)="ponedia('Miercoles')" >Miercoles</label>

      <label *ngIf="estadia('Jueves')" class="badge badge-primary" (click)="quitadia('Jueves')" >Jueves</label>
      <label *ngIf="!estadia('Jueves')" class="badge badge-secondary" (click)="ponedia('Jueves')" >Jueves</label>

      <label *ngIf="estadia('Viernes')" class="badge badge-primary" (click)="quitadia('Viernes')" >Viernes</label>
      <label *ngIf="!estadia('Viernes')" class="badge badge-secondary" (click)="ponedia('Viernes')" >Viernes</label>

      <label *ngIf="estadia('Sabado')" class="badge badge-primary" (click)="quitadia('Sabado')" >Sabado</label>
      <label *ngIf="!estadia('Sabado')" class="badge badge-secondary" (click)="ponedia('Sabado')" >Sabado</label>

      
      <label *ngIf="estadia('Domingo')" class="badge badge-primary" (click)="quitadia('Domingo')" >Domingo</label>
      <label *ngIf="!estadia('Domingo')" class="badge badge-secondary" (click)="ponedia('Domingo')" >Domingo</label>


    
    </div>
    <div *ngIf="Mensual">

      <p>Introducir los dias del mes separados por comas (del 1 a 30)</p>
      <input type="text" class="form-control" id="mesapasar" aria-describedby="emailHelp" placeholder="Ejemplo: 1,10,25 ">

    </div>


    <input style="margin-bottom:30px ;" [(ngModel)]="hora" type="time" name="hora" min="18:00" max="21:00" step="3600" />
  </div>
  
   
 


<div style="margin-bottom:30px ;">

  <label for="exampleFormControlTextarea1">Mensaje</label>
  <textarea class="form-control" id="mensaje" rows="3"></textarea>


</div>





  <alert></alert>




  <a class="btn btn-success btn-lg" style="margin-bottom: 20px;margin-right: 80px;" (click)="checj()">ACEPTAR</a>
  <a class="btn btn-danger btn-lg" style="margin-bottom: 20px;" (click)="cancelarart()">CANCELAR</a>


</div>

<div *ngIf="tituloeditado=='POR PROVEEDOR'" class="form-row justify-content-center" style="margin-bottom: 50px;">

  <div  class="form-group col-md-5 ">
    <label for="inputState">Proveedor</label>
    <select id="inputState" class="form-control" [(ngModel)]="provselecionado" >
      <option [value]="item.id" *ngFor="let item of proveedores">{{item.nombre}}</option>
    </select>
  </div>
  <div  class="form-group col-md-12 ">
    <label style="display: block;">Cantidad en euros de articulos agotados: </label>
    <input  type="number" name="euros" id="euros"/>
  </div >



  

  
        <div  class="col-md-12 ">
             <a class="btn btn-success btn-lg" style="margin-top: 40px;margin-right: 80px;" (click)="subirprove()">ACEPTAR</a>
              <a class="btn btn-danger btn-lg" style="margin-top: 40px;" (click)="cancelarart()">CANCELAR</a>
        </div>

 <alert></alert>

</div>



</div>

</div>