import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nombretienda'
})
export class NombretiendaPipe implements PipeTransform {

  transform(value: number): any{

   if(value==2){

    return "Campoamor"

   }else return "Pulsar" 

   
  }

}
