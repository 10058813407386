
<div class="container justify-content-md-center">

  <a *ngIf="verocultos" class="btn btn-success btn-sm " style="color: aliceblue; margin-top: 15px; margin-bottom: 15px;margin-left: 30px;" (click)=" verocultos=!verocultos">Ver alertas en proceso</a>
  <a *ngIf="!verocultos" class="btn btn-secondary btn-sm " style="color: aliceblue; margin-top: 15px; margin-bottom: 15px;margin-left: 30px;" (click)=" verocultos=!verocultos">Ver alertas en proceso</a>

  <h1 style="margin-top:50px ;" *ngIf="avisosenviar.length==0 && avisosrecibirfaltaglobal.length==0 && avisosmover.length==0 && avisospedir.length==0 && avisosrecibirfalta.length==0 && pormover.length==0 && pordevolver.length==0 && pedidosproveedores.length==0 && tareasactivas.length==0">NO HAY ALERTAS PENDIENTES</h1>



    <div *ngIf="avisosenviar.length>0 || avisosrecibirfaltaglobal.length>0 || avisosmover.length>0 || avisospedir.length>0 || avisosrecibirfalta.length>0 || pormover.length>0 || pordevolver.length>0 || pedidosproveedores.length>0 || tareasactivas.length>0"  class="jumbotron text-center" style="margin-top: 20px; border-style:double; border-color: rgb(255, 0, 0);background-color: rgb(255, 246, 223);" >

        <H2 style="margin-bottom: 30px;color: brown;" > Alertas activas </H2>
        <div *ngIf="avisospedir.length>0">
            <h4 style="margin-top: 30px;">Productos que hay que pedir</h4>
            <table class="table-danger"   style="width:100% ;" >
              <tr *ngFor="let ubi of avisospedir" >
                <th>
                {{ubi.producto.Nombre}} 
                </th>
                <th>
                 {{ubi.cantidad}}
                </th>
                <th> 
                  {{nombreubicacion(ubi.ubicacion)}}
                </th>
                <th>
                  <a class="btn btn-success btn-sm " style="color: aliceblue; margin-top: 15px; margin-bottom: 15px;margin-left: 30px;" (click)=" ponerpedido(ubi)">Ya pedido</a>
                </th>
              </tr>
                 
            </table>
        </div>
        <div  *ngIf="avisosmover.length>0">
          <h4 style="margin-top: 30px;">Productos que hay que mover</h4>
           
            <div *ngFor="let ubi of avisosmover" >

              <table *ngIf="verocultos || ubi.estado=='activo'"class="table-danger" style="width:100% ;"> 
              <tr >
              <td>
                {{ubi.producto.Nombre}}
              </td>
              <td>
               X&nbsp;{{ubi.cantidad}}
              </td>
              <td>
                DESDE:--&nbsp;{{nombreubicacion(ubi.ubicacion)}}&nbsp;&nbsp;&nbsp;>>-----
              </td>
              <td>
                A --->>&nbsp;&nbsp;&nbsp;&nbsp;{{nombreubicacion(ubi.usuario_destino)}}
              </td>
              <td>
                <a *ngIf="ubi.estado=='activo'" class="btn btn-success btn-sm " style="color: aliceblue; margin-top: 15px; margin-bottom: 15px;margin-left: 30px;" (click)="borraraviso(ubi)">Movido</a>
                <a *ngIf="ubi.estado=='Borrado'" class="btn btn-success btn-sm " style="color: aliceblue; margin-top: 15px; margin-bottom: 15px;margin-left: 30px;" (click)="borraravisod(ubi)">BORRAR</a>
              </td>

            </tr>
      
          
      
          </table>
        </div>
      </div>
      <div  *ngIf="avisosrecibirfaltaglobal.length>0 || avisosrecibirfalta.length>0">
        <h4 style="margin-top: 30px;">Productos que tienen que mandar de otras tiendas</h4>
        
          <div *ngFor="let ubi of avisosrecibirfaltaglobal" >

            <table *ngIf="verocultos || ubi.estado=='activo'" class="table-danger" style="width:100% ;">
            <tr>
            <td>
              {{ubi.producto.Nombre}}
            </td>
            <td>
              X&nbsp;{{ubi.cantidad}}
            </td>
            <td>
              {{nombreubicacion(ubi.ubicacion)}}
            </td>
            <td>
              <a *ngIf="ubi.estado=='activo'" class="btn btn-success btn-sm " style="color: aliceblue; margin-top: 15px; margin-bottom: 15px;margin-left: 30px;" (click)="borraraviso(ubi)">Recibido</a>
              <a *ngIf="ubi.estado=='Borrado'" class="btn btn-success btn-sm " style="color: aliceblue; margin-top: 15px; margin-bottom: 15px;margin-left: 30px;" (click)="borraravisod(ubi)">BORRAR</a>
            </td>
            </tr>

            </table>
          </div>

          <div *ngFor="let ubi of avisosrecibirfalta" >

            <table *ngIf="verocultos || ubi.estado=='activo'" class="table-danger" style="width:100% ;">
            <tr>
            <td>
              {{ubi.producto.Nombre}}
            </td>
            <td>
             X&nbsp;{{ubi.cantidad}}
            </td>
            <td>
              DESDE:&nbsp;&nbsp;--&nbsp;{{nombreubicacion(ubi.usuario)}}
       
            </td>
            <td>
              ------->>&nbsp;{{nombreubicacion(ubi.ubicacion)}}
            </td>
            <td>
              <a *ngIf="ubi.estado=='activo'" class="btn btn-success btn-sm " style="color: aliceblue; margin-top: 15px; margin-bottom: 15px;margin-left: 30px;" (click)="borraraviso(ubi)">Recibido</a>
              <a *ngIf="ubi.estado=='Borrado'" class="btn btn-success btn-sm " style="color: aliceblue; margin-top: 15px; margin-bottom: 15px;margin-left: 30px;" (click)="borraravisod(ubi)">BORRAR</a>
            </td>
          </tr>
          </table>
          </div>
     
    </div>
    <div  *ngIf="avisosenviar.length>0">
      <h4 style="margin-top: 30px;">Productos que hay que mandar a otras tiendas</h4>
      
        <div *ngFor="let ubi of avisosenviar" >
         
          <table *ngIf="verocultos || ubi.estado=='activo'" class="table-danger" style="width:100% ;">
            <tr>
          <td>
            {{ubi.producto.Nombre}}
          </td>
          <td>
            X&nbsp;{{ubi.cantidad}}
          </td>
          <td>
           DESDE:&nbsp;&nbsp;--&nbsp; {{nombreubicacion(ubi.usuario)}}
          </td>
          <td>
            &nbsp;&nbsp;------>&nbsp;{{nombretienda(ubi.usuario_destino)}}
          </td>
          <td>
            <a *ngIf="ubi.estado=='activo'" class="btn btn-success btn-sm " style="color: aliceblue; margin-top: 15px; margin-bottom: 15px;margin-left: 30px;" (click)="borraraviso(ubi)">Recibido</a>
            <a *ngIf="ubi.estado=='Borrado'" class="btn btn-success btn-sm " style="color: aliceblue; margin-top: 15px; margin-bottom: 15px;margin-left: 30px;" (click)="borraravisod(ubi)">BORRAR</a>
          </td>
        </tr>
      </table>
    </div>
  </div>
        <div *ngIf="pordevolver.length>0">
            <h4 style="margin-top: 30px;">Productos que hay que devolver</h4>

            <table class="table-danger" style=" width:100% ;">
                <tr>
                    <th>Nombre</th>
                    <th>Fecha de stock</th>
                    <th>Ubicacion</th>
                    <th>Cantidad</th>
                    <th>Dias</th>
                    <th></th>
                </tr>
                <tr *ngFor="let ubi of pordevolver">
                  <td>{{ubi.producto.Nombre}}</td>
                  <td>{{formatearfecha(ubi.createdAt)}}</td>
                  <td>{{nombreubicacion(ubi.ubicacion)}}</td>
                  <td>{{ubi.cantidad}}</td>
                  <td>{{ubi.texto}}</td>
                  <td> <a class="btn btn-success btn-sm " style="color: aliceblue; margin-top: 15px; margin-bottom: 15px;" (click)="borraraviso(ubi.id)">Ya devuelto</a></td>
                </tr>
              </table>
        </div>
        <div *ngIf="pedidosproveedores.length>0">
            <h4 style="margin-top: 30px;">Pedidos a proveedores</h4>
            <table class="table-danger" style=" width:100% ;">
              <tr *ngFor="let ubi of pedidosproveedores">
                  <td>Proveedor</td>
                  <td>Total a pedir:500€</td>
                  <td><a class="btn btn-primary btn-sm " style="color: aliceblue; margin-top: 15px; margin-bottom: 15px;margin-left: 30px;" (click)="null">Ver articulos</a>
                  </td>
                  <th>
                  </th>
              </tr>
            </table>
        </div>

        <div *ngIf="tareasactivas.length>0">
            <h4 style="margin-top: 30px;">Tareas Pendientes</h4>

            <div class="row"  >
              <div class="card col-4" *ngFor="let ubi of tareasactivas">
                <div class="card-body">
                  <h5 class="card-title">Fecha Limite</h5>
                  <span *ngIf="ubi.tipo=='AvTienda'">TAREA DE TIENDA</span>
                  <h6 class="card-subtitle mb-2 text-muted">{{formatearfecha(ubi.fecha)}}</h6>
                  <p class="card-text">{{ubi.texto}}</p>
    
                  <a class="btn btn-success btn-sm " style="color: aliceblue; margin-top: 15px; margin-bottom: 15px;margin-left: 30px;" (click)="ponerterminada(ubi)">Terminada</a>
                  <a class="btn btn-primary btn-sm " style="color: aliceblue; margin-top: 15px; margin-bottom: 15px;margin-left: 30px;" (click)="ponerproceso(ubi)">En proceso</a>
                  <a *ngIf="ubi.periodo=='Puntual'" class="btn btn-warning btn-sm " style="color: aliceblue; margin-top: 15px; margin-bottom: 15px;margin-left: 30px;" (click)="postponer(ubi)">Posponer</a>
                </div>
              </div>
        
            </div>
        </div>

    </div>


    <div *ngIf="porpedirproceso.length>0 || pordemoverrproceso.length>0 || tareasenproceso.length>0" class="jumbotron text-center" style="margin-top: 20px;">

      <H4 style="margin-top: 30px;">Alertas en Proceso</H4>
      <div *ngIf=" porpedirproceso.length>0">
        <h4 style="margin-top: 30px;">Productos en reposicion de proveedores</h4>
        <table class="table-secondary"   style="width:100% ;">
          <tr *ngFor="let ubi of porpedirproceso">
            <th>
              {{ubi.producto.Nombre}}
            </th>
            <th>
             {{ubi.cantidad}}
            </th>
            <th>
              {{nombreubicacion(ubi.ubicacion)}}
            </th>
            <th>
              <a class="btn btn-success btn-sm " style="color: aliceblue; margin-top: 15px; margin-bottom: 15px;margin-left: 30px;" (click)="borraravisod(ubi)">Recibido</a>
        
            </th>
          </tr>
             
        </table>
    </div>

  <div *ngIf=" tareasenproceso.length>0">
    <h4 style="margin-top: 30px;">Tareas en proceso</h4>
    <div class="row">
      <div class="card col-4" *ngFor="let ubi of tareasenproceso" >
        <div class="card-body">
          <h5 class="card-title">Fecha Limite:</h5>
          <span *ngIf="ubi.tipo=='AvTienda'">TAREA DE TIENDA</span>
          <h6 class="card-subtitle mb-2 text-muted">{{formatearfecha(ubi.fecha)}}</h6>
          <p class="card-text">{{ubi.texto}}</p>

          <a class="btn btn-success btn-sm " style="color: aliceblue; margin-top: 15px; margin-bottom: 15px;margin-left: 30px;" (click)="ponerterminada(ubi)">Terminada</a>
          <a *ngIf="ubi.periodo=='Puntual'" class="btn btn-warning btn-sm " style="color: aliceblue; margin-top: 15px; margin-bottom: 15px;margin-left: 30px;" (click)="postponer(ubi)">Posponer</a>
    
        </div>
      </div>

    </div>
  </div>

  </div>

    <div *ngIf=" tareasproximas.length>0" class="jumbotron text-center" style="margin-top: 20px; background-color: rgb(236, 232, 173);">

        <H4 style="margin-bottom: 30px;">Proximas Tareas </H4>
        <div class="row">
          <div class="card col-4"  *ngFor="let ubi of tareasproximas">
            <div class="card-body">

              <h5 class="card-title">Fecha Limite:</h5>
              <span *ngIf="ubi.tipo=='AvTienda'">TAREA DE TIENDA</span>
              <h6 class="card-subtitle mb-2 text-muted">{{formatearfecha(ubi.fecha)}}</h6>
              <p class="card-text">{{ubi.texto}}</p>

              <a class="btn btn-success btn-sm " style="color: aliceblue; margin-top: 15px; margin-bottom: 15px;margin-left: 30px;" (click)="ponerterminada(ubi)">Terminada</a>
              <a class="btn btn-primary btn-sm " style="color: aliceblue; margin-top: 15px; margin-bottom: 15px;margin-left: 30px;" (click)="ponerproceso(ubi)">En proceso</a>
                      </div>
          </div>
    
        </div>

    </div>


</div>