
    <div class="container">

        <h2  style="margin-bottom:50px; margin-top:20px;"> Crear relaciones de productos </h2>

        <div *ngIf="modo==null" >
            <button class="btn btn-info btn-block" (click)="cambiamodo(1)">ASIGNAR EMBALAJES</button>
            <button class="btn btn-info btn-block" (click)="cambiamodo(2)">ASIGNAR PRODUCTOS COMPLEMENTARIOS</button>
            <button class="btn btn-info btn-block" (click)="cambiamodo(3)">ASIGNAR PRODUCTOS SUSTITUTIVOS</button>
        </div>

        <alert></alert>
    </div>
  
  <div *ngIf="modo!=null" class="container" style="margin-top:30px">
    <div class="row">
      <div class="col-md-6">
        <p>Producto:</p>
        <h4 *ngIf="selectedResultValue1">{{selectedResultValue1.Nombre}}</h4>
        <input type="text" [(ngModel)]="inputText1" (input)="search1()" class="form-control mb-3" placeholder="Buscar producto" id="prod" id="prod">
        <table *ngIf="searchResults1.length > 0" class="table table-striped table-bordered">
          <thead class="thead-dark">
            <tr>
              <th>Nombre</th>
              <th>Código</th>
              <th>Código de Barras</th>
              <th>Precio</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let result of searchResults1; let i = index" [ngClass]="{'table-primary': selectedResultIndex1 === i}" (click)="selectResult1(i)" >
                <td>{{ result.Nombre }}</td>
                <td>{{ result.Codigo }}</td>
                <td>{{ result.Barra }}</td>
                <td>{{ precio(result)}}</td>
            </tr>
          </tbody>
        </table>
      
      </div>
      <div class="col-md-6">
        
        <p *ngIf="modo==1">Embalaje:</p>
        <p *ngIf="modo==3">Sustituto:</p>
        <p *ngIf="modo==2">Complementario:</p>
        <h4 *ngIf="selectedResultValue2">{{selectedResultValue2.Nombre}}</h4>
        <input type="text" [(ngModel)]="inputText2" (input)="search2()" class="form-control mb-3" placeholder="Buscar producto" id="rel">
        <table *ngIf="searchResults2.length > 0" class="table table-striped table-bordered">
          <thead class="thead-dark">
            <tr>
              <th>Nombre</th>
              <th>Código</th>
              <th>Código de Barras</th>
              <th>Precio</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let result of searchResults2; let i = index" [ngClass]="{'table-primary': selectedResultIndex2 === i}" (click)="selectResult2(i)">
                <td>{{ result.Nombre }}</td>
                <td>{{ result.Codigo }}</td>
                <td>{{ result.Barra }}</td>
                <td>{{ precio(result)}}</td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="modo==1 || modo==2" style="margin-top:10px" >

            <p *ngIf="modo==1" style="display:left">CANTIDAD EN CADA EMBALAJE</p>
            <p *ngIf="modo==2" style="display:left">Cantidad sugerida (opcional)</p>
            <div class="input-group mb-3" Style="margin-top: 10px;">
              <button type="button" class="btn btn-danger"  style="background-color: rgb(109, 2, 2);"  (click)="menos(100)" >-100</button>
              <button type="button" class="btn btn-danger"  style="background-color: rgb(206, 31, 31);"  (click)="menos(10)" >-10</button>
              <div class="input-group-prepend">
    
            
                <button type="button" class="btn btn-danger" (click)="menos(1)" >-</button>
            
              </div>
        
              <input type="number"  class="form-control" name="cantidad" [(ngModel)]="cantidadelegida">
              <div class="input-group-append">
        
                <button type="button" class="btn btn-success"  (click)="mas(1)">+</button>
    
              </div>
              <button type="button" class="btn btn-success" style="background-color: rgb(26, 184, 47);" (click)="mas(10)">+10</button>
              <button type="button" class="btn btn-success"  style="background-color: rgb(0, 110, 6);"  (click)="mas(100)">+100</button>
            </div>
            
          
       </div>
      </div>

     
    </div>



    <div class="row justify-content-center">

        <button class="btn btn-success" (click)="aceptar()" style="margin-right: 10px;" id="aseptar">Aceptar</button>
        <button class="btn btn-danger" (click)="cancelar()">Cancelar</button>
    </div>

    <div *ngIf="modo==1 && selectedResultValue1">


    <div *ngIf="relacionesdeEmbalaje.length > 0" class="row">
       
          
          <h4>Embalajes asignados</h4>

          <table  class="table table-striped table-bordered">
            <thead class="thead">
              <tr>
                <th>Nombre</th>
                <th>Cantidad</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let result of relacionesdeEmbalaje;" >
               
                 <td>{{ mostrardatos(result.Embalaje) }}</td>
                  <td>{{ result.Cantidad }}</td>
                  <td><button class="btn btn-danger" (click)="borrar(result.id)"><i class="fas fa-trash-alt"></i></button></td>
              </tr>
            </tbody>
          </table>
            
    </div>
</div>
<div *ngIf="modo==2 && selectedResultValue1">


    <div *ngIf="relacionesdeComplement.length > 0" class="row">
       
          
          <h4>Complementos asignados</h4>

          <table  class="table table-striped table-bordered">
            <thead class="thead">
              <tr>
                <th>Nombre</th>
                <th>Cantidad</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let result of relacionesdeComplement;" >
               
                 <td>{{ mostrardatos(result.Complementario) }}</td>
                  <td>{{ result.Cantidad }}</td>
                  <td><button class="btn btn-danger" (click)="borrar(result.id)"><i class="fas fa-trash-alt"></i></button></td>
              </tr>
            </tbody>
          </table>
            
    </div>
</div>
<div *ngIf="modo==3 && selectedResultValue1">


    <div *ngIf="relacionesdeSusti.length > 0" class="row">
       
          
          <h4>Sustitutos asignados</h4>

          <table  class="table table-striped table-bordered">
            <thead class="thead">
              <tr>
                <th>Nombre</th>
        
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let result of relacionesdeSusti;" >
               
                 <td>{{ mostrardatos(result.Sustituto) }}</td>
             
                  <td><button class="btn btn-danger" (click)="borrar(result.id)"><i class="fas fa-trash-alt"></i></button></td>
              </tr>
            </tbody>
          </table>
            
    </div>
</div>

    <button class="btn btn-info btn-block" (click)="cambiarrel()" style="margin-top: 80px;">Cambiar tipo de relacion</button>
  </div>