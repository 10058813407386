import { Component, OnInit } from '@angular/core';
import { DataService } from '../_services/data.service';
import { Ubicacion } from "../models/ubicacion";
import { Ubi_product } from "../models/ubi_product";
import { TokenStorageService } from '../_services/token-storage.service';
import { AlertService } from '../alert.service';
import { JsonPipe } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-devoluciones',
  templateUrl: './devoluciones.component.html',
  styleUrls: ['./devoluciones.component.css']
})
export class DevolucionesComponent implements OnInit {

  ubicaciones: Ubicacion[]; //todas las ubicaciones  

  todosantiguas :Ubi_product[]=[];
  todosantiguastemp :Ubi_product[]=[];

  antigua:Ubi_product; 

  devolu:any={
    
    productosId:0,
    user:0, 
    cantidad:0
  }
  

  producto:any;
  currentUser : any;
  inicio: number;
  nombre:string="";
  cantidad:number=1;
  edit: boolean = false;
  tienehijos: boolean = false;
  hayresultados: boolean = false; 
  seleccionado :Ubicacion={
    id:null,
    user:null,
    tienda:0,
    Nombre: "",
    Padre:null,
    Notas:"",
    Prioridad:0
  }
  listadevo:any[];
  idubi:string;
  cosa: Ubicacion ={
    id:null,
    user:null,
    tienda:0,
    Nombre: "",
    Padre:null,
    Notas:"",
    Prioridad:0
  }
  barra:string;

  movimiento:any={

    cantidad:0,
    user:0,
    productoId:0,
    destino:0,
    origen:0,
    tipo:""
  
  }

  constructor( private router:Router,private dataservice :DataService,private token: TokenStorageService,private alertService: AlertService,private rutaActiva: ActivatedRoute) {

}



//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
ngOnInit() {

  this.inicio=0;
  this.idubi=this.rutaActiva.snapshot.params.id;
  this.currentUser = this.token.getUser();
  this. sacartodas();
  this.sacardevos();
}

//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------


//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
sacarnombre(id:string){


  let devo="";
  const j = this.ubicaciones.findIndex( fruta => fruta.id === parseInt(id));
  if(j!=-1){
    if(this.ubicaciones[j].Padre!=null){
  
      const k = this.ubicaciones.findIndex( fruta => fruta.id === this.ubicaciones[j].Padre);
      devo=this.ubicaciones[k].Nombre+" / "+this.ubicaciones[j].Nombre;
  
    }else{
  
      devo=""+this.ubicaciones[j].Nombre;
    }
  }
  return devo;
  

}


//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------

sacartodas(){

   this.dataservice.gettodasubi(this.token.getUser().tienda).subscribe(res => {
 
    
       this.ubicaciones=res;

      if(res){
        this.nombre=this.sacarnombre(this.idubi);
        this.hayresultados=true;
      }

     },
     err => console.log(err)
   );
   
 }

//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------



//si son distinta ubicacion
/*
productoId:req.body.ProductoId,
cantidad:req.body.Cantidad,
user:req.body.User,
estado: "activo"
*/
subir(){

  //si son distinta ubicacion
 
  
  
  this.devolu.productosId=this.producto.id;
  this.devolu.user=this.token.getUser().id;
  this.devolu.cantidad=1;
  
  




  if(this.antigua.cantidad>1){
  
    this.antigua.cantidad=this.antigua.cantidad-1;
  
    // descuaneta 1
    this.dataservice.modificarubi_produc(""+this.antigua.id,this.antigua).subscribe(res => {
        //sube devolucion
        this.movimiento.cantidad=1;
        this.movimiento.user=this.currentUser.id;
        this.movimiento.productoId=this.producto.id;
        this.movimiento.destino=-1;
        this.movimiento.origen=this.antigua.ubicacionesId; 
      
        this.crearmovi("Añadido a devoluciones")
  
    },
    err => console.log(err)
    );
  
  
  }else{
  
  
    // borra
    this.dataservice. borrarubi_produc(""+this.antigua.id).subscribe(res => {

            //sube devolucion
      this.movimiento.cantidad=1;
      this.movimiento.user=this.currentUser.id;
      this.movimiento.productoId=this.producto.id;
      this.movimiento.destino=-1;
      this.movimiento.origen=this.antigua.ubicacionesId; 

      this.crearmovi("Añadido a devoluciones")

    },
    err => console.log(err)
    );
  
  
  }
  
  console.log("manbdansssdo "+JSON.stringify(this.devolu));




  
  }
  
//---------------------------------------------------------
//---------------------------------------------------------


crearmovi(tipo:string){


  this.movimiento.tipo=tipo;
  this.dataservice.creamovi(this.movimiento).subscribe(res => {

    this.movimiento={

      cantidad:0,
      user:0,
      productoId:0,
      destino:0,
      origen:0,
      tipo:""
    
    }
    this.cancelar();

  },
    err =>{

  });


}

async cargar(){

  let busqueda= (<HTMLInputElement>document.getElementById('barr')).value;
 
  let error=false;
 
  if(this.inicio==1 ){
 
     if(parseInt(this.idubi)!=null){
     this.subir();
 
     }else{
       error=true;
       this.alertService.error("Error de ubicacion");
       this.playAudio("fail");
     }
  }
  
  
  if(!error){
 
         await this.dataservice. getproducto(""+busqueda).subscribe(res => {
 
             this.producto=res;
            
             console.log(JSON.stringify(this.producto))
             
             if(res!=null){
 
               this.sacartodas();
               this.estaubicado(""+this.producto.id);
               this.cantidad=1;
               this.playAudio("ok");
             }else {
               this.inicio=2;
               this.playAudio("fail");
             }
           },
           err => console.log(err)
           );
 
          
  }
  (<HTMLInputElement>document.getElementById('barr')).value="";
  (<HTMLInputElement>document.getElementById('barr')).focus();
 }
 
//---------------------------------------------------------
//---------------------------------------------------------
cancelar(){

this.inicio=0;
this.cantidad=1;
this.producto=[];
this.todosantiguas=[];
this.todosantiguastemp=[];

(<HTMLInputElement>document.getElementById('barr')).focus();
}

//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------

cambiar(nueva:Ubi_product){
 
  this.antigua=nueva;

  (<HTMLInputElement>document.getElementById('barr')).focus();
}

//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------
//---------------------------------------------------------

filtrar(){


  for (var i=0; i<this.todosantiguas.length; i++) {

    if(this.todosantiguas[i].user==this.currentUser.tienda)this.todosantiguastemp.push(this.todosantiguas[i]);
  }

  this.todosantiguas=this.todosantiguastemp;
}

estaubicado(barr:string){
  

  this.dataservice.getubi_product_i(barr).subscribe(res => {

    console.log("esta en"+JSON.stringify(res))

    this.todosantiguas=res;


    if(res!=null){

       console.log("Esta en la ubicacion"+JSON.stringify(this.todosantiguas))

       this.filtrar();
       if(this.todosantiguas.length>0){
   
        this.inicio=1;
         this.antigua=this.todosantiguas[0];

       }else{

        this.antigua=null;
        this.inicio=2;

       }
     

    }else{

      this.todosantiguas=[];
      this.antigua=null;
      this.inicio=2;
    }
  
  },
  err => console.log(err)
  );

 

}

sacardevos(){

  this.listadevo=[];

  this.dataservice.devouser(this.token.getUser().id).subscribe(res => {

    console.log("dev:" +JSON.stringify(res));
    if(res.length>15){


      for (let i = 0; i <14; i++) {

        this.listadevo.push(res[i]);
      }

    }else{

      this.listadevo=res;

    }
    


  },
  err => console.log(err)
  );
}

volver(){

  this.router.navigate(['/gestU']);

}
borrar(id:string){


  if(confirm("seguro que desea eliminar el articulo?")) {


const j = this.listadevo.findIndex( fruta => fruta.id === parseInt(id));

if(j!=-1){

  this.movimiento.cantidad=1;
  this.movimiento.user=this.currentUser.id;
  this.movimiento.productoId=this.listadevo[j].productoId;
  this.movimiento.destino=-1;
  this.movimiento.origen=-1;

 
}


    this.dataservice.borrardevo(id).subscribe(res => {
      
      this.crearmovi("Devuelto");
      this.sacardevos();
    },
    err => console.log(err)
    );

  }

}
playAudio(tipo :string){

  let audio = new Audio();
  if(tipo==="ok"){
    audio.src = "../../../assets/beep-ok.wav";
  }else{
    audio.src = "../../../assets/beep-f.wav";
  }


  audio.load();
  audio.play();
}


}


  
 