import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from './_services/token-storage.service';
import { Variables } from './variables';
import { MensajesService } from './_services/mensajes.service';
import { DataService } from './_services/data.service';
import { Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  private roles: string[];
  isLoggedIn = false;
  showAdminBoard = false;
  showModeratorBoard = false;
  username: string;
 nmensjaes:number=0;
 notificaciones:any[]=[];
 listaporrecibir:any[]=[];
 tienda:string;
  tiendas:any[];
  
  constructor(private tokenStorageService: TokenStorageService, private variable:Variables,private dataservice :MensajesService,private data :DataService,private router:Router) { }

  ngOnInit() {

 

    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {

      const user = this.tokenStorageService.getUser();
      this.roles = user.roles;
      
      this.sacartiendas(user);





      
      
   //   this.watcher();
    //  setInterval(()=> {

    //    this.watcher(); },60000);
      
  
      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showModeratorBoard = this.roles.includes('ROLE_MODERATOR');
      /*
      this.dataservice.getTotal().subscribe((message: any) => {
      
        this.nmensjaes=0;
          for (let i = 0; i < message.length; i++) {
 
                 if(message[i].destino==this.tokenStorageService.getUser().id && message[i].estado=="enviado" && message[i].tipo=="mensaje"){
                   
                  this.nmensjaes=this.nmensjaes+1;
                 }
          }
         
        });
        this.dataservice.getMessages().subscribe((message: any) => {
      
        this.nmensjaes=0;
          for (let i = 0; i < message.length; i++) {
 
                 if(message[i].destino==this.tokenStorageService.getUser().id && message[i].estado=="enviado")this.nmensjaes=this.nmensjaes+1;
 
          }
         
        });
*/
      this.username = user.username;
    }else{

      this.router.navigate(['home']);
    }
  }

  watcher(){
  
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if(this.isLoggedIn){

      console.log("esta logeado"+JSON.stringify(this.isLoggedIn))
   
        this.dataservice.mensajespara(""+this.tokenStorageService.getUser().id,"*").subscribe(res => {
          this.notificaciones=[];
          for (let i = 0; i < res.length; i++) {

              if(res[i].estado=="enviado" && res[i].tipo!="mensaje"){

                this.notificaciones.push(res[i]);

              }

          }

        },
        err => console.log(err)
        );
        this.sacarporrecibir();

      }else{
        this.router.navigate(['home']);
      }
   }

   
 nombretienda(id:number){

  let nombre="ERROR"


 
  const i = this.tiendas.findIndex( fruta => fruta.id === id );

 

  if(i!=-1){
  
   nombre=this.tiendas[i].name;
 

  }

  return nombre 
 
}


sacartiendas(user:any){


  
  this.data.sacartiendas().subscribe(
    res => {

      this.tiendas = res;
      this.tienda =this.nombretienda(user.tienda);

    },
    err => {
  
    }
  );

}
   verrol(){


    if(""+this.roles=="ROLE_ADMIN" )return 1;
    if(""+this.roles=="ROLE_ROOT" )return 2;

    return 0;
    }

   sacarporrecibir(){
  
    this.listaporrecibir=[];
    let destino=this.tokenStorageService.getUser().tienda;


    this.data.buscatransitodestino(""+destino).subscribe(res => {

      this.listaporrecibir=res;
  
    },
    err => console.log(err)
    );
  }
  
  logout() {

   
    this.tokenStorageService.signOut();
    window.location.reload();

  }


}


