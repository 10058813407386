import { Component, OnInit } from '@angular/core';
import { Input, Output} from '@angular/core';
import { DataService } from '../_services/data.service';
@Component({
  selector: 'app-ponerweb',
  templateUrl: './ponerweb.component.html',
  styleUrls: ['./ponerweb.component.css']
})
export class PonerwebComponent implements OnInit {

  @Input() idp:number;
  @Input() tipo:string;

  valor:boolean;
  nueva:any;
  
  constructor(private dataservice :DataService) { }

  ngOnInit(): void {
 
    if(this.tipo=="categoria"){

        this.dataservice.buscarcate(""+this.idp).subscribe(res => {
            this.nueva=res[0];
            this.valor=this.nueva.web;
        },
        err => console.log(err)
        );
    }
    
    if(this.tipo=="producto"){

      
      this.dataservice.getproductoweb(""+this.idp).subscribe(res => {


        this.nueva=res[0];
        this.valor=this.nueva.web;
    },
    err => console.log(err)
    );
      

    }
    //consulta si es categoria web
  }
  cambiar(){

    if(this.tipo=="categoria"){

      this.nueva.web=this.valor;
      this.dataservice.modificarcate(this.nueva.id,this.nueva).subscribe(res => {
        
        },
        err => console.log(err)
        );
    }
    
    if(this.tipo=="producto"){

      this.nueva.web=this.valor;
      this.dataservice.modificarproducto(this.nueva.id,this.nueva).subscribe(res => {
        
        },
        err => console.log(err)
        );
    }


    console.log("cambiando:"+this.valor+" tipo:"+this.tipo+" id:"+this.idp)
    //cambia valor en la web

  }
}
 