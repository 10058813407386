import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sacarfoto'
})
export class SacarfotoPipe implements PipeTransform {

  transform(value: string, ...args: string[]): string{
 

   

      let barra:string =value;

 
      return "https://static.cegal.es/imagenes/marcadas/"+String(barra).substr(0,7)+"/"+String(barra).substr(0,12)+".gif";
  
    
          


  }



  }





