import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Ubicacion } from "../models/ubicacion";
import { Ubi_product } from "../models/ubi_product";
import { createUrlResolverWithoutPackagePrefix } from '@angular/compiler';
import { Router } from '@angular/router';
import { DataService } from '../_services/data.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { MoverComponent } from '../mover/mover.component';
import { AlertService } from '../alert.service';
import { AnyNaptrRecord } from 'dns';
import { JsonpClientBackend } from '@angular/common/http';
import { runInThisContext } from 'vm';


@Component({
  selector: 'app-detallealbaran',
  templateUrl: './detallealbaran.component.html',
  styleUrls: ['./detallealbaran.component.css']
})
export class DetallealbaranComponent implements OnInit {

  constructor(private alertService: AlertService,private rutaActiva: ActivatedRoute, private router:Router, private dataservice :DataService, private token:TokenStorageService) { }
  sweb:boolean;
  nivelcate:number;
   idalbaran:number;
   productos:any[];
   datosampliados:any[];
   proveedores:any[];
    proveedor:any;
    padres :Ubicacion[];  //solo las padres
    ubicaciones: Ubicacion[]; //todas las ubicaciones 
    selectedItemsList = [];
    todascategorias:any[];
    categoriasaponer:any[];
    catego:boolean;
    hijos :Ubicacion[]=[]; //los hijos de la categoria selecionada
    seleccionado :Ubicacion={
      id:null,
      user:null,
      tienda:0,
      Nombre: "",
      Padre:null,
      Notas:"",
      Prioridad:0
    }
    ubiselec:any; 
    tienehijos: boolean = false;
    cosa: Ubicacion ={
      id:null,
      user:null,
      tienda:0,
      Nombre: "",
      Padre:null,
      Notas:"",
      Prioridad:0
    }
    mubiu:boolean;
    currentUser:any;
    t:boolean;
  ngOnInit(): void {
    this.proveedores=[];
    this.catego=false;
    this.nivelcate=0;
    this.sweb=false;
    this.t=false;
    this.currentUser = this.token.getUser();
    this.datosampliados=[];
    this.productos=[];
    this.sacartodosproveedores();
    this.mubiu=false;
    let atributos=this.rutaActiva.snapshot.params.id;
    let atributosvector=atributos.split("-");
    this.idalbaran=atributosvector[0];
    this.categoriasaponer=[];
    this.proveedor=atributosvector[1];
    this.llenarnombrecate()
    this.cargartodos();
 
 
  }

//***************************CATEGORIAS************************************** */



hacerc(op:number){
 
 
  if(op==1){
    this.nivelcate=0;
    this.categoriasaponer=[];
    this.catego=true;


 }else{
  this.nivelcate=0;
  this.categoriasaponer=[];
  this.catego=false;

 }

} 
onChange(categoria:number) {

  const i = this.categoriasaponer.findIndex( element => element == categoria );


  if(i==-1){
  
    this.categoriasaponer.push(categoria)
  }

  (<HTMLInputElement>document.getElementById('miselect')).value="";

  let cate1=(this.categoriaspornivel(1)).length
  let cate2=(this.categoriaspornivel(2)).length

  if(cate2>0){
    this.nivelcate=2;
  }
  if(cate1>0){
    this.nivelcate=1;
  }

}

llenarnombrecate(){

  this.dataservice.buscarcates().subscribe(res => {

    this.todascategorias=res;
  },

    err =>{

  });




}

categoriaspornivel(nivel:number){

    let catenivel:any[]=[];

    if(nivel==0){

      for(let i=0; i<this.todascategorias.length;i++){

        if(this.todascategorias[i].padre==null){
          if(this.sweb==true){
            
            if(this.todascategorias[i].web==1)catenivel.push(this.todascategorias[i])

          }else{

            catenivel.push(this.todascategorias[i])

          }

        }
      }
  
    }

    if(nivel==1){



      for(let i=0; i<this.todascategorias.length;i++){

      if(this.todascategorias[i].padre!=null){


        if(this.estaseleccionada(this.todascategorias[i].padre)){

          if(this.sweb==true){
            
            if(this.todascategorias[i].web==1)catenivel.push(this.todascategorias[i])

          }else{

            catenivel.push(this.todascategorias[i])

          }
    

      }

      }
        
      }

    }


    return catenivel;

}

estaseleccionada(id:number){

  let esta=false;


  for(let i=0; i<this.categoriasaponer.length;i++){

    if(this.categoriasaponer[i]==id)esta=true;

  }


  return esta;

}
nombrecategoria(id:number){

if(this.todascategorias!=[]){


  const i =  this.todascategorias.findIndex( element => element.id == id );


  if(i!=-1){
   
    return this.todascategorias[i].nombre;

  }else{

    return "error";
  }

}

  return "Sin nombre";



}
borrarcate(id:number){


  const i = this.categoriasaponer.findIndex( element => element == id );


  if(i!=-1){

    this.categoriasaponer.splice(i, 1);
  
  }
  let cate1=(this.categoriaspornivel(1)).length
  let cate2=(this.categoriaspornivel(2)).length

  if(cate2==0){
    this.nivelcate=1;
  }
  if(cate1==0){

    this.nivelcate=0;
  }

}
 
 
async categorizar(res:any,catespr:any){

  let producto=res;
  


    const b= await this.dataservice.borrarcate_prod(producto).toPromise();
    
    

  
      for(let i=0; i<catespr.length;i++){
  
        let cate_prod={
  
          productosId:producto,
          categoriaId:catespr[i]
        
        }
        console.log("CATEGORIZANDO :"+producto+ " - en -" +JSON.stringify(cate_prod))
        this.dataservice.crearcate_prod(cate_prod).subscribe(res => {
      
        },
        err =>{
          console.log("ERROR "+err);
        });
      
       
      }
  
    

  }

  llenarcates(res:any){
 

  
    this.dataservice.  sacracatedepro(""+res.id).subscribe(res => { 
  
      this.categoriasaponer=[];
  
      for(let i=0; i<res.length;i++){
  
        this.categoriasaponer.push(res[i].categoriaId);
  
      }
    
    },
      err =>{
  
  
      });
  }
  
  
//***************************************************************** */

  modif(){


  
    this.mubiu=true;


    this.sacapadres();
  
  }
  cancelarm(){

    this.mubiu=false;
    this.sweb=false;
    this.categoriasaponer=[];
    this.seleccionado.id=null;

  }

  sacartodas(){

    this.dataservice.gettodasubi(this.token.getUser().tienda).subscribe(res => {
  
     
        this.ubicaciones=res;
 
        let tota=this.ubicaciones.length;
        this.tienehijos=false;
        this.hijos=[];
 
        for (var i=0; i<tota; i++) {
          
 
          if(this.ubicaciones[i].Padre==this.seleccionado.id ){
 
           let hijo:Ubicacion=this.ubicaciones[i];
 
            this.hijos.push(hijo);
            
          }
        }
      
        if(this.hijos.length>0){
 
          this.tienehijos=true;
 
        }
 
      },
      err => console.log(err)
    );
    
  }
 
 
  sacapadres(){


    this.dataservice.getpa(""+this.currentUser.tienda).subscribe(res => {
  

    this.padres=res;
  
      console.log("TOTAL PADRES"+this.padres.length+ " TT: "+this.currentUser.tienda )
    },
    err => console.log(err)
    );
  
  }
  
  sacarproveedor(id:string){

 

    const j = this.proveedores.findIndex( fruta => fruta.codigo === parseInt(id));
  
    if(j!=-1){
 
      return this.proveedores[j].nombre;
    }

     return "ERROR"
  }
    ampliardatos(){

    for(let i=0; i<  this.productos.length;i++){

      

        this.dataservice.getproductocodigo(""+this.productos[i].articulo).subscribe(res => {

    
          if(res!=null)this.datosampliados.push(res);





        },
        err => console.log(err)
        );
    }

  }



  cargartodos(){

  this.dataservice.albaranes_lineas(""+this.idalbaran).subscribe(res => {

  

    this.productos=res;
    this.ampliardatos();
  



  },
  err => console.log(err)
  );
}





sacarnombre(codigo:string){


  const j = this.datosampliados.findIndex( fruta => fruta.Codigo === parseInt(codigo));
  
  if(j!=-1){

    return this.datosampliados[j].Nombre;
  }

   return "ERROR"


}


sacarcodigo(codigo:string){


  const j = this.datosampliados.findIndex( fruta => fruta.Codigo === parseInt(codigo));
  
  if(j!=-1){

    return this.datosampliados[j].id;
  }

   return "ERROR"


}


precioiva(codigo:string, precio:string){

  let coste ="#"
  let costeiva="#"
  let Venta="#"


  const j = this.datosampliados.findIndex( fruta => fruta.Codigo === parseInt(codigo));
  
  if(j!=-1){

    
    if(this.datosampliados[j].Iva=="1"){
      Venta=((1.21)*parseFloat(this.datosampliados[j].Precio)).toFixed(2);
      costeiva= ((1.21)*parseFloat(precio)).toFixed(2);
    }   
    if(this.datosampliados[j].Iva=="2"){
      Venta=((1.04)*parseFloat(this.datosampliados[j].Precio)).toFixed(2);
      costeiva=((1.04)*parseFloat(precio)).toFixed(2);
    }  


  }

   return precio+" - "+costeiva+" - "+Venta;




}

sacartodosproveedores(){

  this.dataservice.sacratodosprov("-1").subscribe(res => {

    this.proveedores=res;

  

  },
  err => console.log(err)
);

}

total(){

  let base=0.0
  let iva=0.0


  for(let i=0; i<  this.productos.length;i++){

  
  base=base+(parseFloat(this.productos[i].Precio)*parseInt(this.productos[i].cantidad));

  const j = this.datosampliados.findIndex( fruta => fruta.Codigo === parseInt(this.productos[i].articulo));
  
  if(j!=-1){

    if(this.datosampliados[j].Iva=="1"){

      iva=iva+((0.21)*parseFloat(this.productos[i].Precio)*parseInt(this.productos[i].cantidad));

    }   
    if(this.datosampliados[j].Iva=="2"){

      iva=iva+((0.04)*parseFloat(this.productos[i].Precio)*parseInt(this.productos[i].cantidad));
    }  
  }
 

  
} 
base= Math.round((base + Number.EPSILON) * 100) / 100;
iva= Math.round((iva + Number.EPSILON) * 100) / 100; 

return "Base: "+base+"  +  Iva: "+iva+" ----> TOTAL:  "+(base+iva).toFixed(2);



}
subirselec(){


  let g=1;
  let ub="casa ";
  let asubir=[]
  for(let i=0; i<  this.productos.length;i++){

    if(this.productos[i].checked)asubir.push(this.productos[i]);


  }

  if(asubir.length>0){

    if(this.seleccionado.id==null){

      let confirmAction =alert("ERROR: Seleciona una categoria para ubicar")

    }else{

      if (confirm("ATENCION \n Se va a ubicar "+asubir.length+" articulos en:\n "+this.seleccionado.Nombre+"\nEsta operacion es ireversible, tas segur@??? ")) {
        // Save it!
        this.subir(asubir);
        this.cancelarm();

      }
    }
   

  }
 


}


 subir(pro:any){



  for(let i=0; i<  pro.length;i++){

 


 this.crearubisto(this.seleccionado.id,pro[i]);


    let lin={

        "ubicado":1
    }

  this.dataservice.albaranes_lineasubi(pro[i].id,lin).subscribe(res => {
    
  
    },
    err => console.log(err)
  );



    let idweb=this.sacarcodigo(pro[i].articulo);
    
    if(this.categoriasaponer!=[] && this.catego==true){

      this.categorizar(idweb,this.categoriasaponer);
    }
    
    if(this.sweb)this.ponerweb(idweb);

  }

  this.t=false;
  this.datosampliados=[];
  this.productos=[];
  this.mubiu=false;
  this.cargartodos();


}

ponerweb(id:string){

  let producto={id:id, web:"1"}

  this.dataservice.modificarproducto(id,producto).subscribe(res => { 

   
    },
    err =>{
    
    });
 

}
 
async crearubisto(ubicacion:any,linea:any){

  let id=0;
  let cant=0;

  let stock:Ubi_product={

    id:0,
    productosId:this.sacarcodigo(linea.articulo),
    ubicacionesId:ubicacion,
    user:this.currentUser.tienda,
    cantidad:linea.cantidad
   

  };
  
  await this.dataservice.getubi_product_i(""+stock.productosId).subscribe(res => { 

    let esta=res;
   
    
     for (let i = 0; i < esta.length; i++) {
       if(esta[i].ubicacionesId==parseInt(ubicacion)){

          id=esta[i].id;
          cant=esta[i].cantidad;
        }
      }



      if(id>0){

    
        stock.cantidad=stock.cantidad+cant;

    

        this.dataservice.modificarubi_produc(""+id,stock).subscribe(res => {
  


                let movimiento={
                  
                  "cantidad":stock.cantidad,
                  "user":this.currentUser.tienda,
                  "productoId":stock.productosId,
                  "destino":ubicacion,
                  "origen":-1,
                  "tipo":"Nuevo Stock"
                }

                this.guardasmovi(movimiento);
        },
        err =>{

        });

      }else{
        
   
          this.dataservice.crearubi_product(stock).subscribe(res => {

     
            let movimiento={
                  
              "cantidad":stock.cantidad,
              "user":this.currentUser.tienda,
              "productoId":stock.productosId,
              "destino":ubicacion,
              "origen":-1,
              "tipo":"Nuevo Stock"
            }

            this.guardasmovi(movimiento);

     
        
          },
            err =>{

          });

      }
      },
      err =>{
    
      });


}

guardasmovi(sub:any){




  this.dataservice.creamovi(sub).subscribe(res => {



  },
    err =>{

  });




}

tods(op:number){

  if(op==1){
    this.t=true;
    for(let i=0; i<  this.productos.length;i++){

      if(this.productos[i].ubicado==0)this.productos[i].checked=true
  
    }

  }else{
    this.t=false;
    for(let i=0; i<  this.productos.length;i++){

      if(this.productos[i].ubicado==0)this.productos[i].checked=false
  
    }

  }


}

volver(){

  this.router.navigate(['/entradas']);

}


}