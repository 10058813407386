<div class="container"> 
  <br/> 

<div *ngIf="hayresultados" >
  <div *ngIf="!modificando"  >


    <h2>Ubicar productos en {{nombre}}</h2>
<div class="row" style="margin-bottom: 20px;">
  <div>

    <input class="form-check-input" type="checkbox" [(ngModel)]="conbarra" (change)="cambiart()"  id="flexCheckDefault">
    <label class="form-check-label" for="flexCheckDefault">
      BUSCAR POR CODIGO DE BARRAS
    </label>
  </div>

  <div style="margin-left: 30px;">
    <input class="form-check-input" type="checkbox" [(ngModel)]="concodigo" (change)="cambiard()"  id="flexCheckDefault">
    <label class="form-check-label" for="flexCheckDefault">
      BUSCAR POR CODIGO INTERNO
    </label>
      
  </div >
 


</div>


<alert></alert>
 <div class="col-md-4 offset-md-2">
    <div class="card"  *ngIf="!cargando">
      <div class="card-body">
        <form action="">
          <div *ngIf="!edit" class="form-group">
            <input type="text" id=barr name="title" [(ngModel)]="barra" placeholder="Codigo de Barras" class="form-control" autofocus>
             
          </div>
          
          <button class="btn btn-success btn-block" (click)="cargar()" style="display: none">
            Save 
          </button>
        </form>
      </div>
    </div>

  </div>

</div>

<div *ngIf="cargando">
  <div class="col-md-4 offset-md-2">
  <div class="card card-body text-center">
    
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>

  </div>
</div>
  </div>
</div> 


  
  <div *ngIf="!modificando">
    <div *ngIf="!cargando">



<div *ngIf="inicio==1">
  <div class="col-md-4 offset-md-2">


    <div class="card card-body text-center">

        <h3 id="nomb">{{ producto.Nombre}}</h3>
        <h3 style="color: red;" >Precio: {{producto.Precio | sumariva:producto.Iva }}€</h3>
        
        <a class="btn btn-success btn-sm btn-block"  (click)="aceptar()" style="margin-top:10px">aceptar</a>

        <div class="input-group mb-3" Style="margin-top: 10px;">
      
      <div class="input-group-prepend">
        <button type="button" class="btn btn-danger" (click)="menos(1)" >-</button>
    
      </div>
  
      
      <input *ngIf="editc" type="number"  class="form-control" name="cantidad" [(ngModel)]="cantidad" >
      <input *ngIf="!editc" type="number"  class="form-control" name="cantidad" [(ngModel)]="cantidad"  readonly>
      <div class="input-group-append">

        <button type="button" class="btn btn-success" (click)="mas(1)">+</button>
      </div>

    </div>
        
    <div class="row justify-content-center" >

      <button type="button" class="btn btn-danger" style="margin-right:10px" (click)="menos(100)">-100</button>
      <button type="button" class="btn btn-danger" style="margin-right:10px" (click)="menos(10)">-10</button>
      <button type="button" class="btn btn-warning" style="margin-right:10px" (click)="editc=!editc"><i class="fa-solid fa-pencil"></i></button>

      <button type="button" class="btn btn-success" style="margin-right:10px" (click)="mas(10)">+10</button>
      <button type="button" class="btn btn-success" style="margin-right:10px" (click)="mas(100)">+100</button>

    </div>
    <a class="btn btn-primary btn-sm btn-block" style="margin-top:10px"  (click)="cancelar()">cancelar</a>
    

    </div>
  </div>
</div>

</div>
<div *ngIf="inicio==2">
    <div class="col-md-4 offset-md-2">
        <div class="card card-body text-center">
            <div class="alert alert-danger" role="alert">
               EL ARTICULO NO ESTA EN LA BASE DE DATOS
              </div>
    
        </div>
    </div>
</div>


<div *ngIf="pendientes>0">
  <div class="col-md-4 offset-md-2">
    <div class="card card-body text-center">
      <div class="alert alert-warning" role="alert">
        Operaciones Pendientes:{{pendientes}}
       </div>

 </div>
</div>

<div>
    
   <!--  <p>{{ user.email }}</p> -->
</div>



</div>
<div class="row" style="margin-bottom: 20px;">
  <div>

    <input class="form-check-input" type="checkbox" [(ngModel)]="sweb"  id="flexCheckDefault3">
    <label class="form-check-label" for="flexCheckDefault">
      SUBIR A LA WEB
    </label>
  </div>
</div>
 <!--  CATEGORIAS  -->
<div  class="row" style="margin-top: 15px;" >
  <div class="col-md-3" ><h4>Categorizar producto:</h4></div>

    <div  class="col-md-4 " style="margin-top: 15px; margin-left: 30px;" >
   
      <select name="miselect" id="miselect" style="width: 250px;" [(ngModel)]="ubiselec" (change)="onChange($event.target.value)">

        <option [value]="item.id" *ngFor="let item of categoriaspornivel(0)">{{item.nombre}}</option>

      </select>
         
    </div>

    <div  *ngIf="nivelcate>0" class="col-md-4 " style="margin-top: 15px; margin-left: 30px;" >
      <select name="miselect" id="miselect" style="width: 250px;" [(ngModel)]="ubiselec" (change)="onChange($event.target.value)">

        <option [value]="item.id" *ngFor="let item of categoriaspornivel(1)">{{item.nombre}}</option>

      </select>
      <div  *ngIf="nivelcate>1" class="col-md-4 " style="margin-top: 15px; margin-left: 30px;" >
        <select name="miselect" id="miselect" style="width: 250px;" [(ngModel)]="ubiselec" (change)="onChange($event.target.value)">
  
          <option [value]="item.id" *ngFor="let item of categoriaspornivel(2)">{{item.nombre}}</option>
  
        </select>
    </div>
  </div>



 
<div  class="row" style="margin-top: 50px;" [style.background-color]="getColorfondo()">

  <div *ngFor="let ub5i of categoriasaponer">
     
      <p><span class="badge badge-primary" >{{nombrecategoria(ub5i)}}</span><button class="btn btn-sm " (click)="borrarcate(ub5i)" ><i class="fas fa-times"></i></button> </p>

  </div>
  <p  class="col-md-12 " style="text-align: center;">*** ATENCION: Se borraran las categorias anteriores y se añadiran las seleccionadas ***</p>

</div>
 <!-- FIN  CATEGORIAS  -->



<h1 *ngIf="!hayresultados" style="margin-top: 20px;">Es necesario crear ubicaciones antes de ubicar los productos</h1>
</div>