<section div *ngIf="articulo!=null">
    <div class="container" style="margin-top: 20px;">

    <div class="card">

      <h4 class="card-title text-center" style="margin-top:10px">{{articulo.Nombre}}</h4>
      <div class="row">
     
        <div class="col-md-4">
     
                <img class="img-fluid"   style="margin-left:10px" width="250" height="350" src="{{articulo.Barra | sacarfoto}}"  onerror="this.src='../../assets/nofoto.png'" class="rounded float-left"/>

        </div>
       
          <div class="col-md-6" style="margin-left:40px;">
           
             
              <app-ponerweb [idp]="articulo.id" [tipo]="'producto'"></app-ponerweb>
              <p class="card-text" style="margin-top:40px">Autor: {{articulo.Autor}}</p>
              <p class="card-text" >Editorial: {{articulo.Editorial}}</p>
              <p class="card-text">Codigo interno: {{articulo.Codigo}}</p>
              <p class="card-text">C.Barras: {{articulo.Barra}}</p>
              <h5>Precio:{{articulo.Precio | sumariva:articulo.Iva}}</h5>
           
        </div>


    </div>   
   
    <div *ngIf="contenido.length>0" class="row" style=" margin: 20px;">
      <p> Contenido:</p>
        <table class="table table-bordered"> 
              <tr *ngFor="let re of contenido">
                <td>{{re.cantidad}} x {{re.producto.Nombre}}</td>
                <td>C.Barras:{{re.producto.Barra}}</td>
                <td>Codigo:{{re.producto.Codigo}}</td>
                <td>Precio:{{re.producto.Precio | sumariva:re.producto.Iva}}€</td>
                <td><button type="button" class="btn btn-sm  btn-danger" (click)="borrarrel(re.id,'embalaje')"><i class="fas fa-trash-alt"></i></button></td>
              </tr>
           </table>
        </div> 
         
           
    <div *ngIf="embalajes.length>0" class="row" style=" margin: 20px;">
      <p> Referencias de Embalaje:</p>
        <table class="table table-bordered"> 
              <tr *ngFor="let re of embalajes">
                <td>{{re.producto.Nombre}}</td>
                <td>C.Barras:{{re.producto.Barra}}</td>
                <td>Codigo:{{re.producto.Codigo}}</td>
                <td>Precio:{{re.producto.Precio | sumariva:re.producto.Iva}}€</td>
                <td>Contiene {{re.cantidad}} unidades</td>
                <td><button type="button" class="btn btn-sm  btn-danger" (click)="borrarrel(re.id,'embalaje')"><i class="fas fa-trash-alt"></i></button></td>
              </tr>
           </table>
        </div> 
    

       <!--  CATEGORIAS  -->
<div  class="row" style="margin-top: 20px;margin-left: 10px;">

  <div class="col-md-3" ><p>Categorizar producto:<p></div>
  <div class="col-md-6" >
   
      <select name="miselect" id="miselect" style="width: 250px;" [(ngModel)]="ubiselec" (change)="onChange($event.target.value)">

        <option [value]="item.id" *ngFor="let item of todascategorias">{{item.nombre}}</option>

      </select>
          
  </div>
  <div class="col-md-3" *ngIf="this.haycambiocate==true">

    <button type="button" class="btn btn-sm  btn-danger" style="margin-right: 10px;" (click)="cancelarcat()">Cancelar</button>
    <button type="button" class="btn btn-sm  btn-success" (click)="categorizar()">Aceptar</button>
   
  </div>
</div>

<div  class="row" style="margin-left: 20px;">
  <div *ngFor="let ub5i of categoriasaponernuevas" style="display: inline;">
     
      <p><span class="badge badge-primary" >{{nombrecategoria(ub5i)}}</span><button class="btn btn-sm " (click)="borrarcate(ub5i)" ><i class="fas fa-times"></i></button> </p>

  </div>
</div>

 <!-- FIN  CATEGORIAS  -->
 


</div>


  <div class="row" *ngIf="complementos.length>0"  style="margin-top: 20px;margin-left: 10px;">
    <p>  Articulos complementarios:</p>
      <table class="table table-dark"> 
            <tr *ngFor="let re of complementos">
              <td>{{re.cantidad}} x {{re.producto.Nombre}}</td>
              <td>C.Barras:{{re.producto.Barra}}</td>
              <td>Codigo:{{re.producto.Codigo}}</td>
              <td>Precio:{{re.producto.Precio | sumariva:re.producto.Iva}}€</td>
              <td><button type="button" class="btn btn-sm  btn-danger" (click)="borrarrel(re.id,'complementario')"><i class="fas fa-trash-alt"></i></button></td>
            </tr>
         </table>
      </div> 





      <div class="row" *ngIf="similares.length>0"  style="margin-top: 20px;margin-left: 10px;">
        <p>  Articulos similares:</p>
          <table class="table table-dark"> 
                <tr *ngFor="let re of similares">
                  <td>{{re.producto.Nombre}}</td>
                  <td>C.Barras:{{re.producto.Barra}}</td>
                  <td>Codigo:{{re.producto.Codigo}}</td>
                  <td>Precio:{{re.producto.Precio | sumariva:re.producto.Iva}}€</td>
                  <td><button type="button" class="btn btn-sm  btn-danger" (click)="borrarrel(re.id,'sustituto')"><i class="fas fa-trash-alt"></i></button></td>
                </tr>
             </table>
          </div> 


<div *ngIf="comprobar(stocklocal)">
  
<h4 style="margin-top: 10px;">Stock Actual</h4>
    <table class="table" style=" background-color:palegreen">                            
     
        
        <div *ngFor="let re of stocklocal">

          <tr *ngIf="eslocal(re.ubicacionesId)">
            <td> {{nombretienda(quetienda(re.ubicacionesId))}} -</td>
            <td >{{nombreubicaciones(re.ubicacionesId)}}</td>
            <td > - {{re.cantidad}} Unidades</td>
            <td>   <button type="button" class="btn btn-sm  btn-danger" (click)="borrarubi(re)"><i class="fas fa-trash-alt"></i></button></td>
          </tr>
      
      </div>     

    </table>
    <table class="table" style=" background-color:indianred"> 
      <div *ngFor="let re of stocklocal">

        <tr *ngIf="!eslocal(re.ubicacionesId)">
          <td> {{nombretienda(quetienda(re.ubicacionesId))}} - </td>
          <td >{{nombreubicaciones(re.ubicacionesId)}}</td>
          <td > - {{re.cantidad}} Unidades</td>
          <td>   <button  *ngIf="verrol()>2" type="button" class="btn btn-sm  btn-danger" (click)="borrarubi(re)"><i class="fas fa-trash-alt"></i></button></td>
        </tr>
    
    </div>  
</table>
</div>


<div *ngIf="ventas.length>0">
<div class="row">

  <h4 style="margin: 20px;  "> {{totalventas}} Ventas</h4>


  <div style="margin-right: 20px;margin-top: 20px;">
    <button *ngIf="!verventas"  type="button" class="btn btn-sm btn-info" (click)="verventas=!verventas">VER</button>
    <button *ngIf="verventas" type="button" class="btn btn-sm btn-secondary" (click)="verventas=!verventas">OCULTAR</button>
  </div>

  <div *ngFor="let t of tiendas">

    <button *ngIf="verventas" style="margin-left: 20px;margin-top: 20px;" type="button" class="btn btn-sm btn-success" (click)="filtrarventast(t.id) ">{{t.name}}</button>

  </div>
  <div style="margin-left: 20px;margin-top: 20px;">
    <button *ngIf="verventas" type="button" class="btn btn-sm btn-info" (click)="filtrarventast(0) ">TODAS</button>
  </div>

</div>
 

  <table *ngIf="verventas"  style="margin-top: 20px; " class="table"> 

    <tr style="background-color: coral;">
        
      <td>Tienda</td>
      <td>Cantidad</td>
     <!--- <td>{{re.createdAt | date: 'dd'}}/{{re.createdAt | date:'MM'}}/{{re.createdAt | date: 'yyyy'}}</td> -->
     <td>Fecha</td>
     <td>Ticket </td>

    </tr>
      <tr *ngFor="let re of ventas" style="background-color: rgb(255, 211, 197);">

        <td *ngIf="filtrot==0 || filtrot==re.Tienda" >{{nombretienda(re.Tienda)}}</td>
        <td *ngIf="filtrot==0 || filtrot==re.Tienda">{{re.Cantidad}}</td>
        <td *ngIf="filtrot==0 || filtrot==re.Tienda">{{formatearh(re.Hora)}}</td>
        <td *ngIf="filtrot==0 || filtrot==re.Tienda">{{re.Ticket}}</td>
      </tr>
    
  </table>
</div>

<div style="background-color: rgb(224, 249, 250);" >

<div class="row justify-content-center" style="margin-top: 20px;">

  <h4 style="margin-top:20px;">PROVEEDORES</h4>

</div>
<div class="row justify-content-center">
      <h5 style="margin-top:20px;"><button  type="button" class="btn btn-sm btn-info"  (click)="filtrarlineasp('0')">Total de entradas: {{tentradas}} </button></h5>
</div>

<div class="row justify-content-center" style="margin-right: 20px;margin-top: 20px;margin-bottom: 20px;">


  <div *ngFor="let tr of proveedoresp">

    <div class="card" style="margin-left: 20px;margin-top: 10px;">
      <div class="card-header">
        <button  type="button" class="btn btn-sm btn-warning" (click)="filtrarlineasp(tr.id) ">{{tr.nombre}}</button>
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">Ultimo precio: {{tr.precio}}</li>
        <li class="list-group-item">Compras: {{tr.entradas}}</li>
      </ul>
    </div>

  
  </div>

</div>

  <div class="row" style="margin-right: 20px;margin-top: 20px;margin-bottom: 20px;">

    <table class="table"  style="margin-left: 20px;">
      <tr>
        <td>Fecha</td>
        <td>Albaran</td>
        <td>Proveedor</td>
        <td>Cantidad</td>
        <td>Precio</td>
      </tr>

    <tr *ngFor="let t of lineaspfiltradas">
      <td>{{t.createdAt | date: 'dd'}}/{{t.createdAt | date:'MM'}}/{{t.createdAt | date: 'yyyy'}}</td> 
      <td><button type="button" class="btn btn-sm btn-info" [routerLink]="['/dalbaran/',t.documento+'-'+proveedordelinea(t.documento,ceveseras)]">{{t.documento}}</button></td>
      <td>{{nombreprov(proveedordelinea(t.documento,ceveseras))}}</td>
      <td>{{t.cantidad}}</td>
      <td>{{t.Precio}}</td>
     
    </tr>
  </table> 



</div>
</div>

<div class="row" style="margin-right: 20px;margin-top: 20px;margin-bottom: 20px;">

  <h4 style="margin-left: 20px">Movimientos</h4>


  <div  style="margin-left: 20px">
    <button *ngIf="!vermovis"  type="button" class="btn btn-sm btn-info" (click)="vermovis=!vermovis">VER</button>
    <button *ngIf="vermovis" type="button" class="btn btn-sm btn-secondary" (click)="vermovis=!vermovis">OCULTAR</button>
  </div>

<!--

  <div *ngFor="let t of tiendas">
    <button *ngIf="vermovis" style="margin-left: 20px;" type="button" class="btn btn-sm btn-success" (click)="filtramas(t.id) ">{{t.name}}</button>
  </div>
  <div style="margin-left: 20px;">
        <button  *ngIf="vermovis" type="button" class="btn btn-sm btn-secondary" (click)="filtramas(0)">TODOS</button>
  </div>

-->
 

</div>

<div *ngIf="vermovis">
<div *ngIf="movimientos.length>0">

  <ngb-pagination *ngIf="totalmovis>10" [collectionSize]="totalmovis" [maxSize]="10" [pageSize]="10"  [(page)]="page" (pageChange)="loadPage($event)"></ngb-pagination>
      
  <table class="table" > 

    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td>Fecha</td>
      <td>Cantidad</td>
   
    </tr>
       
    <tr *ngFor="let re of movimientos">
     
        <td>{{nombretienda(re.user)}}</td>
        <td>{{re.tipo}}</td>
        <td>{{formatearmovi(re)}}</td>
        <td>{{re.createdAt | date: 'dd'}}/{{re.createdAt | date:'MM'}}/{{re.createdAt | date: 'yyyy'}}</td>
        <td>{{re.cantidad}}</td>
 
    </tr>

  </table>

</div>
</div>



</div>

