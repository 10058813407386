<h1 style="text-align: center; margin-top: 20px;">Categorias de productos</h1>
<div class="container-fluid">
    <div *ngIf="!nueva"  >
      <div class="input-group">
        <div class="card-body d-flex justify-content-between align-items-center">
        <form action="" style="display: none">
        <input class="form-control col-12"  id="filt"  type="search" placeholder="filtrar" aria-label="Search">
        <button class="btn btn-success btn-block" (click)="filtrar()" style="display: none"></button>
        </form>
        <a  *ngIf="verrol()" class="btn btn-primary btn-lg " style="background-color: green; margin-top: 15px; margin-bottom: 15px;" (click)="nuevau()">+ Nueva Categoria +</a>
      </div>
    </div>
 
    </div> 
      <header class="jumbotron" *ngIf="nueva"> 
    
        <div *ngIf="nueva" class="card"> 
          <div class="card-body">
            <h2 *ngIf="editando">Editar Categoria: </h2>
            <h2 *ngIf="!editando">Nueva Categoria</h2>
            <form action="">
              <div  class="form-group">
            
                <input type="text" class="form-control"  [ngModel]="Nombrenueva"  [ngModelOptions]="{standalone: true}" id="nombre" placeholder="Nombre">
 
              </div>
   
              <button class="btn btn-lg btn-success" (click)="crear()">
                Salvar
              </button>
              <button class="btn btn-danger" Style="margin-left: 50px; " (click)="cancelar()">
                Cancelar
              </button>
             
              
            </form>
          </div>
          <alert></alert>
        </div>
        
      </header>
    
    
    <div *ngIf="!nueva" >
        
             <div  *ngFor="let ubi of  catepadres">
                    <div  class="row">  
                        <div class="col-md-8" >
                
                                    <button class="btn-group  btn-dark btn-block">

                                        <button class="btn btn-dark" (click)="editar(ubi.id)" style="text-align: left;">  {{ubi.nombre}} 
                                            
                                           <a  [routerLink]="['../dcategoria/',ubi.id]"   style="margin-left: 20px;">
                                                <i class="fas fa-eye">  {{toti(ubi.id)}}</i>
                                            </a>
                                      
                                        </button>
                                      
                                        <button class="btn btn-lg btn-dark"  (click)="mostrarhijos(ubi.id)" *ngIf="hijomostrado!=ubi.id && tienehijos(ubi.id)" style="width: 5%;" ><i class="fas fa-caret-square-down"></i></button> 
                                        <button class="btn btn-lg  btn-dark"  (click)="mostrarhijos(-1)" *ngIf="hijomostrado==ubi.id && tienehijos(ubi.id)" ><i class="fas fa-caret-square-up" style="width: 5%;"></i></button> 
                            
                                    </button> 
                                  
                                     
                        </div>  
                                         
                        <div class="col-md-3" >
                            <button *ngIf="verrol()" class="btn btn-block btn-success " style="text-align: right;" (click)="nuevahija(ubi.id)" style="text-align: center;">Añadir Subcategoria <i class="fas fa-plus-square"></i></button> 
                                   
                        </div>
                
                        <div class="col-md-1" >
                                    
                                   <button  class="btn btn btn-danger"  *ngIf="!tienehijos(ubi.id) &&  verrol()" (click)="borrar(ubi.id)" style="margin-left: 30px;"><i class="fas fa-trash-alt"></i></button>
                                   <app-ponerweb [idp]="ubi.id" [tipo]="'categoria'"></app-ponerweb>
                        </div> 
                       
                    </div>
                         
                    <div *ngIf="hijomostrado==ubi.id"><app-categoriahijo [idp]="ubi.id" [nivel]="0" (notify)='recibirhijo($event)' ></app-categoriahijo></div>
            </div>
                     
    </div>
         

      
   
       
 
           
         
    
</div>  