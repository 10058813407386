import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../_services/data.service';
import {RoundProgressModule} from 'angular-svg-round-progressbar'; 
import { timer } from 'rxjs';
import { Ubicacion } from "../models/ubicacion";
import { Ubitotal} from "../models/ubitotales";
import { NgbDateStruct, NgbCalendar, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';

import { TokenStorageService } from '../_services/token-storage.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertService } from '../alert.service';
import { Stream } from 'stream';
import { JsonPipe } from '@angular/common';
import { UserService } from '../_services/user.service';
import { RecursiveAstVisitor, ThrowStmt } from '@angular/compiler';


@Component({
  selector: 'app-crearavisos',
  templateUrl: './crearavisos.component.html',
  styleUrls: ['./crearavisos.component.css']
})
export class CrearavisosComponent implements OnInit {

  editando:boolean;
  tituloeditado:string;
  producto:any;
  articuloseleccionados:any[];
  inicio:number;
  colorb:string;
  colora:string;
  portie:boolean;
  porubi:boolean;
  currentUser : any;
  ubicaciones:any[];
  ubicacionestodas:any[];
  tiendas:any[];
  ubi_tiendas:any[];
  todotienda:boolean;
  tselecianda:any;
  ubiselecionada:any;
  users:any[];
  userselecionado:any;
  unuser:boolean;
  puntual:boolean;
  model: NgbDateStruct;
  date: {year: number, month: number };
  hora:any;
  dias:any[];
  Semanal:boolean;
  Mensual:boolean;
  Diario:boolean;
  provselecionado:any;
  proveedores:any[];
  soloartas:boolean;
  
  @ViewChild('dp') dp: NgbDatepicker;

  constructor(private dataservice :DataService,private token: TokenStorageService,private alertService: AlertService,private userService: UserService,private calendar: NgbCalendar) { }

  ngOnInit(): void {

    this.Mensual=true;
    this.dias=[]
    this.unuser=false;
    this.puntual=false;
    this.todotienda=false;
    this.ubi_tiendas=[];
    this.tiendas=[];
    this.proveedores=[]
    this.ubicacionestodas=[];
    this.inicio=0;
    this.articuloseleccionados=[];
    this.tselecianda=null;  
    this.currentUser = this.token.getUser();
    if(this.verrol())this.llenarusers();
    this.llenarhubicaciones();
    this.llenarproveedores();

  }

llenarproveedores(){

  this.dataservice.sacratodosprov("-1").subscribe(res => {

   
    
  
    this.proveedores=res;
 

  

  },
  err => console.log(err)
);



}

mesmal(mes:string){

 
  let todook=true;




if(mes.match("^[0-9,]+$")){
  

let vector=mes.split(",")

for (var i=0; i<vector.length; i++){
console.log(vector[i])

if(vector[i].match("^[0-9]+$")){
 
 if(parseInt(vector[i])>30 || parseInt(vector[i])<1) todook=false;

}else{

  todook=false;
}


}

}else{

  todook=false;
}

return todook;

}

checj(){


  let error=false;
  let mes=",,";
  if(!this.puntual && this.Mensual){

    mes=(<HTMLInputElement>document.getElementById('mesapasar')).value;
    if(mes[mes.length-1]==',')mes=mes.substring(0,mes.length-1)
   

  }

if(this.puntual){

  if(this.model==undefined || this.hora==undefined ){

    error=true;
 
   }

}else{

 

    if(this.hora==undefined ){

      error=true;
   
     }


  if(this.Semanal){

    if(this.dias.length==0 ){

    error=true;
 
   }

  }
  if(this.Mensual){

   if(!this.mesmal(mes)){

      error=true;
   
    }
  }

}

  

  if(error){

    this.alertService.error("Debes selecionar una fecha y hora validas para continuar");

  }else{

   
    let tipo;
    let periodo;
    let periodo2="";
    let user;
    let userhace=this.currentUser.id;
    let hora=""+this.hora;
    let texto=(<HTMLInputElement>document.getElementById('mensaje')).value;
    let fecha;  
    let tienda;

    console.log("hora: "+this.hora+" --"+this.hora.substring(0,2)+"--"+this.hora.substring(3,6) )

    if(this.unuser){

      tipo="AvUser";
      user=this.userselecionado;
   
     }else{
  
        tipo="AvTienda";
        tienda=this.tselecianda.id;
     }
  
    if(this.puntual){

        periodo="Puntual"

         fecha=new Date();
         fecha.setDate(this.model.day); // Cambia a 15/01/2018 15:30:10.999 (Devuelve 1516030210999)
         fecha.setMonth(this.model.month-1); // Cambia a 15/02/2018 15:30:10.999 (Devuelve 1518708610999)
         fecha.setFullYear(this.model.year); // Cambia a 15/02/2020 15:30:10.999 (Devuelve 1581780610999)
         fecha.setHours(this.hora.substring(0,2)); // Cambia a 15/02/2020 21:30:10.999 (Devuelve 1581802210999)
         fecha.setMinutes(parseInt(this.hora.substring(3,6))); // Cambia a 15/02/2020 21:00:10.999 (Devuelve 1581800410999)
         fecha.setSeconds(0); // Cambia a 15/02/2020 21:00:03.999 (Devuelve 1581800403999)
        
        console.log("FEcha" +fecha)

    }else{
    
     
    
        if(this.Diario ){

          periodo="Diario"
         }
    
    
      if(this.Semanal){
    
        periodo="Semanal"
         periodo2=JSON.stringify(this.dias);
       }
    
      
      if(this.Mensual){
    
        periodo="Mensual"
        periodo2=mes;
        
      }
    
    }



let avisor={

  tipo:tipo,
  fecha:fecha,
  usuario:userhace,
  usuario_destino:user,
  estado:"activo",
  periodo:periodo,
  periodo2:periodo2,
  hora:hora,
  texto:texto,
  tienda:tienda
}

console.log("CREANDO"+JSON.stringify(avisor))
this.dataservice.crearaviso(avisor).subscribe(res => {

    
  this.alertService.success("aviso creado");
  this.cancelarart()
  },
  err => console.log(err)

  );


  }




}

estadia(dia:string){
  
if(this.Semanal){

  const i = this.dias.findIndex( fruta => fruta === dia );


  if(i!=-1){
   
    return true 

  }else{

    return false;
  }
}else return false;


}
ponedia(dia:string){

this.dias.push(dia);
console.log("ss"+this.dias)
}
quitadia(dia:string){

  const i = this.dias.findIndex( fruta => fruta === dia );


  if(i!=-1){
   
    this.dias.splice(i,1);

  }
  console.log("ss"+this.dias)
}

subirprove(){

let error=false;
let totaleuros=(<HTMLInputElement>document.getElementById('euros')).value;
let tipo="";
let proveedors;
let user=this.currentUser.id

if(this.provselecionado==null || this.provselecionado==undefined )error=true;

if(totaleuros==null || totaleuros==undefined || totaleuros=="")error=true;

  if(error){

    this.alertService.error("Debe rellenar correctamente para continuar");
  
  }else{

    proveedors=this.provselecionado;


    tipo="PedidoProvedor"
 
    let avisor={

      tipo:tipo,
      user:user,
      usuario:proveedors,
      estado:"activo",
      texto:totaleuros
     


    }

    this.dataservice.crearaviso(avisor).subscribe(res => {

    
    
      this.cancelarart()
      },
      err => console.log(err)
  
      );
  


  }
  

}


estilo(boton: number){

  let stilo="grey"

      if(this.Semanal && boton==1)stilo="green"
    
      if(this.Mensual && boton==2)stilo="green"
    

      if(this.Diario  && boton==3)stilo="green"

      return stilo;
  
}
  selectToday() {
    this.model = this.calendar.getToday();
  }

  setCurrent() {
    //Current Date
    this.dp.navigateTo()
  }
  setDate() {
    //Set specific date
    this.dp.navigateTo({ year: 2013, month: 2 });
  }

  navigateEvent(event) {
    this.date = event.next;
  }


  llenarusers(){

    this.userService.sacarusers().subscribe(
      res => {
        this.users = res;

      },
      err => {

      }
    );


  }


  llenarhubicaciones(){

    this.dataservice.gettodasubi("0").subscribe(res => {
 
  
      this.ubicacionestodas=res;

    
        this.llenartiendas();

    

    },
    err => console.log(err)
  );

  
  }

  comprobarcorresto(){
      
    if(!this.verrol())this.porubi=true;
    if(this.portie== true || this.porubi== true )return true;

    return false
  }
  llenartiendas(){


    this.dataservice.sacartiendas().subscribe(
      res => {

        this.tiendas = res;
        this.unirtiendas();
 

      },
      err => {
       
      }
    );


  }

unirtiendas(){

//- 2 vectores: tiendas ubicaciones

//porcada tienda agregar todas ubicaciones que correspondan y agregar al ubi_tiendas
// luego a encontrar la del usuario yy ponerla a ubicaciones
for (var i=0; i<this.tiendas.length; i++){

  let ti=[];

  for (var j=0; j<this.ubicacionestodas.length; j++){
    if(this.ubicacionestodas[j].tienda==this.tiendas[i].id)ti.push(this.ubicacionestodas[j]);
  }

  if(this.tiendas[i].id==this.currentUser.tienda){
  this.tselecianda=this.tiendas[i];
    this.ubicaciones=ti;
  }
  this.ubi_tiendas.push(ti);
  console.log("ubi t"+ JSON.stringify(this.ubi_tiendas))
}
console.log("ubicacion de user"+ JSON.stringify(this.ubicaciones))
}


verrol(){


    if(this.currentUser.roles=="ROLE_ADMIN")return true;
    
    return false;
}

  editar(id:number){

    this.editando=true;
    switch (id) {
    
      case 1:
        this.tituloeditado="POR ARTICULO"
        
          break;
      case 3:
        this.tituloeditado="POR FECHA"
          break;
      case 4:
        this.tituloeditado="POR PROVEEDOR"
          break;
     
      default:
          console.log("No such day exists!");
          break;
  }
    
  }

  cargarart(){

   let buscando =(<HTMLInputElement>document.getElementById('barr')).value;

    this.dataservice.getproducto(buscando).subscribe(res => {
      

    this.producto=res
    this.inicio=1;
    (<HTMLInputElement>document.getElementById('barr')).value="";  
    },
    err => console.log(err)
  
  );
  
  }
  descartar(id:number){

    const i = this.articuloseleccionados.findIndex( fruta => fruta.id === id );


    if(i!=-1){
     
      this.articuloseleccionados.splice( i, 1 );

  
    }

  }
  subir(){

    let art=this.producto;

   if(this.noesta(art)) this.articuloseleccionados.push(art)
    this.producto=null;
    this.inicio=0;

  }

  noesta(pr:any){

    const i = this.articuloseleccionados.findIndex( fruta => fruta.id === pr.id );


  if(i!=-1){
   
    return false 

  }else{

    return true;
  }

  

  }
  cancelar(){

    this.producto=null;
    this.inicio=0;
  }
  subirart(){

    let error=false;
    let ubicaciont;
    let tipou;

    

 
  if(this.todotienda==true){

    ubicaciont=this.tselecianda.id
    tipou="MinStockTienda";

  }else{
    let ff =(<HTMLInputElement>document.getElementById('inputState')).value;
    ubicaciont=ff
    tipou="MinStock"
    if(ubicaciont==""){

      error=true;
    
    }
    
  }

if(error){

  this.alertService.error("Debes selecionar una ubicacion valida para continuar");

}else{

  let can=(<HTMLInputElement>document.getElementById('inputCantidad')).value;
  if(can=="")can="0";

  for (var i=0; i<this.articuloseleccionados.length; i++){

  let aviso={ 

    tipo:tipou,
    productoId:this.articuloseleccionados[i].id,
    cantidad: (<HTMLInputElement>document.getElementById('inputCantidad')).value, 
    ubicacion:ubicaciont,
    fecha:Date.now(),
    tienda:this.tselecianda.id,
    usuario:this.currentUser.id,
    estado:"activo"
   }
   
   
   this.dataservice.crearaviso(aviso).subscribe(res => {

    
    this.alertService.success("aviso creado");
    this.cancelarart()
    },
    err => console.log(err)

    );

  }

}

    
console.log("subiendo")
    


  }

  peroque(){

    console.log("ubis-"+JSON.stringify(this.ubiselecionada))
  }
  cancelarart(){

    this.producto=null;
    this.inicio=0;
    this.articuloseleccionados=[];
    this.editando=false;
    this.portie=false;
    this.porubi=false;


  }
  cambaraatienda(){

    this.todotienda=!this.todotienda;

  }

 verquepasa(){


let ninguna=true;



    for (var i=0; i<this.ubi_tiendas.length; i++){

        let unbi=(this.ubi_tiendas[i]);
 
        if(unbi!=[] && unbi!=null && unbi[0]!=undefined){

     
          
          if(unbi[0].tienda==this.tselecianda.id){

            ninguna=false;
            this.ubicaciones=unbi

          }

        }

    }
if(ninguna)this.ubicaciones=[];

    if(this.ubicaciones.length==0){
  
      this.todotienda=true;
      
    }else{this.todotienda=false}
  }
  
}
 