
<alert></alert>

<div *ngIf="!editando">

<button type="button" class="btn btn-danger btn-xs" (click)="volver()">Volver</button>
<div id="resultados" *ngIf="hayresultados" >

    <h3>UBICACION: {{nombreu}} , {{totalde}} Articulos</h3>
<div class="float-right" style="margin: 10px;">

    <a *ngIf="!rubi" class="btn btn-primary btn-lg "  (click)="rubi=true">Reubicar seleccion</a>
  
</div>  


    <div class="col-md-10 col-md-offset-1">
        <div class="card-body d-flex justify-content-between align-items-center">
				
            <ngb-pagination *ngIf="total>10" [collectionSize]="total" [maxSize]="10" [(page)]="page" (pageChange)="loadPage($event)"></ngb-pagination>
            <button *ngIf="!rubi"  type="button" class="btn btn-danger" (click)="vaciar()" style="margin-left: 15px;"><i class="fas fa-trash-alt"></i> BORRAR TODOS LOS ARTICULOS </button>
            <button class="btn btn-success btn-lg "  (click)="excel()" >Exportar a Excel</button>
              
          
            
        </div>
    </div> 
    <div *ngIf="rubi" style="margin-bottom: 20px;">  

        <div class="row">
            <div class="form-group">
   
                <label class="col-12" for="inputState">Nueva Ubicacion: <b>{{seleccionado.Nombre}}</b></label>
                <label  class="col-12" for="inputState">Articulos selecionados: <b>{{aseleccion.length}}</b></label>
        
                <select id="inputState" class="form-control"  [(ngModel)]="seleccionado" [ngModelOptions]="{standalone: true}" (change)="sacarhijos()">
            
                    <option [ngValue]="pa" *ngFor="let pa of padres">{{pa.Nombre}}</option>
            
                </select>
            </div>
        </div>
 <div class="row">
    <div class="form-group" *ngIf="seleccionado.id!=null && tienehijos">
    
        <label for="inputState">Sub-Ubicacion</label>
        <select id="inputState" class="form-control"  [(ngModel)]="seleccionado" [ngModelOptions]="{standalone: true}" (change)="mostradestini()">
    
            <option [ngValue]="pa2" *ngFor="let pa2 of hijos">{{pa2.Nombre}}</option>
    
        </select>
    </div>
 </div>
 <div class="float-right" style="margin-bottom: 15px;"> 
     <button type="button" class="btn btn-success btn-xs" style="margin-left:15px;" (click)="aceptare()">Aceptar</button>
    <button type="button" class="btn btn-danger btn-xs" style="margin-left:15px;" (click)="cancelare()">Cancelar</button>
</div>

   
</div>
    <div class="row text-center" *ngIf="verrol()==2 && !rubi">
				 
        <button type="button" class="btn btn-success" (click)="pcomics('1')" style="margin-right: 10px;"> Todo libros </button>
        <button type="button" class="btn btn-success" (click)="pcomics('5')" style="margin-right: 10px;"> Todo comics </button>
        <button type="button" class="btn btn-success" (click)="pcomics('7')" style="margin-right: 10px;"> Todo Juegos </button>
        <button type="button" class="btn btn-success" (click)="pcomics('6')" style="margin-right: 10px;"> Todo Merchan </button>
        <button type="button" class="btn btn-success" (click)="pcomics('4')" style="margin-right: 10px;"> Todo B.Artes </button>
        <button type="button" class="btn btn-success" (click)="pcomics('8')" style="margin-right: 10px;"> Todo Lego </button>
        <button type="button" class="btn btn-success" (click)="pcomics('9')" style="margin-right: 10px;"> Todo Manulaidades </button>

        <button type="button" class="btn  btn-danger" (click)="pcomics('0')"> quitar categorias </button>

    </div>

    <div class="row text-center" *ngIf="verrol()==2 && !rubi" style="margin-bottom: 15px; margin-top: 15px;">
				 

        <button class="col-md-5" type="button" class="btn btn-info" (click)="ponertodoweb(1)" style="margin-right: 20px;"> Poner todo a la web</button>
        <button class="col-md-5" type="button" class="btn btn-dark" (click)="ponertodoweb(0)">Quitar todo de la web </button>

    </div>

      <table class="table">
       
        <tr>
            <td></td>
            <td>Nombre</td> 
            <td>Barra</td>
            <td>Codigo</td>
            <td>Precio</td>
            <td *ngIf="!rubi">Cantidad</td>
            <td *ngIf="rubi">SELECIONAR</td>

        </tr> 
        <tr *ngFor="let re of productos">
            
          <td><a [routerLink]="['../../articulo/',re.producto.Barra]"><img src="{{re.producto.Barra | sacarfoto}}"  onerror="this.src='../../assets/nofoto.png'" width="70" height="100" /></a></td>

          <td><a [routerLink]="['../../articulo/',re.producto.Barra]"> {{re.producto.Nombre}}</a></td>
          <td>{{re.producto.Barra}}</td>
          <td>{{re.producto.Codigo}}</td> 
          <td>{{re.producto.Precio | sumariva:re.producto.Iva}}</td>
          <td *ngIf="!rubi"><button type="button" class="btn btn-info btn-xs" (click)="modifc(re.id)">{{re.cantidad}} <i class="fas fa-arrows-alt-v"></i></button></td>
          <td *ngIf="!rubi"><button type="button" style="margin-right: 10px;" class="btn btn-warning btn-xs" (click)="modif(re.id)"><i class="fas fa-compass"></i></button>
          <button type="button" class="btn btn-danger btn-xs" (click)="borrar(re.id)"><i class="fas fa-trash-alt"></i></button></td>
          <td><input *ngIf="rubi==true" type="checkbox" id="cbox2" [checked]="estaselect(re.id)" (change)="changed($event,re.id)" ></td>
          <div *ngIf="!rubi"><app-ponerweb [idp]="re.producto.id" [tipo]="'producto'"></app-ponerweb></div>
     
        </tr> 

       
        </table>
</div>
<div id="resultados" *ngIf="!hayresultados" >
    <h1>LA UBICACION ESTA VACIA</h1>
</div>
</div>

<div *ngIf="editando">

  <div *ngIf="mubiu">

    <h3 style="color:red">Modificar ubicacion</h3>

    <a class="btn btn-success btn-sm btn-block"  (click)="aceptar()" style="margin-top:10px">aceptar</a> 
    <label for="inputState">Antigua Ubicacion: {{nombreu}}  >>> </label>
    <div class="form-group col-md-4">
   
      <label for="inputState">Nueva Ubicacion</label>
      <select id="inputState" class="form-control"  [(ngModel)]="seleccionado" [ngModelOptions]="{standalone: true}" (change)="sacarhijos()">
  
          <option [ngValue]="pa" *ngFor="let pa of padres">{{pa.Nombre}}</option>
  
      </select>
  </div>
  <div class="form-group col-md-4" *ngIf="seleccionado.id!=null && tienehijos">
  
      <label for="inputState">Sub-Ubicacion</label>
      <select id="inputState" class="form-control"  [(ngModel)]="seleccionado" [ngModelOptions]="{standalone: true}" (change)="mostradestini()">
  
          <option [ngValue]="pa2" *ngFor="let pa2 of hijos">{{pa2.Nombre}}</option>
  
      </select>
  </div>
  <a class="btn btn-danger btn-sm btn-block"  (click)="cancelar()">cancelar</a>
  </div>
  <div *ngIf="!mubiu">


    <h3 style="color:red">Modificar cantidad</h3>

    <a class="btn btn-success btn-sm btn-block"  (click)="aceptar()" style="margin-top:10px">aceptar</a>

    <div class="mom">
      <div class="child">
          <div class="childinner">
              <button type="button" class="btn btn-danger" (click)="menos()">-</button>
              
          </div>
      </div>
      <div class="child">
          <div class="childinner">
              
              <input type="text" name="cantidad" [(ngModel)]="cantidad"  class="form-control" readonly>
          </div>
      </div>
      <div class="child">
          <div class="childinner">
              <button type="button" class="btn btn-success" (click)="mas()">+</button>
          </div>
      </div>
  </div>
    <a class="btn btn-danger btn-sm btn-block"  (click)="cancelar()">cancelar</a>

  </div> 
</div>
