<div *ngIf="cargando" style="margin:30px"><h1> ...GENERANDO INVENTARIO   <div class="spinner-border text-info" role="status">

</div></h1>
</div>
<div *ngIf="!cargando"  style="margin-top: 30px;">
<div class="text-center">
    <h2>INVENTARIO</h2>
    <button type="button" class="btn btn-info" (click)="sinli()"  style="margin: 10px;" >GENERAR SINLI</button> 
</div>
    
    <h3>Productos: {{numtotal | number}}</h3>
    <h3>Total Neto: {{costetotal | number}}€</h3>
 

    <div class="jumbotron" style="margin-top: 30px;" *ngFor="let re of tiendas">
        <h4><b>{{re.name}} - {{totaltienda(re.id)}}</b></h4> 

    <div *ngFor="let res of categorias">

    <div *ngIf="datosn(res,re.id)!='-'">

        &nbsp;&nbsp;<b>{{nombrecategoria(res)}}:</b><br>&nbsp;&nbsp;&nbsp;{{datosn(res,re.id)}}<br>

    </div>
            
    </div>
     


    </div>

</div>