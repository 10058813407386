import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../_services/data.service';
import {RoundProgressModule} from 'angular-svg-round-progressbar'; 
import { timer } from 'rxjs';
import { Ubicacion } from "../models/ubicacion";
import { Ubitotal} from "../models/ubitotales";

import { TokenStorageService } from '../_services/token-storage.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertService } from '../alert.service';
import { Stream } from 'stream';
@Component({
  selector: 'app-gestor-proveedores',
  templateUrl: './gestor-proveedores.component.html',
  styleUrls: ['./gestor-proveedores.component.css']
})
export class GestorProveedoresComponent implements OnInit {
 
  currentUser : any;
  nueva:boolean;
  editando:boolean; 
  filtro:string="";
  catepadres:any[];
  hijospadres:string[];
  productosdentro:string[];
  Nombrenueva:string;
  Padrenuevo:number;
  ubi_totales:any[]=[]; 
  seleccionado :number;
  hijomostrado:number;
  isChecked:boolean;
  isChecked2:boolean;
  categoriaacrear:any;
  dias:string;
  modificando:any;
  diass:string;
  page: number;
  previousPage: number;
  total:number;
constructor(private dataservice :DataService,private token: TokenStorageService,private alertService: AlertService){}

 ngOnInit(){
  this.currentUser = this.token.getUser();
  this.productosdentro=[];
  this.nueva=false;
  this.editando=false;
  this.diass=null;
  this.isChecked=false;
  this.page=1;
  this.previousPage =1;
  this.sacartodas();

}

loadPage(page: number) {
  if (page !== this.previousPage) {
    this.previousPage = page;
    let filtro=(<HTMLInputElement>document.getElementById('filt')).value;
    if(filtro.length>0 ){this.filtrar()}else{   this.sacartodas();}
 
  }
}
//*********************************************** */
//rellena la lista de ubicaciones
sacartodas(){ 


  this.dataservice.sacratodosprov(""+this.page).subscribe(res => {

     console.log("----- "+JSON.stringify(res));
     this.total=res.count;
   
     this.catepadres=res.rows;
   
     this.sacartotal()

   

   },
   err => console.log(err)
 );

}

checkValue(){

this.isChecked=!this.isChecked;


}
quitarerrorp(){
  let tempral=[]

  for(let i=0; i<  this.catepadres.length;i++){

   if(this.catepadres[i].nombre!=null)tempral.push(this.catepadres[i])
   
  }
  this.catepadres=tempral;
}
//*********************************************** */
//Filtra las ubicaciones segun creiterio filt
filtrar(){


  let filtro=(<HTMLInputElement>document.getElementById('filt')).value;



  if(filtro.length>0 ){
   

    this.dataservice.sacratodosprov("-1").subscribe(res => {

      let nuevo:any[]=[];
      let contador=0;
      let filtro=(<HTMLInputElement>document.getElementById('filt')).value;
      //this.total=res.count;
    
      this.catepadres=res;

      for(let i=0; i<  this.catepadres.length;i++){
 
      if(this.catepadres[i].nombre!=null){

        if(((this.catepadres[i].nombre).toLowerCase()).includes(filtro.toLowerCase())){

          nuevo.push(this.catepadres[i]);
          contador++;
        }
      }
     
      }
  
      this.total=contador;
      
      this.catepadres=nuevo;
      this.sacartotal()
  
    
  
    },
    err => console.log(err)
  );


  }else{

    this.sacartodas();
  }





}

//*********************************************** */
// saca el total de articulos en cada ubicacion 

async sacartotal(){ 

  await this.quitarerrorp();

  
  let numubu=this.catepadres.length;
  


  for(let i=0; i<numubu;i++){
  
   
    await this.dataservice.  totastockprove(this.catepadres[i].id).subscribe(res => {
      
  
      let d:Ubitotal={
        
        id:parseInt(this.catepadres[i].id),
        total:parseInt(res)
        
  
      };

      this.ubi_totales[i]=d;
  
      
    },
    err => console.log(err) 
    );
  
  
  }
  
  
}



nuevau(){

 this.nueva=true;


}


reset(){

  this.isChecked=false;
  this.nueva=false;
  this.editando=false;
  this.diass=null;
  this.modificando=null;
  this.sacartodas();
}

//crea una nueva ubicacion
crear(){
if(this.editando){


  let proforma="PROFORMA";

  if(this.isChecked)proforma=(<HTMLInputElement>document.getElementById('dias')).value;

  this.categoriaacrear={

    id:this.modificando.id,
    nombre:(<HTMLInputElement>document.getElementById('nombre')).value,
    tipo_pago:proforma,
    ubicacion:(<HTMLInputElement>document.getElementById('direccion')).value,
    codigo:parseInt((<HTMLInputElement>document.getElementById('codigo')).value),
    dias_envio:parseInt((<HTMLInputElement>document.getElementById('envio')).value),
  };

console.log("creando p "+JSON.stringify(this.categoriaacrear))


  this.dataservice.modificarprov(this.modificando.id,this.categoriaacrear).subscribe(res => {
  
 
    this.reset();

  },
  err => console.log(err)
);

}else {




  let proforma="PROFORMA";

  if(this.isChecked)proforma=(<HTMLInputElement>document.getElementById('dias')).value;

  this.categoriaacrear={

    nombre:(<HTMLInputElement>document.getElementById('nombre')).value,
    tipo_pago:proforma,
    ubicacion:(<HTMLInputElement>document.getElementById('direccion')).value,
    codigo:parseInt((<HTMLInputElement>document.getElementById('codigo')).value),
    dias_envio:parseInt((<HTMLInputElement>document.getElementById('envio')).value),
  };

console.log("creando p "+JSON.stringify(this.categoriaacrear))
  this.dataservice.crearprov(this.categoriaacrear).subscribe(res => {
  
 
    this.reset();

  },
  err => console.log(err)
);
}


}


mostrarhijos(id:number){

  this.hijomostrado=id;

}

//carga los datos de la ubicacion a editar
editar(id :string){
 if( this.verrol()){

  this.nueva=true;
  this.editando=true;
  


  this.dataservice.buscarprov(id).subscribe(res => {

    this.modificando=res;
    console.log("res "+JSON.stringify(this.modificando))

  if(this.modificando.tipo_pago=="PROFORMA"){
    this.isChecked=false;
    (<HTMLInputElement>document.getElementById('exampleRadios1')).checked=true;
    (<HTMLInputElement>document.getElementById('exampleRadios2')).checked=false;
  }else{
    this.isChecked=true;
    (<HTMLInputElement>document.getElementById('exampleRadios1')).checked =false;
    (<HTMLInputElement>document.getElementById('exampleRadios2')).checked =true;
    this.diass=this.modificando.tipo_pago


  }


   (<HTMLInputElement>document.getElementById('nombre')).value=this.modificando.nombre;
   (<HTMLInputElement>document.getElementById('direccion')).value=this.modificando.ubicacion;
   (<HTMLInputElement>document.getElementById('codigo')).value=this.modificando.codigo;
   (<HTMLInputElement>document.getElementById('envio')).value=this.modificando.dias_envio;

  },
  err => console.log(err)
);
 }
}

//cancela la edicion 

cancelar(){

  this.reset();

}
verrol(){


  if(this.currentUser.roles=="ROLE_ADMIN")return true;
  
  return false;
  }
toti(id:number){





  const i = this.ubi_totales.findIndex( fruta => fruta.id === id );


  if(i!=-1){
   
    return this.ubi_totales[i].total;

  }else{

    return 0;
  }


}
//actualiza una ubicacion ya existente
actualizar(){

  
}

borrar(id :string){

  if(confirm("tas seguro?")) {
  ///-comprobar si esta vacia


  this.dataservice.borrarcate(id).subscribe(res => {
  

    this.reset();

  },
  err => console.log(err)
);

 }
}
  
}
