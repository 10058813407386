import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

//const API_URL = 'http://145.239.197.238:30000/api/';
const API_URL = 'https://pruebasstockaitor.es:30000/api/';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  sacarusers():Observable<any>{
 
    return this.http.get(API_URL+`user/`);
  
  }
  sacarrol(id:String):Observable<any>{

    return this.http.get(API_URL+`userrol/${id}`);
  
  }
  modificarusert(id:String, nueva:  any): Observable<any>{
  
    return this.http.put(API_URL+`usert/${id}`,nueva);
  
  
  }
  modificaruserr(id:String, nueva:  any): Observable<any>{
  
    return this.http.put(API_URL+`userr/${id}`,nueva);
  
  
  }
  borraruser(id:String): Observable<any>{

    return this.http.delete(API_URL+`userr/${id}`);
  
  
  }



}
