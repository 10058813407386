<div *ngIf="cargando" style="margin:30px"><h1> ...GENERANDO INFORME     <div class="spinner-border text-info" role="status">

</div></h1>
</div>


<div *ngIf="!cargando">

    
    <div class="text-center">

        <div class="row mt-3 justify-content-center" >
    
            <h1>INFORME DE VENTAS</h1> 
           
        </div>
  

        <div class="container">
         
            <div class="row">

                <div class="col">

            <div class="form-group">
              <label for="fechaInicio">Desde:</label>
              <input type="date" class="form-control" id="fechaInicio" [(ngModel)]="fechaInicio" >
            </div>
        </div>
            <div class="col">
            <div class="form-group">
              <label for="fechaFin">Hasta:</label>
              <input type="date" class="form-control" id="fechaFin" [(ngModel)]="fechaFin">
            </div>
            </div>
            <div class="col">
            <button class="btn btn-primary" (click)="sacarventas()">Consultar</button>

           </div>
        </div>

            <div *ngIf="mostrados.length>0">



          <h3>Filtrar por tienda</h3>
          <div class="row">
            <ng-container  *ngFor="let i of totalestiendasM" >
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">

                    <button style="background-color: #282e69; width: 100%; display: block;" (click)="filtrartienda(i.tienda)" class="btn btn-primary"><b>{{ nombretienda(i.tienda) }}</b><br> {{i.totalVentas}}€ <br>{{i.totalProductos}} Unds</button>
                </div>

          </ng-container>
          </div>
      
    
          <h3>Filtrar por categoria</h3>
          <div class="row">
            <ng-container *ngFor="let i of categoriaquehayM">

                <div  class="col-12 col-sm-6 col-md-4 col-lg-3 mb-1"  *ngIf="mostrarcatessuper(i.Categoria,1)" >
                    <button (click)="filtrarcategoria(i.Categoria)" class="btn btn-success"><b>{{ nombrecategoria(i.Categoria) }}</b><br>{{ i.totalVentas }}€<br>{{ i.totalProductos }} Unds</button>
                 </div>

            </ng-container>
            <ng-container *ngFor="let i of categoriaquehayM">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-1"   *ngIf="mostrarcatessuper(i.Categoria,2)" >
                <button style="background-color: #428a40; width: 100%; display: block;" (click)="filtrarcategoria(i.Categoria)" class="btn">---<b> {{ nombrecategoria(i.Categoria) }}</b> ---<br>{{ i.totalVentas }}€<br>{{ i.totalProductos }} Unds</button>
             </div> 
            </ng-container>
          </div>
            
          <div class="row">
             <ng-container *ngFor="let i of categoriaquehayM">

                <div class="col-12 col-sm-4 col-md-3 col-lg-2 mb-1" *ngIf="mostrarcatessuper(i.Categoria,3)" >
                 <button  style="background-color: #a3ad43; width: 100%; display: block;  font-size:10px"  (click)="filtrarcategoria(i.Categoria)" class="btn btn-success"> <b>{{ nombrecategoria(i.Categoria) }}</b> <br> {{ i.totalVentas }}€ <br> {{ i.totalProductos }} Unds </button>
                </div>
            </ng-container>
            </div>


            <div class="container" style="background-color:gray">

                <div  class="row align-items-center justify-content-center h-100" >
                
                    <div style="margin-top: 30px;">

                        <input  id="filt"  list="datalist1"  [(ngModel)]="ids" type="search" (change)="selected(ids)" aria-label="Search" />
                        <datalist id="datalist1">
                        <option *ngFor="let re of proveedores" [value]="sacarproveedor(re.codigo)">
                    
                        </option>
                        </datalist>

                        <label for="cantidadDias" style="margin-left: 10px;"> Comprado desde hace </label>
                        <input type="number" id="cantidadDias" name="cantidadDias" [(ngModel)]="cantidadDias">
                        <label for="cantidadDias"> Dias</label>
                    
                    </div>
                </div>

                <div class="row align-items-center justify-content-center h-100" style="margin-top:10px" >
                    <button *ngIf="cod=='0'" class="btn btn-success" (click)="filtrarp()" style="margin-left: 10px;" >Filtrar porveedor</button>
                    
                    
                </div>
            </div>
               


        

      <div class="row" style="margin-top:20px">

        <button   class="btn btn-danger" (click)="quitarfiltros()" style="margin-left: 10px;" >QUITAR FILTROS</button>
        <button  *ngIf="!otro" type="button" class="btn btn-info" (click)="excel()"  style="margin-left: 10px;" >DESCARGAR EXCEL</button> 
      </div>
 
    
        <div class="container" style="margin-top:20px">

            <h2>TOTAL VENTAS: <b>{{ totalmostrado() }} €</b></h2>
            <button  type="button" class="btn btn-info" (click)="sacarstock()"  style="margin-top: 10px;" >Comprobar stock</button> 
        
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Ticket</th>
                  <th>Nombre</th>
                  <th>Código</th>
                  <th>Cantidad</th>
                  <th>Importe</th>
                  <th>Stock</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let venta of mostrados">
                  <td>{{ venta.Ticket }}</td>
                  <td>{{ venta.NombreP }}</td>
                  <td>{{ venta.CodigoProducto }}</td>
                  <td>{{ venta.cantidad }}</td>
                  <td>{{ venta.Precio }}</td>
                  <td>{{ venta.stock }}</td>
                </tr>
              </tbody>
             
            </table>
          </div>
    
        </div>
    </div>

   
    

    </div>

</div>
